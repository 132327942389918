import { useEffect, useRef, useState } from "react"
import { ChannelStatus } from "../../../types"
import Editor from "@monaco-editor/react";
import { XMLParser } from 'fast-xml-parser'

export default function DataViewer ({
    visible,
    onClose,
    data
} : {
    visible: boolean,
    data: ChannelStatus | undefined,
    onClose: () => void
}) {

    const scrollWrapper = useRef<any>()
    const responseWrapper = useRef<any>()
    const jsonWrapper = useRef<any>()
    const [query, setQuery] = useState<string>('')
    const [parsedText, setParsedText] = useState<string>('{}')

    useEffect(() => {
        getData()
    }, [ data ])

    async function getData () {
        if (!data) return '{}'
        console.log(new XMLParser().parse(data.channelStatusResponse), data.channelStatusResponse)
        if (data.accomodationApi.apiChannel.apiChannelType === 'JSON') {
            setParsedText(getParsedResponse(data.channelStatusResponse))
        } else {
            try {
                setParsedText(JSON.stringify(new XMLParser({
                    ignoreAttributes: false
                }).parse(data.channelStatusResponse), null, 4))
            } catch (e) {
                setParsedText('{}')
            }
        }
    }

    function hilightText () {
        if (!responseWrapper.current) return
        const regex = new RegExp(query, 'gi')
        let content = responseWrapper.current.innerHTML
        content = content.replace(/(<mark class="highlight">|<\/mark>)/gim, '')
        const newContent = content.replace(regex, '<mark class="highlight">$&</mark>')
        responseWrapper.current.innerHTML = newContent

        const scrollTo = responseWrapper.current.getElementsByClassName('highlight')
        if (scrollTo && scrollTo.length > 0) {
            if (scrollWrapper && scrollWrapper.current) {
                console.log(scrollTo[0])
                console.log(scrollTo[0].offsetTop)
                scrollWrapper.current.scrollTop = scrollTo[0].offsetTop
            }
        }
    }

    useEffect(() => {
        if (query && query.length > 0) {
            hilightText()
        }
    }, [
        query
    ])

    function getParsedResponse (data: any) {
        try {
            return JSON.stringify(JSON.parse(data), null, 2)
        } catch (e) {
            return "Application Error"
        }
    }

    return (
        <div ref={scrollWrapper} className="flex-1 flex h-full overflow-auto">
            <div className="flex-1">
                <div className="h-full relative z-5 flex-1 bg-gray-800">
                    <Editor
                        options={{
                            readOnly: true
                        }}
                        height="100%"
                        width={'100%'}
                        defaultLanguage="json"
                        onChange={(value, event) => {
                        }}
                        value={parsedText}
                    />
                </div>
            </div>
        </div>
    )
}