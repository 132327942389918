import { useEffect, useState } from "react";
import { Country } from "../../../types";
import SelectInput from "../../form/SelectInput";
import { useAppSelector } from "../../../hooks/redux";
import { OptionProps, components } from 'react-select';

export default function CountrySelector ({
    value,
    initialCountryId,
    onSelect
} : {
    initialCountryId?: number,
    value?: Country,
    onSelect: (item: Country) => void
}) {

    const { countries, loading } = useAppSelector(state => state.session)
    
    const [country, setCountry] = useState<any>()

    useEffect(() => {
        if (country === undefined && initialCountryId) {
            const foundCountry = countries.find(c => c.idCountry === initialCountryId)
            setCountry({
                value: initialCountryId,
                flag: foundCountry?.flag,
                label: foundCountry?.countryName
            })
        }
    }, [ countries ])

    useEffect(() => {
        if (value) {
            setCountry({
                label: value.countryName,
                code: value.countryAlpha2,
                value: value.idCountry
            })
        }
    }, [ value ])

    return (
        <SelectInput
            customStyles={{
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
            }}
            value={country}
            options={countries ? countries.map(c => {
                return {
                    label: `${c.flag ? c.flag : ''} ${c.countryName}`,
                    flag: c.flag,
                    value: c.idCountry
                }
            }) : []}
            onChange={(item: any) => {
                setCountry(item)
                if (countries) {
                    const country = countries.find(c => c.idCountry === item.value)
                    if (country) onSelect(country)
                }
            }}
            isLoading={loading.countries} />
    )

}
