import { useQuery } from "react-query"
import { getSettings, updateSettingsMedia } from "../../shared/queries"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../hooks/redux"
import FormField from "../form/FormField"
import TextInput from "../form/TextInput"
import dayjs from "dayjs"
import Checkbox from "../form/Checkbox"
import { classNames, formatCurrency } from "../../shared/utils"
import { QuoteOption } from "../../types"
import Modal from "../common/Modal"
import ModalCard from "../common/ModalCard"
import ModalActions from "../common/ModalActions"
import Uploader from "../common/Uploader"

export default function QuotePreview ({
  currentHotel,
  showNote,
} : {
  currentHotel: any,
  showNote: boolean
}) {

  const { currentHotelObject } = useAppSelector(state => state.hotels)
  const { quote } = useAppSelector(state => state.offerSlice)

  const [currentOption, setCurrentOption] = useState()
  const [fileType, setFileType] = useState<'logo' | 'cover'>('logo')
  const [uploadFile, setUploadFile] = useState(false)

  const {
    data: accomodationSettings,
    refetch: refetchSettings
  } = useQuery([currentHotel, 'accomodationSettings'], () => getSettings(currentHotel))

  function getCurrentOption () {
    return quote.options.find(o => o.idQuoteOption === currentOption)
  }

  useEffect(() => {
    if (quote.options && quote.options.length > 0) {
      setCurrentOption(quote.options[0].idQuoteOption)
    }
  }, [
    quote.options
  ])

  if (!currentHotelObject) return <></>

  return (
    <div className="relative mx-auto bg-white overflow-hidden w-full max-w-xl shadow-xl rounded-md">
      <div className="bg-white flex items-center space-x-4 p-3">
        <div className="flex space-x-2">
          <div className="w-3 h-3 bg-gray-100 rounded-full"></div>
          <div className="w-3 h-3 bg-gray-100 rounded-full"></div>
          <div className="w-3 h-3 bg-gray-100 rounded-full"></div>
        </div>
        <div className="flex-1 flex justify-center text-gray-500">
          <div className="bg-gray-100 rounded-full px-2 py-0.5 w-1/2 flex space-x-2 items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3 h-3">
              <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" />
            </svg>
            <div className="text-sm font-medium">offerta.besafesuite.com</div>
          </div>
        </div>
      </div>

      <Modal visible={uploadFile}>
        <ModalCard
          renderActions={() => <ModalActions
            onClose={() => setUploadFile(false)}
            onSave={() => setUploadFile(false)}
          />}
          title={'Upload ' + fileType}>
          <div className="p-4">
            <Uploader
              fileType={fileType}
              type={'dashboard'}
              folderKey={'quote-assets'}
              fileKey={fileType}
              onUpload={async (data: any) => {
                await updateSettingsMedia(currentHotel, fileType, data.data.uploadURL)
                refetchSettings()
                setUploadFile(false)
              }}
            />
          </div>
        </ModalCard>
      </Modal>

      <div className={'relative bg-cover bg-center h-56'} style={{
        backgroundImage: `url(${accomodationSettings ? accomodationSettings.cover : ''})`
      }}>
        <div className="absolute bottom-2 right-2">
          <button
            onClick={() => {
              setFileType('cover')
              setUploadFile(true)
            }}
            className={'flex items-center space-x-2 text-xs text-orange-600 rounded-md bg-white shadow px-2 py-1 font-bold'}>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M1 5.25A2.25 2.25 0 013.25 3h13.5A2.25 2.25 0 0119 5.25v9.5A2.25 2.25 0 0116.75 17H3.25A2.25 2.25 0 011 14.75v-9.5zm1.5 5.81v3.69c0 .414.336.75.75.75h13.5a.75.75 0 00.75-.75v-2.69l-2.22-2.219a.75.75 0 00-1.06 0l-1.91 1.909.47.47a.75.75 0 11-1.06 1.06L6.53 8.091a.75.75 0 00-1.06 0l-2.97 2.97zM12 7a1 1 0 11-2 0 1 1 0 012 0z" clipRule="evenodd" />
              </svg>
            </div>
            <div>Cambia copertina</div>
          </button>
        </div>
        <div className="absolute shadow-lg -bottom-8 mx-auto left-0 right-0 bg-white px-6 py-4 rounded-lg w-52">
          <img
            className={'w-full'}
            src={accomodationSettings ? accomodationSettings.logo : ''}
          />
          <button
            onClick={() => {
              setFileType('logo')
              setUploadFile(true)
            }}
            className={'border mt-4 w-full justify-center flex items-center space-x-2 shadow-xl text-xs text-orange-600 rounded-md bg-white shadow px-2 py-1 font-bold'}>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M1 5.25A2.25 2.25 0 013.25 3h13.5A2.25 2.25 0 0119 5.25v9.5A2.25 2.25 0 0116.75 17H3.25A2.25 2.25 0 011 14.75v-9.5zm1.5 5.81v3.69c0 .414.336.75.75.75h13.5a.75.75 0 00.75-.75v-2.69l-2.22-2.219a.75.75 0 00-1.06 0l-1.91 1.909.47.47a.75.75 0 11-1.06 1.06L6.53 8.091a.75.75 0 00-1.06 0l-2.97 2.97zM12 7a1 1 0 11-2 0 1 1 0 012 0z" clipRule="evenodd" />
              </svg>
            </div>
            <div>Cambia logo</div>
          </button>
        </div>
      </div>

      <div className="pt-8 px-6 mt-8">
        <div className="text-lg text-gray-600 font-bold">
          Gentile {quote.customer?.firstName} {quote.customer?.lastName}
        </div>
        <div className={'text-gray-500 text-sm mt-1 leading-relaxed'}>
          {
            showNote
            ? quote.message
            : `di seguito la nostra migliore offerta per il suo soggiorno presso la nostra struttura ${currentHotelObject.accomodationName}. Speriamo di poter soddisfare le sue esigenze e speriamo di poterla accogliere presto!`
          }
        </div>
        <div className="mt-4 text-gray-800 font-medium">
          Il preventivo scade il {dayjs(quote.expiresAt?.toString(), 'YYYYMMDDHHmm').format('DD/MM/YY')} alle {dayjs(quote.expiresAt?.toString(), 'YYYYMMDDHHmm').format('HH:mm')}
        </div>
      </div>

      <div className={'px-6 border-t pt-6 mt-8'}>
        <div className="font-semibold text-lg text-gray-600 mb-2">
          Le nostre proposte
        </div>
        {
          (quote.options.filter(o => !o.toDelete).length > 0)
          ? <div className="text-gray-500">
            di seguito {quote.options.filter(o => !o.toDelete).length} proposte per le date richieste, può scegliere quella che preferisce e inviare
            i suoi dati per conferma.
          </div>
          : <div className="text-gray-500">
            di seguito la nostra proposta per le date richieste
          </div>
        }
      </div>

      {
        quote.options.filter(o => !o.toDelete).length > 0 &&
        <div className="flex space-x-2 px-6 mt-6">
          {
            quote.options.filter(o => !o.toDelete).map((option, index) => (
              <button onClick={() => setCurrentOption(option.idQuoteOption)} className={
                classNames(
                  'flex-1 rounded-md px-2 py-2 font-medium',
                  (currentOption === option.idQuoteOption) ? 'bg-orange-500 text-white' : 'border text-gray-500 hover:bg-orange-100'
                )
              } key={index}>
                Opzione {index + 1} per {formatCurrency(option.rooms.filter(r => !r.toDelete).reduce((prev, curr) => prev + curr.price, 0))}
              </button>
            ))
          }
        </div>
      }

      <OfferOptionPreview option={getCurrentOption()} />

      <div className={'px-6 py-8 border-t mt-8'}>
        <div className="text-lg text-gray-600 font-normal mb-4">
          I suoi dati per la conferma
        </div>

        <div className="flex flex-col space-y-4">
          <div className="flex space-x-4">
            <FormField className="flex-1" label={'Nome'}>
              <TextInput></TextInput>
            </FormField>

            <FormField className="flex-1" label={'Cognome'}>
              <TextInput></TextInput>
            </FormField>
          </div>

          <div className="flex space-x-4">
            <FormField className="flex-1" label={'Email'}>
              <TextInput></TextInput>
            </FormField>

            <FormField className="flex-1" label={'Telefono'}>
              <TextInput></TextInput>
            </FormField>
          </div>

          <FormField label={'Note'}>
            <TextInput type={"textarea"}></TextInput>
          </FormField>
        </div>

        <div className={'mt-2'}>
          <div className="button-shadow p-4 rounded-md">
            <div className="mb-4 flex items-center justify-between">
              <div className="lg text-gray-600 font-semibold flex items-center space-x-3">
                <img src="/assets/logo_rate_small.svg" className={'h-8'} />
                <div>Vuoi proteggere il tuo soggiorno?</div>
              </div>
              {
                (!quote.besafeIncluded)
                ? <div className={'bg-orange-600 text-white rounded-full px-2 py-1 text-sm font-semibold'}>
                  +{formatCurrency((parseFloat(quote.insuranceRate as any) + parseFloat(quote.insuranceMarkup as any)) * quote.options.map(o => o.rooms.map(r => r.price).flat()).flat().reduce((prev, curr) => prev + curr, 0) / 100)}
                </div>
                : <div className={'bg-orange-600 text-white rounded-full px-2 py-1 text-sm font-semibold'}>
                  inclusa nel prezzo
              </div>
              }
            </div>
            <div className="text-gray-500 text-sm leading-relaxed">
              La tariffa assicurata <a className={'text-orange-600 underline'} href="https://travel.besafesuite.com/insurances/besafe-plus" target={'_blank'}>BeSafe Rate</a> scelta da oltre 200.000 viaggiatori nel mondo
              ti garnatisce il rimborso per la tua prenotazione non cancellabile in caso di qualunque motivazione oggettivamente documentabile. La richiesta  di rimborso è
              completamente digitale con garanzia di esito in soli 7 giorni.
            </div>
            <div className="mt-4 grid grid-cols-2 text-orange-600 gap-2 flex flex-wrap flex-col lg:flex-row space-y-2 lg:space-y-0">
              <div className={'flex space-x-2'}>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </div>
                <div>
                  Assistenza sanitaria
                </div>
              </div>
              <div className={'flex space-x-2'}>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div>
                  Rimborso per cancellazione
                </div>
              </div>
            </div>
            {
              !quote.besafeIncluded &&
              <div className={'mt-4'}>
                <button className={'px-3 py-2 w-full justify-center text-sm rounded-lg flex items-center space-x-2 bg-gray-200 text-gray-800 font-medium mt-2'}>
                  <div>
                    { quote.besafeIncluded ? 'Inclusa nel prezzo!' : 'Conferma l\'upgrade a BeSafe Rate' }
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                  </div>
                </button>
              </div>
            }
          </div>
        </div>

        <div className={'mt-8 mb-8 flex flex-col space-y-2'}>
          <Checkbox checked={false} label={'Accetto i termini e le condizioni per la cancellazione'}></Checkbox>
          <Checkbox checked={false} label={'Accetto i termini e la politica sulla privacy'}></Checkbox>
        </div>

        <button className={'px-3 py-2 w-full justify-center text-sm rounded-lg flex items-center space-x-2 bg-orange-600 text-white font-medium mt-2'}>
          <div>
            Invia conferma
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  )

}

const OfferOptionPreview = ({ option } : { option?: QuoteOption }) => {

  const { quote } = useAppSelector(state => state.offerSlice)

  function getNights () {
    try {
      return dayjs(option?.departure.toString(), 'YYYYMMDD').diff(dayjs(option?.arrival.toString(), 'YYYYMMDD'), 'days')
    } catch (e) {
      return 0
    }
  }

  if (!option) return <></>

  return (
    <div className="px-6 mt-6 flex flex-col space-y-4">
      {
        option.rooms.map((room, roomIdx) => (
          <>
            <div className="flex items-center space-x-4" key={roomIdx}>
              <div>
                <div className="bg-cover bg-center h-48 w-48 rounded-md" style={{
                  backgroundImage: `url(${(room.room && room.room.images.length > 0) ? room.room.images[0].imageUrl : ''})`
                }}></div>
              </div>
              <div className="flex flex-col space-y-4">
                <div>
                  <div className="text-lg mb-2 text-gray-600 font-semibold">
                    {room.title}
                  </div>
                  <div>
                    { quote.refundable && <div className="text-green-600">cancellabile senza penali</div> }
                    { (!quote.refundable && quote.besafeIncluded) && <>
                      <div className="text-green-600 flex space-x-2 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                          <path fillRule="evenodd" d="M4.93 1.31a41.401 41.401 0 0110.14 0C16.194 1.45 17 2.414 17 3.517V18.25a.75.75 0 01-1.075.676l-2.8-1.344-2.8 1.344a.75.75 0 01-.65 0l-2.8-1.344-2.8 1.344A.75.75 0 013 18.25V3.517c0-1.103.806-2.068 1.93-2.207zm4.822 4.997a.75.75 0 10-1.004-1.114l-2.5 2.25a.75.75 0 000 1.114l2.5 2.25a.75.75 0 001.004-1.114L8.704 8.75h1.921a1.875 1.875 0 010 3.75.75.75 0 000 1.5 3.375 3.375 0 100-6.75h-1.92l1.047-.943z" clipRule="evenodd" />
                        </svg>
                        <div>assicurazione annullamento inclusa</div>
                      </div>
                      <div className="text-orange-600">non cancellabile</div>
                    </> }
                    { (!quote.refundable && !quote.besafeIncluded) && <>
                      <div className="text-orange-600">non cancellabile</div>
                    </> }
                  </div>
                </div>
                
                <div>
                  <div className="mb-2 items-center text-gray-600 flex space-x-2">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                        <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                        <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clipRule="evenodd" />
                      </svg>
                    </div>

                    <div>
                      dal {dayjs(option.arrival.toString(), 'YYYYMMDD').format('DD MMMM YY')} al {dayjs(option.departure.toString(), 'YYYYMMDD').format('DD MMMM YY')}
                    </div>
                  </div>

                  <div className="items-center text-gray-600 flex space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                      <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                    </svg>
                    <div>
                      {room.adults} adulti e {room.children} bambini
                    </div>
                  </div>
                </div>

                <div>
                  <div className="text-gray-800 font-bold text-xl">
                    {formatCurrency(room.price)}
                  </div>
                  <div className="text-gray-500">per {getNights()} notti</div>
                </div>
              </div>
            </div>
            { roomIdx < (option.rooms.filter(r => !r.toDelete).length - 1) && <div className="border-b"></div> }
          </>
        ))
      }
    </div>
  )

}