import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { associateUserToAccomodation, getQueryableResource, getUsersByAccomodation, removeUserFromAccomodation } from "../../../shared/queries";
import { RootState } from "../../../store";
import { RestCriteria, User } from "../../../types";
import Chip from "../../common/Chip";
import DeleteModal from "../../common/DeleteModal";
import Icon from "../../common/Icon";
import Modal from "../../common/Modal";
import ModalActions from "../../common/ModalActions";
import ModalCard from "../../common/ModalCard";
import BaseTable from "../../data/BaseTable";
import Button from "../../form/Button";
import FormField from "../../form/FormField";
import FormSectionTitle from "../../form/FormSectionTitle";
import TextInput from "../../form/TextInput";
import ProgressBar from "../../loading/ProgressBar";

export default function HotelUsers ({
    idAccomodation
} : {
    idAccomodation: string | number
}) {

    const { profile } = useSelector((state: RootState) => state.session)
    
    const history = useHistory()
    const [associate, setAssociate] = useState(false)
    const [searchEmail, setSearchEmail] = useState('')
    const [userToAssociate, setUserToAssociate] = useState<User | undefined>()
    const [userToRemove, setUserToRemove] = useState<number | undefined>()
    const [filters, setFilters] = useState<RestCriteria[]>([{
        field: 'email',
        searchOperation: 'CONTAINS',
        value: '',
        unmanaged: true
    }])

    const debouncedSearch = useDebounce(filters, 2000)
    
    const {
        data,
        isFetching,
        refetch
    } = useQuery(['accomodationUsers', idAccomodation], () => getUsersByAccomodation(idAccomodation.toString()))

    const {
        data: users,
        refetch: refetchUsers,
        isFetching: isFetchingUsers
    } = useQuery(['filteredUsers', debouncedSearch], () => getQueryableResource<User>('users', 0, 50, 'idUser', filters), {
        enabled: (debouncedSearch && debouncedSearch.length > 0 && debouncedSearch[0].value !== '' && debouncedSearch[0].value.length > 3)
    })

    const associateMutation = useMutation(async () => {
        if (userToAssociate) {
            return await associateUserToAccomodation(
                userToAssociate?.idUser,
                idAccomodation
            )
        }
        return Promise.resolve()
    }, {
        onSuccess: () => {
            toast.success('Utente associato')
            setAssociate(false)
            setUserToAssociate(undefined)
            refetch()
        },
        onError: () => {
            toast.error('Impossibile associare l\'utente')
        }
    })

    const removeUserMutation = useMutation(async () => {
        if (userToRemove) {
            return await removeUserFromAccomodation(
                userToRemove,
                idAccomodation
            )
        }
        return Promise.resolve()
    }, {
        onSuccess: () => {
            toast.success('Utente rimosso')
            setUserToRemove(undefined)
            refetch()
        },
        onError: () => {
            toast.error('Impossibile associare l\'utente')
        }
    })

    return (
        <Fragment>
            
            <FormSectionTitle
                borderless={true}
                title={'Utenti connessi'} />

            <BaseTable
                loading={isFetching}
                paginated={false}
                showFilters={true}
                showColumnSelector={true}
                identifierKey={'idUser'}
                onEdit={id => {
                    history.push('/app/users/' + id)
                }}
                renderButtons={(profile && profile.userAdmin) ? () => (
                    <Button
                        className={'border border-orange-500 shadow text-sm font-semibold rounded-md py-1 px-4'}
                        label={'Associate user'}
                        onClick={() => {
                            setAssociate(true)
                        }} />
                ) : () => (<div></div>)}
                columns={[
                    {
                        Header: 'Nome',
                        accessor: (row: any) => {
                            return row.userFirstName + ' ' + row.userLastName
                        }
                    },
                    {
                        Header: 'Email',
                        accessor: 'userEmail'
                    },
                    {
                        Header: 'Stato',
                        accessor: (row: any) => (
                            row.userActive ? 
                            <Chip className={'text-green-600 bg-green-100'}>
                                Attivo
                            </Chip>
                            :
                            <Chip className={'text-red-600 bg-red-100'}>
                                Disattivo
                            </Chip>
                        )
                    },
                    {
                        Header: 'Remove',
                        accessor: (row: any) => (
                            <button onClick={e => {
                                setUserToRemove(row.idUser)
                                e.preventDefault()
                                e.stopPropagation()
                            }} className="text-red-600">Rimuovi</button>
                        )
                    }
                ]}
                data={data} />


            <DeleteModal
                askConfirmation={true}
                message={`Sei sicuro di voler associare ${userToAssociate ? userToAssociate.userEmail : ''} a questa struttura?`}
                visible={(userToAssociate !== undefined)}
                saveLabel={'Associa'}
                onCancel={() => {
                    setUserToAssociate(undefined)
                }}
                onConfirm={() => {
                    associateMutation.mutate()
                }} />

            <DeleteModal
                askConfirmation={true}
                message={`Sei sicuro di voler rimuovere l'utente da questa struttura a questa struttura?`}
                visible={(userToRemove !== undefined)}
                saveLabel={'Rimuovi utente'}
                onCancel={() => {
                    setUserToRemove(undefined)
                }}
                onConfirm={() => {
                    removeUserMutation.mutate()
                }} />

            <Modal
                visible={associate}
                onClose={() => setAssociate(false)}>
                <ModalCard
                    className="w-full max-w-xl"
                    actionsLoading={false}
                    renderActions={() => (
                        <ModalActions
                            onClose={() => {
                                setAssociate(false)
                                setUserToAssociate(undefined)
                                setFilters([{
                                    field: 'email',
                                    searchOperation: 'CONTAINS',
                                    value: '',
                                    unmanaged: true
                                }])
                            }}
                            onSave={() => {}} />
                    )}
                    title={'Associa utente'}>
                    
                    <div className="p-4 flex flex-col space-y-4">
                        <FormField label={'Email utente da associare'}>
                            <TextInput
                                value={(filters && filters[0]) ? filters[0].value : ''}
                                onChange={val => {
                                    setFilters([{
                                        field: 'email',
                                        searchOperation: 'CONTAINS',
                                        value: val,
                                        unmanaged: true
                                    }])
                                }} />
                        </FormField>

                        <div
                            className="overflow-hidden border bg-white shadow rounded-md">
                            {(!isFetchingUsers && users && users.content) && users.content.map((user, index) => (
                                <button
                                    onClick={() => setUserToAssociate(user)}
                                    className={`${index !== 0 && 'border-t'} justify-between flex items-center hover:bg-orange-100 hover:text-orange-700 block w-full text-left p-4`}
                                    key={index}>
                                    <div className="flex-1 text-gray-700 font-medium text-ellipsis overflow-hidden">{user.userEmail}</div>
                                    <div className="flex-1 text-gray-600 text-right">
                                        <div className="whitespace-nowrap text-ellipsis overflow-hidden">
                                            {user.userFirstName || 'ND'} {user.userLastName || 'ND'}
                                        </div>
                                    </div>
                                </button>
                            ))}

                            {
                                isFetchingUsers &&
                                <div>
                                    <ProgressBar />
                                </div>
                            }

                            {
                                (!isFetchingUsers && filters && filters.length > 0 && filters[0].value === '') &&
                                <div
                                    className="p-4 text-gray-600 justify-center items-center flex space-x-2">
                                    <div>
                                        <Icon name={'document-search'} size={'20px'} />
                                    </div>
                                    <div>
                                        Inserisci una mail per visualizzare i risultati
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </ModalCard>
            </Modal>
        </Fragment>
    )

}