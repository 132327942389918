import { useTranslation } from "react-i18next";
import RoomList from "../components/quote/RoomList";

export default function QuoteSettingsRooms () {

  const { t } = useTranslation()

  return (
    <div>
      <div className="text-xl font-bold text-gray-600 mb-6">
        {t('quote.roomList')}
      </div>
      <RoomList onUpdate={() => {}} onSelect={() => {}} />
    </div>
  )

}