import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import { useState } from "react";
import { Fragment } from "react";
import { motion } from 'framer-motion'
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EntityUpdateDialog from "../components/common/EntityUpdateDialog";
import Icon from "../components/common/Icon";
import Modal from "../components/common/Modal";
import ModalActions from "../components/common/ModalActions";
import ModalCard from "../components/common/ModalCard";
import { PageWrapper } from "../components/common/PageWrapper";
import UserAvatar from "../components/common/UserAvatar";
import Checkbox from "../components/form/Checkbox";
import Link from "../components/navigation/Link";
import OnBoarding from "../components/OnBoarding";
import LanguageSelector from "../components/common/LanguageSelector";
import { request } from "../shared/api";
import { activateProduct, getAccomodations, getCompanyProducts, saveAccomodation, updateProfile } from "../shared/queries";
import { RootState } from "../store";
import { CompanyProduct, User } from "../types";
import SelectInput from "../components/form/SelectInput";
import { setCurrentHotel, setCurrentHotelObject } from "../store/slices/hotelsSlice";
import { useEffect } from "react";
import NewsBell from "../components/common/NewsBell";

const time = parseInt(dayjs().format('HH'))

export default function ProductsPage () {

    const {
        user,
        logout
    } = useAuth0()

    const dispatch = useDispatch()
    const { currentHotel, currentHotelObject } = useSelector((state: RootState) => state.hotels)
    const { profile } = useSelector((state: RootState) => state.session)

    const history = useHistory()
    const { t, i18n } = useTranslation()

    const [startOnboarding, setStartOnboarding] = useState(false)
    const [showUpdateProfile, setShowUpdateProfile] = useState(false)
    const [showRateContract, setShowRateContract] = useState(false)
    const [showPayContract, setShowPayContract] = useState(false)
    const [acceptedRateContract, setAcceptedRateContract] = useState(false)
    const [acceptedPayContract, setAcceptedPayContract] = useState(false)

    function getCompanyId () {
        if (currentHotelObject) {
            if (currentHotelObject.idCompany) return currentHotelObject.idCompany
            if (currentHotelObject.company) return currentHotelObject.company.idCompany
        }
        return 0
    }

    const {
        data: products,
        isFetching: fetchingProducts,
        refetch: refetchProducts
    } = useQuery(['companyProducts', currentHotel], () => getCompanyProducts(getCompanyId()), {
        enabled: (currentHotelObject !== undefined)
    })

    const mutation = useMutation((data: {
        data: CompanyProduct,
        type: 'pay' | 'rate'
    }) => activateProduct(data.data, data.type, i18n.language), {
        onSuccess: (data, variables) => {
            refetchProducts()
            if (variables.type === 'rate') history.push('/app/dashboard')
            if (variables.type === 'pay') document.location.href = 'https://pay.besafesuite.com/app/dashboard'
        }
    })

    const profileMutation = useMutation((data: User) => updateProfile(data), {
        onSuccess: () => {
            toast.success('Profilo aggiornato')
            setShowUpdateProfile(false)
        }
    })

    const {
        data,
        isFetching: isFetchingAccomodations,
        refetch: refetchAccomodations
    } = useQuery('accomodations', getAccomodations)

    useEffect(() => {
        if (data && data.length === 0 && profile && !profile.userPasswordReset) setStartOnboarding(true)
        if (data && data.length > 0) {
            dispatch(setCurrentHotel(data[0].idAccomodation))
            dispatch(setCurrentHotelObject(data[0]))
        }
    }, [ data, profile ])

    function isOnboardingPending () {
        return (data && data.length === 0)
    }

    async function handleLogout () {
        const data = await request({
            method: 'GET',
            path: '/API/authorizationServer/logout'
        }) as {
            logout_url: string
        }
        if (data.logout_url) {
            localStorage.removeItem('token')
            logout()
        }
    }

    function getSelectedAccomodation () {
        if (currentHotel && data) {
            const accomodation = data.find(a => a.idAccomodation === currentHotel)
            if (accomodation) {
                return {
                    label: accomodation.accomodationName,
                    value: accomodation.idAccomodation
                }
            }
        }
        return undefined
    }

    return (
        <div className="bg-white overflow-auto">
            <PageWrapper className={'p-4 h-screen mx-auto'}>

                <div className="flex justify-between my-6">
                    <div>
                        <img
                            className={'w-32 xl:w-52 inline-block'}
                            src="https://besafesuite.com/wp-content/themes/besafe/assets/images/besuite_logo.svg"
                            alt="Suite logo" />
                    </div>

                    <div className="flex items-center space-x-4">
                        <NewsBell />
                        <LanguageSelector mini={true} />
                    </div>
                </div>


                <motion.div initial={{ opacity: 0, top: -50 }} animate={{ top: 0, opacity: 1 }} className="relative mt-16">
                    <div className="text-5xl text-slate-700 font-semibold leading-12">
                        {time > 12 ? t('vary.goodevening') : t('vary.goodmorning')} <span className="text-sky-600">{ user ? user.given_name + ' ' + user.family_name : '' }</span>, <br/> {t('vary.welcomeBackInBeSafe')}
                    </div>
                </motion.div>

                <motion.div
                    transition={{ delay: 0.3 }}
                    animate={{ opacity: 1, top: 0 }}
                    initial={{ opacity: 0, top: 90 }}
                    className="relative flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mt-16">

                    <div className="flex-1">
                        {
                            (!currentHotel || fetchingProducts) ?
                            <Fragment>
                                <div className="w-42 rounded-xl overflow-hidden">
                                    <Skeleton width={'100%'} height={110} className={'rounded-xl'} />
                                </div>
                                <div className="w-42 rounded-xl overflow-hidden">
                                    <Skeleton width={'100%'} height={110} className={'rounded-xl'} />
                                </div>
                            </Fragment>
                            :
                            <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                                <motion.button
                                    onClick={() => {
                                        if (isOnboardingPending() || !currentHotel) return
                                        if (products && products.find(p => p.product.idProduct === 1)) {
                                            history.push('/app/dashboard')
                                        } else {
                                            setShowRateContract(true)
                                        }
                                    }}
                                    className={`relative w-full md:w-1/2 flex-col justify-between hover:bg-orange-100 hover:text-orange-600 transition-all flex items-center bg-white border border-slate-200 rounded-xl ${(isOnboardingPending()) && ' opacity-50'}`}>
                                    <div className={'w-full flex space-x-6 p-4'}>
                                        <img src="/logo_rate_small.svg" className={'h-8 xl:h-16'} alt="" />
                                        <div className={'text-left'}>
                                            <div className="text-lg text-gray-700 font-bold">
                                                BeSafe Rate
                                            </div>
                                            <div className={'hidden md:block text-gray-700'}>
                                                The insured rate
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-t font-medium px-4 py-2 w-full">
                                        {t('vary.goToDashboard')}
                                    </div>
                                </motion.button>

                                <motion.button
                                    onClick={() => {
                                        if (isOnboardingPending() || !currentHotel) return
                                        if (products && products.find(p => p.product.idProduct === 2)) {
                                            document.location.href = 'https://pay.besafesuite.com/app/dashboard'
                                        } else {
                                            setShowPayContract(true)
                                        }
                                    }}
                                    className={`relative w-full md:w-1/2 flex-col justify-between hover:bg-green-50 hover:text-green-600 transition-all flex items-center bg-white border border-slate-200 rounded-xl ${(isOnboardingPending()) && ' opacity-50'}`}>
                                    <div className={'w-full flex space-x-6 p-4'}>
                                        <img src="/logo_pay_small.svg" className={'h-8 xl:h-16'} alt="" />
                                        <div className={'text-left'}>
                                            <div className="text-lg text-gray-700 font-bold">
                                                BeSafe Pay
                                            </div>
                                            <div className={'hidden md:block text-gray-700'}>
                                                The Future Gateway
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-t font-medium px-4 py-2 w-full">
                                        {t('vary.goToDashboard')}
                                    </div>
                                </motion.button>
                            </div>
                        }

                        <div className="bg-sky-100 p-8 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 items-center rounded-md mt-8">
                            <div>
                                <img className="w-40" src="/assets/mediakit.svg" alt="BeSafe Media Kit" />
                            </div>
                            <div className="flex-1">
                                <div className="text-2xl font-semibold text-sky-800">
                                    {t('vary.mediaKitTitle')}
                                </div>
                                <div className="text-sky-800 mt-2">
                                    {t('vary.mediaKitText')}
                                </div>
                                <button onClick={() => window.open('https://www.notion.so/besafesuite/Welcome-Kit-Italiano-c754bb7a4ebc41f39f938acfa8971c27', '_blank')} className="border border-sky-200 hover:bg-sky-600 hover:text-white mt-4 bg-white rounded-md px-4 py-1 font-medium text-sky-800">
                                    {t('vary.goToMediaKit')}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="pb-6 md:pb-0 w-full md:w-96">
                        
                        {
                            user &&
                            <div
                                className={'border w-full rounded-xl overflow-hidden bg-white flex-col relative'}>
                                <div className="p-4 flex flex-col items-center">
                                    <div>
                                        <UserAvatar
                                            text={'text-3xl'}
                                            height={'h-20'}
                                            width={'w-20'}
                                            name={'Wanny'} />
                                    </div>
                                    <div className={'mt-2 text-xl text-center text-gray-700 font-semibold'}>
                                        {t('suite.welcome')} { user.given_name + ' ' + user.family_name }
                                    </div>
                                    <div className={'text-gray-600'}>
                                        {
                                            user.email
                                        }
                                    </div>
                                </div>

                                <div>
                                    {
                                        (isFetchingAccomodations && (!data || data.length <= 0)) &&
                                        <Skeleton width={'100%'} height={80} />
                                    }
                                </div>

                                {
                                    (data && data.length > 0) &&
                                    <button className={'cursor-pointer text-left mt-2 hover:bg-orange-100 border-t block w-full'}>
                                        <div className={'flex space-x-2 p-4 px-6 items-center'}>
                                            <div className={'text-gray-600'}>
                                                <Icon name={'office-building'} size={'25px'} />
                                            </div>

                                            <div className="flex-1">
                                                <SelectInput
                                                    value={getSelectedAccomodation()}
                                                    isLoading={isFetchingAccomodations}
                                                    options={data ? data.map(d => {
                                                        return {
                                                            label: d.accomodationName,
                                                            value: d.idAccomodation
                                                        }
                                                    }) : []}
                                                    placeholder={t('Seleziona struttura')}
                                                    styles={{
                                                        control: (provided: any) => ({
                                                            ...provided,
                                                            border: 0
                                                        })
                                                    }}
                                                    onChange={(item: any) => {
                                                        dispatch(setCurrentHotel(item.value))
                                                        const hotel = data?.find(h => h.idAccomodation === item.value)
                                                        if (hotel) dispatch(setCurrentHotelObject(hotel))
                                                    }} />
                                            </div>
                                        </div>
                                    </button>
                                }

                                {
                                    (!isFetchingAccomodations && data && data.length === 0) &&
                                    <button
                                        onClick={() => setStartOnboarding(true)}
                                        className={'w-full px-4 py-4 bg-orange-500 text-white font-semibold pulse'}>
                                        {t('suite.startOnboarding')}
                                    </button>
                                }
                            </div>
                        }

                        <div className={'mt-4'}>
                            <div
                                className="border rounded-xl overflow-hidden bg-white">
                                <button
                                    onClick={() => setShowUpdateProfile(true)}
                                    className={'text-left hover:bg-orange-100 p-4 block w-full'}>
                                    <div className={'font-medium text-gray-700 mb-1'}>
                                        {t('suite.changeDetails')}
                                    </div>
                                    <p className={'text-gray-600 text-sm'}>
                                        {t('suite.changeDetailsText')}
                                    </p>
                                </button>

                                {
                                    false &&
                                    <button className={'text-left hover:bg-orange-100 p-4 border-t block w-full'}>
                                        <div className={'font-medium text-gray-700 mb-1'}>Impostazioni privacy</div>
                                        <p className={'text-gray-600 text-sm'}>
                                            configura la tue preferenze sulla privacy.
                                        </p>
                                    </button>
                                }
                            </div>

                            <button
                                onClick={() => handleLogout()}
                                className={'mt-4 mb-12 md:mb-0 bg-slate-800 text-white hover:bg-slate-950 font-medium p-4 border-t block w-full rounded-md'}>
                                {t('general.logout')}
                            </button>
                        </div>

                    </div>

                </motion.div>

                <Modal
                    visible={showRateContract}>
                    <ModalCard
                        title={t('beSafeRateContract.title')}>
                        <div className="p-4 text-gray-600">
                            <div>
                                {t('beSafeRateContract.intro')}
                            </div>
                        </div>
                        <div className="text-gray-700 text-center mt-4 text-xl font-medium mb-4">
                            {t('beSafeRateContract.conditions')}
                        </div>
                        <div className={'p-4 bg-orange-100'}>
                            <div className="text-orange-600 py-6 flex mt-2 justify-center space-x-14">
                                <div className={'text-center'}>
                                    <div className="font-bold text-xl">
                                        {t('beSafeRateContract.noConstraint')}
                                    </div>
                                    <div className={'text-sm'}>{t('beSafeRateContract.noConstraintText')}</div>
                                </div>

                                <div className={'text-center'}>
                                    <div className="font-bold text-xl">
                                        {t('beSafeRateContract.noCost')}
                                    </div>
                                    <div className={'text-sm'}>
                                        {t('beSafeRateContract.noCostText')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={'px-4 mt-4 text-gray-600 mb-4'}>
                                {t('contract.ifAccept')}
                            </div>
                            <div className={'px-4'}>
                                <Link onClick={() => {
                                    window.open(t('beSafeRateContract.link'))
                                }}>
                                    {t('contract.downloadContract')}
                                </Link>
                            </div>
                        </div>
                        <div className={'pt-4 px-4 mt-6 mb-4'}>
                            <Checkbox
                                checked={acceptedRateContract}
                                onChange={() => setAcceptedRateContract(!acceptedRateContract)}
                                label={t('contract.iAccept')} />
                        </div>
                        <ModalActions
                            disableSave={!acceptedRateContract}
                            saveLabel={t('contract.confirm')}
                            closeLabel={t('contract.cancel')}
                            onClose={() => {
                                setShowRateContract(false)
                            }}
                            onSave={() => {
                                if (currentHotelObject) {
                                    let idCompany = currentHotelObject.idCompany
                                    if (idCompany === null || idCompany === undefined) {
                                        if (currentHotelObject.company) {
                                            idCompany = currentHotelObject.company.idCompany
                                        }
                                    }
                                    mutation.mutate({
                                        data: {
                                            activationDate: parseInt(dayjs().format('YYYYMMDD')),
                                            company: {
                                                idCompany: idCompany
                                            },
                                            product: {
                                                idProduct: 1
                                            }
                                        },
                                        type: 'rate'
                                    })
                                }
                                setShowRateContract(false)
                            }} />
                    </ModalCard>
                </Modal>


                <Modal
                    visible={showPayContract}>
                    <ModalCard
                        title={t('beSafePayContract.title')}>
                        <div className="p-4 text-gray-600">
                            {t('beSafePayContract.intro')}
                        </div>
                        <div className="text-gray-700 text-center mt-4 text-xl font-medium mb-4">
                            {t('beSafePayContract.conditions')}
                        </div>
                        <div>
                            <div className={'px-4 mt-4 text-gray-600 mb-4'}>
                                {t('contract.ifAccept')}
                            </div>
                            <div className={'px-4'}>
                                <Link onClick={() => {
                                    window.open(i18n.language === 'it' ? '/assets/contracts/pay-contract.pdf' : '/assets/contracts/pay-contract-en.pdf')
                                }}>{t('contract.downloadContract')}</Link>
                            </div>
                        </div>
                        <div className={'px-4 mt-6 mb-6'}>
                            <Checkbox
                                checked={acceptedPayContract}
                                onChange={() => setAcceptedPayContract(!acceptedPayContract)}
                                label={t('contract.iAccept')} />
                        </div>
                        <ModalActions
                            saveLabel={t('contract.confirm')}
                            closeLabel={t('contract.cancel')}
                            onClose={() => {
                                setShowPayContract(false)
                            }}
                            onSave={() => {
                                if (currentHotelObject) {
                                    let idCompany = currentHotelObject.idCompany
                                    if (idCompany === null || idCompany === undefined) {
                                        if (currentHotelObject.company) {
                                            idCompany = currentHotelObject.company.idCompany
                                        }
                                    }
                                    mutation.mutate({
                                        data: {
                                            activationDate: parseInt(dayjs().format('YYYYMMDD')),
                                            company: {
                                                idCompany: idCompany
                                            },
                                            product: {
                                                idProduct: 2
                                            }
                                        },
                                        type: 'pay'
                                    })
                                }
                                setShowPayContract(false)
                            }} />
                    </ModalCard>
                </Modal>

                <EntityUpdateDialog
                    title={t('suite.changeDetails')}
                    subTitle={t('suite.editProfileText')}
                    visible={showUpdateProfile}
                    closeLabel={t('general.cancel')}
                    saveLabel={t('general.confirm')}
                    onClose={() => setShowUpdateProfile(false)}
                    onSave={data => {
                        if (profile) {
                            profileMutation.mutate({
                                ...profile,
                                userFirstName: data['given_name'],
                                userLastName: data['family_name']
                            })
                        }
                    }}
                    fields={[
                        {
                            label: t('suite.name'),
                            field: 'given_name',
                            value: ''
                        },
                        {
                            label: t('suite.surname'),
                            field: 'family_name',
                            value: ''
                        }
                    ]}
                    data={user} />

                <OnBoarding
                    neutral={true}
                    onClose={() => setStartOnboarding(false)}
                    onDone={() => {
                        setStartOnboarding(false)
                        refetchAccomodations()
                    }}
                    visible={startOnboarding} />

                </PageWrapper>
        </div>
    )

}
