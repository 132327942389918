import { Auth0Provider } from "@auth0/auth0-react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import SignupPage from "./pages/SignupPage"
import SecureLayout from "./SecureLayout"
import GuestLayout from "./layouts/GuestLayout"

export default function AppRouter () {

  return (
    <Router>

      <Switch>
        <Redirect exact path={'/'} to={{
          search: window.location.search,
          pathname: '/app/suite'
        }} />

        <Route path={'/signup'}>
          <SignupPage />
        </Route>
        
        <Route path={'/guest-area/:code'}>
          <GuestLayout />
        </Route>

        <Auth0Provider
          useRefreshTokens={false}
          domain="besafegroup.eu.auth0.com"
          clientId="wEg6JPRyOGK5njkenQrF9OTeysU8tqnd"
          redirectUri={window.location.origin}
          audience="insurance"
          cacheLocation="localstorage"
          scope="offline_access openid profile read:current_user update:current_user_metadata"
        >
          <SecureLayout />
        </Auth0Provider>
      </Switch>

    </Router>
  )

}