import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { deleteBookingGuest } from "../../../shared/queries";
import { RootState } from "../../../store";
import { BookingGuest } from "../../../types";
import DeleteModal from "../../common/DeleteModal";
import BaseTable from "../../data/BaseTable";
import FormSectionTitle from "../../form/FormSectionTitle";
import Link from "../../navigation/Link";
import CreateGuest from "./CreateGuest";

export default function BookingGuestTable ({
    idBooking,
    canAddGuests = true,
    guests,
    onRefetch
} : {
    canAddGuests: boolean,
    onRefetch?: () => void,
    idBooking: string,
    guests: BookingGuest[]
}) {

    const { t } = useTranslation()

    const { profile } = useSelector((state: RootState) => state.session)

    const [create, setCreate] = useState<boolean>(false)
    const [currentEditGuest, setCurrentEditGuest] = useState<number | string>()
    const [currentDelete, setCurrentDelete] = useState<number | string>()

    const deleteMutation = useMutation((id: string) => deleteBookingGuest(id), {
        onSuccess: () => {
            setCurrentDelete(undefined)
            if (onRefetch) onRefetch()
            toast.success('Ospite eliminato')
        }
    })

    return (
        <Fragment>
            <FormSectionTitle
                dense={true}
                borderless={true}
                title={t('reservation.guestDetails')}
                subtitle={t('reservation.guestDetailsText')}
                edit={canAddGuests}
                onEdit={() => {
                    setCreate(true)
                }}
                editLabel={t('reservation.addGuest')} />

            <CreateGuest
                showFiscal={true}
                visible={(currentEditGuest !== undefined)}
                idGuest={currentEditGuest}
                onSave={() => {
                    onRefetch && onRefetch()
                    setCurrentEditGuest(undefined)
                }}
                onClose={() => setCurrentEditGuest(undefined)}
                idBooking={idBooking} />

            <DeleteModal
                title={t('reservation.deleteGuest')}
                askConfirmation={true}
                message={t('reservation.deleteGuestText')}
                visible={(currentDelete !== undefined)}
                onCancel={() => {
                    setCurrentDelete(undefined)
                }}
                isLoading={deleteMutation.isLoading}
                onConfirm={() => {
                    if (currentDelete) {
                        deleteMutation.mutate(currentDelete.toString())
                    }
            }} />

            <BaseTable
                showFilters={false}
                showColumnSelector={false}
                data={guests}
                filterable={false}
                paginated={false}
                identifierKey={'idBookingGuest'}
                columns={[
                    {
                        Header: t('reservation.guestName').toString(),
                        accessor: (row: any) => {
                            return row.guestFirstName + ' ' + row.guestLastName
                        }
                    },
                    {
                        Header: 'Email',
                        accessor: (row: any) => {
                            return row.guestEmail
                        }
                    },
                    {
                        Header: t('Modifica').toString(),
                        accessor: (row: any) => {
                            if (!row.processedAt) {
                                return (
                                    <div className="flex space-x-2">
                                        <button onClick={() => setCurrentEditGuest(row.idBookingGuest)} className="text-orange-600 underline">{t('Modifica')}</button>
                                        <button onClick={() => setCurrentDelete(row.idBookingGuest)} className="text-red-600 underline">{t('Elimina')}</button>
                                    </div>
                                )
                            } else {
                                return <div className="flex space-x-2 items-center">
                                        {
                                            (profile && profile.userAdmin) &&
                                            <button onClick={() => setCurrentEditGuest(row.idBookingGuest)} className="text-orange-600 underline">{t('Modifica')}</button>
                                        }
                                        <div className={'text-orange-700'}>
                                            {t('reservation.unmutableGuest')}
                                        </div>
                                    </div>
                            }
                        }
                    }
                ]} />

            <CreateGuest
                idBooking={idBooking}
                visible={create}
                showFiscal={true}
                onSave={() => {
                    setCreate(false)
                    if (onRefetch) onRefetch()
                }}
                onClose={() => {
                    setCreate(false)
                }} />
        </Fragment>
    )

}
