import React, { useEffect, useMemo, useState } from 'react'
import { usePagination, useSortBy, useTable } from 'react-table'
import PageTitle from '../common/PageTitle'
import ProgressBar from '../loading/ProgressBar'
import TableColumnSelector from './TableColumnSelector'
import TableFilters from './TableFilters'
import { TableProps } from '../../types'
import Icon from '../common/Icon'
import Button from '../form/Button'
import { useTranslation } from 'react-i18next'

export default function BaseTable ({
  tableKey,
  onDelete,
  onEdit,
  className,
  pageCount = 0,
  currentPage = 0,
  columns = [],
  selectedRows = [],
  filters = [],
  loading,
  data = [],
  title,
  paginated = true,
  renderButtons,
  identifierKey,
  totalRows,
  filtersValue,
  filterable = true,
  displayRows = 15,
  hiddenColumns = [],
  renderQuickFilters,
  showFilters = true,
  showColumnSelector = true,
  renderNoDataButton,
  onFilterChange,
  onColumnChange,
  onRemoveFilters,
  onSorting
} : TableProps) {

  const { t } = useTranslation()

  const cols = useMemo(() => columns, [ selectedRows ])

  const [tablePage, setTablePage] = useState(0)
  const [internalFilters, setInternalFilters] = useState<any>(filters)

  useEffect(() => {
    if (onFilterChange) onFilterChange(undefined, tablePage)
  }, [ tablePage ])

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(internalFilters, tablePage)
    }
  }, [ internalFilters ])

  const tableInstance = useTable<any>(
    {
      columns: cols,
      data: data,
      manualPagination: true,
      manualSortBy: true,
      pageCount: pageCount,
      initialState: {
        hiddenColumns: hiddenColumns,
        pageSize: displayRows
      }
    },
    useSortBy,
    usePagination
  )

  function isNextPageAvailable () {
    if (pageCount === (currentPage + 1)) return false
    return true
  }

  function isPrevPageAvailable () {
    if (currentPage === 0) return false
    return true
  }

  useEffect(() => {
    if (onSorting) {
      onSorting(tableInstance.state.sortBy)
    }
  }, [
    tableInstance.state.sortBy
  ])

  /**
   * When the page changes we save the last position
   * to the localStorage so we can set it again
   * to the table if the user navigates back
   */
  useEffect(() => {
    if (tableKey) {
      const lastKey = localStorage.getItem('lastPaginationKey')
      if (lastKey !== tableKey) {
        // table changed, we need to reset everything
        setTablePage(0)
        localStorage.setItem('lastPaginationKey', tableKey)
        localStorage.setItem('lastPaginationPage', '0')
      } else {
        // same table, reset old page
        const lastPage = localStorage.getItem('lastPaginationPage')
        if (lastPage) {
          setTablePage(parseInt(lastPage))
        }
      }
    }
  }, [
    tableKey
  ])

  useEffect(() => {
    if (tableKey) {
      localStorage.setItem('lastPaginationKey', tableKey)
      localStorage.setItem('lastPaginationPage', tablePage.toString())
    }
  }, [
    tablePage
  ])

  return (
    <div className={className}>
      <div className={`${renderQuickFilters ? 'mb-4' : ''} flex justify-between items-center`}>
        <div className={'flex items-center space-x-4'}>
          {
            title &&
            <PageTitle
              title={title} />
          }

          {
            renderQuickFilters &&
            <div className={'hidden xl:block'}>
              { renderQuickFilters() }
            </div>
          }
        </div>
        {
          ((showFilters && filters && filters.length > 0) || renderButtons) &&
          <div className={'flex items-center space-x-2 justify-end'}>
            {
              (filters && filters.length > 0) &&
              <div className={'hidden lg:block'}>
                <TableFilters
                  filtersValue={filtersValue}
                  columns={filters}
                  onChange={filters => {
                    setInternalFilters(filters)
                  }} />
              </div>
            }
            {
              showColumnSelector &&
              <div className={'hidden xl:block'}>
                <TableColumnSelector
                  columns={tableInstance.allColumns}
                  onChange={columns => {
                    if (onColumnChange) onColumnChange(columns)
                  }} />
              </div>
            }
            {
              renderButtons &&
              <div>
                {renderButtons()}
              </div>
            }
          </div>
        }
      </div>
      <div className={'mt-3 overflow-auto bg-white card-shadow rounded relative'}>
        {
          loading && <ProgressBar className={'absolute top-0 left-0 w-full'}></ProgressBar>
        }
        <table className={'table-fixed w-full'} {...tableInstance.getTableProps()}>
          <thead>
              {
                tableInstance.headerGroups.map((group, index) => (
                  <tr key={index} className={'flex flex-wrap w-full md:table-row text-left uppercase text-xs text-gray-600'}>
                    {group.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={`w-full whitespace-nowrap ${column.width ? column.width : 'w-auto'} truncate font-medium border-b px-4 py-3 p-2`}>
                        {column.render('Header')}
                        {column.isSorted
                        ? column.isSortedDesc
                          ? <Icon className={'inline-block ml-1'} name={'chevron-down'} size={'12px'}></Icon>
                          : <Icon className={'inline-block ml-1'} name={'chevron-up'} size={'12px'}></Icon>
                        : ''}
                      </th>
                    ))}
                  </tr>
                ))
              }
          </thead>

          <tbody {...tableInstance.getTableBodyProps()}>
            {
              tableInstance.rows.map((row, index) => {
                tableInstance.prepareRow(row)
                return (
                  <tr
                    className={'border-b md:border-0 pb-4 md:pb-0 flex flex-wrap w-full md:table-row cursor-pointer text-gray-700 hover:bg-gray-50'}
                    {...row.getRowProps()}
                    onClick={() => {
                      if (onEdit) onEdit(row.original[identifierKey ? identifierKey : 'id'])
                    }}>
                    {
                      row.cells.map(cell => (
                        <td
                          className={`px-4 py-2 whitespace-nowrap w-full truncate text-sm ${index !== (data.length - 1) ? 'border-0 md:border-b' : ''} p-1`}
                          {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))
                    }
                  </tr>
                )
              })
            }

            {
              tableInstance.rows.length === 0 &&
              <tr>
                <td colSpan={tableInstance.visibleColumns.length} className={'w-full text-center'}>
                  <div className="text-center p-4">
                    <img src={'/assets/search.svg'} className={'inline-block h-32'} />
                    <div className="mt-6 font-bold text-lg">
                      {t('general.noResults')}
                    </div>
                    <div className="text-gray-600 mt-1">
                      {t('general.noResultsText')}
                    </div>

                    {
                      renderNoDataButton &&
                      renderNoDataButton()
                    }

                    {
                      filterable &&
                      <Button
                        className="text-orange-600 bg-orange-100"
                        label={t('general.removeFilters')}
                        onClick={() => {
                          if (onRemoveFilters) onRemoveFilters()
                        }} />
                    }

                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      {
        paginated &&
        <div className={'flex mt-6 items-center justify-between'}>

          <div className={'text-sm text-gray-600 font-medium'}>
            {t('general.page')} {tablePage + 1} {t('general.of')} {loading ? '...' : (tableInstance.pageOptions.length > 0) ? tableInstance.pageOptions.length : 1}
          </div>

          <div className={'flex'}>
            <button
              style={{
                opacity: isPrevPageAvailable() ? 1 : 0.5
              }}
              onClick={() => {
                if (!isPrevPageAvailable()) return
                tableInstance.previousPage()
                if (onFilterChange) {
                  setTablePage(tablePage - 1)
                }
              }}
              className={'focus:outline-none mr-2 bg-white-600 shadow border px-3 text-sm font-medium py-1 border-gray-300 rounded'}>{t('general.prev')}</button>
            <button
              style={{
                opacity: isNextPageAvailable() ? 1 : 0.5
              }}
              onClick={() => {
                if (!isNextPageAvailable()) return
                tableInstance.nextPage()
                if (onFilterChange) {
                  setTablePage(tablePage + 1)
                }
              }}
              className={'focus:outline-none bg-white-600 shadow border px-3 text-sm font-medium py-1 border-gray-300 rounded'}>{t('general.next')}</button>
          </div>

        </div>
      }
    </div>
  )

}
