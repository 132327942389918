import Logo from "../common/Logo";
import Icon from "../common/Icon";
import {useHistory, useLocation} from "react-router";
import GlobalSearch from "../GlobalSearch";
import HotelSelector from "./HotelSelector";
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";
import LanguageSelector from "../common/LanguageSelector";
import MobileMenu from "./MobileMenu";
import { useState } from "react";
import { RootState } from "../../store";
import { useQuery } from "react-query";
import { getPartner } from "../../shared/queries";

export const NavButton = ({ label, icon, active, news, onClick } : { label: string, icon: string, news?: boolean, active: boolean, onClick: () => void }) => (
  <div className={'px-1 relative'}>
    {
      news &&
      <div className="absolute right-0 top-0">
        <span className="relative flex h-3 w-3">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
      </div>
    }
    <button onClick={onClick} className={`text-sm flex items-center space-x-1 hover:bg-orange-100 hover:text-orange-700 font-medium text-gray-600 transition-all px-3 py-1 rounded-full ${active && 'bg-orange-100 text-orange-700'}`}>
      <Icon name={icon} size={'20px'} />
      <div>
        {label}
      </div>
    </button>
  </div>
)

export default function NavHeader () {

  const { t } = useTranslation()
  
  const { profile } = useSelector((state: RootState) => state.session)
  const currentHotelObject = useSelector<any>(state => state.hotels.currentHotelObject) as any

  const history = useHistory()
  const location = useLocation()

  const [mobileMenu, setMobileMenu] = useState(false)

  return (
    <div>
      <MobileMenu onNavigate={() => setMobileMenu(false)} visible={mobileMenu} />
      <div className={'border-b bg-white py-3 px-6 items-center justify-between flex'}>
        <div className={'flex items-center space-x-6'}>
          <div className="flex space-x-4 items-center">
            <Logo />
          </div>
          {
            (profile && (profile.userType === 'USER' || profile.userType === 'AGENCY' || profile.userType === 'TO' || profile.userType === 'HOTEL')) &&
            <div className="hidden md:flex items-center w-72 overflow-hidden rounded-md customshadow">
              <div className={'text-gray-600 px-2'}>
                <Icon name={'office-building'} size={'20px'} />
              </div>
              <div className="flex-1">
                <HotelSelector />
              </div>
            </div>
          }
        </div>

        {
          (profile && profile.userType !== 'PARTNER') &&
          <div className="hidden md:block">
            <nav className="flex navmenutour">
              {
                (profile && (profile.userType === 'USER' || profile.userType === 'AGENCY' || profile.userType === 'TO' || profile.userType === 'HOTEL') && currentHotelObject && currentHotelObject.customerType !== 'AGENCY') && <NavButton
                label={'Suite'}
                icon={'view-grid'}
                active={location && location.pathname === '/app/suite'}
                onClick={() => history.push('/app/suite')} /> 
              }
              <NavButton
                label={'Dashboard'}
                icon={'home'}
                active={location && location.pathname === '/app/dashboard'}
                onClick={() => history.push('/app/dashboard')} />
              {
                (profile && (profile.userType === 'USER' || profile.userType === 'AGENCY' || profile.userType === 'TO' || profile.userType === 'HOTEL')) &&
                <>
                  <NavButton
                    label={t('sidebar.reservations')}
                    icon={'book-open'}
                    active={location && location.pathname === '/app/reservations'}
                    onClick={() => history.push('/app/reservations')} />
                  {
                    false &&
                    <NavButton
                      label={'Community'}
                      icon={'chat'}
                      active={location && location.pathname === '/app/community'}
                      onClick={() => history.push('/app/community')} />
                  }
                  {
                    (false && currentHotelObject && currentHotelObject.opportunity) &&
                    <NavButton
                      label={t('Opportunità')}
                      news={true}
                      icon={'money'}
                      active={location && location.pathname === '/app/opportunities'}
                      onClick={() => history.push('/app/opportunities')} />
                  }
                  <div>
                    <NavButton
                      label={t('sidebar.quotes')}
                      icon={'paper-airplane'}
                      active={location && location.pathname === '/app/quotes'}
                      onClick={() => history.push('/app/quotes')} />
                  </div>
                  <div className="invoicestour">
                    <NavButton
                      label={t('sidebar.invoices')}
                      icon={'document-text'}
                      active={location && location.pathname === '/app/invoices'}
                      onClick={() => history.push('/app/invoices')} />
                  </div>
                  {
                    (currentHotelObject && currentHotelObject.customerType !== 'AGENCY') && <NavButton
                      label={'Settings'}
                      icon={'cog'}
                      active={location && location.pathname === '/app/settings'}
                      onClick={() => history.push('/app/settings')} />
                  }
                </>
              }
            </nav>
          </div>
        }

        <div className={'flex space-x-4 items-center'}>
          <div className="block md:hidden">
            <button onClick={() => setMobileMenu(true)} className="border border-gray-200 p-2 rounded-md">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
          </div>
          <div className="hidden md:block globalsearchtour">
            <GlobalSearch />
          </div>
          <div className="hidden md:block">
            <LanguageSelector mini={true} />
          </div>
        </div>
      </div>
    </div>
  )

}
