import { ReactNode } from "react";

export default function Text ({
    size = 'base',
    weight = 'normal',
    children,
    className
} : {
    weight?: 'thin' | 'extralight' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black',
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | '8xl' | '9xl',
    children: ReactNode,
    className?: string
}) { 

    return (
        <div
            className={`text-gray-700 text-${size} font-${weight} ${className}`}>
            {children}
        </div>
    )

}