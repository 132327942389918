import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCountries, getProfile } from "../../shared/queries";
import { Country, User } from "../../types";
import Flags from '../../shared/countyFlag.json'

type SessionState = {
    profile?: User,
    countries: Country[],
    loading: {
        countries: boolean,
        profile: boolean,
    }
}

export const fetchSessionProfile = createAsyncThunk(
    'session/fetchSessionProfile',
    async () => {
        return await getProfile()
    }
)

export const fetchCountries = createAsyncThunk(
    'session/fetchCountries',
    async () => {
        return await getCountries()
    }
)


const sessionSlice = createSlice({
    name: 'hotels',
    initialState: {
        loading: {
            profile: false,
            countries: false
        },
        profile: undefined,
        countries: []
    } as SessionState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchSessionProfile.pending, (state) => {
            state.loading.profile = true
        })
        builder.addCase(fetchSessionProfile.rejected, (state) => {
            state.loading.profile = false
        })
        builder.addCase(fetchSessionProfile.fulfilled, (state, action: PayloadAction<User>) => {
            state.profile = action.payload
            state.loading.profile = false
        })

        builder.addCase(fetchCountries.pending, (state) => {
            state.loading.countries = true
        })
        builder.addCase(fetchCountries.rejected, (state) => {
            state.loading.countries = false
        })
        builder.addCase(fetchCountries.fulfilled, (state, action: PayloadAction<Country[]>) => {
            state.countries = action.payload.map(c => {
                const flag = Flags.find(f => f.code.toLowerCase() === c.countryAlpha2.toLowerCase())
                if (flag) {
                    return { ...c, flag: flag.emoji }
                }
                return c
            })
            state.loading.countries = false
        })
    }
})

export const {} = sessionSlice.actions

export default sessionSlice.reducer