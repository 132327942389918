import { useTranslation } from "react-i18next"
import { classNames } from "../../shared/utils"
import { Route, useHistory, useRouteMatch } from "react-router-dom"
import QuoteSettingsRooms from "../../pages/QuoteSettingsRooms"
import QuoteSettingsInfo from "../../pages/QuoteSettingsInfo"
import QuoteSettingsAmenities from "../../pages/QuoteSettingsAmenities"
import QuoteSettingsPolicies from "../../pages/QuoteSettingsPolicies"
import QuoteSettingPayments from "../../pages/QuoteSettingsPayment"

const SettingsButton = ({ icon, label, onClick, active } : { icon: string, label: string, onClick: () => void, active: boolean }) => (
  <button
    onClick={onClick}
    className={
      classNames(
        'rounded-md px-2 py-1.5 hover:bg-gray-200 hover:text-gray-900 text-left items-center flex space-x-2',
        active ? 'text-gray-900 bg-gray-200' : 'text-gray-500'
      )
    }
  >
    <div className="material-symbols-outlined">
      {icon}
    </div>
    <div className="font-medium">
      {label}
    </div>
  </button>
)

export default function QuoteSettings () {
  
  let { path, url } = useRouteMatch()
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <div className="my-12 w-full h-auto max-w-4xl mx-auto flex space-x-12">
      <div className="w-52">
        <div className="font-bold text-lg mb-6 text-gray-700">
          {t('Configurazione')}
        </div>

        <div className="flex flex-col space-y-3">
          <SettingsButton active={history.location.pathname === '/app/quotes/settings/general'} icon="info" label={t('quote.accommodationInfo')} onClick={() => history.push('/app/quotes/settings/general')} />
          <SettingsButton active={history.location.pathname === '/app/quotes/settings/rooms'} icon="bed" label={t('quote.roomList')} onClick={() => history.push('/app/quotes/settings/rooms')} />
          <SettingsButton active={history.location.pathname === '/app/quotes/settings/amenities'} icon="room_service" label={t('quote.amenities')} onClick={() => history.push('/app/quotes/settings/amenities')} />
          <SettingsButton active={history.location.pathname === '/app/quotes/settings/policies'} icon="policy" label={t('quote.policies')} onClick={() => history.push('/app/quotes/settings/policies')} />
          {
            false && <SettingsButton active={history.location.pathname === '/app/quotes/settings/payments'} icon="payments" label={t('quote.payments')} onClick={() => history.push('/app/quotes/settings/payments')} />
          }
        </div>

      </div>
      <div className="flex-1">
        <div className="bg-white border border-gray-300 p-8 rounded-md">
          <Route exact path={`${path}/general`}>
            <QuoteSettingsInfo />
          </Route>
          <Route exact path={`${path}/rooms`}>
            <QuoteSettingsRooms />
          </Route>
          <Route exact path={`${path}/amenities`}>
            <QuoteSettingsAmenities />
          </Route>
          <Route exact path={`${path}/policies`}>
            <QuoteSettingsPolicies />
          </Route>
          <Route exact path={`${path}/payments`}>
            <QuoteSettingPayments />
          </Route>
        </div>
      </div>
    </div>
  )

}