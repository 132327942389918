import {useEffect, useState} from "react";
import { useMutation, useQuery } from "react-query";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";

import {getChannelList, saveApiChannel, saveApiChannelConfiguration} from "../shared/queries";
import { ApiChannel } from "../types";
import Icon from "../components/common/Icon";
import toast from "react-hot-toast";

const locale = {
  format: '',
  symbols: {
      colon: '',
      comma: '',
      semicolon: '',
      slash: '',
      backslash: '',
      brackets: {
          round: '',
          square: '',
          curly: '',
          angle: '',
      },
      period: '',
      quotes: {
          single: '',
          double: '',
          grave: '',
      },
      space: '',
      ampersand: '',
      asterisk: '',
      at: '',
      equals: '',
      hash: '',
      percent: '',
      plus: '',
      minus: '',
      dash: '',
      hyphen: '',
      tilde: '',
      underscore: '',
      bar: '',
  },
  types: {
      key: '',
      value: '',
      number: '',
      string: '',
      primitive: '',
      boolean: '',
      character: '',
      integer: '',
      array: '',
      float: '',
  },
  invalidToken: {
      tokenSequence: {
          prohibited: '',
          permitted: '',
      },
      termSequence: {
          prohibited: '',
          permitted: '',
      },
      double: '',
      useInstead: '',
      unexpected: '',
  },
  brace: {
      curly: {
          missingOpen: '',
          missingClose: '',
          cannotWrap: '',
      },
      square: {
          missingOpen: '',
          missingClose: '',
          cannotWrap: '',
      }
  },
  string: {
      missingOpen: '',
      missingClose: '',
      mustBeWrappedByQuotes: '',
      nonAlphanumeric: '',
      unexpectedKey: '',
  },
  key: {
      numberAndLetterMissingQuotes: '',
      spaceMissingQuotes: '',
      unexpectedString: '',
  },
  noTrailingOrLeadingComma: '',
}

export default function IntegrationPage () {

  const [currentChannel, setCurrentChannel] = useState<ApiChannel>()

  const {
    data,
    refetch
  } = useQuery('channelList', getChannelList)

  const saveMutation = useMutation((data: ApiChannel) => saveApiChannelConfiguration(data), {
    onSuccess: () => {
      toast.success('Configurazione aggiornata')
      refetch()
    }
  })

  return (
    <div className={'flex border-t w-screen'} style={{ height: 'calc(100% - 64px)' }}>
      <div className="w-64 border-r overflow-auto">
        <div className="bg-gray-100 text-gray-700 border-b border-gray-200 py-2 px-4 text-sm">
          Connessioni esistenti
        </div>
        {
          data && data.filter(c => !c.internal).map((channel, index) => (
            <button
              className={'block border-b py-2 text-left cursor-pointer hover:bg-gray-100 text-sm px-4 w-full ' + (currentChannel?.idApiChannel === channel.idApiChannel && 'bg-orange-100 text-orange-700 font-medium')}
              onClick={() => setCurrentChannel(channel)}
              key={index}>
              {channel.apiChannelName} {channel.idApiChannel}
            </button>
          ))
        }
      </div>
      <div className="flex-1 h-full flex flex-col">
        <div className={'flex border-b space-x-2'}>
          <div>
            <button onClick={() => {
              if (window.confirm('Are you sure you want to update the configuration?')) {
                if (currentChannel) saveMutation.mutate(currentChannel)
              }
            }} className={'flex space-x-2 items-center px-3 cursor-pointer hover:bg-orange-100 hover:text-orange-700 border-r py-2'}>
              <Icon name={'save'} size={'20px'} />
              <div>
                { saveMutation.isLoading ? 'Attendi...' : 'Salva' }
              </div>
            </button>
          </div>
        </div>
        <div className="flex-1">
          <Editor
            height="100%"
            width={'100%'}
            defaultLanguage="json"
            onChange={(value, event) => {
              if (value && currentChannel) {
                setCurrentChannel(prevState => {
                  if (prevState && prevState && prevState !== undefined && prevState.idApiChannel === currentChannel.idApiChannel) {
                    return {
                      ...currentChannel,
                      apiChannelConfig: value
                    }
                  }
                })
              }
            }}
            value={currentChannel ? currentChannel.apiChannelConfig : undefined}
          />
        </div>
      </div>
    </div>
  )
}