import {PageWrapper} from "../components/common/PageWrapper";
import {useTranslation} from "react-i18next";

export default function ReleaseNote () {

	const { t } = useTranslation()

	return (
		<PageWrapper centered>

			<div className="text-center mb-16 font-bold text-gray-600">
				<div className={'mb-2 text-2xl'}>
					📰
				</div>
				<div className="text-2xl">
					{t('news.title')}
				</div>
			</div>

			<div className="flex flex-col space-y-20">
				<div className="flex items-center lg:space-x-8 w-full rounded-md">
					<div className={'flex-1'}>
						<div className="text-xl text-gray-700 font-bold">
							{t('news.v3.title')}
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: t('news.v3.text')
							}}
							className={'text-sm leading-relaxed font-normal text-gray-600 mt-2'}>
						</div>
						<div className={'border-t pt-4 mt-4 flex items-center space-x-2'}>
							<div
								className={'h-8 w-8 bg-cover bg-center'}
								style={{
									backgroundImage: 'url(/team/wanny.png)'
								}} />
							<div>
								<div className="text-sm text-gray-500">
									<div className="font-medium">
										W. Miarelli
									</div>
									<div className="font-normal">
										5 maggio 2022
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="hidden lg:block w-1/3 h-72">
						<div
							style={{
								backgroundImage: 'url(/news/v3.png)'
							}}
							className="shadow-xl border bg-cover rounded-md h-full w-full" />
					</div>
				</div>

				<div className="flex items-center lg:space-x-8 w-full rounded-md">
					<div className="hidden lg:block w-1/3 h-72">
						<div
							style={{
								backgroundImage: 'url(/news/v2.png)'
							}}
							className="shadow-xl border bg-cover rounded-md h-full w-full" />
					</div>
					<div className={'flex-1'}>
						<div className="text-xl text-gray-700 font-bold">
							{t('news.v2.title')}
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: t('news.v2.text')
							}}
							className={'text-sm leading-relaxed font-normal text-gray-600 mt-2'}>
						</div>
						<div className={'border-t pt-4 mt-4 flex items-center space-x-2'}>
							<div
								className={'h-8 w-8 bg-cover bg-center'}
								style={{
									backgroundImage: 'url(/team/wanny.png)'
								}} />
							<div>
								<div className="text-sm text-gray-500">
									<div className="font-medium">
										W. Miarelli
									</div>
									<div className="font-normal">
										5 marzo 2022
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex items-center lg:space-x-8 w-full rounded-md">
					<div className={'flex-1'}>
						<div className="text-xl text-gray-700 font-bold">
							{t('news.v1.title')}
						</div>
						<div
							className={'text-sm leading-relaxed font-normal text-gray-600 mt-2'}
							dangerouslySetInnerHTML={{
								__html: t('news.v1.text')
							}}>

						</div>
						<div className={'border-t pt-4 mt-4 flex items-center space-x-2'}>
							<div
								className={'h-8 w-8 bg-cover bg-center'}
								style={{
									backgroundImage: 'url(/team/wanny.png)'
								}} />
							<div>
								<div className="text-sm text-gray-500">
									<div className="font-medium">
										W. Miarelli
									</div>
									<div className="font-normal">
										2 marzo 2022
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="hidden lg:block w-1/3 h-72">
						<div
							style={{
								backgroundImage: 'url(/news/v1.png)'
							}}
							className="shadow-xl border bg-cover rounded-md h-full w-full" />
					</div>
				</div>
			</div>

		</PageWrapper>
	)

}