import { useTranslation } from "react-i18next";
import PolicyList from "../components/quote/PolicyList";

export default function QuoteSettingsPolicies () {

  const { t } = useTranslation()

  return (
    <div>
      <div className="text-xl font-bold text-gray-600 mb-6">
        {t('quote.policies')}
      </div>
      <PolicyList onUpdate={() => {}} onSelect={() => {}} />
    </div>
  )

}