import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import CreateUser from "../components/business/user/CreateUser";
import Chip from "../components/common/Chip";
import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";
import BaseTable from "../components/data/BaseTable";
import Button from "../components/form/Button";
import { getUsers } from "../shared/queries";

export default function UsersPage () {

    const {
        data,
        isFetching,
        refetch
    } = useQuery('users', getUsers)

    const history = useHistory()

    const [create, setCreate] = useState<boolean>(false)

    return (
        <PageWrapper centered maxWidth={'1000px'}>

            <CreateUser visible={create} onClose={() => setCreate(false)} />
            
            <PageTitle
                title={'Utenti'}
                renderActions={() => (
                    <Button
                        className={'text-white bg-orange-600'}
                        label={'Nuovo utente'}
                        onClick={() => {
                            setCreate(true)
                        }} />
                )} />

            <BaseTable
                loading={isFetching}
                filters={[]}
                showFilters={true}
                showColumnSelector={true}
                identifierKey={'idUser'}
                onEdit={id => {
                    history.push('/app/users/' + id)
                }}
                columns={[
                    {
                        Header: 'Nome',
                        accessor: (row: any) => {
                            return row.userFirstName + ' ' + row.userLastName
                        }
                    },
                    {
                        Header: 'Email',
                        accessor: 'userEmail'
                    },
                    {
                        Header: 'Stato',
                        width: 'w-32',
                        accessor: (row: any) => (
                            row.userActive ? 
                            <Chip className={'bg-green-200 text-green-700'}>
                                Attivo
                            </Chip>
                            :
                            <Chip className={'bg-red-200 text-red-700'}>
                                Disattivo
                            </Chip>
                        )
                    }
                ]}
                data={data} />

        </PageWrapper>
    )
}