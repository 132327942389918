import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { fetchAccomodationGateways, saveGateways } from "../shared/queries";
import SelectInput from "../components/form/SelectInput";
import FormField from "../components/form/FormField";
import TextInput from "../components/form/TextInput";
import { useEffect, useState } from "react";
import Button from "../components/form/Button";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import Checkbox from "../components/form/Checkbox";

const SUPPORTED_GATEWAYS = [
  { label: 'BeSafe Pay', value: 'besafepay' }
]

export default function QuoteSettingPayments () {

  const { t } = useTranslation()
  const currentHotel = useSelector<any>(state => state.hotels.currentHotel)

  const {
    data,
    refetch
  } = useQuery(['accommodationGateways', currentHotel], () => fetchAccomodationGateways(currentHotel as any))

  const [gateway, setGateway] = useState('')
  const [active, setActive] = useState(false)
  const [privateKey, setPrivateKey] = useState('')
  const [publicKey, setPublicKey] = useState('')

  useEffect(() => {
    if (!data || data.length <= 0) return
    setGateway(data[0].gateway)
    setActive(data[0].active)
    setPrivateKey(data[0].privateKey)
    setPublicKey(data[0].publicKey)
  }, [ data ])

  const saveMutation = useMutation(() => saveGateways({
    idAccomodationGateway: (data && data.length > 0) ? data[0].idAccomodationGateway : undefined,
    idAccomodation: parseInt(currentHotel as any),
    publicKey: publicKey,
    privateKey: privateKey,
    gateway: gateway,
    active
  }), {
    onSuccess: () => {
      toast.success('Informazioni memorizzate')
      refetch()
    }
  })

  return (
    <div>
      <div className="text-xl font-bold text-gray-600 mb-2">
        {t('quote.directPayments')}
      </div>
      <div className="mb-6 text-slate-600">
        {t('quote.paymentsText')}
      </div>

      <div className="mb-6 text-blue-600 underline">
        <a target="_blank" href="https://pay.besafesuite.com/app/dashboard">
          {t('quote.payAds')}
        </a>
      </div>

      <div className="flex flex-col space-y-4">
        <FormField required={true} label={t('quote.chooseGateway')}>
          <SelectInput
            value={SUPPORTED_GATEWAYS.find(g => g.value === gateway)}
            options={SUPPORTED_GATEWAYS}
            onChange={(item: any) => {
              if (item) {
                setGateway(item.value)
              }
            }}
          />
        </FormField>

        <FormField label={t('quote.publicKey')}>
          <TextInput
            value={publicKey}
            onChange={val => setPublicKey(val)}
          />
        </FormField>
        <FormField label={t('quote.privateKey')} tooltip="La chiave privata non viene mostrata dopo il salvataggio per motivi di sicurezza.">
          <TextInput
            value={privateKey}
            onChange={val => setPrivateKey(val)}
          />
        </FormField>
        <FormField>
          <Checkbox
            checked={active}
            onChange={() => setActive(!active)}
            label={t('quote.activatePayments')}
          />
        </FormField>
      </div>

      <div className="mt-8">
        <Button
          loading={saveMutation.isLoading}
          className="text-white bg-orange-600"
          onClick={() => saveMutation.mutate()}
          label={t('general.confirm')}
        />
      </div>
    </div>
  )

}