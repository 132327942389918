import { useQuery } from "react-query";
import PartnerHeader from "../../components/partner/PartnerHeader";
import { useAppSelector } from "../../hooks/redux";
import { getPartner } from "../../shared/queries";

export default function PartnerFaqPage () {

  const { profile } = useAppSelector(state => state.session)

  const {
    data: partner
  } = useQuery(['partner', profile], () => getPartner((profile && profile.partnerId) || 0), {
      enabled: (profile !== undefined && profile.partnerId !== null && profile.partnerId !== undefined)
  })

  return (
    <div className="max-w-6xl w-full mx-auto p-8">
      <PartnerHeader partner={partner} />
    </div>
  )

}