import { useTranslation } from "react-i18next"
import SelectInput from "../components/form/SelectInput"
import { Booking, ChannelStatus, RequestFilters, ResyncRequest } from "../types"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { getAccomodationPolicyRate, getAccomodationPolicyRates, getAccomodations, getChannelList, getChannelStatusFiltered, getResyncRequest, saveResyncRequest } from "../shared/queries"
import { useMutation, useQuery } from "react-query"
import Button from "../components/form/Button"
import Chip from "../components/common/Chip"
import { formatCurrency, formatDate } from "../shared/utils"
import DataViewer from "../components/business/common/DataViewer"
import Lottie from "lottie-react";
import astronaut from '../lottie/astronaut.json'
import ReactTooltip from "react-tooltip"
import Modal from "../components/common/Modal"
import ModalCard from "../components/common/ModalCard"
import ModalActions from "../components/common/ModalActions"
import { OnChangeValue } from "react-select"
import FormField from "../components/form/FormField"
import TextInput from "../components/form/TextInput"
import { useHistory } from "react-router-dom"
import { request } from "../shared/api"
import DateRangePicker from "../components/form/DateRangePicker"

export default function MonitoringPage () {

  const { t } = useTranslation()
  const history = useHistory()

  const [apiChannel, setApiChannel] = useState<OnChangeValue<{ label: any, value: any, instruction: string, internal: boolean, config: string }, false> | undefined>()
  const [parsingResponse, setParsingResponse] = useState(false)
  const [askResync, setAskResync] = useState(false)
  const [currentResponse, setCurrentResponse] = useState<ChannelStatus | undefined>()
  const [error, setError] = useState(false)
  const [resyncStatus, setResyncStatus] = useState<ResyncRequest>()
  const [resyncLabels, setResyncLabels] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [bookings, setBookings] = useState<Booking[]>([])
  const [minusDays, setMinusDays] = useState(1)
  const [filters, setFilters] = useState<RequestFilters>({
    dateFrom: parseInt(dayjs().subtract(1, 'days').format('YYYYMMDDHHmmssSSS')),
    dateTo: parseInt(dayjs().format('YYYYMMDDHHmmssSSS')),
    idAccomodations: [],
    maxResults: 100,
    pageOffset: 0
  })

  const { data: accomodations } = useQuery('accomodations', getAccomodations)

  const {
    data,
    refetch,
    isFetching
  } = useQuery(['status'], () => getChannelStatusFiltered(filters), {
    enabled: filters.idAccomodations.length > 0
  })

  const {
    data: channelList
  } = useQuery('channelList', getChannelList)

  const {
    data: policyRates
  } = useQuery(['policyRates', filters.idAccomodations], () => getAccomodationPolicyRates(filters.idAccomodations[0]), {
    enabled: filters.idAccomodations.length > 0
  })

  const {
    data: accommodationRates
  } = useQuery(['accomodationRates', filters.idAccomodations[0]], () => getAccomodationPolicyRate(filters.idAccomodations[0]), {
      retry: false,
      enabled: (filters.idAccomodations.length > 0 && filters.idAccomodations[0] !== 0)
  })

  const resyncMutation = useMutation((data: ResyncRequest) => saveResyncRequest({
    ...data,
    days: minusDays
  }), {
    onSuccess: (data) => {
      refreshResync(data)
      setResyncLabels(prev => [
        'Richiesta avviata, attendi il risultato della sincronizzazione'
      ])
    }
  })

  async function refreshResync (data: ResyncRequest) {
    let tries = 0
    if (data && data.idResyncRequest) {
      const interval = setInterval(async () => {
        if (tries === 10) {
          clearInterval(interval)
          return
        }
        if (data.idResyncRequest) {
          const status = await getResyncRequest(data.idResyncRequest.toString())
          setResyncStatus(status)
          if (status && status.resyncResult !== 'PENDING' && status.resyncResult !== '') {
            let completedMessage = 'Richiesta completata con esito: '
            switch (status.resyncResult) {
              case 'INVALID_REQUEST_CONFIGURATION':
                completedMessage = completedMessage + ' impossibile eseguire la richiesta'
                break
              case 'APPLICATION_ERROR':
                completedMessage = completedMessage + ' impossibile eseguire la richiesta'
                break
              case 'COMPLETED':
                completedMessage = `${completedMessage} richiesta completata, ${data.generatedBookings.length} prenotazioni inserite`
                if (data.generatedBookings) {
                  const bkgs = data.generatedBookings.split(',')
                  completedMessage = `${completedMessage} + ${bkgs.map(b => b + '<br/>')}`
                }
                break
              case 'NO_BOOKINGS':
                completedMessage = completedMessage + ' nessuna prenotazione trovata'
                break
              default:
                completedMessage = completedMessage + ' sconosciuto'
            }
            setResyncLabels(prev => [
              ...prev,
              completedMessage
            ])
            clearInterval(interval)
            return
          }
        }
        tries++
      }, 5000)
    }
  }

  async function runAnalysis () {
    try {
      if (!currentResponse || !filters.idAccomodations || filters.idAccomodations.length === 0) return
      setParsingResponse(true)
      const response = await request({
        path: `/API/process/json/channel/${currentResponse.accomodationApi.apiChannel.idApiChannel}/accommodation/${filters.idAccomodations[0]}`,
        method: 'POST',
        isJson: false,
        body: currentResponse.channelStatusResponse
      })
      
      const data = response
      if (data.success) {
        setError(false)
        setErrorMessage('')
        setBookings(data.bookings)
      } else {
        setBookings([])
        setError(true)
        setErrorMessage(data.error)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setParsingResponse(false)
    }
  }

  useEffect(() => {
    if (currentResponse) {
      runAnalysis()
    }
  }, [ currentResponse ])

  function getBookingGuest (booking: Booking) {
    if (booking && booking.guests && booking.guests.length > 0) {
      return `${booking.guests[0].guestFirstName} ${booking.guests[0].guestLastName}`
    }
    return 'Nessun ospite'
  }

  function isRatedMapped (bookingRate?: string) : boolean {
    if (bookingRate && policyRates && policyRates[bookingRate]) {
      return true
    }
    return false
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen overflow-hidden flex flex-col" style={{ zIndex: 1000 }}>

    <Modal
      visible={askResync}>
      <ModalCard
        renderActions={() => (
          <ModalActions
            isLoading={resyncMutation.isLoading}
            saveLabel={'Richiedi Re-Sync'}
            onClose={() => {
              setAskResync(false)
            }}
            onSave={() => {
              if (filters.idAccomodations.length > 0 && apiChannel) {
                resyncMutation.mutate({
                  idAccomodation: parseInt(filters.idAccomodations[0].toString()),
                  idApiChannel: apiChannel ? apiChannel.value : null,
                  generatedBookings: '',
                  days: 1,
                  resyncResult: 'PENDING'
                })
              }
            }} />
        )}
        className={'w-full'}
        title={'Richiesta Re-Sync'}>
        <div className="px-4">
          <div className="text-gray-600 font-normal mt-6 mb-4">
            Continuando richiederai al sistema di effettuare una sincronizzazione delle prenotazioni della struttura selezionata. La procedura richiede da 1 a 3 minuti solitamente.
          </div>

          <div className="flex-col space-y-4 mb-6">
            <FormField label={'Giorni da sincronizzare'}>
              <TextInput
                numbered
                value={minusDays}
                onChange={value => setMinusDays(value)} />
            </FormField>

            <FormField label={'Canale da sincronizzare'}>
              <SelectInput
                onChange={(item: any) => {
                  if (item) setApiChannel(item)
                }}
                options={channelList ? channelList.map(c => {
                  return {
                    label: c.apiChannelName,
                    value: c.idApiChannel,
                    internal: c.internal,
                    instruction: c.apiChannelInstructions,
                    config: c.apiChannelConfig
                  }
                }) : []} />
            </FormField>
          </div>

          {
            (resyncLabels.length > 0) && <div className="mt-4 text-white bg-gray-800 p-4 rounded-md">
              {resyncLabels.map((l, index) => (<div key={index}>{'>>'} {l}</div>))}
            </div>
          }
        </div>
      </ModalCard>
    </Modal>

      <ReactTooltip />
      <div className="bg-slate-800 py-4 px-4 flex space-x-2 items-centere justify-between shadow-md">
        <div className="flex space-x-2 items-center">
          <div className="flex items-center justify-center">
            <button onClick={() => {
              history.push('/app/settings')
            }} className="text-white bg-slate-900 p-2 rounded-full hover:bg-slate-950">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          <div>
            <div className="text-xl text-white font-semibold">Centro di monitoraggio</div>
          </div>
        </div>
        <div className="flex space-x-2 items-center">
          <div className="w-64">
            <SelectInput
              placeholder={t('Seleziona struttura')}
              onChange={(item: any) => {
                  if (item) {
                      setFilters({
                          ...filters,
                          idAccomodations: [item.value]
                      })
                  }
              }}
              options={accomodations ? accomodations.map(a => {
                  return {
                      label: a.accomodationName,
                      value: a.idAccomodation
                  }
              }) : []}
            />
          </div>

          <div>
            <DateRangePicker
              dark={true}
              onStart={val => {
                setFilters({
                  ...filters,
                  dateFrom: parseInt(dayjs(val).format('YYYYMMDD') + '000000000')
                })
              }}
              onEnd={val => {
                setFilters({
                  ...filters,
                  dateTo: parseInt(dayjs(val).format('YYYYMMDD') + '000000000')
                })
              }}
            />
          </div>

          <div>
            <Button
              label={'Visualizza'}
              className="text-white bg-orange-600"
              loading={isFetching}
              onClick={() => {
                if (filters.idAccomodations.length === 0) {
                  alert('Devi selezionare una struttura')
                  return
                }
                refetch()   
              }}
            />
          </div>

          <div>
            <Button
              label={'Richiedi Resync'}
              className="text-white bg-orange-600"
              loading={isFetching}
              onClick={() => {
                if (filters.idAccomodations.length === 0) {
                  alert('Devi selezionare una struttura')
                  return
                }
                setAskResync(true)
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-hidden flex">
        <div className="h-full w-[400px] border-r overflow-auto">
          <div className="p-4 text-xl text-slate-700 font-bold border-b">
            Log connessione
          </div>
          <div className="flex-1 flex flex-col space-y-2 pt-2 divide-y divide-dashed">
            {
              (data) && data.map((d, index) => (
                <div className="p-2" key={index}>
                  <div className={'flex space-x-4 items-center'}>
                    <div>
                      {
                        !d.isWarning ?
                          <Chip className={'text-green-600 bg-green-100'}>
                              OK
                          </Chip>
                          :
                          <Chip className={'text-red-600 bg-red-100'}>
                              {d.channelStatusErrorCode}
                          </Chip>
                      }
                    </div>

                    <div className="flex-1">
                      <div className="flex justify-between">
                        <div className="font-medium">
                          {d.accomodationApi.apiChannel.apiChannelName}
                        </div>
                        <div>
                          <button onClick={() => setCurrentResponse(d)} className="text-orange-600 font-medium">Analizza</button>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="text-sm text-gray-600">{formatDate(d.channelStatusTime, 'DD-MM-YYYY HH:mm:ss')}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="flex-1 flex flex-col">
          <div className="text-xl text-slate-700 font-bold p-4 border-b">
            Contenuto risposta
          </div>
          <div className="flex-1">
            <DataViewer
              visible={currentResponse !== undefined}
              data={currentResponse}
              onClose={() => {
                  setCurrentResponse(undefined)
              }}
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col overflow-auto">
          <div className="text-xl text-slate-700 font-bold p-4 border-b">
            Analisi automatica
          </div>
          <div className="flex-1">
            {
              error &&
              <div className="flex flex-col space-y-2 items-center py-6">
                <div className="font-medium text-red-600 text-xl">
                  Errore durante l'analisi
                </div>
                <div className="text-lg">
                  risposta del server: {errorMessage}
                </div>
              </div>
            }

            <div className="divide-y divide-dashed">
              {
                (isFetching || parsingResponse) &&
                <div className="flex items-center justify-center flex-col">
                  <Lottie
                    animationData={astronaut} 
                    className="w-96"
                    loop={true}
                  />
                  <div className="text-xl text-slate-700 font-bold">Stiamo analizzando</div>
                  <div className="text-slate-600 text-lg mt-1">il sistema sta analizzando il file</div>
                </div>
              }
              {
                (!isFetching && !parsingResponse) && bookings.map((booking, bIndex) => (
                  <div className="items-center px-4 py-2" key={bIndex}>
                    <div className="mb-1 font-medium">
                      {booking.bookingCode} - {getBookingGuest(booking)}
                    </div>
                    <div className="flex space-x-2">
                      <div className="flex space-x-1 items-center">
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M5.75 2a.75.75 0 0 1 .75.75V4h7V2.75a.75.75 0 0 1 1.5 0V4h.25A2.75 2.75 0 0 1 18 6.75v8.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25v-8.5A2.75 2.75 0 0 1 4.75 4H5V2.75A.75.75 0 0 1 5.75 2Zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75Z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div>
                          {dayjs(booking.bookingArrivalDate.toString(), 'YYYYMMDD').format('DD/MM/YYYY')} {'->'} {dayjs(booking.bookingDepartureDate.toString(), 'YYYYMMDD').format('DD/MM/YYYY')}
                        </div>
                      </div>

                      <div className={`flex space-x-1 items-center ${isRatedMapped(booking.bookingRate) ? 'text-green-600' : 'text-red-700'}`}>
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path fillRule="evenodd" d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm14.25 6a.75.75 0 0 1-.22.53l-2.25 2.25a.75.75 0 1 1-1.06-1.06L15.44 12l-1.72-1.72a.75.75 0 1 1 1.06-1.06l2.25 2.25c.141.14.22.331.22.53Zm-10.28-.53a.75.75 0 0 0 0 1.06l2.25 2.25a.75.75 0 1 0 1.06-1.06L8.56 12l1.72-1.72a.75.75 0 1 0-1.06-1.06l-2.25 2.25Z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div>
                          {booking.bookingRate}
                        </div>
                      </div>

                      <div className="flex space-x-1 items-center">
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.798 7.45c.512-.67 1.135-.95 1.702-.95s1.19.28 1.702.95a.75.75 0 0 0 1.192-.91C12.637 5.55 11.596 5 10.5 5s-2.137.55-2.894 1.54A5.205 5.205 0 0 0 6.83 8H5.75a.75.75 0 0 0 0 1.5h.77a6.333 6.333 0 0 0 0 1h-.77a.75.75 0 0 0 0 1.5h1.08c.183.528.442 1.023.776 1.46.757.99 1.798 1.54 2.894 1.54s2.137-.55 2.894-1.54a.75.75 0 0 0-1.192-.91c-.512.67-1.135.95-1.702.95s-1.19-.28-1.702-.95a3.505 3.505 0 0 1-.343-.55h1.795a.75.75 0 0 0 0-1.5H8.026a4.835 4.835 0 0 1 0-1h2.224a.75.75 0 0 0 0-1.5H8.455c.098-.195.212-.38.343-.55Z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div>
                          {formatCurrency(booking.bookingTotal)}
                        </div>
                      </div>

                      <div className="flex space-x-1 items-center">
                        <div data-tip={'Booking status'}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div>
                          {booking.bookingStatus}
                        </div>
                      </div>
                      

                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}