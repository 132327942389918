import Joi from "joi";
import i18n from '../i18n/index'

export default Joi.object<any, false>({
  accomodationName: Joi.string().required().messages({
    'string.required': i18n.t('validation.required'),
    'string.empty': i18n.t('validation.required'),
    'string.email': i18n.t('validation.invalidEmail')
  }),
  accomodationPhone: Joi.any(),
  usePendingModel: Joi.any(),
  opportunity: Joi.any(),
  opportunitySendEmails: Joi.any(),
  customerType: Joi.any(),
  verificationNeeded: Joi.any(),
  billingEmail: Joi.any(),
  generalNotificationEmail: Joi.any(),
  company: Joi.any(),
  isSuspended: Joi.any(),
  policies: Joi.any(),
  isActive: Joi.any(),
  address: Joi.object({
    addressProvince: Joi.any(),
    addressLatitude: Joi.any(),
    addressLongitude: Joi.any(),
    addressState: Joi.any(),
    idCountry: Joi.any().required().messages({
      'string.required': i18n.t('validation.required'),
      'string.empty': i18n.t('validation.required')
    }),
    addressStreet: Joi.string().required().messages({
      'string.required': i18n.t('validation.required'),
      'string.empty': i18n.t('validation.required')
    }),
    addressCity: Joi.string().required().messages({
      'string.required': i18n.t('validation.required'),
      'string.empty': i18n.t('validation.required')
    }),
    addressZipCode: Joi.string().required().messages({
      'string.required': i18n.t('validation.required'),
      'string.empty': i18n.t('validation.required')
    })
  })
})