import { useContext, useMemo } from 'react'
import { ShepherdTourContext } from 'react-shepherd'

export default function TourStarter (){
    const tour = useContext(ShepherdTourContext)
    
    useMemo(() => {
        if (localStorage.getItem('tour')) return
        const observer = new MutationObserver(mutations => {
            if (document.querySelector('.navmenutour')) {
                if (tour && !tour.isActive()) {
                    console.log('starting tour', tour.isActive())
                    tour.start()
                    localStorage.setItem('tour', 'viewed')
                }
                observer.disconnect()
            }
        })

        observer.observe(document.body, {
            childList: true,
            subtree: true
        })
    }, [])

    return <></>
}