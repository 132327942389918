import {useEffect, useState} from "react"
import {useMutation, useQuery} from "react-query"
import {getAddress, getCompanyById, saveAccomodation, saveAddress, saveCompany} from "../../../shared/queries"
import { Address } from "../../../types"
import Modal from "../../common/Modal"
import ModalActions from "../../common/ModalActions"
import ModalCard from "../../common/ModalCard"
import FormField from "../../form/FormField"
import TextInput from "../../form/TextInput"
import CountrySelector from "../selectors/CountrySelector"

export default function CreateAddress ({
    idAddress,
    visible,
    onClose,
    onSave
} : {
    idAddress?: string | number,
    visible: boolean,
    onClose: () => void,
    onSave: (item: Address) => void
}) {

    const [addressStreet, setAddressStreet] = useState<string>('')
    const [addressState, setAddressState] = useState<string>('')
    const [addressCity, setAddressCity] = useState<string>('')
    const [addressProvince, setAddressProvince] = useState<string>('')
    const [addressZipCode, setAddressZipCode] = useState<string>('')
    const [idCountry, setIdCountry] = useState<number>(0)

    const {
        data
    } = useQuery(['company', idAddress], () => getAddress(idAddress || 0), {
        enabled: idAddress !== undefined
    })

    useEffect(() => {
        if (data) {
            setAddressStreet(data.addressStreet)
            setAddressState(data.addressState)
            setAddressProvince(data.addressProvince)
            setAddressZipCode(data.addressZipCode)
            setAddressCity(data.addressCity)
            setIdCountry(data.idCountry)
        }
    }, [ data ])

    const saveMutation = useMutation(() => saveAddress({
        addressCity,
        addressStreet,
        addressState,
        addressProvince: addressProvince,
        addressZipCode: addressZipCode,
        addressLongitude: 0,
        addressLatitude: 0,
        idCountry
    }), {
        onSuccess: data => {
            onSave(data)
        }
    })

    return (
        <Modal
            visible={visible}>
            <ModalCard
                actionsLoading={saveMutation.isLoading}
                renderActions={() => (
                    <ModalActions
                        onClose={onClose}
                        onSave={() => saveMutation.mutate()}></ModalActions>
                )}
                title={'Nuova indirizzo'}>

                <div className="p-4 flex flex-col space-y-4">
                    <FormField label={'Via/Piazza'}>
                        <TextInput
                            value={addressStreet}
                            onChange={val => setAddressStreet(val)} />
                    </FormField>

                    <div className="flex space-x-4">
                        <FormField className={'flex-1'} label={'Città'}>
                            <TextInput
                                value={addressCity}
                                onChange={val => setAddressCity(val)} />
                        </FormField>

                        <FormField className={'flex-1'} label={'Stato'}>
                            <TextInput
                                value={addressState}
                                onChange={val => setAddressState(val)} />
                        </FormField>
                    </div>

                    <FormField className={'flex-1'} label={'Provincia'}>
                        <TextInput
                          value={addressProvince}
                          onChange={val => setAddressProvince(val)} />
                    </FormField>

                    <FormField className={'flex-1'} label={'Cap'}>
                        <TextInput
                          value={addressZipCode}
                          onChange={val => setAddressZipCode(val)} />
                    </FormField>

                    <FormField label={'Paese'}>
                        <CountrySelector
                            initialCountryId={idCountry}
                            onSelect={c => setIdCountry(c.idCountry)} />
                    </FormField>
                </div>
            </ModalCard>
        </Modal>
    )

}
