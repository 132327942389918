import { createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import { AccomodationAmenity, AccomodationInfo, CancellationPolicy} from "../../types"
import { getAccommodationAmenities, getAccomodationInfo, getCancellationPolicies, getPolicies } from "../../shared/queries"

type AccommodationSettingsState = {
  info?: AccomodationInfo,
  amenities: AccomodationAmenity[],
  policies: CancellationPolicy[]
  fetching: {
    policies: boolean,
    info: boolean,
    amenities: boolean
  }
}

export const fetchPolicies = createAsyncThunk(
  'accommodationSettings/fetchPolicies',
  async (idAccomodation: number | string | null, { getState }) => {
      if (idAccomodation) return await getCancellationPolicies(idAccomodation)
      return []
  }
)


export const fetchInfo = createAsyncThunk(
  'accommodationSettings/fetchInfo',
  async (idAccomodation: number | string | null, { getState }) => {
      if (idAccomodation) return await getAccomodationInfo(idAccomodation)
      return undefined
  }
)

export const fetchAmenities = createAsyncThunk(
  'accommodationSettings/fetchAmenities',
  async (idAccomodation: number | string | null, { getState }) => {
      if (idAccomodation) return await getAccommodationAmenities(idAccomodation)
      return [] as AccomodationAmenity[]
  }
)

const accommodationSettingsSlice = createSlice({
    name: 'hotels',
    initialState: {
      info: undefined,
      amenities: [],
      policies: [],
      fetching: {
        info: false,
        policies: false,
        amenities: false
      }
    } as AccommodationSettingsState,
    reducers: {

      setInfo: (state, action: PayloadAction<AccomodationInfo>) => {
        state.info = action.payload
      },

      setAmenities: (state, action: PayloadAction<AccomodationAmenity[]>) => {
        state.amenities = action.payload
      },

      addAmenity: (state, action: PayloadAction<AccomodationAmenity>) => {
        state.amenities.push(action.payload)
      },

      updateAmenities: (state, action: PayloadAction<AccomodationAmenity>) => {
        state.amenities = state.amenities.map(a => {
          if (a.amenityKey == action.payload.amenityKey) {
            return {
              ...a,
              idAccomodationAmenity: action.payload.idAccomodationAmenity
            }
          }
          return a
        })
      },

    },
    extraReducers: builder => {

      builder.addCase(fetchInfo.pending, (state) => {
        state.fetching.info = true
      })
      builder.addCase(fetchInfo.rejected, (state) => {
        state.fetching.info = false
        state.info = {
          checkIn: '15:00',
          checkOut: '11:00',
          description: 'Descrivi il tuo hotel...',
          petsAllowed: false,
          childrenMaxAge: 5,
          petsCost: 0,
          idAccomodation: 0
        }
      })
      builder.addCase(fetchInfo.fulfilled, (state, action: PayloadAction<AccomodationInfo | undefined>) => {
        if (action.payload === undefined) {
          state.info = {
            checkIn: '15:00',
            checkOut: '11:00',
            description: 'Descrivi il tuo hotel...',
            petsAllowed: false,
            childrenMaxAge: 5,
            petsCost: 0,
            idAccomodation: 0
          }
        } else {
          state.info = action.payload
        }
        state.fetching.info = false
      })

      builder.addCase(fetchAmenities.pending, (state) => {
        state.fetching.amenities = true
      })
      builder.addCase(fetchAmenities.rejected, (state) => {
        state.fetching.amenities = false
      })
      builder.addCase(fetchAmenities.fulfilled, (state, action: PayloadAction<AccomodationAmenity[]>) => {
        state.amenities = action.payload
        state.fetching.info = false
      })

      builder.addCase(fetchPolicies.pending, (state) => {
        state.fetching.policies = true
      })
      builder.addCase(fetchPolicies.rejected, (state) => {
        state.fetching.policies = false
      })
      builder.addCase(fetchPolicies.fulfilled, (state, action: PayloadAction<CancellationPolicy[]>) => {
        state.policies = action.payload
        state.fetching.policies = false
      })

    }
})

export const {
  setInfo,
  setAmenities,
  updateAmenities,
  addAmenity
} = accommodationSettingsSlice.actions

export default accommodationSettingsSlice.reducer