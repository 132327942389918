import ModalActions from "./ModalActions";

export default function ModalCard ({
  title,
  subTitle,
  children,
  style,
  renderActions,
  className,
  maximized = false,
  onSave,
  noscroll = false,
  onClose,
  actionsLoading = false,
} : {
  noscroll?: boolean,
  subTitle?: string,
  renderActions?: () => void,
  onSave?: () => void,
  onClose?: () => void,
  style?: object,
  maximized?: boolean,
  title?: string,
  children: any,
  className?: string,
  actionsLoading?: boolean
}) {

  return (
    <div style={style} className={`flex flex-col ${!maximized && 'm-10'} ${noscroll ? 'overflow-hidden' : 'overflow-auto overflow-y-hidden overflow-x-hidden'} bg-white shadow-lg rounded ${className}`}>
      <div>
        {
          title &&
          <div className={'pt-5 border-b pb-4 bg-gray-50 px-4 text-xl text-gray-700 font-bold'}>
            {title}
          </div>
        }
        {
          subTitle &&
          <div className={'px-4 mt-4 text-gray-600'}>
            {subTitle}
          </div>
        }
      </div>
      <div className={`relative flex-1 ${noscroll ? 'overflow-hidden h-full flex flex-col' : 'overflow-y-auto overflow-x-hidden'}`}>
        {children}
      </div>

      {
        (onSave || onClose) &&
        <ModalActions
          isLoading={actionsLoading}
          onClose={onClose ? onClose : undefined}
          onSave={onSave ? onSave : undefined} />
      }

      {
        renderActions && renderActions() as any
      }
    </div>
  )

}
