import { Component } from 'react'
import Select, { components } from 'react-select'

export default function SelectInput (props:  any) {

  return (
    <Select
      menuPosition={'fixed'}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#FEF3C7',
          primary: '#FBBF24',
        },
      })}
      components={{
        Input: ({ ...rest }) => <components.Input {...rest} autoComplete='nope'></components.Input>
      }}
      styles={{
        container: (base) => {
          return {
            ...base,
            border: 0
          }
        },
        control: (base) => {
          return {
            ...base,
            border: 0,
            height: 30,
            minHeight: 35,
            maxHeight: 30,
            boxShadow: '0px 2px 3px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.2)'
          }
        },
        ...props.customStyles ? props.customStyles : {}
      }}
      {...props}
    />
  )

}
