import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {deleteAccomodationRate, deleteGroup, getAccomodationApiChannels, getAccomodationGroups, getAccomodationPolicyRate, getGroups, saveAccomodation, saveGroup} from "../../../shared/queries";
import { RootState } from "../../../store";
import { Accomodation, CreateGroup } from "../../../types";
import Chip from "../../common/Chip";
import EntityUpdateDialog from "../../common/EntityUpdateDialog";
import DetailRow from "../../data/DetailRow";
import Checkbox from "../../form/Checkbox";
import Link from "../../navigation/Link";
import CreateCommissionPlan from "./CreateCommissionPlan";
import HotelConnectedPolicies from "./HotelConnectedPolicies";
import HotelConnectedRates from "./HotelConnectedRates";
import HotelConnectedSystems from "./HotelConnectedSystems";
import HotelProduction from "./HotelProduction";
import SectionCard from "../../SectionCard";
import { formatCurrency } from "../../../shared/utils";
import Modal from "../../common/Modal";
import ModalCard from "../../common/ModalCard";
import CompanySelector from "../selectors/CompanySelector";
import ModalActions from "../../common/ModalActions";

export default function HotelDetails ({
    accomodation,
    onUpdate
} : {
    accomodation: Accomodation,
    onUpdate: () => void
}) {

    const { t } = useTranslation()

    const { profile } = useSelector((state: RootState) => state.session)

    const history = useHistory()

    const [showAssociateGroup, setShowAssociateGroup] = useState(false)
    const [currentCommissionPlanId, setCurrentCommissionPlanId] = useState<string | number | undefined>()
    const [addCommissionPlan, setAddCommissionPlan] = useState<boolean>(false)
    const [showUpdate, setShowUpdate] = useState<boolean>(false)
    const [showCreateCompany, setShowCreateCompany] = useState<boolean>(false)
    const [newCompanyId, setNewCompanyId] = useState<any>()

    const {
        data: accomodationRate,
        refetch,
    } = useQuery(['accomodationRates', accomodation.idAccomodation], () => getAccomodationPolicyRate(accomodation.idAccomodation), {
        retry: false
    })
    
    const {
        data: apiChannels,
        isFetching: isLoadingChannels
    } = useQuery(['accomodationApiChannel', accomodation.idAccomodation], () => getAccomodationApiChannels(accomodation.idAccomodation ? accomodation.idAccomodation : 0))

    const {
      data: groups,
      refetch: refetchGroups
    } = useQuery('groups', () => getGroups())

    const {
      data: accomodationGroups,
      refetch: refetchAccomodationGroups
    } = useQuery('accommodationGroups', () => getAccomodationGroups(accomodation.idAccomodation || 0), {
      enabled: (accomodation !== undefined && accomodation.idAccomodation !== undefined )
    })

    const deleteAccomodationRateMutation = useMutation((id: any) => deleteAccomodationRate(id), {
      onSuccess: () => {
        toast.success('Piano rimosso')
        refetch()
      }
    })

    const pendingModelMutation = useMutation((flag: boolean) => saveAccomodation({
        ...accomodation,
        usePendingModel: flag
    }), {
        onSuccess: () => {
            toast.success('Modello aggiornato')
            onUpdate()
        }
    })

    const updateAccomodationMutation = useMutation((data: any) => saveAccomodation({
        ...accomodation,
        ...data
    }), {
        onSuccess: () => {
            toast.success(t('vary.infoUpdated'))
            setShowUpdate(false)
            onUpdate()
        }
    })

    const saveGroupMutation = useMutation((g: CreateGroup) => saveGroup(g), {
      onSuccess: () => {
        refetchAccomodationGroups()
        setShowAssociateGroup(false)
        toast.success(t('vary.groupAdded'))
      }
    })

    const deleteGroupMutation = useMutation((data: { groupId: number, accommodationId: number }) => deleteGroup(data.groupId, data.accommodationId), {
      onSuccess: () => {
        refetchAccomodationGroups()
        toast.success(t('vary.groupRemoved'))
      }
    })

    function hasUnmappedRates () {
      console.log('acc',accomodationRate)
      if (accomodationRate?.find(p => p.connectedRates === '' || p.connectedRates === null)) {
        // return false
      }
      
      if (!accomodationRate || accomodationRate.length === 0) {
        return true
      }

      const ratePlanRates = accomodationRate
        .filter(ac => (ac.connectedRates !== null && ac.connectedRates !== ''))
        .map(ac => ac.connectedRates?.split(','))
        .flat()

      const connectedRates = apiChannels ? apiChannels?.map(ap => {
        const apiProps = JSON.parse(ap.apiProps)
        console.log('apiProps', apiProps)
        return apiProps.rates.flat()
      }).flat().filter(r => r !== 'WINTER' && r !== 'SUMMER' && r !== 'BUSINESS') : []

      console.log('cc --- cc', ratePlanRates, connectedRates)

      for (const connectedRate of connectedRates) {
        if (!ratePlanRates.includes(connectedRate) && ratePlanRates.length !== 0) {
          return true
        }
      }

      return false
    }

    return (
        <Fragment>
            <Modal
              visible={showCreateCompany}>
              <ModalCard
                title={'Cambia anagrafica'}
                renderActions={() => (
                  <ModalActions
                    onClose={() => setShowCreateCompany(false)}
                    onSave={async () => {
                      if (newCompanyId === null || newCompanyId === undefined) {
                        return
                      }
                      await updateAccomodationMutation.mutate({
                        ...accomodation,
                        company: {
                          idCompany: newCompanyId,
                        }
                      })
                      setShowCreateCompany(false)
                    }}
                  />
                )}>
                <div className="p-4 w-96">
                  <CompanySelector onSelect={async c => {
                    await updateAccomodationMutation.mutate({
                      ...accomodation,
                      company: {
                        idCompany: c.idCompany,
                      }
                    })
                    setShowCreateCompany(false)
                  }} />
                </div>
              </ModalCard>
            </Modal>

            <CreateCommissionPlan
                accomodationRateId={currentCommissionPlanId}
                visible={addCommissionPlan}
                onClose={() => setAddCommissionPlan(false)}
                onSave={() => {
                    refetch()
                    setAddCommissionPlan(false)
                    setCurrentCommissionPlanId(undefined)
                }}
                accomodation={accomodation} />

            <EntityUpdateDialog
                fields={[
                    {
                        label: t('vary.group'),
                        value: '',
                        type: 'select',
                        options: groups?.map(g => {
                          return {
                            label: g.description,
                            value: g.description
                          }
                        }),
                        field: 'groupId'
                    }
                ]}
                visible={showAssociateGroup}
                onClose={() => setShowAssociateGroup(false)}
                onSave={(data: any) => {
                  if (!accomodation || !accomodation.idAccomodation) return
                    saveGroupMutation.mutate({
                      idAccomodationToAttach: accomodation.idAccomodation,
                      description: data.groupId
                    })
                }}
                title={t('vary.addGroup')}
                data={{
                  groupId: undefined
                }} />

            <EntityUpdateDialog
                fields={(profile && profile.userAdmin) ? [
                    {
                        label: t('vary.partnerName'),
                        value: '',
                        field: 'accomodationName'
                    },
                    {
                        label: t('vary.notificationEmail'),
                        value: '',
                        field: 'generalNotificationEmail'
                    },
                    {
                      label: t('vary.billingEmail'),
                      value: '',
                      field: 'generalNotificationEmail'
                    },
                    {
                        label: t('accommodation.phone'),
                        value: '',
                        field: 'accomodationPhone'
                    },
                    {
                        label: t('Crediti'),
                        value: '',
                        field: 'credits'
                    },
                    {
                      label: t('Valuta'),
                      value: '',
                      field: 'currency',
                      type: 'currency'
                    },
                    {
                      label: t('addHotel.partnerType'),
                      value: '',
                      field: 'customerType',
                      options: [
                        { label: 'HOTEL', value: 'HOTEL'},
                        { label: 'TO', value: 'TO '},
                        { label: 'AGENCY', value: 'AGENCY'},
                      ],
                      type: 'select'
                    },
                    {
                      label: t('Profilo da verificare'),
                      value: '',
                      field: 'verificationNeeded',
                      type: 'checkbox'
                    }
                ] : [{
                      label: t('Nome struttura'),
                      value: '',
                      field: 'accomodationName'
                    },
                    {
                      label: t('vary.notificationEmail'),
                      value: '',
                      field: 'generalNotificationEmail'
                    },
                    {
                      label: t('vary.billingEmail'),
                      value: '',
                      field: 'generalNotificationEmail'
                    },
                    {
                        label: t('Telefono'),
                        value: '',
                        field: 'accomodationPhone'
                    },
                    {
                        label: t('Crediti'),
                        value: '',
                        field: 'credits'
                    },
                    {
                      label: t('Valuta'),
                      value: '',
                      field: 'currency',
                      type: 'currency'
                    }
                ]}
                visible={showUpdate}
                onClose={() => setShowUpdate(false)}
                onSave={(data: any) => {
                    updateAccomodationMutation.mutate(data)
                }}
                title={'Modifica struttura'}
                data={accomodation} />

          {
            (accomodation && accomodation.verificationNeeded) &&
            <div className="mt-6 bg-orange-100 text-orange-700 p-6 rounded-md">
              <div className="text-orange-800 font-bold text-lg">Attesa validazione</div>
              <div className="text-lg text-orange-700">
                questo profilo è in attesa di validazione, non potrà operare fino al termine delle verifiche operative.
              </div>
            </div>
          }

          <SectionCard
            className={'mb-6 mt-6'}
            onEdit={() => (profile && profile.userAdmin) ? setShowUpdate(true) : () => {} }
            title={t('accommodation.accommodationDetails')}>
            <div className="flex flex-col space-y-4">
              <DetailRow
                label={t('accommodation.status')}>
                {
                  accomodation.isActive ?
                    <Chip className={'text-green-600 bg-green-100'}>
                      {t('accommodation.active')}
                    </Chip>
                    :
                    <Chip className={'text-red-600 bg-red-100'}>
                      {t('accommodation.inactive')}
                    </Chip>
                }
              </DetailRow>

              <DetailRow
                label={t('accommodation.identifier')}>
                {accomodation.idAccomodation}
              </DetailRow>

              <DetailRow
                label={t('accommodation.information')}>
                <div className="flex space-x-4">
                  <Link
                    onClick={() => {
                      history.push('/app/companies/' + accomodation.company.idCompany)
                    }}>
                    { accomodation.company ? accomodation.company.companyName : 'ND' }
                  </Link>
                  {
                    profile?.userAdmin &&
                    <>
                      <div className="text-slate-300">
                        |
                      </div>
                      <button
                        onClick={() => setShowCreateCompany(true)}
                        className="hover:text-orange-700 flex items-center space-x-1 rounded-md text-orange-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                        </svg>
                        <div>cambia anagrafica</div>
                      </button>
                    </>
                  }
                </div>
              </DetailRow>

              <DetailRow
                label={t('accommodation.address')}>
                {accomodation.address ? accomodation.address.addressStreet : 'ND'}
              </DetailRow>

              <DetailRow
                label={t('accommodation.phone')}>
                {accomodation.accomodationPhone || 'ND'}
              </DetailRow>

              <DetailRow
                label={t('Email')}>
                <Link
                  onClick={() => {}}>
                  {accomodation.company.companyEmail}
                </Link>
              </DetailRow>

              <DetailRow
                label={t('vary.billingEmail')}>
                {accomodation.billingEmail || 'ND'}
              </DetailRow>

              <DetailRow
                label={t('vary.notificationEmail')}>
                {accomodation.generalNotificationEmail || 'ND'}
              </DetailRow>

              <DetailRow
                label={t('Valuta')}>
                {accomodation.currency}
              </DetailRow>

              <DetailRow
                label={t('Crediti')}>
                {accomodation.credits}
              </DetailRow>
            </div>
          </SectionCard>

          {
            profile?.userAdmin &&
            <SectionCard
              onEdit={() => {
                setShowAssociateGroup(true)
              }}
              className={'mb-6 mt-6'}
              title={t('vary.group')}>
              <div>
                <DetailRow
                  label={t('vary.associatedGroups')}>
                  {
                    (accomodationGroups != undefined) &&
                    accomodationGroups.map((ag, gIndex) => {
                      return (
                        <div key={gIndex} className="flex space-x-2">
                          <div>{ag.description}</div>
                          <div>
                            <button onClick={() => {
                              if (accomodation && accomodation.idAccomodation) {
                                deleteGroupMutation.mutate({
                                  groupId: ag.idGroup,
                                  accommodationId: accomodation.idAccomodation
                                })
                              }
                            }}>Remove</button>
                          </div>
                        </div>
                      )
                    })
                  }/
                </DetailRow>
              </div>
            </SectionCard>
          }

          {
            profile?.userAdmin &&
              <SectionCard
                  title={t('accommodation.commissionPlan')}>
                <div className="flex flex-col space-y-4">
                  {
                    hasUnmappedRates() &&
                    <div className="bg-red-100 text-red-600 p-4 rounded-md">
                      <div className="font-medium mb-2">Attenzione</div>
                      <div>
                        I piani commissionali non includono alcuni dei piani tariffari collegati, alcune prenotazioni potrebbero non essere fatturate.
                      </div>
                    </div>
                  }
                  {
                    (accomodationRate && accomodationRate.length > 0) ?
                      accomodationRate.map(rate => (
                        <div className={'border-b flex flex-col space-y-4 py-4'}>
                          <DetailRow
                            label={t('accommodation.commission')}>
                            {rate.internalRate}%, {formatCurrency(rate.internalFixedRate)}
                          </DetailRow>

                          <DetailRow
                            label={t('accommodation.insurance')}>
                            {rate.insuranceRate}%, {formatCurrency(rate.insuranceFixedRate)}
                          </DetailRow>

                          <DetailRow
                            label={t('accommodation.agent')}>
                            {rate.agentRate}%
                          </DetailRow>

                          <DetailRow
                            label={t('accommodation.subAgent')}>
                            {rate.subAgentRate}%
                          </DetailRow>

                          <DetailRow
                            label={'Hotel'}>
                            {rate.hotelRate}%
                          </DetailRow>

                          <DetailRow
                            label={'Tariffe'}>
                            {rate.connectedRates || 'Tutte le tariffe'}
                          </DetailRow>

                          <DetailRow
                            label={'Fixed cost'}>
                            {
                              rate.fixedCost ?
                                <Chip className={'text-green-600 bg-green-100'}>
                                  Yes
                                </Chip>
                                :
                                <Chip className={'text-red-600 bg-red-100'}>
                                  No
                                </Chip>
                            }
                          </DetailRow>

                          {
                            rate.fixedCost &&
                            <DetailRow label={'Modifiers'}>
                              {rate.perPerson && <div>Per persona</div>}
                              {rate.perNight && <div>Per notte</div>}
                            </DetailRow>
                          }

                          <Checkbox
                            className={'mt-6'}
                            checked={accomodation.usePendingModel}
                            label={'Utilizza modalità pending'}
                            onChange={() => {
                              pendingModelMutation.mutate(!accomodation.usePendingModel)
                            }} />

                          <div className={'flex space-x-4'}>
                            <button type={"button"} className={'text-orange-600 text-left'} onClick={() => {
                              setCurrentCommissionPlanId(rate.idAccomodationsRate)
                              setAddCommissionPlan(true)
                            }}>Modifica piano</button>
                            <button type={"button"} className={'text-red-600 text-left'} onClick={() => deleteAccomodationRateMutation.mutate(rate.idAccomodationsRate)}>Elimina piano</button>
                          </div>
                        </div>
                      ))
                      :
                      <Fragment>
                        Nessun piano commissionale disponibile. Le prenotazioni ricevute non verranno inviate su nessuna polizza.
                        <div className={'mt-1'}>
                          <Link
                            onClick={() => {
                              setAddCommissionPlan(true)
                            }}>
                            Aggiungi nuovo piano
                          </Link>
                        </div>
                      </Fragment>
                  }
                </div>
                <div onClick={() => setAddCommissionPlan(true)} className={'mt-6'}>
                  <button className={'px-4 py-3 bg-gray-100 rounded-md text-orange-600 font-semibold'}>Aggiungi piano commissionale</button>
                </div>
              </SectionCard>
          }

          {
            (accomodation && accomodation.idAccomodation) &&
              <HotelProduction
                  idAccomodation={accomodation.idAccomodation} />
          }

          {
              (accomodation && accomodation.idAccomodation) &&
              <Fragment>
                  {
                      profile?.userAdmin &&
                      <Fragment>
                          <HotelConnectedSystems
                              idAccomodation={accomodation.idAccomodation} />

                          <HotelConnectedPolicies
                              accomodation={accomodation} />

                          <HotelConnectedRates
                              loading={isLoadingChannels}
                              apiChannels={apiChannels || []}
                              accomodation={accomodation} />
                      </Fragment>
                  }
              </Fragment>
          }

        </Fragment>
    )

}
