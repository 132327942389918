import Joi from "joi";
import { QuoteCustomer } from "../types";

export default Joi.object<QuoteCustomer, false>({
  idQuoteCustomer: Joi.any(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  phoneNumber: Joi.any(),
  countryCode: Joi.string().required(),
  email: Joi.string().email({ tlds: { allow: false }}).required(),
  target: Joi.any(),
  idAccomodation: Joi.any()
})