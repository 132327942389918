import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import GuestHomePage from "../pages/guest/GuestHomePage"
import ManageGuestPage from "../pages/guest/ManageGuestPage"
import RouteTransition from "../components/RouteTranistion"
import { useDispatch, useSelector } from 'react-redux'
import { setCode } from '../store/slices/guestAreaSlice'
import { RootState } from '../store'
import { useEffect, useState } from 'react'
import GuestLogin from '../pages/guest/GuestLogin'
import OpenClaimPage from '../pages/guest/OpenClaim'
import LanguageSelector from '../components/common/LanguageSelector'

import '../guestarea.css'
import { useTranslation } from 'react-i18next'
import { fetchCountries } from '../store/slices/sessionSlice'

export default function GuestLayout () {

    const { t } = useTranslation()    
    const history = useHistory()
    const { code } = useParams<any>()
    const dispatch = useDispatch()

    const [width, setWidth] = useState(window.innerWidth)

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        dispatch(fetchCountries())
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    const { bookingDetails } = useSelector((state: RootState) => state.guestArea)

    useEffect(() => {
        dispatch(setCode(code))
    }, [ code ])

    return (
        <div className='guest-area-container'>
            {
                (!bookingDetails) &&
                <GuestLogin code={code} />
            }

            {
                bookingDetails &&
                <div className='bg-gray-100 h-screen w-screen overflow-auto'>
                    <div className="border-b">
                        <header className='flex mx-auto w-full max-w-3xl items-center my-4 justify-between px-4 md:px-0'>
                            <div>
                                <img
                                    className={'h-10 cursor-pointer'}
                                    onClick={() => history.push(`/guest-area/${code}`)}
                                    src="https://besafesuite.com/besaferate/wp-content/themes/besafe/assets/images/berate_logo.svg"
                                    alt="BeSafe Rate" />
                            </div>
                            <div>
                                <LanguageSelector visibleLanguages={['it', 'en', 'fr', 'de']} mini={false} />
                            </div>
                        </header>
                    </div>

                    <Switch>
                        <Route exact path={'/guest-area/:code'}>
                            <RouteTransition>
                                <GuestHomePage />
                            </RouteTransition>
                        </Route>
                        <Route exact path={'/guest-area/:code/open-claim'}>
                            <RouteTransition>
                                <OpenClaimPage />
                            </RouteTransition>
                        </Route>
                        <Route exact path={'/guest-area/guests'}>
                            <RouteTransition>
                                <ManageGuestPage />
                            </RouteTransition>
                        </Route>
                    </Switch>

                    <footer className='max-w-3xl w-full mx-auto mb-10 flex flex-col space-y-4 px-4 md:px-0'>
                        <div className="text-gray-600">
                            <div className="font-medium">
                                © 2023 Be Safe Group SRL
                            </div>
                            Via giunio bazzoni, 1 - 00195 Roma. P.Iva: 14562641002 
                        </div>
                        <div className='border-gray-300 border-t '></div>
                        <div className="text-gray-600">
                            {t('vary.ivass')}
                        </div>
                    </footer>

                </div>
            }
        </div>
    )

}