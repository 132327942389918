import { CheckIcon } from '@heroicons/react/24/solid'

export default function Stepper ({
  neutral = false,
  currentStep,
  onStepClick,
  steps
} : {
  neutral: boolean,
  currentStep: number,
  onStepClick: (stepIdx: number) => void,
  steps: { id: string, name: string, clickable?: boolean }[]
}) {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="divide-y divide-gray-300 border-b border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {(currentStep > stepIdx) ? (
              <button onClick={() => {
                if (step.clickable) onStepClick(stepIdx)
              }} className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full ${neutral ? 'bg-sky-600 group-hover:bg-sky-800' : 'bg-orange-600 group-hover:bg-orange-800'}`}>
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </button>
            ) : (currentStep === stepIdx) ? (
              <button onClick={() => {
                if (step.clickable) onStepClick(stepIdx)
              }} className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${neutral ? 'border-sky-600' : 'border-orange-600'}`}>
                  <span className={`${neutral ? 'text-sky-600' : 'text-orange-600'}`}>{step.id}</span>
                </span>
                <span className={`ml-4 text-sm font-medium ${neutral ? 'text-sky-600' : 'text-orange-600'}`}>{step.name}</span>
              </button>
            ) : (
              <button onClick={() => {
                if (step.clickable) onStepClick(stepIdx)
              }} className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                </span>
              </button>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}
