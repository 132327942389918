import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useMutation, useQuery } from "react-query";
import { deleteBookingPayment, getBookingPayments } from "../../../shared/queries";
import { formatDate } from "../../../shared/utils";
import BaseTable from "../../data/BaseTable";
import Icon from "../../common/Icon";
import {Booking, BookingPayment} from "../../../types";
import { useState } from "react";
import CreatePayment from "./CreatePayment";
import DeleteModal from "../../common/DeleteModal";
import toast from "react-hot-toast";

export default function BookingPaymentsTable ({
    idBooking,
    booking,
    onRefetch
} : {
    booking: Booking
    idBooking: string,
    onRefetch?: () => void
}) {

    const { t } = useTranslation()

    const [currentEdit, setCurrentEdit] = useState<number | string>()
    const [currentDelete, setCurrentDelete] = useState<number | string>()

    const {
        data,
        isFetching,
        refetch: refetchPayments
    } = useQuery(['payments', idBooking], () => getBookingPayments(idBooking))

    const deleteMutation = useMutation((id: string) => deleteBookingPayment(id), {
        onSuccess: () => {
            setCurrentDelete(undefined)
            refetchPayments()
            toast.success('Pagamento eliminato')
        }
    })

    return (
        <>
          {
            (data && data.length > 0 && booking.bookingTotal !== data.reduce((prev, curr) => prev + curr.paymentAmount, 0)) &&
              <div className={'text-orange-700 space-x-2 items-center flex'}>
                  <div>
                      <Icon name={'information-circle'} size={'20px'} />
                  </div>
                  <div className={'flex-1'}>
                      <strong>{t('guestPage.warning')}</strong> The amount insured is different from the booking amount
                  </div>
              </div>
          }

        <CreatePayment
            bookingTotal={booking.bookingTotal}
            visible={(currentEdit !== undefined)}
            idPayment={currentEdit}
            onSave={() => {
                setCurrentEdit(undefined)
                refetchPayments()
            }}
            onClose={() => setCurrentEdit(undefined)}
            idBooking={idBooking} />

        <DeleteModal
            title={t('createPayment.deletePayment')}
            askConfirmation={true}
            message={t('createPayment.deletePaymentText')}
            visible={(currentDelete !== undefined)}
            onCancel={() => {
                setCurrentDelete(undefined)
            }}
            isLoading={deleteMutation.isLoading}
            onConfirm={() => {
                if (currentDelete) {
                    deleteMutation.mutate(currentDelete.toString())
                }
        }} />

          <BaseTable
              showFilters={false}
              showColumnSelector={false}
              data={data}
              loading={isFetching}
              paginated={false}
              filterable={false}
              identifierKey={'idBookingPayment'}
              columns={[
                  {
                      Header: t('createPayment.paymentAmount').toString(),
                      accessor: (row: any) => {
                          if (row.currency !== 'EUR') {
                            return (
                                <div className="flex space-x-2 items-center">
                                    <div>
                                        <NumberFormat
                                            value={row.paymentAmount}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            decimalSeparator={','}
                                            thousandSeparator={'.'}
                                            prefix={row.currency + ' '}
                                        />
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                                        </svg>
                                    </div>
                                    <div>
                                        <NumberFormat
                                            value={(row.exchangeRate * row.paymentAmount)}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            decimalSeparator={','}
                                            thousandSeparator={'.'}
                                            prefix={'EUR '}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <NumberFormat
                                value={row.paymentAmount}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                decimalSeparator={','}
                                thousandSeparator={'.'}
                                prefix={row.currency + ' '}
                            />
                        )
                      }
                  },
                  {
                      Header: t('createPayment.paymentDate').toString(),
                      accessor: (row: any) => {
                          return formatDate(row.paymentDate, 'DD-MM-YYYY HH:mm')
                      }
                  },
                  {
                    Header: t('general.edit').toString(),
                    accessor: (row: BookingPayment) => {
                        if (row.paymentInsuredAt) {
                            return (<div className="font-semibold text-orange-700">{t('vary.paymentLocked')}</div>)
                        }
                        return (
                            <div className="flex space-x-2">
                                <button onClick={() => setCurrentEdit(row.idBookingPayment)} className="text-orange-600 underline">{t('Modifica')}</button>
                                <button onClick={() => setCurrentDelete(row.idBookingPayment)} className="text-red-600 underline">{t('Elimina')}</button>
                            </div>
                        )
                    }
                }
              ]} />
          </>
    )

}
