import { motion } from "framer-motion"
import React from 'react'
import { useEffect, useRef, useState } from "react"
import { Tab } from "../../types"
// import useResizeAware from 'react-resize-aware'
import Icon from "../common/Icon"
import Popover from "../common/Popover"

type TabSelectorProps = {
  tabs: Tab[],
  className?: string,
  onChange?: (tabs: Tab) => void
}
export default function TabSelector ({
  tabs,
  className,
  onChange
} : TabSelectorProps) {

  const refs = useRef<React.RefObject<HTMLDivElement>[]>(tabs.map(() => React.createRef()))
  const [innerTabs, setInnerTabs] = useState<Tab[]>(tabs)

  useEffect(() => {
    if (onChange) {
      const tab =  innerTabs.find(t => t.active)
      if (tab) onChange(tab)
    }
  }, [ innerTabs ])

  // const [resizeListener, sizes] = useResizeAware()
  const [popupTabs, setPopupTabs] = useState<Tab[]>([])

  /*
  useEffect(() => {
    if (sizes.width) {
      let progressive = 0
      let hideFrom = refs.current.map(el => {
        return progressive += (el.current ? el.current?.clientWidth + 20 : 0)
      }).findIndex(el => el > (sizes.width || 0))

      if (hideFrom <= 0) return

      console.log(hideFrom)

      const popTbas = []

      for (let i = 0; i < refs.current.length; i++) {
        const el = refs.current[i]
        if (!el || !el.current) continue
        if (i < hideFrom) {
          el.current.style.visibility = 'visible'
        } else {
          el.current.style.visibility = 'hidden'
          popTbas.push(tabs[i])
        }
      }

      setPopupTabs(popTbas)
    }
  }, [
    sizes.width
  ])
  */

  function changeTab (key:string) {
    setInnerTabs(innerTabs.map(t => {
      if (t.key === key) {
        t.active = true
      } else {
        t.active = false
      }
      return t
    }))
  }

  return (
    <div
      style={{
        boxShadow: 'inset 0px 2px 4px rgba(0,0,0,0.1)',
        padding: '10px'
      }}
      className={'bg-gray-200 rounded-lg relative flex w-full overflow-hidden ' + className}>
      {
        innerTabs.map((tab:Tab, index:number) => (
          <div
            key={index}
            ref={refs.current[index]}
            className={'mr-2 cursor-pointer relative'}
            onClick={() => changeTab(tab.key)}>
            
            <span
              style={{
                boxShadow: tab.active ? '0px 1px 2px rgba(0,0,0,0.250), 0px 0px 0px 1px rgba(0,0,0,0.02)' : ''
              }}
              className={`font-medium px-2 py-1 ${tab.active ? 'bg-white rounded-lg' : 'text-gray-500 hover:text-gray-900'}`}>
              {tab.label}
            </span>

            {
              tab.pulse &&
              <div
                style={{
                  animation: 'pulse 2s infinite'
                }}
                className="border-2 border-white absolute top-0 -left-1 w-3 h-3 bg-red-600 rounded-full"></div>
            }

            {
              tab.active &&
              <motion.div
                style={{
                  bottom: '-2px'
                }}
                initial={{
                  opacity: 0
                }}
                animate={{
                  opacity: 1
                }}
                className={'absolute left-0 w-full border-cyan-600'}></motion.div>
            }
          </div>
        ))
      }

      <Popover
        className={'cursor-pointer absolute right-0 h-full flex items-center justify-center'}
        renderContent={() => (
          popupTabs.map((tab: Tab, index: number) => (
            <div
              key={index}
              onClick={() => changeTab(tab.key)}
              className={'mt-1 mb-1 hover:bg-cyan-100 pt-1 pb-1 px-3 cursor-pointer'}>
              {tab.label}
            </div>
          ))
        )}>
          {
            popupTabs.length > 0 && <Icon name={'dots-horizontal'} size={'20px'} />
          }
      </Popover>
      
    </div>
  )

}