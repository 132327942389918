import FormField from "../form/FormField";
import TextInput from "../form/TextInput";
import {useState} from "react";
import Modal from "../common/Modal";
import ModalCard from "../common/ModalCard";
import ModalActions from "../common/ModalActions";
import {useMutation, useQuery} from "react-query";
import {getAccomodationPolicyRate, getQuoteCustomersByEmail, getRooms, getSettings, markHotelQuote, saveQuote} from "../../shared/queries";
import {QuoteCustomer, QuoteRoom, Room} from "../../types";
import Checkbox from "../form/Checkbox";
import {motion} from 'framer-motion'
import DatePickerInput from "../form/DatePickerInput";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import QuotePreview from "./QuotePreview";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import AddRoomModal from "./AddRoomModal";
import QuoteCustomerSelector from "./QuoteCustomerSelector";
import QuoteOptions from "./QuoteOptions";
import { fetchQuote, setBesafeIncluded, setExpireTime, setInsuranceMarkup, setInsuranceRate, setMessage } from "../../store/slices/offerSlice";
import QuotePolicyConfiguration from "./QuotePolicyConfiguration";
import QuoteExpireSettings from "./QuoteExpireSettings";
import toast from "react-hot-toast";
import quoteSchema from "../../validation/quoteSchema";
import { ValidationError, ValidationErrorItem } from "joi";
import Lottie from "lottie-react";
import loading from '../../lottie/loading.json'
import confirmed from '../../lottie/confirmed.json'
import QuoteConfirmationInfo from "./QuoteConfirmationInfo";
import QuotePaymnetProcessing from "./QuotePaymentProcessing";

const GuestSelector = ({ label, value, onChange } : { label: string, value: number, onChange: (val: number) => void }) => {

  return (
    <div className={'flex space-x-1 items-center'}>
      <div className="flex space-x-1 items-center">
        <div className={'text-gray-600'}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
            <path
              d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z"/>
          </svg>
        </div>
        <div className={'w-16 text-gray-600'}>{value} {label}</div>
      </div>
      <div className={'text-gray-600 flex space-x-1'}>
        <button onClick={() => onChange(value + 1)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clipRule="evenodd" />
          </svg>
        </button>
        <button onClick={() => onChange(value - 1)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  )

}

export default function AddQuote ({ onClose, onSent, onRefresh } : { onRefresh: () => void, onClose: () => void, onSent: () => void }) {

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { currentHotel } = useAppSelector(state => state.hotels) as any
  const { quote } = useAppSelector(state => state.offerSlice)

  const [editRoom, setEditRoom] = useState<number | undefined>()
  const [addRoom, setAddRoom] = useState(false)
  const [showNote, setShowNote] = useState(false)
  const [askBillInfo, setAskBillInfo] = useState(false)

  const [errors, setErrors] = useState<ValidationError | undefined>()
  const [note, setNote] = useState<string>('')

  const {
    data: rooms,
    isFetching: loadingRooms,
    refetch: refetchRooms
  } = useQuery([currentHotel], () => getRooms(currentHotel))

  const approveMutation = useMutation((data: {id: any, action: any}) => markHotelQuote(data.id, data.action), {
    onSuccess: () => {
      dispatch(fetchQuote({ idQuote: quote.idQuote }))
    },
    onError: () => {
      dispatch(fetchQuote({ idQuote: quote.idQuote }))
    }
  })

  useQuery(['accomodationRate', currentHotel], () => getAccomodationPolicyRate(currentHotel), {
      retry: false,
      onSuccess: data => {
        if (data) {
          const rate = data.filter(d => {
            return d.connectedRates === '' || !d.connectedRates || d.connectedRates.indexOf('MANUAL') > -1
          })
          if (rate && rate.length > 0) {
            dispatch(setInsuranceRate((rate[0].insuranceRate + rate[0].internalRate)))
          } else {
            dispatch(setInsuranceRate(5))
          }
        }
      }
  })

  function getRoomData (idRoom: number) {
    console.log(idRoom, rooms)
    const room = rooms?.filter(r => r.idRoom).find(r => r.idRoom === idRoom)
    return {
      url: (room && room.imageUrl) ? room.imageUrl : '',
      name: (room && room.name) ? room.name : '',
      description: (room && room.description) ? room.description : '',
    }
  }

  const saveMutation = useMutation(() => saveQuote({
    ...quote,
    idAccomodation: currentHotel,
    options: quote.options.map(o => {
      return {
        ...o,
        idQuoteOption: (typeof o.idQuoteOption === 'string') ? undefined : o.idQuoteOption,
        services: o.services.map(s => {
          return {
            ...s,
            idQuoteOptionService: (typeof s.idQuoteOptionService === 'string') ? undefined : s.idQuoteOptionService,
            idQuoteOption: (typeof s.idQuoteOption === 'string') ? undefined : s.idQuoteOption
          }
        }),
        rooms: o.rooms.map(r => {
          return {
            ...r,
            idQuoteRoom: (typeof r.idQuoteRoom === 'string') ? undefined : r.idQuoteRoom
          }
        })
      }
    })
  }), {
    onSuccess: data => {
      toast.success('Offerta salvata')
      onSent()
    }
  })

  function isQuoteConfirmed () {
    if (quote && quote.idQuoteOptionAccepted && quote.acceptedAt) {
      return true
    }
    return false
  }
  
  function handleSave () {
    // validate
    const { error, value, warning } = quoteSchema.validate({
      ...quote,
      idAccomodation: currentHotel,
      options: quote.options.filter(o => !o.toDelete).map(o => {
        return {
          ...o,
          idQuoteOption: (typeof o.idQuoteOption === 'string') ? undefined : o.idQuoteOption,
          rooms: o.rooms.map(r => {
            return {
              ...r,
              idQuoteRoom: (typeof r.idQuoteRoom === 'string') ? undefined : r.idQuoteRoom
            }
          }),
          services: o.services.map(s => {
            return {
              ...s,
              idQuoteOptionService: (typeof s.idQuoteOptionService === 'string') ? undefined : s.idQuoteOptionService,
              idQuoteOption: (typeof s.idQuoteOption === 'string') ? undefined : s.idQuoteOption
            }
          })
        }
      })
    }, {
      abortEarly: false
    })
    setErrors(error)
    console.log(error)
    if (error && error.details.length > 0) {
      toast.error(t('general.missingFields'))
      return
    } else {
      saveMutation.mutate()
    }
  }

  function handleMarkQuote (action: 'confirm' | 'reject') {
    if (!quote || !quote.idQuote) return
    approveMutation.mutate({
      id: quote.idQuote,
      action: action
    })
  }

  return (
    <motion.div
      initial={{
        top: 400,
        opacity: 0
      }}
      transition={{
        duration: 0.2
      }}
      exit={{ top: 400, opacity: 0 }}
      animate={{
        top: 0,
        opacity: 1
      }}
      style={{ zIndex: 999 }}
      className={'fixed top-0 left-0 overflow-hidden w-screen bg-white h-screen'}>

      {
        saveMutation.isLoading &&
        <div className="flex items-center justify-center fixed top-0 left-0 w-screen h-screen" style={{ zIndex: 999, backgroundColor: 'rgba(0,0,0,0.4)' }}>
          <div className="bg-white p-8 rounded-full">
            <Lottie
              animationData={loading} 
              className="w-52"
              loop={true}
            />
          </div>
        </div>
      }

      <div className="flex flex-col h-screen">
        <div className="px-6 py-4 border-b">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2 text-gray-600 text-lg">
              <div className={'border-r border-gray-300 pr-3 mr-2'}>
                <button onClick={onClose} className={'flex items-center'}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div>
                {t('quote.new')}
              </div>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => {
                  if (!quote.idQuote) return
                  window.open(`https://quote.besafesuite.com/${quote.idQuote}`, '_blank')
                }}
                className={`${quote.idQuote ? 'opacity-100' : 'opacity-50'} text-orange-600 hover:bg-orange-300 bg-orange-100 px-4 py-2 rounded-lg flex items-center space-x-2`}>
                <div>{t('quote.view')}</div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clipRule="evenodd" />
                </svg>
              </button>

              {
                (isQuoteConfirmed() || quote.paymentStatus === 'processing') ?
                <button
                  onClick={onSent}
                  className={'bg-orange-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2'}>
                  <div>{t('general.close')}</div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
                  </svg>
                </button>
                :
                <button
                  onClick={handleSave}
                  className={'bg-orange-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2'}>
                  <div>{t('quote.send')}</div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                  </svg>
                </button>
              }
            </div>
          </div>
        </div>
        
        <div className="overflow-hidden flex flex-1">
          <div className="w-1/3 overflow-auto">
            <div className="p-6 flex flex-col space-y-4">

              <div className="text-gray-700 text-lg mt-2 font-bold">
                {t('quote.guest')}
              </div>

              <FormField errors={errors} errorKey="customer">
                <QuoteCustomerSelector />
              </FormField>

              {
                errors && errors.details.filter((d: ValidationErrorItem) => d.context?.key === 'idQuoteCustomer').length > 0 &&
                <div className="text-red-600">{t('quote.mustSelectGuest')}</div>
              }

              <div className="text-gray-700 text-lg pt-4 font-bold">
                {t('quote.offerOptions')}
              </div>

              <QuoteOptions />

              {
                errors && errors.details.filter((d: ValidationErrorItem) => d.context?.key === 'options').length > 0 &&
                <div className="text-red-600">{t('quote.mustAddOption')}</div>
              }

              {
                errors && errors.details.filter((d: ValidationErrorItem) => d.context?.key === 'price').length > 0 &&
                <div className="text-red-600">{t('quote.minPrice')}</div>
              }

              {
                errors && errors.details.filter((d: ValidationErrorItem) => d.context?.key === 'rooms').length > 0 &&
                <div className="text-red-600">{t('quote.minRooms')}</div>
              }

              <div className="text-gray-700 text-lg pt-4 font-bold">
                {t('quote.cancellationPolicy')}
              </div>

              <QuotePolicyConfiguration />

              {
                errors && errors.details.filter((d: ValidationErrorItem) => d.context?.key === 'idCancellationPolicy').length > 0 &&
                <div className="text-red-600">{t('quote.mustSelectPolicy')}</div>
              }

              <div className="text-gray-700 text-lg pt-4 font-bold">
                {t('quote.insurance')}
              </div>

              <Checkbox
                onChange={() => dispatch(setBesafeIncluded(!quote.besafeIncluded))}
                checked={quote.besafeIncluded}
                label={t('quote.besafeIncluded')}
              />

              {
                !quote.besafeIncluded &&
                <>
                  <div className="text-slate-600 font-medium flex space-x-2">
                    <div>
                      🎉
                    </div>
                    <div>
                      {t('quote.addInsurance')}
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <div className="w-64">
                      <TextInput
                        value={quote.insuranceRate}
                        suffix={t('quote.commission')}
                        numbered={true}
                      />
                    </div>
                    <div className="w-64">
                      <TextInput
                        value={quote.insuranceMarkup}
                        suffix={'Markup'}
                        numbered={true}
                        onChange={val => dispatch(setInsuranceMarkup(parseFloat(val)))}
                      />
                    </div>
                  </div>
                </>
              }

              <div className="text-gray-700 text-lg pt-4 font-bold">
                {t('quote.advanced')}
              </div>

              <Checkbox
                onChange={() => {
                  if (quote.expiresAt !== undefined) {
                    dispatch(setExpireTime(undefined))
                  } else {
                    dispatch(setExpireTime(60))
                  }
                }}
                checked={quote.expiresAt !== undefined}
                label={t('quote.noExpire')}
              />

              {
                (quote.expiresAt !== undefined) && <QuoteExpireSettings />
              }

              <Checkbox onChange={() => setShowNote(!showNote)} checked={showNote} label={t('quote.customMessage')} />
              {
                showNote &&
                <FormField
                  className={'flex-1'}
                  label={t('quote.note')}
                  errorKey={'note'}>
                  <TextInput
                    type={'textarea'}
                    value={quote.message}
                    onChange={val => dispatch(setMessage(val))} />
                </FormField>
              }

              <Checkbox onChange={() => setAskBillInfo(!askBillInfo)} checked={askBillInfo} label={t('quote.requestBillingInfo')} />
            </div>
          </div>
          <div className="p-6 overflow-auto flex-1 border-l bg-gray-50">
            {
              (quote && quote.paymentStatus === 'processing')
                ? <QuotePaymnetProcessing />
                : !isQuoteConfirmed()
                ? <QuotePreview
                  currentHotel={currentHotel}
                  showNote={showNote}
                />
                :
                <div className="flex flex-col space-y-2 justify-center items-center">
                  <div className="flex flex-col items-center space-y-2">
                    <Lottie
                      animationData={confirmed}
                      className="w-52"
                      loop={false}
                    />
                    <div className="text-2xl text-slate-700 font-bold">
                      {t('quote.acceptedTitle')}
                    </div>
                    <div className="text-lg text-slate-600 mb-4">
                      {t('quote.acceptedText')}
                    </div>

                    <div className="pt-4">
                      <div className="w-full max-w-xl p-4 rounded-md bg-sky-100 text-sky-700">
                        <div className="text-lg font-semibold">
                          {t('quote.customerWaitingYou')}
                        </div>
                        <div className="pt-4 text-lg">
                          {t('quote.customerWaitingYouText')}
                        </div>
                        
                        {
                          (quote.hotelConfirmedAt === null && quote.hotelRejectedAt === null) ?
                          <div className="flex space-x-4 mt-6">
                            <button
                                onClick={() => handleMarkQuote('confirm')}
                                className="w-full px-4 py-2 rounded-md text-white bg-sky-800 flex space-x-2 justify-center font-medium">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                <path d="M7.493 18.5c-.425 0-.82-.236-.975-.632A7.48 7.48 0 0 1 6 15.125c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75A.75.75 0 0 1 15 2a2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23h-.777ZM2.331 10.727a11.969 11.969 0 0 0-.831 4.398 12 12 0 0 0 .52 3.507C2.28 19.482 3.105 20 3.994 20H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 0 1-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227Z" />
                              </svg>
                              <div>{approveMutation.isLoading ? t('general.wait') : 'Conferma'}</div>
                            </button>
                            <button
                              onClick={() => handleMarkQuote('reject')}
                              className="w-full px-4 py-2 rounded-md text-sky-700 bg-sky-200 flex space-x-2 justify-center font-medium">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                <path d="M15.73 5.5h1.035A7.465 7.465 0 0 1 18 9.625a7.465 7.465 0 0 1-1.235 4.125h-.148c-.806 0-1.534.446-2.031 1.08a9.04 9.04 0 0 1-2.861 2.4c-.723.384-1.35.956-1.653 1.715a4.499 4.499 0 0 0-.322 1.672v.633A.75.75 0 0 1 9 22a2.25 2.25 0 0 1-2.25-2.25c0-1.152.26-2.243.723-3.218.266-.558-.107-1.282-.725-1.282H3.622c-1.026 0-1.945-.694-2.054-1.715A12.137 12.137 0 0 1 1.5 12.25c0-2.848.992-5.464 2.649-7.521C4.537 4.247 5.136 4 5.754 4H9.77a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23ZM21.669 14.023c.536-1.362.831-2.845.831-4.398 0-1.22-.182-2.398-.52-3.507-.26-.85-1.084-1.368-1.973-1.368H19.1c-.445 0-.72.498-.523.898.591 1.2.924 2.55.924 3.977a8.958 8.958 0 0 1-1.302 4.666c-.245.403.028.959.5.959h1.053c.832 0 1.612-.453 1.918-1.227Z" />
                              </svg>
                              <div>{approveMutation.isLoading ? t('general.wait') : 'Rifiuta'}</div>
                            </button>
                          </div>
                          :
                          <div className="mt-4 text-lg">
                            Hai <span className="font-bold">{quote.hotelConfirmedAt ? 'confermato' : 'rifiutato'}</span> il preventivo alle {quote.hotelConfirmedAt?.toString() ? dayjs(quote.hotelConfirmedAt?.toString(), 'YYYYMMDDHHmm').format('DD/MM/YY HH:mm') : dayjs(quote.hotelRejectedAt, 'YYYYMMDDHHmm').format('DD/MM/YY HH:mm')}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
  
                  <div className="pt-4 w-full">
                    <QuoteConfirmationInfo />
                  </div>
                </div>
            }
          </div>
        </div>
      </div>

      <AddRoomModal
        editRoom={editRoom}
        onClose={() => {
          setAddRoom(false)
          refetchRooms()
        }}
        visible={addRoom}
      />
    </motion.div>
  )

}

const OfferRoomRow = ({
  offerRoom,
  rooms,
  loadingRooms,
  onAddRoom,
  onEditRoom,
  onChange,
  onRemove
} : {
  offerRoom: QuoteRoom,
  rooms?: Room[],
  loadingRooms: boolean,
  onEditRoom: (id?: number) => void,
  onAddRoom: () => void,
  onChange: (of: QuoteRoom) => void,
  onRemove: () => void
}) => {

  const { t } = useTranslation()

  const [selectRoom, setSelectRoom] = useState(false)
  const [selectedRoom, setSelectedRoom] = useState<any>()

  function getRoom () {
    if (!rooms) return undefined
    const room = rooms.find(r => r.idRoom === selectedRoom)
    if (room) return room
    return undefined
  }

  return (
    <div className="relative rounded-md px-2 pb-4 button-shadow flex flex-col p-2">
      <Modal visible={selectRoom}>
        <ModalCard
          className={'w-full max-w-xl'}
          renderActions={() => <ModalActions
            onClose={() => setSelectRoom(false)}
            onSave={() => {
              if (rooms) {
                const room = rooms.find(r => r.idRoom === selectedRoom)
                if (room && room.idRoom) {
                  onChange({
                    ...offerRoom,
                    idRoom: room.idRoom,
                    imageUrl: room.imageUrl,
                    description: room.description
                  })
                  setSelectRoom(false)
                }
              }
            }}
            saveLabel={'Conferma'}
          />}
          title={'Scegli una camera'}>
          <div className="p-4 flex flex-col space-y-5">
            {
              (rooms) && rooms.map((room, index) => (
                <button
                  onClick={() => setSelectedRoom(room.idRoom)}
                  key={index}
                  className={`${selectedRoom === room.idRoom ? 'bg-orange-200 text-orange-700 shadow-lg' : 'bg-gray-50 hover:bg-gray-100 text-gray-600'} w-full flex items-center space-x-4 cursor-pointer rounded-lg p-4`}>
                  <div className="w-full flex items-center justify-between">
                    <div className={'flex items-center space-x-4'}>
                      <div>
                        <div className={'bg-cover bg-center w-16 h-16 rounded-lg'} style={{ backgroundImage: `url(${room.imageUrl})` }}></div>
                      </div>
                      <div>
                        <div className="text-lg font-medium">{room.name}</div>
                      </div>
                    </div>
                    <div className={'flex space-x-2 items-center'}>
                      <button onClick={() => {
                        if (room && room.idRoom) onEditRoom(room.idRoom)
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                          <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                          <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                        </svg>
                      </button>
                      {
                        (selectedRoom === room.idRoom) &&
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                          <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                        </svg>
                      }
                    </div>
                  </div>
                </button>
              ))
            }

            <button
              onClick={() => {
                onEditRoom(undefined)
                onAddRoom()
              }}
              className={`text-center text-lg border font-medium py-5 hover:bg-gray-100 text-gray-600 w-full cursor-pointer rounded-lg p-4`}>
              Crea nuova tipologia
            </button>
          </div>
        </ModalCard>
      </Modal>
      <button
        onClick={onRemove}
        className={'text-gray-600 absolute -right-3 -top-3'}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
        </svg>
      </button>

      <div className="flex space-x-2">

        <div>
          <div className="flex space-x-4">
            <div className={'flex-1'}>
              <FormField
                errors={undefined}
                errorKey={'bookingArrivalDate'}
                label={t('general.arrival')}>
                <DatePickerInput
                  mode={'single'}
                  value={undefined}
                  onChange={(val) => {
                    if (val) {
                    }
                  }} />
              </FormField>
            </div>

            <div className={'flex-1'}>
              <FormField
                errorKey={'bookingDepartureDate'}
                label={t('general.departure')}>
                <DatePickerInput
                  fromDate={dayjs(undefined).add(1, 'day').toDate()}
                  mode={'single'}
                  value={undefined}
                  onChange={(val) => {
                    if (val) {
                    }
                  }} />
              </FormField>
            </div>
          </div>
        </div>

      </div>
      <div>
        {
          (getRoom() !== undefined) ?
          <div className="border-b pb-3 flex space-x-4 items-center">
            <div className="w-16 h-16 rounded-md bg-cover bg-center" style={{
              backgroundImage: `url(${getRoom()?.imageUrl})`
            }}></div>
            <div className="flex flex-col">
              <div className="font-medium">{getRoom()?.name}</div>
              <button onClick={() => setSelectRoom(true)} className="text-orange-600">cambia camera</button>
            </div>
          </div>
          :
          <div onClick={() => setSelectRoom(true)} className="mb-3 font-medium py-4 border border-dashed rounded-lg border-gray-300 px-4 text-center text-sm hover:bg-orange-100 cursor-pointer bg-orange-100">
            Scegli una tipologia di camera
          </div>
        }
      </div>
      <div className="mt-2 flex space-x-12 items-center justify-between">
        <FormField label={'Ospiti'}>
          <div className="flex-1 flex space-x-4">
            <GuestSelector value={offerRoom.adults} label={'Ad.'} onChange={(val) => {
              onChange({
                ...offerRoom,
                adults: val
              })
            }} />

            <GuestSelector value={offerRoom.children} label={'Bm.'} onChange={(val) => {
              onChange({
                ...offerRoom,
                children: val
              })
            }} />
          </div>
        </FormField>
        <div className={'w-32'}>
          <FormField
            label={'Prezzo'}
            errorKey={'price'}>
            <TextInput
              type={'currency'}
              value={offerRoom.price}
              onChange={val => onChange({
                ...offerRoom,
                price: val
              })} />
          </FormField>
        </div>
      </div>
    </div>
  )
}