import Button from "./form/Button"

export default function CompanyVerification () {

  return (
    <div className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center" style={{ zIndex: 99999 }}>
        <div className="bg-black opacity-60 w-full h-full flex absolute top-0 left-0 z-10">          
        </div>
        <div className="bg-white max-w-xl rounded-lg p-10 text-gray-700 z-20 relative">
          <div className="font-bold text-3xl">Benvenuto in BeSafe</div>

          <p className="mt-4 text-lg">
            Grazie per la tua registrazione e per il tuo interesse nel nostro prodotto.
            La tua dashboard è in fase di configurazione, i nostri operatiori stanno eseguendo le ultime verifiche
            prima di procedere all'attivazione del tuo profilo.
          </p>

          <div className="mt-10 border-t pt-6">
          <div className="mb-4 flex justify-center items-center">
              <div className={'md:mb-0'}>
                  <div className={"flex -space-x-6"}>
                      <div className={'border-4 border-white relative bg-cover z-10 bg-center w-20 h-20 rounded-full'} style={{
                          backgroundImage: 'url(/assets/team/francesca.jpg)'
                      }} />
                      <div className={'border-4 border-white relative bg-cover z-10 bg-center w-20 h-20 rounded-full'} style={{
                          backgroundImage: 'url(/assets/team/pasquale.png)'
                      }} />
                      <div className={'border-4 border-white relative bg-cover z-10 bg-center w-20 h-20 rounded-full'} style={{
                          backgroundImage: 'url(/assets/team/valeria.png)'
                      }} />
                      <div className={'border-4 border-white relative bg-cover z-10 bg-center w-20 h-20 rounded-full'} style={{
                          backgroundImage: 'url(/assets/team/marina.png)'
                      }} />
                  </div>
              </div>
            </div>
            <div className="mb-2 text-xl font-semibold text-gray-700 text-center">Dubbi o domande?</div>
            <div className="mb-10 text-lg text-center">
              il nostro team è a tua completa disposizione. Se hai bisogno di ulteriori informazioni o se necessiti
              di una più rapida attivazione non esitare a prenotare un incontro diretto con uno dei nostri
              specialisti
            </div>
          </div>

          <div className="mt-6 flex justify-center">
            <Button
              label={'Contatta il team'}
              className="text-orange-400 text-orange-900"
              onClick={() => {
                  window.open("https://calendly.com/bsg-onboarding/onboarding?month=2024-01", "_blank")
              }}
            />
          </div>
        </div>
      </div>
  )

}