import {PageWrapper} from "../components/common/PageWrapper";
import PageTitle from "../components/common/PageTitle";
import Icon from "../components/common/Icon";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {Fragment} from "react";
import {getPendingReport} from "../shared/queries";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";

export default function SettingsPage () {

  const history = useHistory()

  const { t } = useTranslation()
  const { profile } = useSelector((state: RootState) => state.session)

  async function handleDownloadReport () {
    const blob = await getPendingReport()
    const url = window.URL.createObjectURL(blob as any)
    const a = document.createElement('a')
    a.href = url;
    a.download = "besaferate_reservation_pending_export.csv"
    document.body.appendChild(a)
    a.click()
    a.remove()
    toast.success('Export completato, trovi il file nei download')
  }

  return (
    <PageWrapper centered>

      <PageTitle title={t('Configurazione')} />

      <button onClick={() => history.push('/app/hotels')} className="text-left w-full border-b py-6 flex items-center justify-between cursor-pointer space-x-2">
        <div>
          <div className="font-semibold text-lg text-gray-700">{t('vary.partners')}</div>
          <div className="font-normal text-gray-600">
            {t('vary.companySubtitle')}
          </div>
        </div>
        <div>
          <button>
            <Icon name={'arrow-right'} size={'25px'} />
          </button>
        </div>
      </button>

      <button onClick={() => history.push('/app/invoices')} className="text-left w-full border-b py-6 flex items-center justify-between cursor-pointer space-x-2">
        <div>
          <div className="font-semibold text-lg text-gray-700">{t('vary.billingCentre')}</div>
          <div className="font-normal text-gray-600">
            {t('vary.companySubtitle')}
          </div>
        </div>
        <div>
          <button>
            <Icon name={'arrow-right'} size={'25px'} />
          </button>
        </div>
      </button>

      {
        profile?.userAdmin &&
        <Fragment>
            <div className="mt-10 mb-4 text-gray-800 font-bold text-xl">
                {t('Amministrazione')}
            </div>
            {
              false &&
              <button onClick={() => history.push('/app/companies')} className="text-left w-full border-b py-6 flex items-center justify-between cursor-pointer space-x-2">
                <div>
                    <div className="font-semibold text-lg text-gray-700">{t('Anagrafiche fiscali')}</div>
                    <div className="font-normal text-gray-600">
                        {t('gestione dei dati di fatturazione associati alle strutture')}
                    </div>
                </div>
                <div>
                    <button>
                        <Icon name={'arrow-right'} size={'25px'} />
                    </button>
                </div>
            </button>
            }
            <button onClick={() => history.push('/app/intelligence/monitoring')} className="text-left w-full border-b py-6 flex items-center justify-between cursor-pointer space-x-2">
                <div>
                    <div className="font-semibold text-lg text-gray-700">Monitoraggio canali</div>
                    <div className="font-normal text-gray-600">
                        pannello per la visualizzazione di flussi API
                    </div>
                </div>
                <div>
                    <button>
                        <Icon name={'arrow-right'} size={'25px'} />
                    </button>
                </div>
            </button>

            <button onClick={() => history.push('/app/users')} className="text-left w-full border-b py-6 flex items-center justify-between cursor-pointer space-x-2">
                <div>
                    <div className="font-semibold text-lg text-gray-700">Utenti</div>
                    <div className="font-normal text-gray-600">
                        gestione utenti del sistema
                    </div>
                </div>
                <div>
                    <button>
                        <Icon name={'arrow-right'} size={'25px'} />
                    </button>
                </div>
            </button>

            <button onClick={() => history.push('/app/configuration/api-users')} className="text-left w-full border-b py-6 flex items-center justify-between cursor-pointer space-x-2">
                <div>
                    <div className="font-semibold text-lg text-gray-700">Utenti API</div>
                    <div className="font-normal text-gray-600">
                        gestione utenti per accesso API
                    </div>
                </div>
                <div>
                    <button>
                        <Icon name={'arrow-right'} size={'25px'} />
                    </button>
                </div>
            </button>

            <button onClick={() => history.push('/app/configuration/integrations')} className="text-left w-full border-b py-6 flex items-center justify-between cursor-pointer space-x-2">
                <div>
                    <div className="font-semibold text-lg text-gray-700">Integrazioni</div>
                    <div className="font-normal text-gray-600">
                        gestione della configurazione delle connessioni XML/JSON
                    </div>
                </div>
                <div>
                    <button>
                        <Icon name={'arrow-right'} size={'25px'} />
                    </button>
                </div>
            </button>

          <button onClick={() => handleDownloadReport()} className="text-left w-full border-b py-6 flex items-center justify-between cursor-pointer space-x-2">
            <div>
              <div className="font-semibold text-lg text-gray-700">Pending Report</div>
              <div className="font-normal text-gray-600">
                estrapolazione delle prenotazioni in stato pending
              </div>
            </div>
            <div>
              <button>
                <Icon name={'arrow-right'} size={'25px'} />
              </button>
            </div>
          </button>
        </Fragment>
      }

    </PageWrapper>
  )

}
