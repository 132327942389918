import dayjs from "dayjs";
import i18n from '../i18n/index'
import { AccomodationRate, Booking, BookingGuest, Policy, Translation } from "../types";
import { fetchInsuranceFile } from "./queries";

const shortNumberFormatter = Intl.NumberFormat('en', { notation: 'compact' });

export const formatCurrency = (value: number, currency?: string) => Intl.NumberFormat('it-It', { style: 'currency', currency: currency ? currency : 'EUR' }).format(value)

export function kFormatter (num: any) {
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1) as any) + 'k' : Math.sign(num) * Math.abs(num)
}

export function getMainGuestFromBooking (booking: Booking) : BookingGuest | null {
    if (booking.guests && booking.guests.length > 0) {
        return booking.guests[0]
    }
    return null
}

export function getSelectedInternalRate (bookingRate: string, accomodationRate?: AccomodationRate[]) {
    let foundRate = undefined

    if (accomodationRate) {
        foundRate = accomodationRate.find(ac => {
        if (ac.connectedRates) {
            const mappedRates = ac.connectedRates.split(',').map(mp => mp.toLowerCase())
            return mappedRates.includes(bookingRate.toLowerCase())
        }
        return false
    })

    if (!foundRate) {
        foundRate = accomodationRate.find(ac => ac.connectedRates === '' || ac.connectedRates === null || ac.connectedRates === undefined)
    }
    }

    return foundRate
}

export function needsTotalValues (bookingRate: string, accomodationRate?: AccomodationRate[]) {
    const currentRate = getSelectedInternalRate(bookingRate, accomodationRate)
    if (currentRate) {
        if (currentRate.insuranceRate === 0 && currentRate.internalRate === 0) {
            return false
        }
    }
    return true
}

export function checkPasswordValidity (password: string) : string[] {
    const missing = []
    // must be at least 8 characters long
    if (password.length < 8) missing.push('LENGTH')
    // must have at least 1 uppercase letter and 1 special character
    if (!/[A-Z]/.test(password)) missing.push('UPPERCASE')
    if (!/[a-z]/.test(password)) missing.push('LOWERCASE')
    if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(password)) missing.push('SPECIAL')
    return missing
}

export function formatDate (date: number, outputFormat: string) : string {
    if (!date) return 'ND'
    if (date.toString().length === 8) return dayjs(date.toString(), 'YYYYMMDD').format(outputFormat)
    if (date.toString().length === 12) return dayjs(date.toString(), 'YYYYMMDDHHmm').format(outputFormat)
    if (date.toString().length === 14) return dayjs(date.toString(), 'YYYYMMDDHHmmss').format(outputFormat)
    if (date.toString().length === 17) return dayjs(date.toString(), 'YYYYMMDDHHmmsssSSS').format(outputFormat)
    return 'ND'
}

export function abbreviateNumber (value: number) {
    return shortNumberFormatter.format(value)
}

export function getPolicyType (policy: Policy) : string {
    if (policy.policyIsSanitary) return 'Sanitaria'
    return 'Cancellazione'
}

export function getTranslatedValue (translations: Translation[], key: string) {
    console.log(key, translations)
    const translatedValue = translations.find(t => t.type === key)
    if (translatedValue) return translatedValue.value
    return ''
}

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export function getProductName (key: string) : string {
    switch (key) {
        case 'MANUAL_PLUS':
            return 'BeSafe Plus'
        case 'MANUAL_PLUS_INTERRUPTION':
            return 'BeSafe Plus - Interruzione'
        case 'SANITARY':
            return 'BeSafe Flex (spese mediche)'
        case 'CANCELLATION':
            return 'BeSafe solo Annullamento'
        case 'MANUAL':
            return 'BeSafe Classic'
        case 'WINTER':
            return 'BeSafe Winter'
        case 'BUSINESS':
            return 'BeSafe Bussiness'
        case 'SUMMER':
            return 'BeSafe Summer'
        case 'WORLD_SANITARY':
            return 'Sanitaria mondo (no pregresse)'
        case 'WORLD_CANC_SANITARY':
            return 'Cancellazione + Sanitaria mondo (no pregresse)'
        case 'WORLD_FIXED_SANITARY':
            return 'Sanitaria mondo (no pregresse)'
        case 'WORLD_FIXED_CANC_SANITARY':
            return 'Cancellazione + Sanitaria mondo (no pregresse)'
        case 'BESAFE_RAIN':
            return 'BeSafe Pioggia'
        default:
            return 'ND'
    }
}

export const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: true
        }
    },
    useModalOverlay: true
}

export async function getPolicyURL (policy: Policy, idBooking?: string, code?: string, forceDirect?: boolean, language?: string) {
    if (policy.dynamicFileUrl && !forceDirect) {
        const data = await fetchInsuranceFile(code, idBooking, policy.policyCompany.toLocaleLowerCase(), language)
        if (data.type === 'url') {
            window.open(data.url, '_blank')
        } else {
            const linkSource = `data:application/pdf;base64,${data.base64File}`
            const downloadLink = document.createElement("a")
            const fileName = data.fileName
            downloadLink.href = linkSource
            downloadLink.download = fileName
            downloadLink.click()
        }
    } else {
        const baseUrl = 'https://be-safe-assets.s3.eu-west-1.amazonaws.com/insurances/'
        window.open(baseUrl + `${policy.policyCompany}_${policy.policyCode}_${i18n.language}.pdf`, '_blank')
    }
}

export async function fileListToBase64(fileList: any) {
    function getBase64(file: any) {
        const reader = new FileReader()
        return new Promise(resolve => {
            reader.onload = (ev: any) => {
                resolve({
                    base64String: ev.target.result,
                    file
                })
            }
            reader.readAsDataURL(file)
        })
    }
    const promises = []
    for (let i = 0; i < fileList.length; i++) {
        promises.push(getBase64(fileList[i]))
    }
    return await Promise.all(promises)
}