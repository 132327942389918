import { motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";
import { ColumnInstance } from "react-table";
import Icon from "../common/Icon";
import Checkbox from "../form/Checkbox";

export default function TableColumnSelector ({
  columns,
  onChange
} : {
  columns: ColumnInstance[],
  onChange: (columns: string[]) => void
}) {

  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)
  const [filterCount, setFilterCount] = useState<number>(0)

  const [popperElement, setPopperElement] = useState<any>(null)
  const [referenceElement, setReferenceElement] = useState<any>(null)

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom'
    }
  )

  return (
    <div>
      <button        
        ref={setReferenceElement}
        onClick={() => setVisible(!visible)}
        style={{
          boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px'
        }}
        className={'flex items-center bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded'}>
        <div className={'flex py-1'}>
          <div className={'flex items-center'}>
            <Icon name={'menu-alt-2'} size={'16px'} className={'mr-2'} /> {t('general.columns')}
          </div>
          {
            filterCount > 0 &&
            <div className={'border-l text-sm font-medium ml-3 pl-3'}>
              { filterCount }
            </div>
          }
        </div>
      </button>
      {
        visible &&
        <div
          className={'z-50'}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
            <motion.div
              transition={{
                duration: 0.1
              }}
              initial={{
                scale: 0.8
              }}
              animate={{
                scale: 1
              }}>
              <div className={'m-1 max-h-80 overflow-auto w-52 bg-white border rounded shadow-lg'}>
                <div className={'flex p-2 bg-gray-50 border-b justify-between items-center'}>
                  <div className={'font-medium text-sm'}>
                    {t('general.columns')}
                  </div>
                  <div>
                    <button
                      style={{
                        boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px'
                      }}
                      onClick={() => {
                        setVisible(false)
                      }}
                      className={'bg-white text-sm flex text-cyan-600 items-center focus:outline-none px-3 text-sm font-medium py-1 rounded'}>
                      {t('general.done')}
                    </button>
                  </div>
                </div>
                {
                  columns.map((column, index) => (
                    <div key={index}>
                      <div className={'p-2 py-1 flex items-center border-b'}>
                        <Checkbox
                          size={'sm'}
                          label={(column && column.Header) ? column.Header.toString() : ''}
                          checked={column.getToggleHiddenProps().checked}
                          onChange={() => {
                            column.toggleHidden()
                            onChange(columns
                              .filter(c => !c.isVisible)
                              .filter(c => c.id !== column.id)
                              .map(c => c.id)
                            )
                          }}></Checkbox>
                      </div>
                    </div>
                  ))
                }
              </div>
            </motion.div>          
        </div>
      }
    </div>
  )

}