import { useTranslation } from "react-i18next"
import FormField from "../../form/FormField"
import HotelSelector from "../../navigation/HotelSelector"
import { useState } from "react"
import dayjs from "dayjs"
import { exportReservations } from "../../../shared/queries"
import toast from "react-hot-toast"
import SelectInput from "../../form/SelectInput"
import Button from "../../form/Button"
import { useSelector } from "react-redux"

export default function AccountingReport () {

  const { t } = useTranslation()

  const currentHotelObject = useSelector<any>(state => state.hotels.currentHotelObject) as any

  const [loading, setLoading] = useState(false)
  const [fromDate, setFromDate] = useState(new Date())
  const [year, setYear] = useState(parseInt(dayjs().format('YYYY')))

  async function handleExport () {
    try {
      if (!currentHotelObject) {
        alert('Devi selezionare un hotel')
        return
      }
      setLoading(true)
      const accomodations = {
        [currentHotelObject.idAccomodation]: currentHotelObject.accomodationName
      } as any
      const blob = await exportReservations<any>({
        filters: {
          page: 0,
          pageOffset: 0,
          criterias: [],
          sortType: 'DESC',
          orderBy: 'idBooking',
          maxResults: 5000
        },
        year,
        fromDate: parseInt(dayjs(fromDate).format('YYYYMMDD')),
        toDate: parseInt(dayjs(fromDate).format('YYYYMMDD')),
        columns: [],
        accomodations
      })
      const url = window.URL.createObjectURL(blob as any)
      const a = document.createElement('a')
      a.href = url;
      a.download = "besaferate_reservation_export.xlsx"
      document.body.appendChild(a)
      a.click()
      a.remove()
      toast.success('Export completato, trovi il file nei download')
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <div className="p-4 text-slate-600">
        {t('accountReport.text')}
      </div>

      <div className="px-4">
        <FormField className="mt-2 mb-6" label="Anno">
          <SelectInput
            value={{ label: year, value: year }}
            onChange={(val: any) => {
              setYear(val.value)
            }}
            options={[
              { label: '2024', value: 2024 }
            ]}
          />
        </FormField>

        <Button loading={loading} type={'primary'} label={t('accountReport.dowload')} onClick={handleExport} />
      </div>
    </div>
  )

}