import toast, {Toaster} from "react-hot-toast";

export default function CopyToClipboard ({ copyText, children, colLayout } : { copyText: string, children: any, colLayout?: boolean }) {

  function handleCopy (text: string) {
    const w = window as any
    if (w.clipboardData && w.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return w.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      }
      catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return prompt("Copy to clipboard: Ctrl+C, Enter", text);
      }
      finally {
        toast.success('Copiato in appunti')
        document.body.removeChild(textarea);
      }
    }
  }

  return (
    <div className={`flex items-center ${colLayout && 'flex-col space-y-4'}`}>
      <Toaster position={'bottom-center'} />
      <div className={'w-full'}>{children}</div>
      <div>
        <button onClick={() => handleCopy(copyText)} data-tip={'Copia'} className={`flex items-center justify-center text-gray-600 hover:bg-gray-100 rounded-lg p-1 ${colLayout && 'space-x-4 font-medium cursor-pointer bg-gray-100 rounded-lg px-4 py-2'}`}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
          </svg>
          {
            colLayout &&
            <div>Copia la descrizione</div>
          }
        </button>
      </div>
    </div>
  )

}
