import Toggle from 'react-toggle'
import {useQuery} from "react-query";
import {getChannelList} from "../shared/queries";
import CopyToClipboard from "../components/CopyToClipboard";
import Checkbox from "../components/form/Checkbox";
import TextInput from "../components/form/TextInput";
import FormField from "../components/form/FormField";
import {useEffect, useState} from "react";

const rateConfig = {
	'IT': {
		title: 'BeSafe Rate - La tariffa assicurata',
		value: 'classic',
		description: {
			classic: `Sai che l’80% delle cause di cancellazione di una vacanza si verificano a pochi giorni dalla partenza? BeSafe Rate protegge la tua vacanza e quella dei tuoi cari prima e durante il soggiorno. \n\n 1. ANNULLAMENTO FINO AL GIORNO DEL CHECK-IN E RIMBORSO FINO AL 100% DELL’IMPORTO PREPAGATO per eventi imprevisti, come malattia (COVID-19 incluso), degenza, infortunio, annullamento ferie, licenziamento o nuova assunzione. \n\n 2. ASSISTENZA DURANTE IL SOGGIORNO: dall’arrivo alla partenza potrai usufruire di assistenza sanitaria, garanzia bagaglio, furto ed assistenza stradale. \n\n 3. ASSISTENZA CLIENTI DEDICATA prima, durante e dopo il soggiorno. \n\n Prima di prenotare leggi il Fascicolo delle Coperture su https://www.travel.besafesuite.com/insurances/axa`,
			summer: `Sai che l’80% delle cause di cancellazione di una vacanza si verificano a pochi giorni dalla partenza? BeSafe Rate protegge la tua vacanza e quella dei tuoi cari anche con assistenza clienti dedicata prima, durante e dopo il soggiorno. \n\n 1. ANNULLAMENTO FINO AL GIORNO DEL CHECK-IN E RIMBORSO FINO AL 100% DELL’IMPORTO PREPAGATO per eventi imprevisti, come malattia (COVID-19 incluso), degenza, infortunio, annullamento ferie, licenziamento o nuova assunzione. \n\n 2. ASSISTENZA DURANTE IL SOGGIORNO: dall’arrivo alla partenza potrai usufruire di assistenza sanitaria, garanzia bagaglio, furto ed assistenza stradale. \n\n 3. SPECIALE SUMMER: durante il soggiorno sarai tutelato in caso di responsabilità verso terzi, garanzia infortuni nella pratica di sport e attività outdoor, rimborso dei servizi non usufruiti per infortunio. \n\n Prima di prenotare leggi il Fascicolo delle Coperture su \n https://www.travel.besafesuite.com/insurances/summer`,
			winter: `Sai che l’80% delle cause di cancellazione di una vacanza si verificano a pochi giorni dalla partenza? BeSafe Rate protegge la tua vacanza e quella dei tuoi cari anche con assistenza clienti dedicata prima, durante e dopo il soggiorno. \n\n 1. ANNULLAMENTO FINO AL GIORNO DEL CHECK-IN E RIMBORSO FINO AL 100% DELL’IMPORTO PREPAGATO per eventi imprevisti, come malattia (COVID-19 incluso), degenza, infortunio, annullamento ferie, licenziamento o nuova assunzione. \n\n 2. ASSISTENZA DURANTE IL SOGGIORNO: dall’arrivo alla partenza potrai usufruire di assistenza sanitaria, garanzia bagaglio, furto ed assistenza stradale. \n\n 3. SPECIALE WINTER: RC obbligatoria sulle piste e rimborso corsi/attrezzatura non usufruita causa incidenti o malattia. \n\n Prima di prenotare leggi il Fascicolo delle Coperture su: \n https://www.travel.besafesuite.com/insurances/winter`,
			business: 'Viaggio di lavoro non assicurato? Azzera le tue preoccupazioni e scegli BeSafe Rate che rimborsa il tuo soggiorno anche in caso di cancellazione evento / meeting di lavoro. E in più, assistenza clienti dedicata prima, durante e dopo il soggiorno. \n\n 1. ANNULLAMENTO FINO AL GIORNO DEL CHECK-IN E RIMBORSO FINO AL 100% DELL’IMPORTO PREPAGATO per eventi imprevisti, come malattia, degenza, infortunio. \n\n 2. RIMBORSO per annullamento evento, cancellazione o ritardo del volo. \n\n 3. RIMBORSO per perdita bagaglio e ASSISTENZA stradale e sanitaria durante il soggiorno. \n\n Consulta il Fascicolo Assicurativo: \n https://www.travel.besafesuite.com/insurances/business'
		},
		fullPayment: `La tariffa BeSafe Rate garantisce la cancellazione per eventi imprevisti e offre il rimborso fino al 100% dell'importo prepagato in conformità con il regolamento della Polizza Assicurativa applicata al momento della prenotazione.\n\n L'intero ammontare del soggiorno sarà prelevato al momento della prenotazione.`,
		tranchePayment: `La tariffa BeSafe Rate garantisce la cancellazione per eventi imprevisti e offre il rimborso fino al 100% dell'importo prepagato in conformità con il regolamento della Polizza Assicurativa applicata al momento della prenotazione. \n\n Il {PREPAID}% dell'importo della prenotazione sarà prelevato al momento della prenotazione. Il saldo del {REMAIN}% sarà richiesto {DAYS} giorni prima della data del check-in. \n La copertura assicurativa per l'Annullamento Viaggio inclusa nella polizza assicurativa sarà  applicata all'importo prepagato, rimangono invariate le coperture relative incluse relative all'assistenza.`,
		partialPayment: `La tariffa BeSafe Rate garantisce la cancellazione per eventi imprevisti e offre il rimborso fino al 100% dell'importo prepagato in conformità con il regolamento della Polizza Assicurativa applicata al momento della prenotazione. \n\n Il {PREPAID}% dell'importo della prenotazione sarà prelevato al momento  della prenotazione. Il saldo del {REMAIN}% sarà richiesto in struttura. \n La copertura assicurativa per l'Annullamento Viaggio inclusa nella polizza assicurativa sarà applicata all'importo prepagato, rimangono invariate le coperture assicurative incluse relative all'assistenza.`
	},
	'EN': {
		title: 'BeSafe Rate - The Insured Rate',
		value: 'classic',
		description: {
			classic:  `Did you know that 80% of holiday cancellations occur only a few days before departure? BeSafe Rate protects your holiday and that of your loved ones before and during your stay. \n\n 1. CANCELLATION UNTIL THE DAY OF CHECK-IN AND REFUND OF UP TO 100% OF THE PREPAID AMOUNT for unforeseen events, such as illness (COVID-19 included), hospitalisation, accident, holiday cancellation, redundancy or new employment. \n\n 2. ASSISTANCE DURING YOUR STAY: from arrival to departure, you can benefit from medical assistance, luggage guarantee, theft and roadside assistance. \n\n 3. DEDICATED CUSTOMER CARE before, during and after your stay. \n\n Before booking, read the Insurance Policy at \n https://www.travel.besafesuite.com/insurances/axa`,
			summer: `Did you know that 80% of holiday cancellations occur only a few days before departure? BeSafe Rate protects your holiday and that of your loved ones with dedicated customer care before, during and after your stay. \n\n 1. CANCELLATION UNTIL THE DAY OF CHECK-IN AND REFUND OF UP TO 100% OF THE PREPAID AMOUNT for unforeseen events, such as illness (COVID-19 included), hospitalisation, accident, holiday cancellation, redundancy or new employment. \n\n 2. ASSISTANCE DURING YOUR STAY: from arrival to departure, you can benefit from medical assistance, luggage guarantee, theft and roadside assistance. \n\n 3. SUMMER SPECIAL: during your holiday, you will be covered in the event of third-party liability, injuries during outdoor sports and activities, and reimbursed for unused services due to injury. \n\n Before booking, read the Insurance Policy at \n https://www.travel.besafesuite.com/insurances/summer`,
			winter: `Did you know that 80% of holiday cancellations occur only a few days before departure? BeSafe Rate protects your holiday and that of your loved ones with dedicated customer care before, during and after your stay. \n\n 1. CANCELLATION UNTIL THE DAY OF CHECK-IN AND REFUND OF UP TO 100% OF THE PREPAID AMOUNT for unforeseen events, such as illness (COVID-19 included), hospitalisation, accident, holiday cancellation, redundancy or re-employment. \n\n 2. ASSISTANCE DURING YOUR STAY: from arrival to departure, you can benefit from medical assistance, luggage guarantee, theft and roadside assistance. \n\n 3. WINTER SPECIAL: compulsory third-party liability on the slopes and refund for courses/equipment not used due to accidents or illness. \n\n Before booking, read the Insurance Policy at \n https://www.travel.besafesuite.com/insurances/winter`,
			business: 'Business trip not insured? Reset your worries and choose BeSafe Rate which refunds your stay even in case the event/work meeting is cancelled. Plus, dedicated customer support before, during and after your stay. \n\n 1. CANCELLATION UNTIL THE DAY OF CHECK-IN AND REFUND OF UP TO 100% OF THE PREPAID AMOUNT for unforeseen events, such as illness, hospitalisation, accident. \n\n 2. REFUND for event cancellation, flight cancellation or delay. \n\n 3. REFUND for lost luggage and ASSISTANCE on road and for medical during your stay. \n\n Before booking, read the Insurance Policy: https://www.travel.besafesuite.com/insurances/business'
		},
		fullPayment: `The BeSafe Rate guarantees cancellation for unforeseen events and offers a refund of up to 100% of the prepaid amount in accordance with the regulations of the Insurance Policy applied at the time of booking. \n\n The full amount of the stay will be charged at the time of booking. `,
		tranchePayment: `The BeSafe Rate guarantees cancellation for unforeseen events and offers a refund of up to 100% of the prepaid amount in accordance with the regulations of the Insurance Policy applied at the time of booking. \n\n {PREPAID}% of the booking amount will be charged at the time of booking. \n The balance of {REMAIN}% will be required {DAYS} days before the check-in date. The Travel Cancellation insurance coverage included in the insurance policy will be applied to the prepaid amount, the included insurance coverage relating to assistance remains unchanged.`,
		partialPayment: `The BeSafe Rate guarantees cancellation for unforeseen events and offers a refund of up to 100% of the prepaid amount in accordance with the regulations of the Insurance Policy applied at the time of booking. \n\n {PREPAID}% of the booking amount will be charged at the time of booking. The balance of {REMAIN}% will be required at check-in/out. The Travel Cancellation insurance coverage included in the  insurance policy will be applied to  the prepaid amount, the included insurance coverage relating to assistance remains unchanged.`
	},
	'FR': {
		title: 'BeSafe Rate - The Insured Rate',
		value: 'classic',
		description: {
			classic:  `Saviez-vous que 80 % des annulations de vacances ont lieu quelques jours seulement avant le départ ? BeSafe Rate protège vos vacances et celles de vos proches avant et pendant votre séjour. \n\n 1. ANNULATION JUSQU'AU JOUR DE L'ENREGISTREMENT ET REMBOURSEMENT JUSQU'À 100 % DU MONTANT PRÉPAYÉ en cas d'événements imprévus, tels que maladie (COVID-19 inclus), hospitalisation, accident, annulation de vacances, licenciement ou nouvel emploi. \n\n 2. ASSISTANCE PENDANT LE SÉJOUR: de votre arrivée jusqu’à votre départ, vous pourrez bénéficier d’une assistance médicale, d’une garantie bagages, vol et assistance routière. \n\n 3. UNE ASSISTANCE CLIENTÈLE DÉVOUÉE avant, pendant et après votre séjour. \n\n Avant de réserver, lire le Dossier des polices: \n https://www.travel.besafesuite.com/insurances/axa`,
			summer: `Saviez-vous que 80 % des annulations de vacances ont lieu quelques jours seulement avant le départ? BeSafe Rate protège également vos vacances et celles de vos proches grâce à un service clientèle dédié avant, pendant et après votre séjour. \n\n 1. ANNULATION JUSQU'AU JOUR DE L'ENREGISTREMENT ET REMBOURSEMENT JUSQU'À 100 % DU MONTANT PRÉPAYÉ en cas d'événements imprévus, tels que maladie (COVID-19 inclus), hospitalisation, accident, annulation de vacances, licenciement ou nouvel emploi. \n\n 2. ASSISTANCE PENDANT LE SÉJOUR: de votre arrivée jusqu’à votre départ, vous pourrez bénéficier d’une assistance médicale, d’une garantie bagages, vol et assistance routière. \n\n 3. SPÉCIAL ÉTÉ: pendant votre séjour, vous serez protégé en cas de responsabilité envers des tiers, vous bénéficierez de la garantie accident pour les activités sportives et en plein air, ainsi que du remboursement des services non utilisés en cas d’accident. \n\n Avant de réserver, lire le Dossier des polices: \n https://www.travel.besafesuite.com/insurances/summer`,
			winter: `Saviez-vous que 80 % des annulations de vacances ont lieu quelques jours seulement avant le départ ? BeSafe Rate protège également vos vacances et celles de vos proches grâce à un service clientèle dédié avant, pendant et après votre séjour. \n\n 1. ANNULATION JUSQU'AU JOUR DE L'ENREGISTREMENT ET REMBOURSEMENT JUSQU'À 100 % DU MONTANT PRÉPAYÉ en cas d'événements imprévus, tels que maladie (COVID-19 inclus), hospitalisation, accident, annulation de vacances, licenciement ou nouvel emploi. \n\n 2. ASSISTANCE PENDANT LE SÉJOUR de votre arrivée jusqu'à votre départ, vous bénéficierez d'une assistance santé, d'une garantie bagages, assurance contre le vol et assistance routière. \n\n 3. SPÉCIAL WINTER: : pendant votre séjour, vous serez protégé en cas de responsabilité civile envers les tiers, frais de secours sur les pistes, services non utilisés pour cause de blessure. \n\n Avant de réserver, lire le Dossier des polices \n https://www.travel.besafesuite.com/insurances/winter`,
			business: 'Voyage d\'affaires non assuré ? Ne vous inquiétez pas et choisissez BeSafe Rate qui rembourse votre séjour même en cas d\'annulation d\'un événement ou d\'une réunion de travail. De plus, vous bénéficiez d\'une assistance clientèle dédiée avant, pendant et après votre séjour. \n\n 1. ANNULATION JUSQU\'AU JOUR DE L\'ENREGISTREMENT ET REMBOURSEMENT JUSQU\'À 100 % DU MONTANT PRÉPAYÉ en cas d\'événements imprévus, tels que maladie, hospitalisation, accident. \n\n 2. REMBOURSEMENT en cas d\'annulation d\'un événement, d\'un vol ou d\'un retard. \n\n 3. REMBOURSEMENT en cas de perte de bagages, d\'assistance routière et médicale pendant le séjour. \n\n Avant de réserver, lire le Dossier des polices: \n https://www.travel.besafesuite.com/insurances/business'
		},
		fullPayment: `Le BeSafe Rate garantit l’annulation en cas d’imprévu et offre un remboursement jusqu’à 100% du montant prépayé conformément aux règlements de la police d’assurance appliquée au moment de la réservation.`,
		tranchePayment: `The BeSafe Rate guarantees cancellation for unforeseen events and offers a refund of up to 100% of the prepaid amount in accordance with the regulations of the Insurance Policy applied at the time of booking. \n\n {PREPAID}% of the booking amount will be charged at the time of booking. \n The balance of {REMAIN}% will be required {DAYS} days before the check-in date. The Travel Cancellation insurance coverage included in the insurance policy will be applied to the prepaid amount, the included insurance coverage relating to assistance remains unchanged.`,
		partialPayment: `The BeSafe Rate guarantees cancellation for unforeseen events and offers a refund of up to 100% of the prepaid amount in accordance with the regulations of the Insurance Policy applied at the time of booking. \n\n {PREPAID}% of the booking amount will be charged at the time of booking. The balance of {REMAIN}% will be required at check-in/out. The Travel Cancellation insurance coverage included in the  insurance policy will be applied to  the prepaid amount, the included insurance coverage relating to assistance remains unchanged.`
	}
} as any

const SetupStrategy = () => {

	const [rate, setRate] = useState('classic')
	const [language, setLanguage] = useState('IT')
	const [prepaidAmount, setPrepaidAmount] = useState(100)
	const [paymentBeforeArrival, setPaymentBeforeArrival] = useState(false)
	const [days, setDays] = useState(15)

	useEffect(() => {
		if (prepaidAmount === 100) {
			setPaymentBeforeArrival(false)
		}
	}, [
		prepaidAmount
	])

	return (
		<div>
			<div className="text-2xl">
				Crea la tariffa
			</div>
			<div className="leading-relaxed mt-4 text-lg text-gray-600">
				BeSafe Rate ha pensato a tutto! Qui trovi le descrizioni e le politiche di cancellazione per la tariffa BeSafe Rate nelle maggiori lingue Europee. Dovrai solamente copiare e incollare i testi, ricorda che sono elaborati in conformità
				alla direttiva assicurativa Europea e ottimizzati in termini di marketing.
			</div>
			<div className="text-lg mt-4 text-sm text-gray-700">
				L'utilizzo delle descrizioni è obbligatorio, il contenuto non può essere modificato.
			</div>

			<div className="my-6 border-t pt-6 pb-6 border-b border-gray-300">
				<div className="text-xl">
					Qualche domanda per personalizzare i testi
				</div>
				<div className="text-lg mt-4">
					<FormField label={'Quale percentuale di pre-pagamento vuoi applicare?'}>
						<div className="w-44">
							<TextInput value={prepaidAmount} onChange={val => setPrepaidAmount(val)} numbered={true} />
						</div>
					</FormField>

					{
						(prepaidAmount < 100) &&
						<div className={'mt-8'}>
								<Checkbox checked={paymentBeforeArrival} onChange={() => setPaymentBeforeArrival(!paymentBeforeArrival)} label={`Incasserai il restante ${100 - prepaidAmount}% prima dell'arrivo in struttura?`} />

								{
									paymentBeforeArrival &&
                    <FormField label={'Quanti giorni prima dell\'arrivo incasserai il saldo?'} className={'mt-6'}>
                        <div className="w-44">
                            <TextInput value={days} onChange={val => setDays(val)} numbered={true} />
                        </div>
                    </FormField>
								}
						</div>
					}
				</div>
			</div>

			<div className="my-6 flex space-x-4">
				<div>
					<button onClick={() => setLanguage('IT')}>
						<img className={'w-8'} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAbklEQVR4nO3YwQ3AIAxD0YzFhX2yFQsiISZIbx2AnqjelzwAAhLbEQAA/IEcrU401/6k6nEkB0g3sD0hnzhNoW2MvlhkyyYuViKZuc2NFjs9BJqSyLpI2YT6qZUItUoptpZmrlSLeUu5CwBAXMgDzRxz9wZ1eBwAAAAASUVORK5CYII=" />
					</button>
				</div>
				<div>
					<button onClick={() => setLanguage('EN')}>
						<img className={'w-8'} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEXUlEQVR4nO2Yj09TVxTH+0/NCTKKOCUoKBTZpPwQZUwCbChxZrgYolkIicRsI8vi5rZs7mfCzH6VgdMMgQGR8aMi/gKFMUv7Xkv7XmlL+9q+73Lv6ys8Hq8tUp1Z3je5aZr0nnM/99x77jk1GHTp0qVLly5dz4GiHIe1WglG8Pl3D/By6W8wFltSHrJsGS8ohqzN2DIWW5B7sBudn07Dx/nh/eQiFrN3UHsqAHv+bvh/+QkQRQXIgs2HE60j/wlA4+khPJz3Ijg8BOZgsWQrcxs8755TA8iO2CNVEO7eUUAQpt6+xyiq/v2ZABRWXkXXr3MQWAbus61xG2xlGULWSWpHBRC42gNHQb7046zt4D94D2LArwBZ9gk0nDtLup8KQI7Jgrb3J8G5A1j+5iss5hrpXPJJviMSidtRAVzumkXI6wP/YSdsO16kEwmQv3t1UbKm73tQ0zyQVoDXmgeo3dDkBNiKsvi8pZMnEHHYFf6FcFQNQIyY6//AzQkWwswDOGuPxo24GuoQnp9TGImKIn7u/Rt7y3u3BJBX1oOvr8xC8PDgLnSsbl5JEVaGBlWbN3l7CYffvLExgDxa2kbBOgN098nlpmHMzqTREUMhhUGXO0jD/iQALW2jYFjZzx7Jz0sZG/pZ8kh+yDEjcxMCkEF2llyksIdT7AzJBiQrrNfElAtVb9xICUAR6ddrlJGeU0eaJJDCKmUCSQogj5o1Z5MpP5TwbIYjYlIA8rYE+dhdy9qe8K7df8ih7tSfG64rZYD12cH3/bdY3CVnh2x4L30MURBUzrUAVgb64SgqiGc77kIHRJ9v09lOBYA0SwsgXTL87wBs6xw+78OgA2ToEUBaj1C6L5mehZIo5YdsX4VUm0ejykYnvLAAV1NjfKeddbUQZmeSRmB4jFUtJjQxDsb8quKVDzsYWkLsP7xxD5ISQOv5cbg5ZVElBoP09ZVbO3veLvo6O51++lonA5CLOAcbUNoNh6VXPtYD2HfvpN95Pii9yqbu1AEqGvrwl9Wp2qmVwQE4Sg5Ii8rcRrslweWmEco3S2V1KgC0jD7UQ+siUtuvVYRl4T79dnweW1WO0O0pWKeXUN3UnxggoVFFa2dGaOoWbt1x48jx/i31AxWNfRizutSbRWom037NzVIBkLDaGY2wrineli9/oRnWJ+3IckwWtHdawfHJj6v/xytwsP7kaTQ0PgbG/Ep8Ae4z7yDMsvRiHdC4WFvtiQsqNRLGo0dw1h9bTRj1x7QBojwPT3sbDRv5MVNqQnBkGLNzPBpahhIuYKsAxtioPTmIuzPK/6mIAtevwb4vT+Mhi0bh+6EL9j250nHJyYL3s0sIeAO4+OU9+gdTKs7TAWAsttDj2fHRFHz+sAIi6vWCO9+uBnDWVK/m4ebjiNgeY2iUQWnt9ZSdphPAGBumo9fosV0vFQBx4ijcS1s7m92Pt87d3LSzpwFgjI2mMyOY/2dZG0CXLl26dOnSZXj2+hdh4D9R4Q/X/wAAAABJRU5ErkJggg==" />
					</button>
				</div>
			</div>

			<div className={'mb-4 text-xl'}>
				{rateConfig[language].title}
			</div>
			<div className="flex space-x-6">
				<div className="w-1/2">
					<div style={{ whiteSpace: 'pre-line' }} className="bg-white p-6 rounded-md shadow-xl">
						<div className="text-gray-600 text-lg font-medium mb-2">Descrizione della tariffa</div>
						<CopyToClipboard colLayout={true} copyText={rateConfig[language].description[rate]}>
							{rateConfig[language].description[rate]}
						</CopyToClipboard>
					</div>
				</div>
				<div className="w-1/2">
					<div style={{ whiteSpace: 'pre-line' }} className="bg-white p-6 rounded-md shadow-xl">
						<div className={'text-gray-600 text-lg font-medium mb-2'}>Politica di cancellazione & pagamento</div>
						{
							(parseInt(prepaidAmount.toString()) === 100) &&
                <CopyToClipboard colLayout={true} copyText={rateConfig[language].fullPayment}>
									{rateConfig[language].fullPayment}
                </CopyToClipboard>
						}

						{
							(parseInt(prepaidAmount.toString()) < 100 && !paymentBeforeArrival) &&
                <CopyToClipboard colLayout={true} copyText={rateConfig[language].partialPayment.replace('{PREPAID}', prepaidAmount.toString()).replace('{REMAIN}', (100 - prepaidAmount).toString())}>
									{rateConfig[language].partialPayment.replace('{PREPAID}', prepaidAmount.toString()).replace('{REMAIN}', (100 - prepaidAmount).toString())}
                </CopyToClipboard>
						}

						{
							(parseInt(prepaidAmount.toString()) < 100 && paymentBeforeArrival) &&
                <CopyToClipboard colLayout={true} copyText={rateConfig[language].tranchePayment.replace('{PREPAID}', prepaidAmount.toString()).replace('{REMAIN}', (100 - prepaidAmount).toString()).replace('{DAYS}', days.toString())}>
									{rateConfig[language].tranchePayment.replace('{PREPAID}', prepaidAmount.toString()).replace('{REMAIN}', (100 - prepaidAmount).toString()).replace('{DAYS}', days.toString())}
                </CopyToClipboard>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

const SelectSoftware = ({ onNext } : { onNext: (id: any) => void }) => {

	const {
		data: channelList
	} = useQuery('channelList', getChannelList)

	return (
		<div>
			<div className={'mb-8'}>
				<div className="text-2xl font-medium">
					Scegli il gestionale da collegare
				</div>
				<div className="text-lg mt-1 text-gray-600">
					BeSafe Rate è integrata nativamente con oltre 50 gestionali in tutta Europa
				</div>
			</div>
			<div className="font-medium text-xl text-gray-700 mb-6">
				seleziona il gestionale che utilizzi
			</div>
			<div className={'grid grid-cols-4 gap-4 '}>
				{
					channelList && channelList.map((c, index) => (
						<div onClick={() => onNext(c.idApiChannel)} className={'bg-white rounded-lg p-4 text-md text-center cursor-pointer font-medium hover:bg-orange-500 hover:text-white'}>
							{c.apiChannelName}
						</div>
					))
				}
			</div>
			<div className="text-lg mt-12">
				<div className="text-2xl font-medium">
					Non trovi il tuo gestionale?
				</div>
				<div className={'mt-2 text-gray-600'}>
					puoi usare BeSafe Rate manualmente caricando i preventivi inviati e attivando le polizze alla ricezione del pagamento
				</div>
			</div>
			<div className="my-8">
				<button onClick={() => onNext(undefined)} className={'bg-orange-500 text-white text-left flex space-x-4 px-4 py-3 rounded-md font-medium text-lg items-center'}>
					<div>
						Continua con la modalità manuale
					</div>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
						<path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
					</svg>
				</button>
			</div>
		</div>
	)

}

const ChooseRate = ({ onNext } : { onNext: (rate: string) => void }) => {

	const [rate, setRate] = useState('classic')

	return (
		<div>
			<div className={'flex space-x-8 mt-10'}>
				<div className={'flex-1 rounded-md w-1/3'}>
					<div className={'mb-10 relative w-full h-96 bg-cover bg-center rounded-xl'} style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1534821084228-d985662dfb56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80)' }}>
						<div className="mx-auto w-full -bottom-5 flex items-center justify-center absolute">
							<div className={'bg-white p-3 rounded-md shadow flex items-center space-x-2'}>
								<div className={'flex items-center justify-center'}>
									<Toggle
										id={'percentMode'}
										checked={rate === 'classic'}
										onChange={e => setRate('classic')}
									/>
								</div>
								<div className={'text-lg font-medium'}>
									Attiva
								</div>
							</div>
						</div>
					</div>
					<div className="mt-4 text-lg">
						BeSafe Classic
					</div>
					<div className="text-gray-600 flex flex-col mt-2 space-y-1">
						<div className={'flex space-x-2'}>
							<div className={'text-orange-500'}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
								</svg>
							</div>
							<div>Cancellazione</div>
						</div>
						<div className={'flex space-x-2'}>
							<div className={'text-orange-500'}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
								</svg>
							</div>
							<div>Sanitaria</div>
						</div>
					</div>
				</div>

				<div className={'flex-1 rounded-md w-1/3'}>
					<div className={'mb-10 relative w-full h-96 bg-cover bg-center rounded-xl'} style={{ backgroundImage: 'url(/assets/winter.png)' }}>
						<div className="mx-auto w-full -bottom-5 flex items-center justify-center absolute">
							<div className={'bg-white p-3 rounded-md shadow flex items-center space-x-2'}>
								<div className={'flex items-center justify-center'}>
									<Toggle
										id={'percentMode'}
										checked={rate === 'winter'}
										onChange={e => setRate('winter')}
									/>
								</div>
								<div className={'text-lg font-medium'}>
									Attiva
								</div>
							</div>
						</div>
					</div>
					<div className="mt-4 text-lg">
						BeSafe Winter
					</div>
					<div className="text-gray-600 flex flex-col mt-2 space-y-1">
						<div className={'flex space-x-2'}>
							<div className={'text-orange-500'}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
								</svg>
							</div>
							<div>Cancellazione</div>
						</div>
						<div className={'flex space-x-2'}>
							<div className={'text-orange-500'}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
								</svg>
							</div>
							<div>Sanitaria</div>
						</div>
						<div className={'flex space-x-2'}>
							<div className={'text-orange-500'}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
								</svg>
							</div>
							<div>
								RC Piste
							</div>
						</div>
					</div>
				</div>
				<div className={'flex-1 rounded-md w-1/3'}>
					<div className={'mb-10 relative w-full h-96 bg-cover bg-center rounded-xl'} style={{ backgroundImage: 'url(/assets/summer.png)' }}>
						<div className="mx-auto w-full -bottom-5 flex items-center justify-center absolute">
							<div className={'bg-white p-3 rounded-md shadow flex items-center space-x-2'}>
								<div className={'flex items-center justify-center'}>
									<Toggle
										id={'percentMode'}
										checked={rate === 'summer'}
										onChange={e => setRate('summer')}
									/>
								</div>
								<div className={'text-lg font-medium'}>
									Attiva
								</div>
							</div>
						</div>
					</div>
					<div className="mt-4 text-lg">
						BeSafe Summer
					</div>
					<div className="text-gray-600 flex flex-col mt-2 space-y-1">
						<div className={'flex space-x-2'}>
							<div className={'text-orange-500'}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
								</svg>
							</div>
							<div>Cancellazione</div>
						</div>
						<div className={'flex space-x-2'}>
							<div className={'text-orange-500'}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
								</svg>
							</div>
							<div>Sanitaria</div>
						</div>
						<div className={'flex space-x-2'}>
							<div className={'text-orange-500'}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
								</svg>
							</div>
							<div>
								Attività all'aperto
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-12 flex justify-center">
				<button onClick={() => onNext(rate)} className={'bg-orange-500 text-white text-left flex space-x-4 px-4 py-3 rounded-md font-medium text-lg items-center'}>
					<div>
						Continua
					</div>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
						<path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
					</svg>
				</button>
			</div>
		</div>
	)
}

export default function OnboardingPage () {

	const [needHelp, setNeedHelp] = useState(true)
	const [step, setStep] = useState('step1')

	useEffect(() => {
		if (step !== 'step1') {
			setTimeout(() => {
				const container = document.getElementById('content-container')
				const wrapper = document.getElementById('content-wrapper')
				const el = document.getElementById('steps')
				if (container && wrapper && el) {
					container.scrollTop = el.offsetTop
					wrapper.scrollTop = el.offsetTop
				}
			}, 200)
		}
	}, [ step ])

	return (
		<div id={'content-wrapper'} className="overflow-auto w-full bg-gray-100 flex justify-center pt-12">
			<div className={'absolute bottom-10 left-5 z-50'}>
				{
					needHelp &&
            <div onClick={() => setNeedHelp(!needHelp)} className="relative shadow-xl bg-white rounded-md mb-4 p-4 w-64">
								<div className={'absolute -right-3 -top-3'}>
										<button className={'rounded-md bg-gray-300 shadow-xl rounded-full p-2'}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                        </svg>
										</button>
								</div>
                <div className="font-medium mb-4">
                    Hai bisogno di aiuto?
                </div>
                <div className="text-gray-600">
                    il nostro team di onboarding è sempre a disposizione per aiutarti con la configurazione della tariffa BeSafe Rate
                </div>
                <button className={'mt-4 text-orange-600'}>Chiamaci al +39 06 57831727</button>
            </div>
				}
				<div onClick={() => setNeedHelp(!needHelp)} className={'cursor-pointer shadow-lg bg-cover bg-center rounded-full w-20 h-20'} style={{
					backgroundImage: `url(/assets/team/francesca.jpg)`
				}}></div>
			</div>
			<div className={'flex-1 max-w-5xl'}>

				<div style={{ height: '400px' }} className="rounded-xl overflow-hidden flex bg-white">
					<div className="w-1/2 p-10 flex flex-col justify-center">
						<div className="text-4xl mb-8 font-bold text-gray-700">
							🚀 Attiva subito la tariffa
						</div>
						<div className={'text-lg leading-relaxed text-gray-600'}>
							<div className="mb-4">
								oltre 1500 strutture offrono la tariffa BeSafe Rate ed è stata scelta da oltre 250.000 viaggiatori da tutto il mondo.
							</div>
							<div>
								<strong>Attivare la tariffa è facile! Richiede solo 3 semplici step.</strong>
							</div>
						</div>
					</div>
					<div style={{
						backgroundImage: 'url(/assets/family.jpg)'
					}} className={'bg-center bg-cover w-1/2 h-full'}></div>
				</div>

				<div id={'steps'} className="mt-12 pb-12">
					<div className="mb-12 shadow-xl rounded-xl overflow-hidden">
						<div className="flex">
							<div onClick={() => setStep('step1')} className={`border-gray-300 cursor-pointer flex-1 p-4 ${(step === 'step1') ? 'bg-white border-orange-600 border-b-4' : 'border-r bg-gray-200 opacity-70'}`}>
								<div className="mb-1 text-gray-400">Step 1</div>
								<div className="text-lg text-gray-600 font-medium">
									Scegli la tariffa
								</div>
							</div>
							<div onClick={() => setStep('step2')} className={`border-gray-300 cursor-pointer flex-1 p-4 ${(step === 'step2') ? 'bg-white border-orange-600 border-b-4' : 'border-r bg-gray-200 opacity-70'}`}>
								<div className="mb-1 text-gray-400">Step 2</div>
								<div className="text-lg text-gray-600 font-medium">
									Seleziona il gestionale
								</div>
							</div>
							<div onClick={() => setStep('step3')} className={`border-gray-300 cursor-pointer flex-1 p-4 ${(step === 'step3') ? 'bg-white border-orange-600 border-b-4' : 'border-r bg-gray-200 opacity-70'}`}>
								<div className="mb-1 text-gray-400">Step 3</div>
								<div className="text-lg text-gray-600 font-medium">
									Crea la tariffa
								</div>
							</div>
						</div>
					</div>

					{ (step === 'step1') && <ChooseRate onNext={() => setStep('step2')} /> }
					{ (step === 'step2') && <SelectSoftware onNext={() => setStep('step3')} />}
					{ (step === 'step3') && <SetupStrategy /> }
				</div>

			</div>
		</div>
	)

}
