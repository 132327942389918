import dayjs from "dayjs";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useQuery } from "react-query";
import { OnChangeValue } from "react-select";
import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";
import BaseTable from "../components/data/BaseTable";
import Button from "../components/form/Button";
import DatePickerInput from "../components/form/DatePickerInput";
import SelectInput from "../components/form/SelectInput";
import { getPolicies, getProcessedBookings } from "../shared/queries";
import { formatDate } from "../shared/utils";
import DateRangePicker from "../components/form/DateRangePicker";

export default function MonitoringInsurances () {

    const {
        data: policies
    } = useQuery('policies', getPolicies)

    const [currentPolicy, setCurrentPolicy] = useState<OnChangeValue<{ label: string, value: any }, false> | undefined>(undefined)
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(dayjs().add(1, 'day').toDate())

    const {
        data,
        refetch,
        isFetching
    } = useQuery(['status'], () => getProcessedBookings(
        currentPolicy ? currentPolicy.value : undefined,
        dayjs(fromDate).format('YYYYMMDD') + '000000',
        dayjs(toDate).format('YYYYMMDD') + '000000'
    ), {
        enabled: (currentPolicy !== undefined && currentPolicy !== null && currentPolicy.value !== 0)
    })

    useEffect(() => {
        if (policies && policies.length > 0) {
            setCurrentPolicy({
                label: policies[0].policyName,
                value: policies[0].idPolicy
            })
        }
    }, [
        policies
    ])

    return (
        <PageWrapper centered maxWidth={'1000px'}>

            <PageTitle
                title={'Monitoraggio polizze'} />

            <div className="flex space-x-4 justify-end mb-8">
                <div className={'w-64'}>
                    <SelectInput
                        value={currentPolicy}
                        placeholder={'Seleziona compagnia...'}
                        onChange={(item: any) => {
                            if (item) {
                                setCurrentPolicy(item)
                            }
                        }}
                        options={policies ? policies.map(a => {
                            return {
                                label: a.policyName,
                                value: a.idPolicy
                            }
                        }) : []} />
                </div>
                <div>
                    <DateRangePicker onStart={val => setFromDate(val)} onEnd={val => setToDate(val)} />
                </div>
                <div>
                    <Button
                        className="text-white bg-orange-600"
                        label={'Refresh dati'}
                        loading={isFetching}
                        onClick={() => {
                            refetch()
                        }} />
                </div>
            </div>

            <BaseTable
                filterable={false}
                loading={isFetching}
                paginated={false}
                columns={[
                    {
                        Header: 'Codice',
                        accessor: row => {
                            return row.bookingCode
                        }
                    },
                    {
                        Header: 'Arrivo',
                        accessor: row => {
                            return formatDate(row.bookingArrivalDate.toString(), 'DD-MM-YYYY')
                        }
                    },
                    {
                        Header: 'Partenza',
                        accessor: row => {
                            return formatDate(row.bookingDepartureDate.toString(), 'DD-MM-YYYY')
                        }
                    },
                    {
                        Header: 'Totale',
                        width: 'w-32',
                        accessor: row => <NumberFormat
                            value={row.bookingTotal}
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator={','}
                            thousandSeparator={'.'}
                            prefix={'€ '} />
                    },
                    {
                        Header: 'Ospiti',
                        width: 'w-20',
                        accessor: row => {
                            return row.guests ? row.guests.length : 0
                        }
                    },
                    {
                        Header: 'Ultima comunicazione',
                        accessor: row => {
                            return row.processedAt ? formatDate(row.processedAt.toString(), 'DD-MM-YYYY HH:mm:ss') : 'Not processed'
                        }
                    },
                ]}
                data={data} />

        </PageWrapper>
    )

}