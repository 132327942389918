import {
    initializeApp,
    FirebaseApp
} from 'firebase/app'

import {
    Firestore,
    getFirestore,
} from 'firebase/firestore'
import firestoreFeedback, { FeedbackCollection } from './modules/firestoreFeedback'
import { getStorage, ref, uploadBytes, FirebaseStorage, UploadResult, getDownloadURL } from 'firebase/storage'

export default {
    
    app: undefined,
    db: undefined,
    storage: undefined,

    init () {
        this.app = initializeApp({
            apiKey: "AIzaSyDV-vM4kFNDWwe-jIcrvp24iLzjDo-Q1mk",
            authDomain: "besaferate-5f43d.firebaseapp.com",
            databaseURL: "https://besaferate-5f43d-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "besaferate-5f43d",
            storageBucket: "besaferate-5f43d.appspot.com",
            messagingSenderId: "829323431227",
            appId: "1:829323431227:web:3130fc7881bb9717fdfb1e",
            measurementId: "G-X1XJ81L14M"
        })
        this.db = getFirestore(this.app)
        this.storage = getStorage()
    },

    modules () {
        if (!this.db) throw Error('Accessing modules before database initialization')
        return {
            feedback: firestoreFeedback(this.db)
        }
    },

    async upload (postId: string, blob: File) {
        if (this.storage) {
            const storageRef = ref(this.storage, postId)
            return await uploadBytes(storageRef, blob)
        } else {
            throw new Error('Can not upload file. Storage is not initializzed.')
        }
    },

    async getFileUrl (fileReference: string) {
        if (this.storage) {
            const fileRef = ref(this.storage, fileReference)
            return getDownloadURL(fileRef)
        }
    }

} as {
    app: undefined | FirebaseApp
    db: undefined | Firestore,
    storage: undefined | FirebaseStorage,
    init: () => void,
    modules: () => {
        feedback: FeedbackCollection
    },
    upload: (postId: string, blob: File) => Promise<UploadResult>,
    getFileUrl: (fileReference: string) => Promise<string>
}
