import { createSlice } from "@reduxjs/toolkit";
import {RestCriteria, TableFilterColumn} from "../../types";

type BookingsState = {
	filters: RestCriteria[],
	currentRes?: string,
	tableFilters: TableFilterColumn[]
}

const bookingsSlice = createSlice({
	name: 'bookingsSlice',
	initialState: {
		filters: [],
		currentRes: undefined,
		tableFilters: []
	} as BookingsState,
	reducers: {
		setCurrentRes: (state, action) => {
			state.currentRes = action.payload
		},
		setFilters: (state, action) => {
			state.filters = action.payload
		},
		setTableFilters: (state, action) => {
			state.tableFilters = action.payload
		}
	}
})

export const { setFilters, setTableFilters, setCurrentRes } = bookingsSlice.actions

export default bookingsSlice.reducer
