import DatePicker from "./DatePicker";
import Popover from "../common/Popover";
import { useState } from "react";
import dayjs from "dayjs";
import Icon from "../common/Icon";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function DatePickerInput ({
    mode = 'single',
    value,
    fromDate,
    maxDate,
    labelFormat = 'MM/DD',
    onChange
} : {
    mode?: string,
    value?: any,
    labelFormat?: string,
    fromDate?: Date,
    maxDate?: Date,
    onChange?: (value: any) => void
}) {
    const { t } = useTranslation()

    const [calendarVisible, setCalendarVisible] = useState(false)
    const [currentValue, setCurrentValue] = useState<any>(value)

    useEffect(() => {
        setCurrentValue(value)
    }, [ value ])

    function getCurrentValue () {
        if (!currentValue) return ''
        let value = ''
        if (mode === 'range') {
            const from = dayjs(currentValue.from).format('DD/MM/YYYY')
            const to = dayjs(currentValue.to).format('DD/MM/YYYY')
            value = from + ' -> ' + to
        } else {
            const date = dayjs(currentValue).format('DD/MM/YYYY')
            return value = date
        }

        return value
    }

    return (
        <Popover
            handleMobile={true}
            managedVisibility={calendarVisible}
            onHide={() => setCalendarVisible(false)}
            onShow={() => setCalendarVisible(true)}
            renderContent={() => (
                <>
                <DatePicker
                    onChange={value => {
                        console.log(value)
                        if (onChange) onChange(value)
                        setCurrentValue(value)
                    }}
                    renderFooter={() => (
                        <div className={'border-t'}>
                            <button
                                onClick={() => setCalendarVisible(false)}
                                className={'px-4 py-2 w-full hover:bg-gray-100 text-orange-600 underline text-center'}>
                                {t('done')}
                            </button>
                        </div>
                    )}
                    value={getCurrentValue}
                    fromDate={fromDate}
                    maxDate={maxDate}
                    mode={mode} />
                </>
            )}>
            <div className="relative">
                <Icon
                    className={'text-gray-600 absolute z-10 top-2 left-2'}
                    name={'calendar'}
                    size={'20px'} />
                <div className="pl-9 w-full bg-white rounded-md appearance-none px-2 py-1 focus:outline-none h-9 flex items-center cursor-pointer hover:bg-gray-100" style={{
                    boxShadow: '0px 2px 3px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1)'
                }}>
                    {
                        (mode === 'range') ?
                        currentValue ?
                            <div className={'flex space-x-1 items-center'}>
                                <div>{dayjs(currentValue.from).format(labelFormat)}</div>
                                <div><Icon name={'arrow-narrow-right'} size={'20px'} /></div>
                                <div>{dayjs(currentValue.to).format(labelFormat)}</div>
                            </div>
                            :
                            <div>Seleziona range</div>
                        :
                        <div>
                            {dayjs(currentValue).format('DD/MM/YYYY')}
                        </div>
                    }
                </div>
            </div>
        </Popover>
    )

}