import {useMutation, useQuery, useQueryClient} from "react-query";
import {getQueriedQuotes, getQuote, triggerKanbanEvent} from "../shared/queries";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { AnimatePresence } from "framer-motion";
import AddQuote from "../components/quote/AddQuote";
import { PaginatedResponse, Quote, QuoteKanbanEvent, RestCriteria } from "../types";
import useDebounce from "../hooks/useDebounce";
import { useHistory } from "react-router-dom";
import { classNames, formatCurrency } from "../shared/utils";
import dayjs from "dayjs";
import Button from "../components/form/Button";
import { fetchQuote, resetQuote } from "../store/slices/offerSlice";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import ProgressBar from "../components/loading/ProgressBar";
import QuoteIntro from "../components/quote/QuoteIntro";

const QuoteCard = ({ quote, onClick } : { quote: Quote, onClick: (id: number) => void }) => {

	const { t } = useTranslation()

	const [{ isDragging }, drag] = useDrag(() => ({
		// "type" is required. It is used by the "accept" specification of drop targets.
    type: 'BOXS',
		item: quote,
		// The collect function utilizes a "monitor" instance (see the Overview for what this is)
		// to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

	return (
		<div
			ref={drag}
			style={{ opacity: isDragging ? 0.5 : 1}}
			onClick={() => {
				if (quote.idQuote) onClick(quote.idQuote)
			}}
			className="shadow-sm hover:shadow-lg cursor-pointer p-2.5 bg-white border rounded-md">
			<div className="flex space-x-2 justify-between">
				<div className="font-medium">{quote.firstName} {quote.lastName}</div>
				{
					(quote.acceptedAt !== null && quote.acceptedAt !== undefined) ?
					<div className="flex space-x-2 text-green-600 font-medium items-center">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
							<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
						</svg>
						<div>
							{t('quote.accepted')}
						</div>
					</div>
					:
					<div className={
						classNames(
							'flex space-x-2 items-center',
							dayjs(quote.expiresAt?.toString(), 'YYYYMMDDHHmm').isBefore(dayjs(), 'minute') ? 'text-red-600' : 'text-gray-500'
						)
					}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
							<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-13a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 000-1.5h-3.25V5z" clipRule="evenodd" />
						</svg>
						<div>
							{dayjs(quote.expiresAt?.toString(), 'YYYYMMDDHHmm').format('DD/MM HH:mm')}
						</div>
					</div>
				}
			</div>
			<div className="mt-1">
				<div>{(quote && quote.options) && quote.options.length} {t('quote.options')}</div>
				<div className="text-gray-500 flex space-x-1">
					<div>per {(quote.options) && formatCurrency(quote.options.map(o => o.rooms.map(r => r.price).flat()).flat().reduce((prev, curr) => prev + curr, 0))}</div>
					<div><svg viewBox="0 0 2 2" className="md:inline mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1"></circle></svg></div>
					<div>
						{quote.besafeIncluded ? <span>{t('quote.insured')}</span> : <span>{t('quote.extraInsurance')}</span>}
					</div>
				</div>
			</div>
			<div>
				<button className="mt-1 text-orange-600" onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					window.open(`https://quote.besafesuite.com/${quote.idQuote}`, '_blank')
				}}>{t('quote.goToQuote')}</button>
			</div>
		</div>
	)
}

function KanbanCard ({
	type,
	title,
	data,
	last = false,
	onClick,
	onChange
} : {
	type: string,
	title: string,
	data: Quote[],
	last?: boolean,
	onClick: (idQuote: number) => void,
	onChange: (kanbanEvent: QuoteKanbanEvent) => void
}) {
	
	const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'BOX',
		drop: (item: Quote) => {
			if (!item.idQuote) return	
			onChange({
				idQuote: item.idQuote,
				action: type as any
			})
		},
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }))

	function getAverageTotal () {
		const aggregated = data.map(
			q => q.options.map(o => o.rooms.map(r => r.price)).flat().reduce((prev, curr) => prev + curr, 0) / q.options.length
		)
		return aggregated.reduce((prev, curr) => prev + curr, 0) / aggregated.length
	}

	return (
		<div ref={drop} className={
			classNames(
				'relative',
				isOver ? 'border-orange-600 border-2' : 'border-r border-gray-200'
			)
		}>
			<div className="border-b border-gray-200 p-4 px-6 relative">
				{
					!last &&
					<div className="text-gray-600 absolute -right-4 top-6 bottom-0 border bg-gray-50 rounded-full w-8 h-8 flex items-center justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
							<path fillRule="evenodd" d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
							<path fillRule="evenodd" d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
						</svg>
					</div>
				}
				<div className="font-medium text-gray-800 text-lg">
					{title} ({data.length})
				</div>
				<div className="text-gray-600">
					{formatCurrency(getAverageTotal() || 0)}
				</div>
			</div>

			<div className="h-full p-4 flex flex-col space-y-3">
				{data.map((quote, index) => <QuoteCard quote={quote} key={`kanban-quote${index}`} onClick={onClick} />)}
			</div>
		</div>
	)
}

export default function QuoteList () {

	const history = useHistory()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const currentHotel = useSelector<any>(state => state.hotels.currentHotel) as any

	const [addQuote, setAddQuote] = useState(false)

	const [page, setPage] = useState<number>(0)
	const [filters, setFilters] = useState<RestCriteria[]>([{
		field: 'status',
    unmanaged: true,
    searchOperation: 'EQUAL',
    value: 'all'
	}])
	const [sortBy, setSortBy] = useState('idQuote')
	const [sortType, setSortType] = useState('DESC')

	const queryClient = useQueryClient()
	const debounceFilters = useDebounce(filters, 500)

	const {
		data: quotes,
		isFetching: loadingQuotes,
		refetch
	} = useQuery([
		'filteredQuotes'
	], () => getQueriedQuotes(
		currentHotel,
		page,
		300,
		sortBy,
		filters,
		sortType
	), {
		enabled: (currentHotel !== null && currentHotel !== undefined)
	})

	useEffect(() => {
		if (refetch !== undefined && currentHotel !== null && currentHotel !== undefined) refetch()
	}, [
		refetch,
		debounceFilters,
		currentHotel,
		page,
		sortBy,
		sortType
	])

	function getQuoteByType (type: 'ALL' | 'APPROVED' | 'REJECTED' | 'WAITING' | 'EXPIRED') : Quote[] {
		if (!quotes || !quotes.content) return []
		switch (type) {
			case 'ALL':
				return quotes.content
			case 'APPROVED':
				return quotes.content.filter(q => q.acceptedAt && !q.rejectedAt)
			case 'REJECTED':
				return quotes.content.filter(q => !q.acceptedAt && q.rejectedAt)
			case 'EXPIRED':
				return quotes.content.filter(q => !q.rejectedAt && !q.acceptedAt && q.expiresAt !== null && q.expiresAt !== undefined && dayjs(q.expiresAt.toString(), 'YYYYMMDDHHmm').isBefore(dayjs(), 'minutes'))
			case 'WAITING':
					return quotes.content.filter(q => !q.acceptedAt && !q.rejectedAt && (!q.expiresAt || dayjs(q.expiresAt.toString(), 'YYYYMMDDHHmm').isAfter(dayjs(), 'minute')))
		}
	}

	function getAverageTotal () {
		if (!quotes || !quotes.content) return 0
		const aggregated = quotes?.content.map(
			q => q.options.map(o => o.rooms.map(r => r.price)).flat().reduce((prev, curr) => prev + curr, 0) / q.options.length
		)
		return isNaN(aggregated.reduce((prev, curr) => prev + curr, 0) / aggregated.length) ? 0 : aggregated.reduce((prev, curr) => prev + curr, 0) / aggregated.length
	}

	const kanbanMutation = useMutation((kanbanEvent: QuoteKanbanEvent) => triggerKanbanEvent(kanbanEvent), {
		onMutate: async data => {
			const previousData = queryClient.getQueryData('filteredQuotes') as PaginatedResponse<Quote>
			queryClient.setQueryData('filteredQuotes', {
				...previousData,
				content: previousData.content.map(quote => {
					if (quote.idQuote?.toString() === data.idQuote.toString()) {
						switch (data.action) {
							case 'APPROVE':
								return { ...quote, acceptedAt: dayjs().format('YYYYMMDD'), expiresAt: undefined }
							case 'EXPIRE':
								return { ...quote, expiresAt: parseInt(dayjs().subtract(10, 'minute').format('YYYYMMDD')), acceptedAt: undefined, rejectedAt: undefined }
							case 'REJECT':
								return { ...quote, rejectedAt: parseInt(dayjs().format('YYYYMMDD')), acceptedAt: undefined }
							case 'SENT':
								return { ...quote, rejectedAt: undefined, acceptedAt: undefined }
							case 'VIEW':
								return { ...quote, rejectedAt: undefined, acceptedAt: undefined, lastViewAt: parseInt(dayjs().format('YYYYMMDD'))}
						}
					}
					return quote
				})
			})
			return { previousData }
		},
		onSettled: () => {
			// queryClient.invalidateQueries('filteredQuotes')
		}
	})

	return (
		<div className={'relative w-full h-full flex flex-col'}>
			<QuoteIntro />

			<div className="bg-orange-100 text-orange-700 border-b border-orange-200 py-2 px-6">
				<strong>BETA</strong> {t('quote.beta')}
			</div>

			{
				addQuote &&
				<AnimatePresence>
					<AddQuote
						onRefresh={() => {
							refetch()
						}}
						onSent={() => {
							setAddQuote(false)
							refetch()
						}}
						onClose={() => setAddQuote(false)}
					/>
				</AnimatePresence>
			}

			{ kanbanMutation.isLoading && <div className="absolute top-0 left-0 w-full h-4">
				<ProgressBar />
			</div> }
			

			<div className="border-b bg-gray-100 py-2.5 px-6 flex space-x-4 items-center justify-between">
				<div className="flex space-x-2 items-center">
					<Button
							className={'border bg-orange-600 text-white shadow text-sm font-semibold rounded-md py-1 px-4'}
							label={t('quote.addQuote')}
							onClick={() => {
								dispatch(resetQuote())
								setAddQuote(true)
							}}
					/>

					<div className="text-gray-600">
						{(quotes && quotes.content) ? quotes?.content.length : 0} {t('quote.quotes')} {t('quote.averageTotal')} {formatCurrency(getAverageTotal())}
					</div>
				</div>

				<div className="flex items-center justify-center">
					<button className="text-gray-600 hover:text-gray-800 flex items-center font-medium space-x-2" onClick={() => history.push('/app/quotes/settings/general')}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
							<path fillRule="evenodd" d="M7.84 1.804A1 1 0 018.82 1h2.36a1 1 0 01.98.804l.331 1.652a6.993 6.993 0 011.929 1.115l1.598-.54a1 1 0 011.186.447l1.18 2.044a1 1 0 01-.205 1.251l-1.267 1.113a7.047 7.047 0 010 2.228l1.267 1.113a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-1.187.447l-1.598-.54a6.993 6.993 0 01-1.929 1.115l-.33 1.652a1 1 0 01-.98.804H8.82a1 1 0 01-.98-.804l-.331-1.652a6.993 6.993 0 01-1.929-1.115l-1.598.54a1 1 0 01-1.186-.447l-1.18-2.044a1 1 0 01.205-1.251l1.267-1.114a7.05 7.05 0 010-2.227L1.821 7.773a1 1 0 01-.206-1.25l1.18-2.045a1 1 0 011.187-.447l1.598.54A6.993 6.993 0 017.51 3.456l.33-1.652zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
						</svg>
						<div>{t('quote.settings')}</div>
					</button>
				</div>
			</div>
			
			<DndProvider backend={HTML5Backend}>
				<div className="flex-1 grid grid-cols-4 h-full">
					<KanbanCard onChange={(data: QuoteKanbanEvent) => kanbanMutation.mutate(data)} type={'SENT'} onClick={(idQuote: number) => {
						dispatch(fetchQuote({ idQuote }))
						setAddQuote(true)
					}} data={getQuoteByType('WAITING')} title={t('quote.pending')} />
					<KanbanCard onChange={(data: QuoteKanbanEvent) => kanbanMutation.mutate(data)} type={'APPROVE'} onClick={(idQuote: number) => {
						dispatch(fetchQuote({ idQuote }))
						setAddQuote(true)
					}} data={getQuoteByType('APPROVED')} title={t('quote.approveds')} />
					<KanbanCard onChange={(data: QuoteKanbanEvent) => kanbanMutation.mutate(data)} type={'REJECT'} onClick={(idQuote: number) => {
						dispatch(fetchQuote({ idQuote }))
						setAddQuote(true)
					}} data={getQuoteByType('REJECTED')} title={t('quote.rejected')} />
					<KanbanCard onChange={(data: QuoteKanbanEvent) => kanbanMutation.mutate(data)} type={'EXPIRE'} onClick={(idQuote: number) => {
						dispatch(fetchQuote({ idQuote }))
						setAddQuote(true)
					}} last={true} data={getQuoteByType('EXPIRED')} title={t('quote.expired')} />
				</div>
			</DndProvider>
		</div>
	)

}
