import { classNames } from "../../shared/utils"

export default function Chip ({
    children,
    className
} : {
    children: any,
    className: string
}) {

    return (
        <div className={
            classNames(
                `inline text-sm rounded-xl font-medium px-2 py-1`,
                className
            )
        }>
            {children}
        </div>
    )

}