import { useMutation, useQuery } from "react-query"
import { deletePaymentMethod, listPaymentMethods } from "../../shared/queries"
import { useAppSelector } from "../../hooks/redux"
import Modal from "../common/Modal"
import { useState } from "react"
import ModalCard from "../common/ModalCard"
import PaymentMethods from "./PaymentMethods"
import Button from "../form/Button"
import Faq from "../Faq"
import { useTranslation } from "react-i18next"
import toast from "react-hot-toast"

export default function PaymentMethodList () {

  const { t } = useTranslation()

  const [addPaymentMethod, setAddPaymentMethod] = useState(false)
  const currentHotelObject = useAppSelector(state => state.hotels.currentHotelObject)

  const {
    data,
    refetch
  } = useQuery([], () => listPaymentMethods(currentHotelObject?.idAccomodation || 0), {
    enabled: (currentHotelObject !== null && currentHotelObject !== undefined)
  })

  const deleteMutation = useMutation((id: number) => deletePaymentMethod(id), {
    onSuccess: () => {
      refetch()
      toast.success(t('vary.methodRemove'))
    },
    onError: () => {
      refetch()
      toast.success(t('vary.generalError'))
    }
  })

  if (!currentHotelObject || !currentHotelObject.idAccomodation) return <></>

  if (!data || data.length === 0) return <div className="my-10">
    <Modal
        visible={addPaymentMethod}>
        <ModalCard
          onClose={() => setAddPaymentMethod(false)}
          title={t('sepa.addMethod')}>
          <div className="p-4">
            <PaymentMethods idAccommodation={currentHotelObject.idAccomodation} />
          </div>
        </ModalCard>
    </Modal>
    <div className="text-xl font-bold text-slate-700">
      {t('sepa.associateMethod')}
    </div>
    <div className="mt-2 text-lg text-slate-600">
      {t('sepa.noMethod')}
    </div>

    <Button className="bg-orange-600 text-white mt-6" label={t('sepa.addMethod')} onClick={() => setAddPaymentMethod(true)} />

    <div className="mt-10 border-t boder-slate-300">
      <Faq />
    </div>
  </div>

  return (
    <div>
      <Modal
          visible={addPaymentMethod}>
          <ModalCard
            onClose={() => setAddPaymentMethod(false)}
            title={'Aggiungi un metodo di pagamento'}>
            <div className="p-4">
              <PaymentMethods idAccommodation={currentHotelObject.idAccomodation} />
            </div>
          </ModalCard>
      </Modal>

      <div className="mt-4 font-bold text-slate-800 text-xl">{t('sepa.yourMethods')}</div>
      <div className="text-lg text-slate-700 mt-2">
        {t('sepa.yourMethodText')}
      </div>
      <div className=" mt-6 grid grid-cols-3 space-x-4">
        {
          data.map(method => (
            <div className="bg-white rounded-md border p-4 flex space-x-4">
              <div className="text-slate-600">
                {
                  method.methodType === 'CREDIT_CARD' ?
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path d="M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z" />
                    <path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clipRule="evenodd" />
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                    <path d="M11.584 2.376a.75.75 0 0 1 .832 0l9 6a.75.75 0 1 1-.832 1.248L12 3.901 3.416 9.624a.75.75 0 0 1-.832-1.248l9-6Z" />
                    <path fillRule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75v-9.918a.75.75 0 0 1 .634-.74A49.109 49.109 0 0 1 12 9c2.59 0 5.134.202 7.616.592a.75.75 0 0 1 .634.74Zm-7.5 2.418a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Zm3-.75a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0v-6.75a.75.75 0 0 1 .75-.75ZM9 12.75a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Z" clipRule="evenodd" />
                    <path d="M12 7.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
                  </svg>
                }
              </div>
              <div>
                <div className="font-medium text-slate-600">{method.methodType === 'CREDIT_CARD' ? 'Carta di credito' : 'Addebito bancario'}</div> 
                <div className="mt-2">
                  {method.methodType === 'CREDIT_CARD' && '•••• •••• •••• '}{method.methodLabel}
                </div>
                <div className="mt-2 text-orange-600 font-semibold">
                  <button
                    onClick={() => deleteMutation.mutate(method.idPaymentMethod)}
                    className="bg-red-100 text-red-600 text-sm px-2 py-1">{
                      deleteMutation.isLoading ? t('general.wait') + '...' : t('general.deactivate')
                    }</button>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      <Button className="bg-orange-600 text-white mt-6" label={t('sepa.addMethod')} onClick={() => setAddPaymentMethod(true)} />
    </div>
  )

}