import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import CreateCompany from "../components/business/companies/CreateCompany";
import Chip from "../components/common/Chip";
import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";
import BaseTable from "../components/data/BaseTable";
import Button from "../components/form/Button";
import { getCompanies } from "../shared/queries";
import { useTranslation } from "react-i18next";

export default function CompaniesPage () {

    const { t } = useTranslation()

    const {
        data,
        isFetching,
        refetch
    } = useQuery('companies', getCompanies)

    const history = useHistory()

    const [create, setCreate] = useState<boolean>(false)

    return (
        <PageWrapper centered maxWidth={'1000px'}>
            
            <PageTitle
                title={t('Aziende')}
                renderActions={() => (
                    <Button
                        className="text-white bg-orange-600"
                        label={t('Nuova azienda')}
                        onClick={() => {
                            setCreate(true)
                        }} />
                )} />

            <BaseTable
                loading={isFetching}
                filters={[]}
                showFilters={true}
                showColumnSelector={true}
                identifierKey={'idCompany'}
                onEdit={id => {
                    history.push('/app/companies/' + id)
                }}
                columns={[
                    {
                        Header: t('Ragione Sociale') || '',
                        accessor: 'companyName'
                    },
                    {
                        Header: t('Partita IVA') || '',
                        width: 'w-48',
                        accessor: 'companyVat'
                    },
                    {
                        Header: t('Codice Fiscale') || '',
                        width: 'w-48',
                        accessor: 'companyFiscalCode'
                    },
                    {
                        Header: t('Eliminata') as string || '',
                        width: 'w-32',
                        accessor: row => {
                            if (row.deletedAt) {
                                return <Chip className={'text-red-600 bg-red-100'}>{t('Si')}</Chip>
                            } else {
                                return <Chip className={'text-green-600 bg-green-100'}>{t('No')}</Chip>
                            }
                        }
                    }
                ]}
                data={data} />

            <CreateCompany
                visible={create}
                onClose={() => setCreate(false)}
                onSave={() => {
                    setCreate(false)
                    refetch()
                }} />

        </PageWrapper>
    )
}