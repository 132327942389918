import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import { SessionContext } from './contexts/SessionContext';
import store from './store';
import { Toaster } from 'react-hot-toast';
import { Fragment, useEffect, useMemo } from 'react';
import firebase from './shared/firebase/firebase';
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import './i18n'
import './App.scss'
import dayjs from 'dayjs';
import 'react-loading-skeleton/dist/skeleton.css'
import './index.scss';
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.tz.guess()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

function App() {

  useEffect(() => {
    firebase.init()
  }, [])

  return (
    <Fragment>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <SessionContext.Provider value={{
            profile: null,
            setProfile: () => {}
          }}>
            <AppRouter />
          </SessionContext.Provider>
        </QueryClientProvider>
      </Provider>
      <Toaster
        position={'bottom-center'}
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          }
        }}
      />
    </Fragment>
  );
}

export default App;
