import Button from "../form/Button";
import {useTranslation} from "react-i18next";
import {Fragment, useState} from "react";
import {useQuery} from "react-query";
import {getAccomodationApiChannels} from "../../shared/queries";
import {useSelector} from "react-redux";

export default function WelcomeOnboard () {

    const { t } = useTranslation()

    const [showInitialOnboard, setShowInitialOnboard] = useState(true)

    const currentHotel = useSelector<any>(state => state.hotels.currentHotel) as any

    const {
        isFetching: loadingConnectedChannels,
        data: connectedChannels
    } = useQuery(['accomodationApiChannel', currentHotel], () => getAccomodationApiChannels(currentHotel), {
        enabled: (currentHotel !== undefined && currentHotel !== null)
    })

    return (
        <Fragment>
            {
                (showInitialOnboard && !localStorage.getItem("noonboarding") && !loadingConnectedChannels && connectedChannels && connectedChannels.length === 0) &&
                <div className={'bg-orange-900 flex flex-wrap md:flex-nowrap items-center space-x-0 md:space-x-8 py-6 p-4'}>
                    <div className={'mb-4 md:mb-0'}>
                        <div className={"flex -space-x-6"}>
                            <div className={'border-4 border-white relative bg-cover z-10 bg-center w-20 h-20 rounded-full'} style={{
                                backgroundImage: 'url(/assets/team/francesca.jpg)'
                            }} />
                            <div className={'border-4 border-white relative bg-cover z-10 bg-center w-20 h-20 rounded-full'} style={{
                                backgroundImage: 'url(/assets/team/pasquale.png)'
                            }} />
                            <div className={'border-4 border-white relative bg-cover z-10 bg-center w-20 h-20 rounded-full'} style={{
                                backgroundImage: 'url(/assets/team/valeria.png)'
                            }} />
                            <div className={'border-4 border-white relative bg-cover z-10 bg-center w-20 h-20 rounded-full'} style={{
                                backgroundImage: 'url(/assets/team/marina.png)'
                            }} />
                        </div>
                    </div>
                    <div className={'flex flex-col space-y-4'}>
                        <div className="text-xl font-semibold text-white">
                            {t('bookOnboarding.title')}
                        </div>
                        <div className={'text-white text-lg font-normal'}>
                            {t('bookOnboarding.message')}
                        </div>
                        <div className={'flex flex-wrap md:flex-nowrap space-y-2 md:space-y-0 space-x-0 md:space-x-2'}>
                            <div className={'w-full md:w-auto'}>
                                <Button
                                    className="text-orange-900 bg-orange-400"
                                    label={t('bookOnboarding.bookNow')}
                                    onClick={() => {
                                        window.open("https://calendly.com/bsg-onboarding/onboarding?month=2024-01", "_blank")
                                    }} />
                            </div>
                            <div className={'w-full md:w-auto'}>
                                <Button
                                    className="text-orange-700 bg-orange-100"
                                    label={t('bookOnboarding.noThanks')}
                                    onClick={() => {
                                        setShowInitialOnboard(false)
                                        localStorage.setItem('noonboarding', "true")
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>

    )

}
