import { deleteQuoteCustomer, getCancellationPolicies } from '../../shared/queries'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useState } from 'react'
import { removePolicy, setCustomer, setPolicy } from '../../store/slices/offerSlice'
import { useQuery } from 'react-query'
import toast from 'react-hot-toast'
import AddPolicyModal from './AddPolicyModal'
import Select from 'react-select/creatable'
import { useTranslation } from 'react-i18next'

export default function QuotePolicySelector () {

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { currentHotel } = useAppSelector(state => state.hotels)
  const { quote } = useAppSelector(state => state.offerSlice)

  const [addPolicy, setAddPolicy] = useState(false)

  const {
    data,
    refetch,
    isLoading
  } = useQuery(['policies', currentHotel], () => getCancellationPolicies(currentHotel))

  if(quote.idCancellationPolicy !== undefined && quote.idCancellationPolicy !== null) return (
    <div className='flex space-x-4 mt-4'>
      <div className='text-gray-600'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M12 1.5c-1.921 0-3.816.111-5.68.327-1.497.174-2.57 1.46-2.57 2.93V21.75a.75.75 0 001.029.696l3.471-1.388 3.472 1.388a.75.75 0 00.556 0l3.472-1.388 3.471 1.388a.75.75 0 001.029-.696V4.757c0-1.47-1.073-2.756-2.57-2.93A49.255 49.255 0 0012 1.5zm-.97 6.53a.75.75 0 10-1.06-1.06L7.72 9.22a.75.75 0 000 1.06l2.25 2.25a.75.75 0 101.06-1.06l-.97-.97h3.065a1.875 1.875 0 010 3.75H12a.75.75 0 000 1.5h1.125a3.375 3.375 0 100-6.75h-3.064l.97-.97z" clipRule="evenodd" />
        </svg>
      </div>

      <div className='flex flex-col space-y-2 flex-1'>
        <div className='flex-1'>
          <div className="text-gray-600 font-semibold">{quote.policyTitle}</div>
          <div className="text-gray-500 overflow-hidden max-h-14 w-full pb-1 border-b border-gray-300" dangerouslySetInnerHTML={{
            __html: quote.policyText ? quote.policyText : ''
          }} />
        </div>
        <div className='text-slate-600'>
          {t('quote.prepaid')} {quote.prepaidAmount} {t(quote.prepaidUnit)}, {t('quote.cancellationUntil')} {quote.cancellationAmount} {t(quote.cancellationUnit)}
        </div>
        <div className="flex space-x-2">
          <div>
            <button onClick={() => dispatch(removePolicy())} className='text-gray-600 font-medium'>{t('quote.change')}</button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <AddPolicyModal
        visible={addPolicy}
        onClose={() => {
          refetch()
          setAddPolicy(false)
        }}
      />

      <Select
        className={'mt-4'}
        isLoading={isLoading}
        onCreateOption={() => {
          setAddPolicy(true)
        }}
        onChange={item => {
          if (item) {
            const p = data?.find(i => i.idCancellationPolicy?.toString() == item.value?.toString())
            if (p) dispatch(setPolicy(p))
          }
        }}
        allowCreateWhileLoading={true}
        placeholder={t('quote.selectPolicy')}
        options={data ? data.map(i => {
          return {
            label: i.title,
            value: i.idCancellationPolicy
          }
        }) : []}
      />

      <button onClick={() => setAddPolicy(true)} className='mt-2 text-orange-600 flex space-x-2 items-center cursor-pointer hover:text-orange-700'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
          <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
        </svg>
        <div>{t('quote.addPolicy')}</div>
      </button>
    </div>
  )

}