import { useEffect, useState } from "react";
import Modal from "../common/Modal";
import ModalActions from "../common/ModalActions";
import ModalCard from "../common/ModalCard";
import FormField from "../form/FormField";
import TextInput from "../form/TextInput";
import CountrySelector from "../business/selectors/CountrySelector";
import { useMutation } from "react-query";
import { QuoteCustomer } from "../../types";
import { getQuoteCustomer, saveQuoteCustomer } from "../../shared/queries";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setCustomer } from "../../store/slices/offerSlice";
import { useTranslation } from "react-i18next";
import quoteCustomerSchema from "../../validation/quoteCustomerSchema";

export default function AddCustomerModal ({
  visible,
  editCustomer,
  onClose
} : {
  visible: boolean,
  editCustomer?: number,
  onClose: () => void
}) {

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { currentHotel } = useAppSelector(state => state.hotels) as any

  const [errors, setErrors] = useState<any>()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('IT')
  const [email, setEmail] = useState('')
  const [target, setTarget] = useState('')
  
  useEffect(() => {
    async function loadEdit () {
      if (editCustomer) {
        const editData = await getQuoteCustomer(editCustomer)
        setFirstName(editData.firstName)
        setLastName(editData.lastName)
        setPhoneNumber(editData.phoneNumber)
        setCountryCode(editData.countryCode)
        setEmail(editData.email)
        setTarget(editData.target)
      } else {
        setFirstName('')
        setLastName('')
        setPhoneNumber('')
        setCountryCode('')
        setEmail('')
        setTarget('')
      }
    }
    loadEdit()
  }, [ editCustomer ])

  const saveMutation = useMutation((data: QuoteCustomer) => saveQuoteCustomer(data), {
    onSuccess: data => {
      dispatch(setCustomer(data))
      toast.success('Cliente salvato')
      onClose()
    }
  })

  return (
    <Modal visible={visible}>
      <ModalCard
        renderActions={() => (
          <ModalActions
            isLoading={saveMutation.isLoading}
            onSave={() => {
              const { error } = quoteCustomerSchema.validate({
                firstName,
                lastName,
                email,
                phoneNumber,
                target,
                countryCode,
                idAccomodation: currentHotel
              }, {
                abortEarly: false
              })
              if (error) {
                setErrors(error)
                toast.error(t('general.missingFields'))
                return
              }
              saveMutation.mutate({
                firstName,
                lastName,
                email,
                phoneNumber,
                target,
                countryCode,
                idAccomodation: currentHotel
              })
          }}
            onClose={onClose}
          />
        )}
        className={'w-full max-w-2xl'} title={editCustomer ? `Modifica ${firstName} ${lastName}` : 'Crea nuovo cliente'}>
          
          <div className="p-4 pb-6 flex flex-col space-y-4">
            
            <div className="flex space-x-4">
              <FormField errors={errors} errorKey={'firstName'} className={'flex-1'} label={t('quote.name')}>
                <TextInput value={firstName} onChange={val => setFirstName(val)} />
              </FormField>

              <FormField errors={errors} errorKey={'lastName'} className={'flex-1'} label={t('quote.lastName')}>
                <TextInput value={lastName} onChange={val => setLastName(val)} />
              </FormField>
            </div>

            <div className="flex space-x-4">
              <FormField errors={errors} errorKey={'email'} className={'flex-1'} label={'Email'}>
                <TextInput value={email} onChange={val => setEmail(val)} />
              </FormField>

              <FormField errors={errors} errorKey={'phoneNumber'} className={'flex-1'} label={t('quote.phone')}>
                <TextInput value={phoneNumber} onChange={val => setPhoneNumber(val)} />
              </FormField>
            </div>

            <div className="flex space-x-4">
              <FormField errors={errors} errorKey={'countryCode'} className={'flex-1'} label={t('quote.country')}>
                <CountrySelector onSelect={c => setCountryCode(c.countryAlpha2)} />
              </FormField>
            </div>

          </div>

        </ModalCard>
    </Modal>
  )

}