import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { deleteExternalGuest, getDecodedBooking, saveExternalGuest } from "../../shared/queries";
import { Booking, BookingGuest, BookingGuestDetails } from "../../types";

type GuestAreaState = {
    loading: boolean,
    code: string,
    bookingDetails: BookingGuestDetails | undefined,
    accessPin: string[]
}

export const fetchBookingWithPin = createAsyncThunk(
    'guestArea/fetchBookingWithPin',
    async ({ code, marketing } : { code: number | string, marketing: boolean }, { getState }) => {
        const state = getState() as RootState
        return await getDecodedBooking(code.toString(), state.guestArea.accessPin.join(''), marketing)
    }
)

export const storeExternalGuest = createAsyncThunk<any, any, any>(
    'guestArea/storeExternalGuest',
    async (guest: BookingGuest, { getState }) => {
        const state = getState() as RootState
        return await saveExternalGuest(
            state.guestArea.code,
            state.guestArea.accessPin.join(''),
            guest
        )
    }
)

export const deleteBookingGuest = createAsyncThunk<any, any, any>(
    'guestArea/deleteBookingGuest',
    async (idBookingGuest: number, { getState }) => {
        const state = getState() as RootState
        return await deleteExternalGuest(
            state.guestArea.code,
            state.guestArea.accessPin.join(''),
            idBookingGuest
        )
    }
)

const guestAreaSlice = createSlice({
    name: 'guestArea',
    initialState: {
        code: '',
        loading: false,
        bookingDetails: undefined,
        accessPin: []
    } as GuestAreaState,
    reducers: {
        setCode: (state, action) => {
            state.code = action.payload
        },
        setAccessPin: (state, action) => {
            localStorage.setItem(state.code, action.payload)
            state.accessPin = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchBookingWithPin.pending, (state) => {
            state.loading = true
          })
        builder.addCase(fetchBookingWithPin.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(fetchBookingWithPin.fulfilled, (state, action: PayloadAction<BookingGuestDetails>) => {
            state.bookingDetails = action.payload
            state.loading = false
        })

        builder.addCase(storeExternalGuest.pending, (state) => {
            state.loading = true
          })
        builder.addCase(storeExternalGuest.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(storeExternalGuest.fulfilled, (state, action: PayloadAction<BookingGuest>) => {
            if (state.bookingDetails && state.bookingDetails.booking) {
                const guestIndex = state.bookingDetails.booking.guests.findIndex(g => g.idBookingGuest === action.payload.idBookingGuest)
                if (guestIndex < 0) {
                    state.bookingDetails.booking.guests.push(action.payload)
                } else {
                    state.bookingDetails.booking.guests[guestIndex] = action.payload
                }
            }
            state.loading = false
        })

        builder.addCase(deleteBookingGuest.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteBookingGuest.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(deleteBookingGuest.fulfilled, (state, action: PayloadAction<BookingGuest>) => {
            if (state.bookingDetails && state.bookingDetails.booking && state.bookingDetails.booking.guests) {
                state.bookingDetails.booking.guests = state.bookingDetails?.booking.guests.filter(g => g.idBookingGuest !== action.payload.idBookingGuest)
            }
            state.loading = false
        })
    }
})

export const { setAccessPin, setCode } = guestAreaSlice.actions

export default guestAreaSlice.reducer