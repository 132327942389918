import { motion } from "framer-motion"
import Icon from "../common/Icon"

type CheckboxProps = {
  label?: string,
  checked: boolean,
  hint?: string,
  size?: string,
  onChange?: any,
  renderLabel?: Function,
  disabled?: boolean,
  className?: string
}

function getRandomId (length: any) {
  let result           = '';
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  let date = new Date()
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result
}

export default function Checkbox ({
  label,
  renderLabel,
  checked,
  hint,
  size,
  disabled = false,
  onChange,
  className
} : CheckboxProps) {

  const id = getRandomId(5)

  return (
    <div className={'flex items-center space-x-4'}>
      <div>
        <div className={`relative check-wrapper ${checked && 'checked bg-orange-600'}`}>
          <input
            className={'w-2 h-2 cursor-pointer'}
            checked={checked}
            onChange={() => {
              if (!disabled && onChange) onChange()
            }}
            id={'chk_' + id}
            name={'chk_' + id}
            type="checkbox" />

          {
            checked &&
            <div onClick={() => {
              if (!disabled) onChange()
            }} className={'absolute text-white flex items-center justify-center w-full h-full top-0 left-0'}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="w-3 h-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            </div>
          }
        </div>
      </div>
      <div>
        <label className={'text-gray-600 cursor-pointer text-md font-normal'} htmlFor={'chk_' + id}>
          {renderLabel ? renderLabel() : label}
        </label>
        {
          hint &&
          <div style={{ marginTop: '0.2rem' }}>
            {hint}
          </div>
        }
      </div>
    </div>
  )

}
