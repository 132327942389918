import { useAuth0 } from "@auth0/auth0-react";
import { Partner } from "../../types";
import Logo from "../common/Logo";
import Button from "../form/Button";

export default function PartnerHeader ({ partner } : { partner?: Partner }) {

  const { logout } = useAuth0()

  if (!partner) return <></>

  return (
    <div className="my-6 w-full flex items-center justify-between">
      <div className="flex items-center justify-center space-x-3">
        <div><Logo size={'h-8'} /></div>
        <div className="text-4xl">
            🤝
        </div>
        <div><Logo size={'h-8'} url={partner?.partnerLogoUrl} /></div>
      </div>

      <div>
        <Button className="flex space-x-2" icon="logout" type="primary" label="Logout" onClick={logout}></Button>
      </div>
    </div>
  )

}