import React, {useEffect, useRef, useState} from 'react';
import Icon from "./common/Icon";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {useHistory} from "react-router";
import {searchIndex} from "../shared/queries";
import useDebounce from "../hooks/useDebounce";
import {useTranslation} from "react-i18next";

function useOutsideAlerter(ref: any, onOutsideClick: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function HighlightText ({ text, item, itemKey }: { text: any, itemKey: any, item: any }) {
  if (item._matchesInfo && item._matchesInfo[itemKey]) {
    const splitString = text.split('')
    const matches = item._matchesInfo[itemKey]
    if (matches) {
      matches.forEach((match: any) => {
        splitString[match.start] = `<mark class="bg-orange-200">${splitString[match.start]}`
        splitString[match.start + (match.length - 1)] = `${splitString[match.start + (match.length - 1)]}</mark>`
      })
    }
    return (
      <span dangerouslySetInnerHTML={{
        __html: splitString.join('')
      }} />
    )
  }
  return (
    <span>{text}</span>
  )
}

export default function GlobalSearch () {

  const { t } = useTranslation()
  const wrapperRef = useRef(null)

  const router = useHistory()

  const { profile } = useSelector((state: RootState) => state.session)
  const currentHotel = useSelector<any>(state => state.hotels.currentHotel)

  const [emptyState, setEmptyState] = useState(false)
  const [query, setQuery] = useState('')
  const [items, setItems] = useState<any>([])

  const debouncedQuery = useDebounce(query, 400)

  useOutsideAlerter(wrapperRef, () => {
    setItems([])
  })

  async function searchItems (text: string) {
    try {
      const data = await searchIndex(text)
      if (data) {
        setItems(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (debouncedQuery !== null && debouncedQuery !== '') {
      searchItems(debouncedQuery)
    }
  }, [ debouncedQuery ])

  return (
    <div className="relative">
      {
        (emptyState) && <div style={{ zIndex: 999 }} className="fixed bg-black opacity-30 top-0 left-0 w-screen h-screen z-40"></div>
      }
      <div ref={wrapperRef}>
        <div
          style={{
            boxShadow: '0px 0px 4px rgba(0,0,0,0.120), 0px 0px 0px 1px rgba(0,0,0,0.130)',
            zIndex: 999
          }}
          className="flex rounded-md items-center relative">
          <div className={'absolute top-0 left-0 h-full flex items-center justify-center px-2 text-gray-500'}>
            <Icon name={'search'} size={'25px'} />
          </div>
          <input
            onFocus={() => setEmptyState(true)}
            onBlur={() => setEmptyState(false)}
            value={query}
            onChange={e => {
              setQuery(e.target.value)
            }}
            placeholder={t('globalSearch.globalSearch')}
            className="px-4 pl-12 pr-8 py-2 bg-white customshadow focus:bg-white rounded-md w-full focus:shadow-xl outline-none transition-all"
          />
          {
            ((query && query.length > 0) || (items && items.hits && items.hits.length > 0)) &&
              <button
                  onClick={() => {
                    setQuery('')
                    setItems([])
                  }}
                  className={'absolute top-0 right-0 h-full flex items-center justify-center px-2 text-gray-500'}>
                  <Icon name={'close'} size={'20px'} />
              </button>
          }
        </div>

        {
          (emptyState && items.length === 0) &&
            <div
                style={{
                  top: '45px',
                  zIndex: 99999,
                  height: '250px',
                  boxShadow: '0px 0px 4px rgba(0,0,0,0.120), 0px 0px 0px 1px rgba(0,0,0,0.120)',
                  backgroundImage: 'url(/searchbox.svg)',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
                className="p-4 flex items-center justify-center flex-col bg-white absolute w-full rounded-md">
                <div className="text-lg mt-4 text-center font-semibold text-gray-600">
                  {t('globalSearch.globalSearch')}
                </div>
                <div className={'text-center font-normal text-gray-500'}>
                    {t('globalSearch.searchEverything')}
                </div>
            </div>
        }

        {
          (items && items.hits && items.hits.length > 0) &&
            <div
                style={{
                  top: '45px',
                  zIndex: 99999,
                  boxShadow: '0px 0px 4px rgba(0,0,0,0.120), 0px 0px 0px 1px rgba(0,0,0,0.120)'
                }}
                className="flex overflow-hidden flex-col bg-white absolute w-full rounded-md">
                <div className={'overflow-auto'} style={{
                  maxHeight: '400px',
                }}>
                  {
                    (items && items.hits) && items.hits.map((item: any, index: any) => (
                      <button
                        onClick={() => {
                          router.push('/app/reservations/' + item.idBooking)
                        }}
                        className="text-left block w-full border-b cursor-pointer hover:bg-gray-100 px-2 py-3 flex space-x-3 text-gray-600" key={index}>
                        <div>
                          <div className="bg-blue-600 p-1 text-white rounded-md">
                            <Icon name={'user-circle'} size={'18px'} />
                          </div>
                        </div>
                        <div className="truncate w-full text-ellipsis overflow-hidden flex flex-col">
                          <div className="flex text-gray-700 font-semibold space-x-1">
                            <HighlightText itemKey={'firstName'} text={item.firstName} item={item}/>
                            <HighlightText itemKey={'lastName'} text={item.lastName} item={item}/>
                          </div>
                          <div className="text-normal text-sm text-gray-600">
                            <HighlightText itemKey={'email'} text={item.email} item={item}/>
                          </div>
                          <div className="text-normal text-sm text-gray-600">
                            <HighlightText itemKey={'bookingCode'} text={item.bookingCode} item={item}/>
                          </div>
                          <div className="text-normal text-sm text-gray-600">
                            <HighlightText itemKey={'accomodationName'} text={item.accomodationName} item={item}/>
                          </div>
                        </div>
                      </button>
                    ))
                  }
                </div>
                <div className={'text-center text-sm text-gray-600 border-t py-2 px-2'}>
                    🚀 {t('globalSearch.youType')}
                </div>
            </div>
        }
      </div>
    </div>
  );

}
