import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useMutation, useQuery} from "react-query";
import {useSelector} from "react-redux";
import {getAccomodationById, getOpportunityReport, toggleOpportuntiy} from "../shared/queries";
import { formatCurrency, kFormatter } from "../shared/utils";
import { RootState } from "../store";
import Confetti from 'react-confetti'

const Question = ({ title, children } : { title: string, children: any }) => {

  const [visible, setVisible] = useState(false)

  return (
    <div className="cursor-pointer bg-white border rounded-lg p-6" onClick={() => setVisible(!visible)}>
      <div className="items-center flex justify-between">
        <div className="text-gray-700 font-medium text-lg">
          {title}
        </div>
        <div>
          {
            !visible ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
              </svg>
          }
        </div>
      </div>
      {
        visible &&
        <div className="leading-relaxed mt-4 text-gray-600">
          {children}
        </div>
      }
    </div>
  )
}

export default function OpportunityPage () {

  const { t } = useTranslation()

  const { data: report } = useQuery(['opportunity'], () => getOpportunityReport(currentHotel))

  const [viewConfetti, setViewConfetti] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const [opportunityState, setOpportunityState] = useState(false)

  const {
    currentHotel,
    currentHotelObject
  } = useSelector((state: RootState) => state.hotels)

  const toggleMutation = useMutation((id: any) => toggleOpportuntiy(id), {
    onSuccess: () => {
      loadOpportunityState()
    },
    onError: () => {}
  })

  useEffect(() => {
    loadOpportunityState()
  }, [])

  async function loadOpportunityState () {
    try {
      if (!currentHotelObject || !currentHotelObject.idAccomodation) return
      setLoadingState(true)
      const data = await getAccomodationById(currentHotelObject.idAccomodation)
      if (data.opportunitySendEmails) {
        setOpportunityState(true)
      } else {
        setOpportunityState(false)
        localStorage.removeItem('opportunity_confetti')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingState(false)
    }
  }

  useEffect(() => {
    if (opportunityState) {
      const lValue = localStorage.getItem('opportunity_confetti')
      if (lValue) return
      setViewConfetti(true)
      localStorage.setItem('opportunity_confetti', 'true')
      setTimeout(() => {
        setViewConfetti(false)
      }, 12000)
    }
  }, [ opportunityState ])

	return (
		<div className={'w-full max-w-3xl mx-auto'}>
      {
        viewConfetti && <Confetti />
      }
			<div className="my-8 flex justify-center">
        <img alt={'BeSafe Opportunity'} src={'/opportunityhero.svg'} style={{ width: '450px' }} />
			</div>

      <div className={'border bg-white rounded-md p-6 text-gray-700'}>
        <div className={'text-lg font-bold text-gray-700 mb-4'}>👀 {t('vary.whatItIs')}</div>
        <div className={'leading-relaxed'}>
          {t('vary.fromTheBeginning')}<br/><br/>
          {t('vary.todayWe')}<br/><br/>
          {t('vary.forEachNew')}
        </div>
      </div>

      <div className="my-10">
        <div className="font-medium text-gray-700 text-center text-2xl mt-12">
          {t('vary.howItWorksClick')}
        </div>
        <div className="text-gray-600 text-center mt-2 text-lg">
          {t('vary.weDoSell')}
        </div>
      </div>
      
      <div className="rounded-lg p-8">
        <div className="flex items-center justify-center">
          <img style={{ width: '100%' }} src="/stepsopportunity.svg" alt="" />
        </div>
      </div>

      <div className="py-8 text-center">
        <div className="flex space-x-3 justify-center">
          {
            (!opportunityState) ?
            <button onClick={() => {
              if (toggleMutation.isLoading) return
              if (currentHotelObject && currentHotelObject.idAccomodation) {
                toggleMutation.mutate(currentHotelObject.idAccomodation)
              }
            }} className="rounded-md bg-orange-600 text-white font-medium px-4 py-2 text-lg">
              {toggleMutation.isLoading ? 'Attendi...' : t('vary.activateAutomaticOffer')}
            </button>
            :
            <button onClick={() => {
              if (toggleMutation.isLoading) return
              if (currentHotelObject && currentHotelObject.idAccomodation) {
                toggleMutation.mutate(currentHotelObject.idAccomodation)
              }
            }} className="rounded-md bg-red-600 text-white font-medium px-4 py-2 text-lg">
              {toggleMutation.isLoading ? 'Attendi...' : t('vary.deactivateAutomaticOffer')}
            </button>
          }
          <button onClick={() => window.open('https://besafer.besafesuite.com', '_blank')} className="rounded-md text-orange-600 text-white font-medium px-4 py-2 text-lg">
            {t('vary.viewPreview')}
          </button>
        </div>

        <div className="text-gray-600 mt-6">
          {t('Attivando il presente servizio dichiaro di aver letto e accettato l’Informativa sul Servizio. In particolare autorizzo Be Safe Group a scaricare i dati delle prenotazioni future dal gestionale utilizzato dalla struttura, al fine esclusivo di inviare comunicazioni commerciali relative a proposte assicurative agli ospiti della struttura risultanti dalle suddette prenotazioni.')}
        </div>
      </div>

      <div className="text-2xl my-12 text-gray-700 text-center font-medium">
        {t('vary.faq')}
      </div>

      <div className="pb-12 flex flex-col space-y-4">
        <Question title={t('vary.whatItHappens')}>
          {t('vary.weDownload')}
        </Question>

        <Question title={t('vary.howPaymentIsManaged')}>
          {t('vary.beSafePart')}
        </Question>

        <Question title={t('vary.whatIfTravellerBuys')}>
          {t('vary.ifTravellerBuys')}
        </Question>

        <Question title={t('vary.whatIfTravellerCancel')}>
          {t('vary.ifTravellerCancel')}
        </Question>

        <Question title={t('vary.whatCoverage')}>
          {t('vary.whatCoverageText')}
        </Question>
      </div>

		</div>
	)

}
