import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { fetchRooms, removeOptionRoom, updateOptionRoom, updateOptionRoomType } from "../../store/slices/offerSlice"
import { QuoteOption, QuoteRoom } from "../../types"
import FormField from "../form/FormField"
import SelectInput from "../form/SelectInput"
import TextInput from "../form/TextInput"
import RoomListModal from "./RoomListModal"
import dayjs from "dayjs"
import quoteRoomSchema from "../../validation/quoteRoomSchema"
import { ValidationError } from "joi"

const CHILDREN_LIST = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 }
]

const ADULT_LIST = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 }
]

export default function QuoteOptionRoom ({
  option,
  room
} : {
  option: QuoteOption,
  room: QuoteRoom
}) {

  const dispatch = useAppDispatch()
  const [changeRoom, setChangeRoom] = useState(false)
  const { currentHotel } = useAppSelector(state => state.hotels)
  const [errors, setErrors] = useState<ValidationError | undefined>()
  const { rooms } = useAppSelector(state => state.offerSlice)

  const handleValidate = () => {
    const { error, value, warning } = quoteRoomSchema.validate(room, {
      abortEarly: false
    })
    setErrors(error)
  }

  useEffect(() => {
    handleValidate()
  }, [
    room
  ])

  return (
    <div className="flex space-x-6">
      <RoomListModal
        visible={changeRoom}
        onSelect={id => {
          dispatch(updateOptionRoomType({
            optionId: option.idQuoteOption,
            quoteRoomId: room.idQuoteRoom,
            roomId: id
          }))
          setChangeRoom(false)
        }}
        onUpdate={() => dispatch(fetchRooms({ idAccomodation: currentHotel }))}
        onClose={() => setChangeRoom(false)}
      />

      <div className="w-24 h-24 rounded-md bg-cover bg-center" style={{
        backgroundImage: `url(${(room.room.images.length > 0) ? room.room.images[0].imageUrl : ''})`
      }} />

      <div className="flex flex-col space-y-2">

        <div className="flex justify-between font-bold text-gray-600 mb-2">
          <div>{room.title}</div>
          <div className="flex space-x-2">
            <button onClick={() => setChangeRoom(true)} className="text-left text-orange-600 p-1 rounded-md hover:bg-orange-100">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                <path d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z" />
              </svg>
            </button>
            <button onClick={() => {
              if (window.confirm('Sei sicuro di voler rimuovere la camera dall\'opzione?')) {
                dispatch(removeOptionRoom({
                  idOption: option.idQuoteOption,
                  idQuoteRoom: room.idQuoteRoom
                }))
              }
            }} className="text-left text-red-600 p-1 rounded-md hover:bg-red-100">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>

        <div className="flex w-80 space-x-2">
          <FormField errors={errors} errorKey="adults" className="flex-1" label={'Adulti'}>
            <SelectInput
              value={ADULT_LIST.find(al => al.value === room.adults)}
              options={ADULT_LIST}
              onChange={(item: any) => {
                if (item) {
                  dispatch(updateOptionRoom({
                    idOption: option.idQuoteOption,
                    room: {
                      ...room,
                      adults: item.value
                    }
                  }))
                }
              }}
            />
          </FormField>

          <FormField errors={errors} errorKey="children" className="flex-1" label={'Bamibini'}>
            <SelectInput
              value={CHILDREN_LIST.find(al => al.value === room.children)}
              options={CHILDREN_LIST}
              onChange={(item: any) => {
                if (item) {
                  dispatch(updateOptionRoom({
                    idOption: option.idQuoteOption,
                    room: {
                      ...room,
                      children: item.value
                    }
                  }))
                }
              }}
            />
          </FormField>
          
        </div>

        <div>
          <FormField errors={errors} errorKey="price" label="Prezzo">
            <TextInput
              numbered={true}
              suffix="EUR"
              value={room.price}
              onChange={val => {
                dispatch(updateOptionRoom({
                  idOption: option.idQuoteOption,
                  room: {
                    ...room,
                    price: parseFloat(val)
                  }
                }))
              }}
            />
          </FormField>
        </div>

        <div className="flex items-center space-x-2">
          <button onClick={() => dispatch(updateOptionRoom({
            idOption: option.idQuoteOption,
            room: {
              ...room,
              price: room.price * (dayjs(option.departure.toString(), 'YYYYMMDD').diff(dayjs(option.arrival.toString(), 'YYYYMMDD'), 'days'))
            }
          }))} className="font-medium text-orange-600">moltiplica per notte</button>
          <svg viewBox="0 0 2 2" className="hidden text-gray-600 md:inline mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1"></circle></svg>
          <button onClick={() => dispatch(updateOptionRoom({
            idOption: option.idQuoteOption,
            room: {
              ...room,
              price: room.price * (room.adults + room.children)
            }
          }))} className="font-medium text-orange-600">moltiplica per persona</button>
        </div>

      </div>
    </div>
  )

}