import { useTranslation } from "react-i18next";
import Button from "../form/Button";

export default function ModalActions ({
    onClose,
    onSave,
    disableSave = false,
    saveLabel,
    closeLabel,
    isLoading = false
} : {
    onClose?: () => void,
    onSave?: () => void,
    saveLabel?: string,
    closeLabel?: string,
    isLoading?: boolean,
    disableSave?: boolean
}) {

    const { t } = useTranslation()

    return (
        <div className={'bg-slate-50 flex border-t space-x-2 p-4'}>
            {
                onSave &&
                <Button
                    className="bg-orange-600 text-white"
                    disabled={disableSave}
                    loading={isLoading}
                    onClick={onSave}
                    label={saveLabel || t('general.confirm')} />
            }
            {
                onClose &&
                <Button
                    loading={isLoading}
                    onClick={onClose}
                    className="bg-red-100 text-red-600"
                    label={closeLabel || t('general.cancel')} />
            }
        </div>
    )

}