import { Fragment, useMemo, useState } from "react";
import { Accomodation, AccomodationApi, ApiChannel, Policy, PolicyMap, PolicyRate } from '../../../types'
import FormSectionTitle from "../../form/FormSectionTitle";
import Modal from "../../common/Modal";
import ModalCard from "../../common/ModalCard";
import ModalActions from "../../common/ModalActions";
import SelectInput from "../../form/SelectInput";
import FormField from "../../form/FormField";
import HotelConnectedRate from "./HotelConnectedRate";
import { useQuery } from "react-query";
import { getAccomodationPolicyRates } from "../../../shared/queries";
import TextInput from "../../form/TextInput";

export default function HotelConnectedRates ({
    accomodation,
    loading,
    apiChannels
} : {
    loading: boolean,
    accomodation: Accomodation,
    apiChannels: AccomodationApi[]
}) {

    const [searchRate, setSearchRate] = useState('')
    const [rateMap, setRateMap] = useState<PolicyMap>({})
    const [create, setCreate] = useState<boolean>(false)
    const [currentChannel, setCurrentChannel] = useState<{ label: string, value: number | string }>()
    const [currentRate, setCurrentRate] = useState<{ label: string, value: number | string }>() 

    const {
        data: policyRates
    } = useQuery('policyRates', () => getAccomodationPolicyRates(accomodation.idAccomodation ? accomodation.idAccomodation : 0), {
        onSuccess: data => {
            setRateMap(data)
        }
    })

    function getRateList (idAccomodationApi: string | number) {
        if (apiChannels) {
            const apiChannel = apiChannels.find(c => c.idAccomodationApi === idAccomodationApi)
            if (apiChannel) {
                const rates = JSON.parse(apiChannel?.apiProps).rates
                console.log('rrr', rates)
                let codes = [
                    ...rates.filter((rate: string) => !rateMap[rate])
                        .map((rate: string) => {
                        return {
                            label: rate,
                            value: rate
                        }
                    })
                ]
                if (apiChannel.apiChannel.isInternal) {
                    codes = [
                        ...codes,
                        {
                            label: 'BeSafe Classic',
                            value: 'MANUAL'
                        },
                        {
                            label: 'BeSafe Flex (spese mediche)',
                            value: 'SANITARY'
                        },
                        {
                            label: 'BeSafe solo Annullamento',
                            value: 'CANCELLATION'
                        },
                        {
                            label: 'BeSafe Flex',
                            value: 'SANITARY'
                        },
                        {
                            label: 'BeSafe Plus',
                            value: 'MANUAL_PLUS'
                        },
                        {
                            label: 'BeSafe Plus - Interruzione',
                            value: 'MANUAL_PLUS_INTERRUPTION'
                        },
                        {
                            label: 'BeSafe Winter',
                            value: 'WINTER'
                        },
                        {
                            label: 'BeSafe Summer',
                            value: 'SUMMER'
                        },
                        {
                            label: 'BeSafe Business',
                            value: 'BUSINESS'
                        },
                        {
                            label: 'BeSafe Pioggia',
                            value: 'BESAFE_RAIN'
                        }
                    ]
                }

                return codes
            }
        }
        return []
    }

    const filteredRateMap = useMemo(() => {
        if (!rateMap) return []
        return Object.keys(rateMap).filter(key => key.toLowerCase().indexOf(searchRate.toLowerCase()) > -1).sort((a, b) => a.localeCompare(b))
    }, [rateMap, searchRate])

    return (
        <div className="pb-12">
            <FormSectionTitle
                title={'Tariffe connesse'}
                subtitle={'associazione tra tariffe e polizze assicurative'}
                onEdit={() => {
                    setCreate(true)
                }}
                edit={true}
                borderless
                editLabel={'Aggiungi tariffa'} />
                
                <div className="mb-4">
                    <TextInput
                        value={searchRate}
                        placeholder="Ricerca tariffa"
                        onChange={val => setSearchRate(val)} />
                </div>

                <div className="border bg-white rounded">
                    <table className="min-w-full">
                        <thead>
                            <tr>
                                <th scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                    Tariffa
                                </th>
                                <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">
                                    % Copertura
                                </th>
                                <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">
                                    Interna
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                <span className="sr-only">Azioni</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredRateMap.map((key, index) => (
                                    <HotelConnectedRate
                                        accomodation={accomodation}
                                        rateMap={rateMap}
                                        key={index}
                                        value={key} 
                                    />
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                {
                    (filteredRateMap.length === 0) &&
                    <div>
                        Nessuna tariffa associata
                    </div>
                }

            <Modal
                visible={create}>
                <ModalCard
                    renderActions={() => (
                        <ModalActions
                            onSave={() => {
                                if (currentRate) {
                                    setRateMap({
                                        ...rateMap,
                                        [currentRate.value]: []
                                    })
                                    setCreate(false)
                                }
                            }}
                            onClose={() => {
                                setCreate(false)
                            }} />
                    )}
                    className={'w-full max-w-md'}
                    title={'Associa tariffa'}
                    subTitle={'Scegli la tariffa da associare alla polizza'}>
                    <div className="p-4 flex flex-col space-y-4">
                        <FormField
                            label={'Canale'}>
                            <SelectInput
                                onChange={(item: any) => {
                                    if (item) setCurrentChannel(item)
                                }}
                                loading={loading}
                                options={apiChannels ? apiChannels.map(c => {
                                    return {
                                        label: c.apiChannel.apiChannelName,
                                        value: c.idAccomodationApi
                                    }
                                }) : []} />
                        </FormField>

                        <FormField
                            label={'Tariffa del canale'}>
                            <SelectInput
                                disabled={!currentChannel}
                                onChange={(item: any) => {
                                    if (item) setCurrentRate(item)
                                }}
                                options={currentChannel ?
                                    getRateList(currentChannel.value) :
                                    []
                                } />
                        </FormField>
                    </div>
                </ModalCard>
            </Modal>

        </div>

    )

}
