import { createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import { CancellationPolicy, Quote, QuoteCustomer, QuoteOption, QuoteOptionService, QuoteRoom, Room, ShortAccomodation, Translation} from "../../types"
import { v4 as uuidv4 } from 'uuid'
import dayjs from "dayjs"
import { getQuote, getRooms } from "../../shared/queries"

type OfferState = {
  quote: Quote,
  rooms: Room[],
  fetching: {
    quote: boolean,
    rooms: boolean
  }
}

export const fetchRooms = createAsyncThunk(
  'quote/getRooms',
  async ({ idAccomodation } : { idAccomodation: number | string | null }, { getState }) => {
      if (idAccomodation) return await getRooms(idAccomodation)
      return []
  }
)

export const fetchQuote = createAsyncThunk(
  'quote/getQuote',
  async ({ idQuote } : { idQuote: number | string | null | undefined }, { getState }) => {
      if (idQuote) return await getQuote(idQuote)
      return undefined
  }
)

const offerSlice = createSlice({
    name: 'hotels',
    initialState: {
      fetching: {
        rooms: false,
        quote: false
      },
      rooms: [],
      quote: {
        insuranceRate: 0,
        insuranceMarkup: 0,
        language: 'it',
        idCancellationPolicy: undefined,
        policyText: undefined,
        policyTitle: undefined,
        options: [],
        customer: undefined,
        expiresAt: undefined,
        email: '',
        note: '',
        firstName: '',
        lastName: '',
        message: '',
        besafeIncluded: true,
        refundable: true,
        idAccomodation: 0,
        askBilling: false,
        insuranceProduct: 'CLASSIC',
        isDeleted: false,
        idQuote: undefined,
        idQuoteCustomer: undefined,
        idQuoteOptionAccepted: undefined,
        customerAddress: undefined,
        customerCountryCode: undefined,
        customerEmail: undefined,
        customerPhone: undefined,
        customerZipCode: undefined
      }
    } as OfferState,
    reducers: {
      resetQuote: (state) => {
        state.quote = {
          insuranceRate: 0,
          insuranceMarkup: 0,
          language: 'it',
          idCancellationPolicy: undefined,
          policyText: undefined,
          policyTitle: undefined,
          options: [],
          customer: undefined,
          expiresAt: undefined,
          isDeleted: false,
          email: '',
          note: '',
          firstName: '',
          lastName: '',
          message: '',
          besafeIncluded: true,
          refundable: true,
          idAccomodation: 0,
          askBilling: false,
          insuranceProduct: 'CLASSIC'
        }
      },
      setPolicy: (state, action: PayloadAction<CancellationPolicy>) => {
        state.quote.idCancellationPolicy = action.payload.idCancellationPolicy
        state.quote.policyTitle = action.payload.title
        state.quote.cancellationUnit = action.payload.cancellationUnit
        state.quote.cancellationAmount = action.payload.cancellationAmount
        state.quote.prepaidAmount = action.payload.prepaidAmount
        state.quote.prepaidUnit = action.payload.prepaidUnit
        try {
          const ts = JSON.parse(action.payload.translations as any) as Translation[]
          if (state.quote.language) {
            state.quote.policyTitle = action.payload.title
            if (state.quote.language === 'it') {
              state.quote.policyText = action.payload.description
            } else {
              const correctTs = ts.find(t => t.lang === state.quote.language)
              if (correctTs) {
                state.quote.policyText = correctTs.value
              }
            }
          }
        } catch (e) {}
      },
      removePolicy: (state) => {
        state.quote.idCancellationPolicy = undefined
        state.quote.policyText = undefined
        state.quote.policyTitle = undefined
      },
      setExpireTime: (state, action: PayloadAction<number | undefined>) => {
        state.quote.expiresAt = action.payload
      },
      setRefundable: (state, action: PayloadAction<boolean>) => {
        state.quote.refundable = action.payload
      },
      setMessage: (state, action: PayloadAction<string>) => {
        state.quote.message = action.payload
      },
      setBesafeIncluded: (state, action: PayloadAction<boolean>) => {
        state.quote.besafeIncluded = action.payload
      },
      setInsuranceRate: (state, action: PayloadAction<number>) => {
        state.quote.insuranceRate = action.payload
      },
      setInsuranceMarkup: (state, action: PayloadAction<number>) => {
        state.quote.insuranceMarkup = parseFloat(action.payload.toString())
      },
      setCustomer: (state, action: PayloadAction<QuoteCustomer | undefined>) => {
        state.quote.customer = action.payload
      },
      addOption: (state) => {
        state.quote.options.push({
          idQuoteOption: uuidv4(),
          optionType: 'ROOM',
          idAccomodation: null,
          idQuote: null,
          arrival: parseInt(dayjs().format('YYYYMMDD')),
          departure: parseInt(dayjs().add(1, 'day').format('YYYYMMDD')),
          rooms: [],
          services: [],
          destination: undefined,
          destinationTotal: undefined
        })
      },
      updateOption: (state, action: PayloadAction<QuoteOption>) => {
        state.quote.options = state.quote.options.map(o => {
          if (o.idQuoteOption === action.payload.idQuoteOption) return action.payload
          return o
        })
      },
      removeOption: (state, action: PayloadAction<{ id: any }>) => {
        state.quote.options = state.quote.options.map(o => {
          if (o.idQuoteOption !== action.payload.id) return o
          return {
            ...o,
            toDelete: true
          }
        })
      },
      updateOptionRoomType: (state, action: PayloadAction<{ optionId: any, quoteRoomId: number, roomId: number }>) => {
        const option = state.quote.options.find(o => o.idQuoteOption === action.payload.optionId)
        const room = state.rooms.find(r => r.idRoom === action.payload.roomId)
        if (option && room) {
          const optionRoom = option.rooms.find(o => o.idQuoteRoom === action.payload.quoteRoomId)
          if (optionRoom) {
            optionRoom.imageUrl = room.imageUrl
            optionRoom.title = room.name
            optionRoom.description = room.description
            optionRoom.idRoom = room.idRoom
            optionRoom.price = room.basePrice
          }
        }

      },
      addOptionService: (state, action: PayloadAction<{ optionId: any, service: QuoteOptionService }>) => {
        const option = state.quote.options.find(o => o.idQuoteOption === action.payload.optionId)
        if (option) {
          console.log(option)
          option.services.push({
            ...action.payload.service,
            idQuoteOptionService: uuidv4()
          })
        }
      },
      updateOptionService: (state, action: PayloadAction<{ idOption: any, service: QuoteOptionService }>) => {
        const option = state.quote.options.find(o => o.idQuoteOption === action.payload.idOption)
        if (option) {
          option.services = option.services.map(s => {
            if (s.idQuoteOptionService === action.payload.service.idQuoteOptionService) return action.payload.service
            return s
          })
        }
      },
      removeOptionService: (state, action: PayloadAction<{ idOption: any, idOptionService: any }>) => {
        const option = state.quote.options.find(o => o.idQuoteOption === action.payload.idOption)
        if (option) {
          option.services = option.services.map(r => {
            if (r.idQuoteOptionService !== action.payload.idOptionService) return r
            return {
              ...r,
              toDelete: true
            }
          })
        }
      },
      addOptionRoom: (state, action: PayloadAction<{ optionId: any, roomId: number }>) => {
        const option = state.quote.options.find(o => o.idQuoteOption === action.payload.optionId)
        const room = state.rooms.find(r => r.idRoom === action.payload.roomId)
        if (option && room) {
          option.rooms.push({
            idQuoteRoom: uuidv4(),
            idRoom: room.idRoom,
            imageUrl: room.imageUrl,
            title: room.name,
            room: {
              images: room.images
            },
            price: room.basePrice,
            description: room.description,
            accepted: false,
            adults: 2,
            children: 0
          })
        }
      },
      updateOptionRoom: (state, action: PayloadAction<{ idOption: any, room: QuoteRoom }>) => {
        const option = state.quote.options.find(o => o.idQuoteOption === action.payload.idOption)
        if (option) {
          option.rooms = option.rooms.map(r => {
            if (r.idQuoteRoom === action.payload.room.idQuoteRoom) return action.payload.room
            return r
          })
        }
      },
      removeOptionRoom: (state, action: PayloadAction<{ idOption: any, idQuoteRoom: any }>) => {
        const option = state.quote.options.find(o => o.idQuoteOption === action.payload.idOption)
        if (option) {
          option.rooms = option.rooms.map(r => {
            if (r.idQuoteRoom !== action.payload.idQuoteRoom) return r
            return {
              ...r,
              toDelete: true
            }
          })
        }
      }
    },
    extraReducers: builder => {

      builder.addCase(fetchRooms.pending, (state) => {
        state.fetching.rooms = true
      })
      builder.addCase(fetchRooms.rejected, (state) => {
        state.fetching.rooms = false
      })
      builder.addCase(fetchRooms.fulfilled, (state, action: PayloadAction<Room[]>) => {
          state.rooms = action.payload
          state.fetching.rooms = false
      })

      builder.addCase(fetchQuote.pending, (state) => {
        state.fetching.quote = true
      })
      builder.addCase(fetchQuote.rejected, (state) => {
        state.fetching.quote = false
      })
      builder.addCase(fetchQuote.fulfilled, (state, action: PayloadAction<Quote | undefined>) => {
        if (action.payload !== undefined) {
          state.quote = action.payload
        }
        state.fetching.quote = false
      })

    }
})

export const {
  resetQuote,
  setCustomer,
  addOption,
  removePolicy,
  updateOption,
  setInsuranceRate,
  removeOption,
  addOptionRoom,
  updateOptionRoom,
  removeOptionRoom,
  updateOptionRoomType,
  setBesafeIncluded,
  setMessage,
  setRefundable,
  setExpireTime,
  setInsuranceMarkup,
  setPolicy,
  addOptionService,
  updateOptionService,
  removeOptionService
} = offerSlice.actions

export default offerSlice.reducer