export default function Link ({
    onClick,
    children,
    className
} : {
    children: any,
    className?: string,
    onClick: () => void
}) {

    return (
        <button
            className={className + ' text-orange-600 underline'}
            onClick={onClick}>
            {children}
        </button>
    )

}