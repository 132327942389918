import { useMemo } from "react"
import { useAppSelector } from "../../hooks/redux"
import Flags from '../../shared/countyFlag.json'

export default function CountryFlag ({ countryId } : { countryId: number }) {

  const { countries } = useAppSelector(state => state.session)

  const currentCountry = useMemo(() => {
    return countries.find(c => c.idCountry === countryId)
  }, [ countryId, countries ])

  function getFlag () {
    if (currentCountry) {
      const flag = Flags.find(f => f.code.toLowerCase() === currentCountry.countryAlpha2.toLowerCase())
      if (flag) return flag.emoji
    }
    return null
  }

  return (
    <div className="flex space-x-2 items-center">
      {
        currentCountry &&
        <div>
          {getFlag()}
        </div>
      }
      <div>
        {currentCountry ? currentCountry.countryName : 'ND'}
      </div>
    </div>
  )

}