import Joi from 'joi'

export default Joi.object({
    email: Joi.string().required(),
    familyName: Joi.string().required(),
    givenName: Joi.string().required(),
    hotelName: Joi.any(),
    userType: Joi.any(),
    password: Joi.string().required(),
    promoCode: Joi.any(),
    username: Joi.string().required(),
    partnerId: Joi.any()
})
