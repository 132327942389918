import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { RootState } from "../../store"
import { BookingGuest, Policy } from "../../types"
import AddGuestModal from "./AddGuestModal"
import dayjs from "dayjs"
import { useAppDispatch } from "../../hooks/redux"
import { deleteBookingGuest } from "../../store/slices/guestAreaSlice"
import toast from "react-hot-toast"
import { fetchInsuranceFile } from "../../shared/queries"
import { getPolicyURL } from "../../shared/utils"

const b64toBlob = (base64: any, type = 'application/pdf') => fetch(`data:${type};base64,${base64}`).then(res => res.blob())

const PolicyDialog = ({ policy, onClose } : { policy?: Policy, onClose: () => void }) => {

    const { code } = useParams<any>()
    const { t, i18n } = useTranslation()
    const { bookingDetails, loading } = useSelector((state: RootState) => state.guestArea)

    const [loadingPolicyFile, setLoadingPolicyFile] = useState(false)

    function getCurrentDescription () {
        if (policy && policy.translatedInfoText) {
            const translatedData = JSON.parse(policy.translatedInfoText)
            if (translatedData[i18n.language]) {
                return translatedData[i18n.language]
            }
        }
        return ''
    }

    async function handleRetrieveFile (policy: Policy, forceDirect: boolean) {
        try {
            setLoadingPolicyFile(true)
            let language = bookingDetails?.booking.guests.find(g => g.country.countryAlpha2 === 'IT') ? 'IT' : 'EN'
            await getPolicyURL(policy, undefined, code, forceDirect, language)
        } catch (e) {
        } finally {
            setLoadingPolicyFile(false)
        }
    }

    if (!policy) return <></>

    return (
        <div className="w-screen h-screen fixed z-50 top-0 left-0" style={{ background: 'rgba(0,0,0,0.5)' }}>
            <div className="bg-white max-w-xl w-full rounded-md m-6 overflow-auto mx-auto md:mt-24">
                <div className="p-6">
                    <div className="text-xl text-gray-600 font-bold mb-6 flex space-x-2">
                        {t('vary.policyDetails')}
                    </div>
                    <div>
                        <div className="text-gray-600 mb-4">
                            {getCurrentDescription()}
                        </div>

                        <div className="text-lg font-medium text-gray-600 mb-4">
                            {t('vary.contacts')}
                        </div>
                        <div className="mb-4 text-gray-600">
                            {t('vary.insuranceOperatedBy')} <strong>{policy.policyCompany}</strong>, {t('vary.insuranceId')} <strong>{policy.policyCode}</strong>
                        </div>
                        <div className="text-gray-600 flex flex-col space-y-3">
                            {
                                (policy?.policyIsSanitary && policy?.roadAssistanceNumber) &&
                                <div className="flex space-x-2 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clipRule="evenodd" />
                                    </svg>
                                    <div className="flex flex-col space-y-1 md:flex-row md:space-y-0 md:space-x-2">
                                        <div>{t('vary.roadHelp')}:</div> <a className="text-orange-600 underline" href={`tel:${policy.roadAssistanceNumber}`}>{policy.roadAssistanceNumber}</a>
                                    </div>
                                </div>
                            }

                            {
                                (policy.policyIsSanitary && policy?.supportNumber) &&
                                <div className="flex space-x-2 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clipRule="evenodd" />
                                    </svg>
                                    <div className="flex flex-col space-y-1 md:flex-row md:space-y-0 md:space-x-2">
                                        <div>{t('vary.medHelp')}:</div> <a className="text-orange-600 underline" href={`tel:${policy.supportNumber}`}>{policy.supportNumber}</a>
                                    </div>
                                </div>
                            }

                            {
                                policy?.supportEmail &&
                                <div className="flex space-x-2 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M5.404 14.596A6.5 6.5 0 1116.5 10a1.25 1.25 0 01-2.5 0 4 4 0 10-.571 2.06A2.75 2.75 0 0018 10a8 8 0 10-2.343 5.657.75.75 0 00-1.06-1.06 6.5 6.5 0 01-9.193 0zM10 7.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" clipRule="evenodd" />
                                    </svg>
                                    <div className="flex flex-col space-y-1 md:flex-row md:space-y-0 md:space-x-2">
                                        <div>{t('vary.emailAddress')}:</div> <a className="text-orange-600 underline" href={`mailto:${policy.supportEmail}`}>{policy.supportEmail}</a>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="text-lg font-medium text-gray-600 mt-4 mb-4">
                            {t('vary.usefulDoc')}
                        </div>

                        {
                            loadingPolicyFile
                            ?
                            <div className="flex space-x-2 items-center text-orange-600 font-medium">
                                <div>
                                    <svg className="animate-spin -ml-1 mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div>
                                <div>
                                    {t('general.wait')}...
                                </div>
                            </div>
                            :
                            <button onClick={() => handleRetrieveFile(policy, false)} className="flex space-x-2 items-center text-orange-600">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clipRule="evenodd" />
                                        <path fillRule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div>
                                    {t('vary.goToFile')}
                                </div>
                            </button>
                        }
                        {
                            policy.dynamicFileUrl &&
                            <button onClick={() => handleRetrieveFile(policy, true)} className="flex space-x-2 items-center text-orange-600">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clipRule="evenodd" />
                                        <path fillRule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div>
                                    {t('vary.generalCondition')}
                                </div>
                            </button>
                        }
                        {
                            policy.privacyUrl &&
                            <button onClick={() => window.open(policy.privacyUrl, '_blank')} className="flex space-x-2 items-center text-orange-600">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clipRule="evenodd" />
                                        <path fillRule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div>
                                    {t('vary.privacyPolicy')}
                                </div>
                            </button>
                        }

                    </div>
                </div>
                <div className="border-t px-6 py-3">
                    <button
                        onClick={onClose}
                        className="bg-orange-600 text-white rounded-md px-4 py-2">{t('vary.close')}</button>
                </div>
            </div>
        </div>
    )

} 

export default function GuestHomePage () {

    const { t, i18n } = useTranslation()

    const dispatch = useAppDispatch()
    const history = useHistory()
    const { code } = useParams<{ code: string }>()

    const { bookingDetails, loading } = useSelector((state: RootState) => state.guestArea)

    const [addGuest, setAddGuest] = useState<boolean>(false)

    const [currentPolicy, setCurrentPolicy] = useState<Policy | undefined>()
    const [rcPolicy, setRcPolicy] = useState<Policy | undefined>()
    const [rainPolicy, setRainPolicy] = useState<Policy | undefined>()
    const [sanitaryPolicy, setSanitaryPolicy] = useState<Policy | undefined>()
    const [cancellationPolicy, setCancellationPolicy] = useState<Policy | undefined>()

    const [editGuest, setEditGuest] = useState<BookingGuest>()

    function getMainGuest () {
        if (bookingDetails && bookingDetails.booking) {
            const mainGuest = bookingDetails.booking.guests[0]
            return mainGuest.guestFirstName + ' ' + mainGuest.guestLastName
        }
    }

    function openWidget () {
        const w = window as any
        w.FreshworksWidget('open')
    }

    useEffect(() => {
        if (bookingDetails) {
            setSanitaryPolicy(bookingDetails.policies.find(p => p.policy.policyIsSanitary)?.policy)
            console.log(bookingDetails)
            setCancellationPolicy(bookingDetails.policies.find(p => !p.policy.policyIsSanitary && !p.policy.policyIsRc && !p.policy.policyIsRain)?.policy)
            setRcPolicy(bookingDetails.policies.find(p => p.policy.policyIsRc)?.policy)
            setRainPolicy(bookingDetails.policies.find(p => p.policy.policyIsRain)?.policy)
        }
    }, [ bookingDetails ])

    async function handleDeleteGuest (idBookingGuest?: number) {
        if (!idBookingGuest) return
        if (bookingDetails?.booking.guests.length === 1) {
            alert(t('vary.canNotDeleteGuest'))
            return
        }

        const deletingGuest = bookingDetails?.booking.guests.find(g => g.idBookingGuest === idBookingGuest)
        if (deletingGuest?.isBooker) {
            alert(t('Non puoi cancellare il proprietario della prenotazione'))
            return
        }
        
        const result = await dispatch(deleteBookingGuest(idBookingGuest))
        if (deleteBookingGuest.fulfilled.match(result)) {
            toast.success('✅')
        } else {
            toast.error('Impossibile creare l\'ospite')
        }
    }

    return (
        <div className="max-w-3xl px-4 md:px-0 mx-auto">

            <PolicyDialog
                policy={currentPolicy}
                onClose={() => setCurrentPolicy(undefined)}
            />

            <section className="my-10 flex flex-col space-y-2">
                <div className="text-center text-2xl font-bold text-gray-700">
                    { t('guestPage.welcome', { name: getMainGuest() }) }
                </div>
                <div className="text-lg text-center text-gray-600">
                    {t('guestPage.subtitle')}
                </div>
            </section>

            <section className="bg-white rounded-lg my-10">
                <div className="p-6 md:p-8">
                    <div className="text-lg text-gray-700 font-bold">
                        {t('vary.yourTripOf')} {dayjs(bookingDetails?.booking.bookingArrivalDate.toString(), 'YYYYMMDD').format('DD MMMM YYYY')}
                    </div>
                    <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-4 mt-4 text-gray-600">
                        <div>
                            <div className="font-medium mb-1">{t('vary.stayDates')}</div>
                            <div>
                                {t('vary.from')} {dayjs(bookingDetails?.booking.bookingArrivalDate.toString(), 'YYYYMMDD').format('DD MMMM YYYY')} {t('vary.to')} {dayjs(bookingDetails?.booking.bookingDepartureDate.toString(), 'YYYYMMDD').format('DD MMMM YYYY')}
                            </div>
                        </div>
                        <div className="border-b md:border-r md:h-12"></div>
                        <div>
                            <div className="font-medium mb-1">{t('vary.protectedTravellers')}</div>
                            <div>
                                {bookingDetails?.booking.guests.length}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (cancellationPolicy || sanitaryPolicy || rcPolicy) &&
                    <div className="border-t px-6 py-4 justify-center flex flex-col md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-6">
                        {
                            (bookingDetails?.policies.find(p => p.policy.idPolicy === 13)) &&
                            <button
                                onClick={() => history.push(`/guest-area/${code}/open-claim`)}
                                className="text-lg hover:bg-orange-100 hover:text-orange-600 px-2 py-1 rounded-md text-gray-600 font-medium flex space-x-2 items-center">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M4.93 1.31a41.401 41.401 0 0110.14 0C16.194 1.45 17 2.414 17 3.517V18.25a.75.75 0 01-1.075.676l-2.8-1.344-2.8 1.344a.75.75 0 01-.65 0l-2.8-1.344-2.8 1.344A.75.75 0 013 18.25V3.517c0-1.103.806-2.068 1.93-2.207zm4.822 4.997a.75.75 0 10-1.004-1.114l-2.5 2.25a.75.75 0 000 1.114l2.5 2.25a.75.75 0 001.004-1.114L8.704 8.75h1.921a1.875 1.875 0 010 3.75.75.75 0 000 1.5 3.375 3.375 0 100-6.75h-1.92l1.047-.943z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div>
                                    {t('vary.askRefund')}
                                </div>
                            </button>
                        }
                        {
                            sanitaryPolicy &&
                            <button
                                onClick={() => setCurrentPolicy(sanitaryPolicy)}
                                className="text-lg hover:bg-orange-100 hover:text-orange-600 px-2 py-1 rounded-md text-gray-600 font-medium flex space-x-2 items-center">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
                                    </svg>
                                </div>
                                <div>
                                    {t('vary.askSanHelp')}
                                </div>
                            </button>
                        }
                    </div>
                }
            </section>

            <section className="bg-white rounded-lg my-10">
                <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-12 p-6 md:p-8">
                    <div className="flex-1">
                        <div className="text-2xl font-bold text-gray-700 mb-4">
                            {t('vary.protectedYourLovedOne')}
                        </div>
                        <div className="text-gray-600 leading-relaxed">
                            {t('vary.toGuranteeProtection')}
                        </div>
                    </div>
                    <div className="flex-1">
                        <img src="/assets/guestportal/couple.svg" alt="Travel couple" />
                    </div>
                </div>

                <div className="border-t px-6 md:px-8 py-6">
                    <div className="text-lg text-gray-600 font-semibold">
                        {t('vary.listOfTravellers')}
                    </div>
                    <div className="text-gray-600 mt-1">
                        {t('vary.allGuestAreCovered')}
                    </div>

                    <div className="my-6 flex space-y-4 flex-col">
                        {
                            (bookingDetails) && bookingDetails.booking.guests.map((guest, index) => (
                                <div className={`flex space-x-4 text-lg text-gray-600 items-center rounded-md`} key={index}>
                                    <div className="uppercase bg-orange-100 rounded-full flex justify-center items-center font-medium text-orange-600 w-9 h-9">
                                        {guest.guestLastName.charAt(0)}
                                    </div>
                                    <div>
                                        {guest.guestFirstName} {guest.guestLastName}, {guest.country.countryName}
                                    </div>
                                    <div className="flex space-x-2 items-center">
                                        {
                                            (bookingDetails && !bookingDetails.policies.find(p => p.policy.dynamicGuests === false)) &&
                                            <>
                                                <button onClick={() => {
                                                    setEditGuest(guest)
                                                    setAddGuest(true)
                                                }} className="bg-orange-50 px-2 py-1 text-sm font-semibold rounded-md text-orange-600">
                                                    {loading ? '...' : t('Modifica')}
                                                </button>
                                                <button onClick={() => handleDeleteGuest(guest.idBookingGuest)} className="bg-orange-50 px-2 py-1 text-sm font-semibold rounded-md text-red-600">
                                                    {loading ? '...' : t('Elimina')}
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    {
                        addGuest &&
                        <AddGuestModal
                            editGuest={editGuest}
                            onSave={() => {
                                setAddGuest(false)
                                setEditGuest(undefined)
                            }}
                            onCancel={() => {
                                setAddGuest(false)
                                setEditGuest(undefined)
                            }}
                        />
                    }

                    {
                        (bookingDetails && !bookingDetails.policies.find(p => p.policy.dynamicGuests === false)) &&
                        <button
                            onClick={() => setAddGuest(true)}
                            className="px-4 py-2 rounded-md font-medium bg-orange-600 text-white flex space-x-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                            </svg>
                            <div>
                                {t('guestPage.addGuests')}
                            </div>
                        </button>
                    }
                </div>
            </section>

            <section className="my-10 flex flex-col space-y-2">
                <div className="text-center text-2xl font-bold text-gray-700">
                    {t('vary.listOfCoverage')}
                </div>
                <div className="text-lg text-center text-gray-600">
                    {t('vary.listOfActiveCoverage')}
                </div>
            </section>

            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-10">
                {
                    cancellationPolicy &&
                    <section className="bg-white rounded-lg px-8 py-6 flex-1">
                        <div className="flex items-center justify-center">
                            <img src="/assets/guestportal/refund.svg" className={'h-24 md:h-36'} alt="" />
                        </div>
                        <div className="text-lg text-center mt-4 font-medium text-gray-600">
                            {t('vary.cancellation')}
                        </div>
                        <button onClick={() => setCurrentPolicy(cancellationPolicy)} className="bg-orange-100 text-orange-700 font-medium w-full mt-4 rounded-md px-4 py-2">
                            {t('vary.viewDetails')}
                        </button>
                    </section>
                }

                {
                    sanitaryPolicy &&
                    <section className="bg-white rounded-lg px-8 py-6 flex-1">
                        <div className="flex items-center justify-center">
                            <img src="/assets/guestportal/health.svg" className={'h-24 md:h-36'} alt="" />
                        </div>
                        <div className="text-lg text-center mt-4 font-medium text-gray-600">
                            {t('vary.medHelp')}
                        </div>
                        <button onClick={() => setCurrentPolicy(sanitaryPolicy)} className="bg-orange-100 text-orange-700 font-medium w-full mt-4 rounded-md px-4 py-2">
                            {t('vary.viewDetails')}
                        </button>
                    </section>
                }

                {
                    rcPolicy &&
                    <section className="bg-white rounded-lg px-8 py-6 flex-1">
                        <div className="flex items-center justify-center">
                            <img src="/assets/guestportal/outdoor.svg" className={'h-36'} alt="" />
                        </div>
                        <div className="text-lg text-center mt-4 font-medium text-gray-600">
                            {t('vary.outdoor')}
                        </div>
                        <button onClick={() => setCurrentPolicy(rcPolicy)} className="bg-orange-100 text-orange-700 font-medium w-full mt-4 rounded-md px-4 py-2">
                            {t('vary.viewDetails')}
                        </button>
                    </section>
                }

                {
                    rainPolicy &&
                    <section className="bg-white rounded-lg px-8 py-6 flex-1">
                        <div className="flex items-center justify-center">
                            <img src="/assets/guestportal/rain.svg" className={'h-36'} alt="" />
                        </div>
                        <div className="text-lg text-center mt-4 font-medium text-gray-600">
                            {t('vary.rain')}
                        </div>
                        <button onClick={() => setCurrentPolicy(rainPolicy)} className="bg-orange-100 text-orange-700 font-medium w-full mt-4 rounded-md px-4 py-2">
                            {t('vary.viewDetails')}
                        </button>
                    </section>
                }
            </div>

            {
                (sanitaryPolicy || rcPolicy) &&
                <section className="bg-white rounded-lg my-10">
                    <div className="flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-12 p-6 md:p-8">
                        <div className="flex-1">
                            <div className="text-2xl font-bold text-gray-700 mb-4">
                                {t('vary.helpNumber')}
                            </div>
                            <div className="text-gray-600 leading-relaxed">
                                <div className="mb-4">
                                    {t('vary.youCanCall')}
                                </div>
                                <div className="flex flex-col space-y-2">
                                    {
                                        sanitaryPolicy &&
                                        <div className="flex space-x-2 items-center">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                    <path fillRule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <div>
                                                <div>{t('vary.medHelp')}</div>
                                                <a href={`tel:${sanitaryPolicy.supportNumber}`} className="text-orange-600 underline">{sanitaryPolicy.supportNumber}</a>
                                            </div>
                                        </div>
                                    }
                                    {
                                        rcPolicy &&
                                        <div className="flex space-x-2 items-center">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                    <path fillRule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <div>
                                                <div>{t('vary.civilLiab')} / {t('vary.outdoor')}</div>
                                                <a href={`tel:${rcPolicy?.supportNumber}`} className="text-orange-600 underline">{rcPolicy.supportNumber}</a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex-1">
                            <img src="/assets/guestportal/help.svg" alt="Travel couple" />
                        </div>
                    </div>
                </section>
            }

            <section className="bg-yellow-400 rounded-lg my-10">
                <div className="p-5 md:p-10">
                    <div className="flex justify-center mb-8">
                        <div className={"flex -space-x-6"}>
                            <div className={'border-4 border-yellow-400 relative bg-cover z-10 bg-center w-20 md:w-28 h-20 md:h-28 rounded-full'} style={{
                                backgroundImage: 'url(/assets/team/francesca.jpg)'
                            }} />
                            <div className={'border-4 border-yellow-400 relative bg-cover z-10 bg-center w-20 md:w-28 h-20 md:h-28 rounded-full'} style={{
                                backgroundImage: 'url(/assets/team/pasquale.png)'
                            }} />
                            <div className={'border-4 border-yellow-400 relative bg-cover z-10 bg-center w-20 md:w-28 h-20 md:h-28 rounded-full'} style={{
                                backgroundImage: 'url(/assets/team/valeria.png)'
                            }} />
                            <div className={'border-4 border-yellow-400 relative bg-cover z-10 bg-center w-20 md:w-28 h-20 md:h-28 rounded-full'} style={{
                                backgroundImage: 'url(/assets/team/marina.png)'
                            }} />
                        </div>
                    </div>

                    <div className="text-2xl font-bold text-center text-orange-800">
                        {t('vary.byYourSide')}
                    </div>

                    <div className="text-center md:text-lg mt-3 mb-8 text-orange-900">
                        {t('vary.doubts')}
                    </div>

                    <div className="text-center">
                        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6 justify-center">
                            <a href="tel:+390669429163" className="bg-yellow-100 px-4 py-2 rounded-md text-lg md:text-2xl text-center font-bold text-orange-700">
                                +39 06 6942 9163
                            </a>
                            <a href="mailto:help@besaferate.com" className="bg-yellow-100 px-4 py-2 rounded-md text-lg md:text-2xl text-center font-bold text-orange-700">
                                help@besaferate.com
                            </a>
                        </div>

                        <div className="mt-6 md:text-lg text-orange-800 flex space-x-2 items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 hidden md:block">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-13a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 000-1.5h-3.25V5z" clipRule="evenodd" />
                            </svg>
                            <div>
                                {t('vary.openTimes')}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}