import { useEffect, useState } from 'react'
import { checkJobStatus, getBookingPolicies, startEmailJob, startInsuranceEmission } from '../../shared/queries'
import { Booking, BookingPolicy, Policy } from '../../types'
import { getPolicyURL } from '../../shared/utils'
import { useTranslation } from 'react-i18next'

const timer = (ms: any) => new Promise(res => setTimeout(res, ms))

export default function BookingPolicyJob ({
  booking
} : {
  booking?: Booking
}) {

  const { t } = useTranslation()
  const [loadingFile, setLoadingFile] = useState(false)
  const [hasRealTimePolicies, setHasRealTimePolicies] = useState(false)
  const [emissionError, setEmissionError] = useState(false)
  const [emissionTooLong, setEmissionTooLong] = useState(false)
  const [externalReference, setExternalReference] = useState<string | undefined>()
  const [policyToEmit, setPolicyToEmit] = useState<BookingPolicy | undefined>()
  const [emailProcessedAt, setEmailProcessedAt] = useState<number | undefined>()

  useEffect(() => {
    console.log('emit')
    loadPolicies()
  }, [
    booking
  ])

  async function loadPolicies () {
    if (booking && booking.idBooking) {
      let policies = await getBookingPolicies(booking.idBooking)
      if (policies && policies.length > 0) {
        const filteredPolicies = policies.filter(p => p.policy.policyCompany === 'INTM')
        if (filteredPolicies.length === 1) {
          handleRealtimeEmission(filteredPolicies)
          setPolicyToEmit(filteredPolicies[0])
          setHasRealTimePolicies(true)
        } else {
          setHasRealTimePolicies(false)
        }
      } else {
        setHasRealTimePolicies(false)
      }
    }
  }

  async function handleRealtimeEmission (bookingPolicies: BookingPolicy[]) {
    if (!booking || !booking.idBooking) return
    for (const policy of bookingPolicies) {
      if (policy.processedAt || policy.hasErrors) {

        if (policy.externalReference) {
          setExternalReference(policy.externalReference)
          setEmissionError(false)
        } else if (policy.hasErrors) {
          setEmissionError(true)
          setEmissionTooLong(false)
          setExternalReference(undefined)
        }

        continue
      }

      // fire emission job
      const emissionResult = await startInsuranceEmission({
        idBooking: booking.idBooking,
        policyCode: policy.policy.policyCode,
        idPolicy: policy.policy.idPolicy
      })

      if (!emissionResult.success) {
        // job failed, notify the user
        setEmissionError(true)
        return
      }
      /*
      await startEmailJob({
        idBooking: booking.idBooking,
      })
      */

      // watch for job outcome
      let completed = false
      let watchCount = 0
      while (watchCount < 15) {
        const emissionStatus = await checkJobStatus({ jobId: emissionResult.jobId })
        if (emissionStatus.outcome === 'COMPLETED') {
          completed = true
          break
        }
        await timer(2500)
        watchCount++
      }

      if (completed === true) {
        const data = await getBookingPolicies(booking.idBooking)
        if (data) {
          const currentPolicy = data.find(p => p.idBookingPolicy === policy.idBookingPolicy)
          if (currentPolicy) {
            if (currentPolicy.hasErrors) {
              setEmissionError(true)
            }
            if (currentPolicy.externalReference) {
              setExternalReference(currentPolicy.externalReference)
            }
          }
        }
      } else {
        setEmissionTooLong(true)
      }
    }
  }

  async function handleDownloadDynamicFile (booking: Booking, policy: Policy) {
    try {
        setLoadingFile(true)
        let language = booking.guests.find(g => g.country.countryAlpha2 === 'IT') ? 'IT' : 'EN'
        await getPolicyURL(policy, booking.idBooking?.toString(), undefined, undefined, language)
    } catch (e) {
    } finally {
        setLoadingFile(false)
    }
}

  if (!hasRealTimePolicies) return <></>
  if (!booking) return <></>

  return (
    <div className='bg-orange-50 border border-orange-300 p-6 rounded-md mb-6'>
      <div className="text-orange-900 text-lg font-bold">
        {t('bookingPolicyMsg.emissionTitle')}
      </div>
      <div className='text-orange-900 mt-1'>
        {t('bookingPolicyMsg.waiting')}
      </div>

      {
        (externalReference === undefined) ?
        <div className="flex mt-4 items-center space-x-2">
          <div>
            <svg className="animate-spin -ml-1 mr-1 h-5 w-5 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <div>
            {t('bookingPolicyMsg.creatingFile')}
          </div>
        </div>
        :
        <div>
          <div className="mt-4 flex space-x-2 items-center text-orange-800 font-bold">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd" />
              </svg>
            </div>
            <div className='flex-1 text-orange-900'>
              {t('bookingPolicyMsg.successPolicyCreation')}
            </div>
          </div>
          <button
            onClick={() => {
              if (policyToEmit) {
                handleDownloadDynamicFile(booking, policyToEmit.policy)
              }
            }}
            className='hover:bg-orange-50 border border-orange-300 bg-white text-orange-700 font-bold rounded-md p-2 mt-4 flex space-x-2 items-center'>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
              </svg>
            </div>
            <div>
              {loadingFile ? t('bookingPolicyMsg.retrievingFile') : t('bookingPolicyMsg.DownloadFile')}
            </div>
          </button>
        </div>
      }

      {
        emissionTooLong &&
        <div className="mt-4 flex space-x-2 items-center text-orange-700 font-bold">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
              <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z" clipRule="evenodd" />
            </svg>
          </div>
          <div className='flex-1'>
            {t('bookingPolicyMsg.policyDelay')}
          </div>
        </div>
      }

      {
        (emissionError) &&
        <div className="mt-4 flex space-x-2 items-center text-orange-700 font-bold">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
              <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
            </svg>
          </div>
          <div className='flex-1'>

            {t('bookingPolicyMsg.policyRejection')}
            
          </div>
        </div>
      }
    </div>
  )

}