import { Fragment, useState } from "react"
import ReactMarkdown from "react-markdown"
import { useMutation, useQuery } from "react-query"
import { OnChangeValue } from "react-select"
import { getAccomodationById, getChannelList, saveAccomodationApiChannel } from "../../../shared/queries"
import { AccomodationApi, ApiChannel } from "../../../types"
import Modal from "../../common/Modal"
import ModalActions from "../../common/ModalActions"
import ModalCard from "../../common/ModalCard"
import FormField from "../../form/FormField"
import SelectInput from "../../form/SelectInput"
import TextInput from "../../form/TextInput"
import Link from "../../navigation/Link"
import { useTranslation } from "react-i18next"
import Editor from "@monaco-editor/react"
import DatePickerInput from "../../form/DatePickerInput"
import dayjs from "dayjs"
import Button from "../../form/Button"

export default function CreateApiChannel ({
    idAccomodation,
    visible,
    onClose,
    onSave
} : {
    idAccomodation: string | number,
    visible: boolean,
    onClose: () => void,
    onSave: (data: AccomodationApi) => void
}) {

    const { t } = useTranslation()

    const [invalidJson, setInvalidJson] = useState(false)
    const [connectionStartDate, setConnectionStartDate] = useState(undefined)
    const [apiChannel, setApiChannel] = useState<OnChangeValue<{ label: any, value: any, instruction: string, internal: boolean, config: string }, false> | undefined>()
    const [apiProps, setApiProps] = useState<{
        hotelUser: string,
        hotelPwd: string,
        hotelCode: string,
        services: string[],
        rates: string[],
        override?: string,
        filters?: string,
        connectionStartDate?: string
    }>({
        hotelUser: '',
        hotelPwd: '',
        hotelCode: '',
        services: [],
        rates: [],
        override: undefined,
        filters: undefined,
        connectionStartDate: undefined
    })
    
    const {
        data: channelList,
        isFetching: isFetchingChannelList
    } = useQuery('channelList', getChannelList)

    const {
        data: accomodation,
        isFetching: isFetchingAccomodation
    } = useQuery(['accomodation', idAccomodation], () => getAccomodationById(idAccomodation))

    const saveMutation = useMutation((data: AccomodationApi) => saveAccomodationApiChannel(data), {
        onSuccess: data => {
            onSave(data)
        }
    })

    function handleSave () {
        if (accomodation && apiChannel && channelList) {
            const channelToConnect = channelList.find(c => c.idApiChannel === apiChannel.value)
            
            if (channelToConnect) {
                saveMutation.mutate({
                    accomodation,
                    apiChannel: channelToConnect,
                    apiInternalId: '',
                    apiPassword: '',
                    apiUsername: '',
                    apiRatePlanIds: '',
                    apiProps: JSON.stringify({
                        ...apiProps,
                        override: apiProps.override ? JSON.stringify(JSON.parse(apiProps.override)) : undefined,
                        filters: apiProps.filters ? JSON.stringify(JSON.parse(apiProps.filters)) : undefined,
                    })
                })
            }
        }
    }

    function getChannelConfig () {
        if (apiChannel) {
            return JSON.parse(apiChannel.config)
        }
        return undefined
    }

    function isConfigFieldRequired (field: string) : boolean {
        if (apiChannel) {
            const config = getChannelConfig()
            if (config && config.requiredFields.includes(field)) {
                return true
            }
        }
        return false
    }

    return (
        <Fragment>
            <Modal
                visible={visible}>
                <ModalCard
                    className={'w-full'}
                    actionsLoading={saveMutation.isLoading}
                    renderActions={() => (
                        <ModalActions
                            onClose={onClose}
                            onSave={() => {
                                handleSave()
                            }}></ModalActions>
                    )}
                    title={'Collegamento canale'}>

                    <div className="w-full p-4 flex flex-col space-y-4">
                        <FormField label={'Seleziona il canale'}>
                            <SelectInput
                                onChange={(item: any) => {
                                    if (item) setApiChannel(item)
                                }}
                                options={channelList ? channelList.map(c => {
                                    return {
                                        label: c.apiChannelName,
                                        value: c.idApiChannel,
                                        internal: c.internal,
                                        instruction: c.apiChannelInstructions,
                                        config: c.apiChannelConfig
                                    }
                                }) : []} />
                        </FormField>

                        <div className="flex space-x-6 items-center pt-4">
                            <div>
                                <div className="text-lg text-gray-600 font-medium">
                                    Istruzioni canale
                                </div>
                            </div>
                            <div className="flex-1 border-b"></div>
                        </div>
                        <div className="text-normal text-gray-600 mark-editor">
                            <ReactMarkdown>
                                {apiChannel && apiChannel.instruction || ''}
                            </ReactMarkdown>
                        </div>

                        {
                            (apiChannel && !apiChannel.internal) &&
                            <Fragment>
                                <div className="flex space-x-6 items-center pt-4 pb-4">
                                    <div>
                                        <div className="text-lg text-gray-600 font-medium">
                                            Configurazione obbligatoria del canale
                                        </div>
                                    </div>
                                    <div className="flex-1 border-b"></div>
                                </div>

                                <FormField label={'Live date tariffa'}>
                                    <DatePickerInput
                                        mode={'single'}
                                        value={connectionStartDate}
                                        onChange={(val) => {
                                            if (val) {
                                                setConnectionStartDate(val)
                                                setApiProps({
                                                    ...apiProps,
                                                    connectionStartDate: dayjs(val).format('YYYYMMDD')
                                                })
                                            }
                                        }} />
                                </FormField>

                                {
                                    (isConfigFieldRequired('hotelUser')) &&
                                    <FormField label={'Username'}>
                                        <TextInput
                                            value={apiProps.hotelUser}
                                            onChange={val => {
                                                setApiProps({
                                                    ...apiProps,
                                                    hotelUser: val
                                                })
                                            }} />
                                    </FormField>
                                }

                                {
                                    (isConfigFieldRequired('hotelPwd')) &&
                                    <FormField label={'Password'}>
                                        <TextInput
                                            value={apiProps.hotelPwd}
                                            onChange={val => {
                                                setApiProps({
                                                    ...apiProps,
                                                    hotelPwd: val
                                                })
                                            }} />
                                    </FormField>
                                }

                                {
                                    (isConfigFieldRequired('hotelCode')) &&
                                    <FormField label={t('vary.hotelId')}>
                                        <TextInput
                                            value={apiProps.hotelCode}
                                            onChange={val => {
                                                setApiProps({
                                                    ...apiProps,
                                                    hotelCode: val
                                                })
                                            }} />
                                    </FormField>
                                }

                                {
                                    (isConfigFieldRequired('rates')) &&
                                    <FormField label={'Lista Tariffe'}>
                                        <div className="flex flex-col space-y-2">
                                            {
                                                apiProps.rates.map((rate, index) => (
                                                    <TextInput
                                                        key={index}
                                                        value={rate}
                                                        onChange={val => {
                                                            setApiProps({
                                                                ...apiProps,
                                                                rates: apiProps.rates.map((rate, current) => {
                                                                    if (current === index) return val
                                                                    return rate
                                                                })
                                                            })
                                                        }}></TextInput>
                                                ))
                                            }
                                        </div>
                                        <Link className={'block mt-2'} onClick={() => {
                                            setApiProps({
                                                ...apiProps,
                                                rates: [
                                                    ...apiProps.rates,
                                                    ''
                                                ]
                                            })
                                        }}>
                                            Aggiungi tariffa
                                        </Link>
                                    </FormField>
                                }

                                {
                                    (isConfigFieldRequired('rates')) &&
                                    <FormField label={'Lista Servizi'} tooltip={'ID dei servizi da considerare nel totale prenotazione'}>
                                        <div className="flex flex-col space-y-2">
                                            {
                                                apiProps.services.map((service, index) => (
                                                    <TextInput
                                                        key={index}
                                                        value={service}
                                                        onChange={val => {
                                                            setApiProps({
                                                                ...apiProps,
                                                                services: apiProps.services.map((service, current) => {
                                                                    if (current === index) return val
                                                                    return service
                                                                })
                                                            })
                                                        }} />
                                                ))
                                            }
                                        </div>
                                        <Link className={'block mt-2'} onClick={() => {
                                            setApiProps({
                                                ...apiProps,
                                                services: [
                                                    ...apiProps.services,
                                                    ''
                                                ]
                                            })
                                        }}>
                                            {t('quote.addService')}
                                        </Link>
                                    </FormField>
                                }

                                <FormField label="Connection override (from great power comes great responsibility)" className="border p-2">
                                    {
                                        invalidJson &&
                                        <div className="text-red-600 font-medium">
                                            La confiurazione inserita non ha una sintassi valida, correggi per continuare.
                                        </div>
                                    }
                                    <Editor
                                        height="300px"
                                        width={'100%'}
                                        defaultLanguage="json"
                                        onChange={(value, event) => {
                                            if (value) {
                                                try {
                                                    JSON.parse(value)
                                                    setInvalidJson(false)

                                                    setApiProps({
                                                        ...apiProps,
                                                        override: value
                                                    })
                                                } catch (e) {
                                                    setInvalidJson(true)
                                                }
                                            }
                                        }}
                                        value={apiProps.override}
                                    />
                                </FormField>

                                <FormField label="Connection filters (from great power comes great responsibility)" className="border p-2">
                                    {
                                        invalidJson &&
                                        <div className="text-red-600 font-medium">
                                            La confiurazione inserita non ha una sintassi valida, correggi per continuare.
                                        </div>
                                    }
                                    <Editor
                                        height="300px"
                                        width={'100%'}
                                        defaultLanguage="json"
                                        onChange={(value, event) => {
                                            if (value) {
                                                try {
                                                    JSON.parse(value)
                                                    setInvalidJson(false)

                                                    setApiProps({
                                                        ...apiProps,
                                                        filters: value
                                                    })
                                                } catch (e) {
                                                    setInvalidJson(true)
                                                }
                                            }
                                        }}
                                        value={apiProps.filters}
                                    />
                                </FormField>

                            </Fragment>
                        }
                    </div>
                </ModalCard>
            </Modal>
        </Fragment>
    )

}