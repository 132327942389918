export default function UserAvatar ({
    width = 'w-8',
    bg = 'bg-orange-500',
    height = 'h-8',
    text = 'text-base',
    name
} : {
    bg?: string,
    text?: string,
    name: string,
    width?: string,
    height?: string
}) {

    return (
        <div
            className={`${text} ${width} ${height} no-underline uppercase flex items-center justify-center font-bold text-white rounded-full ${bg}`}>
            {name.charAt(0)}
        </div>
    )

}