import { useState } from "react";
import { useTranslation } from "react-i18next";
import FormField from "../form/FormField";
import TextInput from "../form/TextInput";
import Modal from "./Modal";
import ModalActions from "./ModalActions";
import ModalCard from "./ModalCard";

export default function DeleteModal ({
    message = '',
    children,
    visible = false,
    askConfirmation = false,
    confirmationKey = 'confermo',
    title = 'Elimina elemento',
    isLoading = false,
    saveLabel = 'Elimina',
    closeLabel = 'Annulla',
    onConfirm,
    onCancel
} : {
    title?: string,
    children?: any,
    message: string
    visible: boolean,
    isLoading?: boolean,
    askConfirmation?: boolean,
    confirmationKey?: string,
    saveLabel?: string,
    closeLabel?: string,
    onConfirm: () => void,
    onCancel: () => void
}) {

    const { t } = useTranslation()

    const [confirmValue, setConfirmValue] = useState<string>('')

    function handleConfirmation () {
        if (askConfirmation) {
            if (confirmValue.toLowerCase() === 'confermo' ||  confirmValue.toLowerCase() === 'confirm' || confirmValue.toLowerCase() === 'i confirm' || confirmValue.toLowerCase() === 'confirmo' || confirmValue.toLowerCase() === 'korrekt' || confirmValue.toLowerCase() === 'je confirme' || confirmValue.toLowerCase() === 'επιβεβαιώνω') {
                onConfirm()
                setConfirmValue('')
            }
        } else {
            onConfirm()
            setConfirmValue('')
        }
    }

    return (
        <Modal
            visible={visible}>
            <ModalCard
                actionsLoading={isLoading}
                renderActions={() => (
                    <ModalActions
                        saveLabel={saveLabel || t('Elimina')}
                        closeLabel={closeLabel || t('Annulla')}
                        isLoading={isLoading}
                        onClose={() => {
                            onCancel()
                            setConfirmValue('')
                        }}
                        onSave={handleConfirmation}></ModalActions>
                )}
                title={title}>

                <div className="p-4 text-gray-700 font-normal">
                    {message}
                </div>

                {
                    children &&
                      <div>
                          {children}
                      </div>
                }

                {
                    askConfirmation &&
                    <div className="text-gray-700 p-4 pt-0 border-gray-300">
                        {t('deleteDialog.write')} <span className={'font-bold'}>{t('deleteDialog.confirm')}</span> {t('deleteDialog.toConfirm')}

                        <div className="mt-2">
                            <TextInput
                                value={confirmValue}
                                onChange={val => setConfirmValue(val)} />
                        </div>
                    </div>
                }

            </ModalCard>
        </Modal>
    )

}
