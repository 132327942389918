import AsyncSelect from 'react-select/async';
import { placeDetails, serachAddress } from '../shared/queries';
import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Address } from '../types';
import { useAppSelector } from '../hooks/redux';


export default function AddressSearch ({
  onAddressSelected
} : {
  onAddressSelected: (details: Address, inputValue: any) => void
}) {

  const { t } = useTranslation()
  const { countries, loading: loadingCountries } = useAppSelector(state => state.session)

  const [invalidAddress, setInvalidAddress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState<any>()

  const _loadOptions = async (
    inputValue: string,
    callback: (options: any[]) => void
  ) => {
    try {
      setLoading(true)
      const serachAddresses = await serachAddress(inputValue)
      callback(serachAddresses.map((item: any) => {
        return {
          value: item.place_id,
          label: item.description
        }
      }))
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }
  
  const loadOptions = debounce(_loadOptions, 300);

  const onChange = (value: any) => {
    setInputValue(value)
    console.log(value)
  }

  function getComponentFromAddress (details: any, type: string, target: 'long_name' | 'short_name') {
    try {
      return details.address_components.find((ac: any) => ac.types.includes(type))[target].toString()
    } catch (e) {
      return null
    }
  }

  async function loadPlaceDetails (value: string) {
    try {
      setLoading(true)
      if (value && value !== '') {
        const details = await placeDetails(value)

        console.log(details)

        const parsedAddress = {
          addressCity: getComponentFromAddress(details, 'city', 'long_name') || getComponentFromAddress(details, 'locality', 'long_name') || getComponentFromAddress(details, 'administrative_area_level_2', 'long_name'),
          addressState: getComponentFromAddress(details, 'administrative_area_level_1', 'short_name'),
          addressZipCode: getComponentFromAddress(details, 'postal_code', 'short_name'),
          addressProvince: getComponentFromAddress(details, 'administrative_area_level_2', 'short_name'),
          addressStreet: getComponentFromAddress(details, 'route', 'long_name') || getComponentFromAddress(details, 'locality', 'long_name') || getComponentFromAddress(details, 'administrative_area_level_2', 'long_name'),
          idCountry: countries.find(c => c.countryAlpha2 === getComponentFromAddress(details, 'country', 'short_name'))?.idCountry || 0,
          addressLatitude: details.geometry.location.lat,
          addressLongitude: details.geometry.location.lng,
        }

        console.log(parsedAddress)

        if (
          parsedAddress.addressStreet == null ||
          parsedAddress.idCountry == null ||
          parsedAddress.addressCity == null
        ) {
          setInvalidAddress(true)
        } else {
          setInvalidAddress(false)
        }

        onAddressSelected(parsedAddress, inputValue)
      }
    } catch (e) {} finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (inputValue) {
      loadPlaceDetails(inputValue.value)
    }
  }, [ inputValue ])

  return (
    <>
      <AsyncSelect
        placeholder={t('vary.searchForAddress')}
        value={inputValue}
        isMulti={false}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
          container: (base) => {
            return {
              ...base,
              border: 0
            }
          },
          control: (base) => {
            return {
              ...base,
              border: 0,
              height: 30,
              minHeight: 35,
              maxHeight: 30,
              boxShadow: '0px 2px 3px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.2)'
            }
          }
        }}
        menuShouldScrollIntoView={false}
        menuPortalTarget={document.body}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null
        }}
        isLoading={(loading || loadingCountries.countries)}
        onChange={onChange}
        loadOptions={loadOptions as any}
      />
      {
        invalidAddress && <div className="text-red-600 mt-1 font-medium">{t('vary.incompleteAddress')}</div>
      }
    </>
  )

}