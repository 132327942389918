import { combineReducers, configureStore } from "@reduxjs/toolkit";
import guestAreaSlice from "./slices/guestAreaSlice";
import hotelsSlice from "./slices/hotelsSlice";
import sessionSlice from "./slices/sessionSlice";
import bookingSlice from "./slices/bookingSlice";
import offerSlice from "./slices/offerSlice";
import accommodationSettingsSlice from "./slices/accommodationSettingsSlice";

const rootReducer = combineReducers({
    hotels: hotelsSlice,
    session: sessionSlice,
    guestArea: guestAreaSlice,
    bookings: bookingSlice,
    offerSlice: offerSlice,
    accommodationSettingsSlice: accommodationSettingsSlice
})

const store = configureStore({
    reducer: rootReducer
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
