import {useTranslation} from "react-i18next";

export default function SectionCard ({
  title,
  children,
  className,
  onEdit,
  editLabel
} : {
  className?: string,
  onEdit?: () => void,
  editLabel?: string,
  title: string,
  children: any
}) {

  const { t } = useTranslation()

  return (
    <div className={'rounded overflow-hidden card-shadow bg-white ' + className}>
      <div className="bg-gray-50 text-gray-700 px-4 py-3 border-b font-medium flex items-center justify-between">
        <div>
          {title}
        </div>
        <div>
          {
            onEdit &&
              <button
                  onClick={() => {
                    if (onEdit) onEdit()
                  }}
                  className={'bg-white btn-shadow text-sm text-orange-600 focus:outline-none focus:ring-2 ring-cyan-600 font-medium hover:bg-cyan-100 md:px-2 py-1 rounded text-cyan-600'}>
                {editLabel || t('general.edit')}
              </button>
          }
        </div>
      </div>
      <div className={'bg-white p-4'}>
        {children}
      </div>
    </div>
  )

}
