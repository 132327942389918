import { useHistory } from "react-router-dom"
import LanguageSelector from "../../components/common/LanguageSelector"
import { useTranslation } from "react-i18next"
import { useRef, useState } from "react"
import { RootState } from "../../store"
import { useDispatch, useSelector } from "react-redux"
import { fetchBookingWithPin, setAccessPin } from "../../store/slices/guestAreaSlice"
import toast from "react-hot-toast"
import Checkbox from "../../components/form/Checkbox"
import Button from "../../components/form/Button"

export default function GuestLogin ({
  code
} : {
  code: string
}) {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const [privacy, setPrivacy] = useState(false)

  const { accessPin, loading } = useSelector((state: RootState) => state.guestArea)

  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const ref4 = useRef<any>()
  const ref5 = useRef<any>()
  const ref6 = useRef<any>()

  function handlePaste (text: string) {
    console.log('pasting', text)
    if (text.length === 6) {
        dispatch(setAccessPin(text.split('')))
    }
  }

  async function unlockRes () {
    const result = await dispatch(fetchBookingWithPin({
      code,
      marketing: privacy
    })) as any
    console.log(result)
    if (!fetchBookingWithPin.fulfilled.match(result)) {
      toast.error(t('guestPage.invalidPin'))
    }
  }

  return (
    <>
      <div className={'px-4 md:px-0 bg-gray-100 flex flex-col h-screen overflow-auto'}>
        <div className="w-full max-w-lg mx-auto">
            <div className="flex items-center justify-between my-10">
                <div>
                    <img
                        className={'h-10 cursor-pointer'}
                        onClick={() => history.push(`/guest-area/${code}`)}
                        src="https://besafesuite.com/besaferate/wp-content/themes/besafe/assets/images/berate_logo.svg"
                        alt="BeSafe Rate" />
                </div>

                <div>
                    <LanguageSelector visibleLanguages={['en', 'it', 'fr', 'de']} />
                </div>
            </div>

            <section className='bg-white rounded-md shadow-xl'>
                <div className="px-10 py-10">
                    <div className="text-2xl text-gray-600 font-bold text-center">
                        {t('guestPage.guestArea')}
                    </div>
                    <div className="text-center mt-2 text-gray-600">
                        {t('guestPage.guestAreaText')}
                    </div>
                </div>

                <div className="border-t px-10 py-6">
                    <div className="text-center mb-4 text-lg font-medium text-gray-500">
                        {t('vary.insertPin')}
                    </div>
                    <div className="flex justify-center space-x-2">
                        <div>
                            <input
                                ref={ref1}
                                onFocus={() => {
                                    ref1.current.select()
                                }}
                                onPaste={event => {
                                    handlePaste(event.clipboardData.getData('text'))
                                }}
                                value={accessPin[0]}
                                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (event.target.value && event.target.value.length > 1) {
                                        return
                                    }
                                    const value = [...accessPin]
                                    value[0] = event.target.value[event.target.value.length - 1]
                                    dispatch(setAccessPin(value))
                                    if (ref2 && ref2.current) {
                                        ref2.current.select()
                                    }
                                }}
                                className={'w-10 h-10 md:w-12 md:h-12 border rounded-md px-2 py-2 lg:px-4 lg:py-4 font-medium text-gray-600 focus:outline-none focus:border-2 focus:border-orange-400 focus:ring-4 ring-orange-200 text-xl text-center'}
                                placeholder={'•'}
                                type="text" />
                        </div>
                        <div>
                            <input
                                ref={ref2}
                                onFocus={() => {
                                    ref2.current.select()
                                }}
                                value={accessPin[1]}
                                onChange={event => {
                                    const value = [...accessPin]
                                    value[1] = event.target.value[event.target.value.length - 1]
                                    dispatch(setAccessPin(value))
                                    if (ref3 && ref3.current) {
                                        ref3.current.select()
                                    }
                                }}
                                className={'w-10 h-10 md:w-12 md:h-12 border rounded-md px-2 py-2 lg:px-4 lg:py-4 font-medium text-gray-600 focus:outline-none focus:border-2 focus:border-orange-400 focus:ring-4 ring-orange-200 text-xl text-center'}
                                placeholder={'•'}
                                type="text" />
                        </div>
                        <div>
                            <input
                                ref={ref3}
                                onFocus={() => {
                                    ref3.current.select()
                                }}
                                value={accessPin[2]}
                                onChange={event => {
                                    const value = [...accessPin]
                                    value[2] = event.target.value[event.target.value.length - 1]
                                    dispatch(setAccessPin(value))
                                    if (ref4 && ref4.current) {
                                        ref4.current.select()
                                    }
                                }}
                                className={'w-10 h-10 md:w-12 md:h-12 border rounded-md px-2 py-2 lg:px-4 lg:py-4 font-medium text-gray-600 focus:outline-none focus:border-2 focus:border-orange-400 focus:ring-4 ring-orange-200 text-xl text-center'}
                                placeholder={'•'}
                                type="text" />
                        </div>
                        <div>
                            <input
                                ref={ref4}
                                onFocus={() => {
                                    ref4.current.select()
                                }}
                                value={accessPin[3]}
                                onChange={event => {
                                    const value = [...accessPin]
                                    value[3] = event.target.value[event.target.value.length - 1]
                                    dispatch(setAccessPin(value))
                                    if (ref5 && ref5.current) {
                                        ref5.current.select()
                                    }
                                }}
                                className={'w-10 h-10 md:w-12 md:h-12 border rounded-md px-2 py-2 lg:px-4 lg:py-4 font-medium text-gray-600 focus:outline-none focus:border-2 focus:border-orange-400 focus:ring-4 ring-orange-200 text-xl text-center'}
                                placeholder={'•'}
                                type="text" />
                        </div>
                        <div>
                            <input
                                ref={ref5}
                                onFocus={() => {
                                    ref5.current.select()
                                }}
                                value={accessPin[4]}
                                onChange={event => {
                                    const value = [...accessPin]
                                    value[4] = event.target.value[event.target.value.length - 1]
                                    dispatch(setAccessPin(value))
                                    if (ref6 && ref6.current) {
                                        ref6.current.select()
                                    }
                                }}
                                className={'w-10 h-10 md:w-12 md:h-12 border rounded-md px-2 py-2 lg:px-4 lg:py-4 font-medium text-gray-600 focus:outline-none focus:border-2 focus:border-orange-400 focus:ring-4 ring-orange-200 text-xl text-center'}
                                placeholder={'•'}
                                type="text" />
                        </div>
                        <div>
                            <input
                                ref={ref6}
                                onFocus={() => {
                                    ref6.current.select()
                                }}
                                value={accessPin[5]}
                                onChange={event => {
                                    const value = [...accessPin]
                                    value[5] = event.target.value[event.target.value.length - 1]
                                    dispatch(setAccessPin(value))
                                }}
                                className={'w-10 h-10 md:w-12 md:h-12 border rounded-md px-2 py-2 lg:px-4 lg:py-4 font-medium text-gray-600 focus:outline-none focus:border-2 focus:border-orange-400 focus:ring-4 ring-orange-200 text-xl text-center'}
                                placeholder={'•'}
                                type="text" />
                        </div>
                    </div>

                    <div className='mt-3 text-center'>
                        <button onClick={() => {}} className='text-orange-600 text-center'>
                            {t('vary.resendPin')}
                        </button>
                    </div>
                </div>

                <div className="border-t px-10 pt-6">
                    <Checkbox
                        checked={privacy}
                        onChange={() => setPrivacy(!privacy)}
                        renderLabel={() => (
                        <div>
                            {t('guestPage.acceptPrivacy')} <a
                            onClick={e => {
                                e.preventDefault()
                                window.open('/assets/privacy_policy_en.pdf', '_blank')
                            }}
                            href="#" className={'text-orange-600'}>privacy policy</a>.
                        </div>
                        )}
                    />
                </div>

                <div className='px-4 pb-4'>
                    <Button
                        loading={loading}
                        className={`rounded-lg text-lg font-semibold block w-full py-2 mt-5 text-white bg-orange-600`}
                        onClick={() => {
                            if (accessPin && accessPin.length === 6) {
                                unlockRes()
                            }
                        }}
                        label={t('guestPage.access')}
                    />
                </div>

            </section>

        </div>

        <footer className='max-w-lg mt-8 w-full mx-auto mb-10 flex flex-col space-y-4'>
            <div className="text-gray-600">
                <div className="font-medium">
                    © 2023 Be Safe Group SRL
                </div>
                Via giunio bazzoni, 1 - 00195 Roma. P.Iva: 14562641002
            </div>
            <div className='border-gray-300 border-t '></div>
            <div className="text-gray-600">
                {t('vary.ivass')}
            </div>
        </footer>
    </div>
    </>
    )

}