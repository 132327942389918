import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {request} from "../../shared/api";
import AwsS3 from "@uppy/aws-s3";
import {Uppy, UppyFile} from "@uppy/core";
import {DragDrop, FileInput} from "@uppy/react";
import {useSelector} from "react-redux";
import {RootState} from "../../store";

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'

export default function Uploader ({ folderKey, fileType, type = 'dashboard', fileKey, docRef, onUpload } : {
	folderKey: string,
	fileType: string,
	type: 'dashboard' | 'input',
	fileKey: string,
	docRef?: any,
	onUpload: any
}) {

	const currentHotel = useSelector((state: RootState) => state.hotels.currentHotel)

	const [uppy, setUppy] = useState<any>()

	function handleUpload (file: any, data: any) {
		onUpload({
			fileKey,
			docRef,
			file,
			data
		})
	}

	useEffect(() => {
		const uppy = new Uppy({
			autoProceed: true
		})

		uppy.use(AwsS3, {
			async getUploadParameters (file: UppyFile) {
				try {
					const response = await request({
						method: 'POST',
						path: `/API/files/upload/${currentHotel}/${fileType}`,
						body: {
							folderKey: folderKey,
							fileKey: file.name
						}
					})
					console.log({
						method: 'PUT',
						url: response.success
					})
					return {
						method: 'PUT',
						url: response.success
					}
				} catch (e) {
					toast.error('Impossibile caricare il fle')
					return {
						method: 'PUT',
						url: null
					}
				}
			}
		})

		uppy.on('upload-success', (file: any, data: any) => {
			toast.success('File caricato con successo')
			handleUpload(file, data)
		})

		setUppy(uppy)

		return () => {
			uppy.off('upload-success', handleUpload)
		}
	}, [])

	return (
		uppy ?
			type === 'dashboard' ?
				<>
					<DragDrop
						locale={{
							strings: {
								dropHereOr: 'Trascina qui i tuoi files o %{browse}',
								browse: 'cerca sul dispositivo'
							}
						}}
						allowMultipleFiles={true}
						height={'300px'}
						uppy={uppy}
					/>
				</>
				:
				<FileInput
					uppy={uppy}
				/>
			:
			<div>...</div>
	)

}
