import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

export default function Faq () {

  const { t } = useTranslation()

  const faqs = [
    { question: t('sepa.faq1'), answer: t('sepa.faq1Text') },
    { question: t('sepa.faq2'), answer: t('sepa.faq2Text') },
    { question: t('sepa.faq3'), answer: t('sepa.faq3Text') },
    { question: t('sepa.faq4'), answer: t('sepa.faq4Text') },
    { question: t('sepa.faq5'), answer: t('sepa.faq5Text') }
  ]

  return (
    <div className='my-6'>
      <h2 className="text-xl font-bold leading-10 tracking-tight text-slate-800">Domande frequenti</h2>
      <dl className="mt-2 space-y-6 divide-y divide-slate-200">
        {faqs.map((faq) => (
          <Disclosure as="div" key={faq.question} className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <DisclosureButton className="flex w-full items-start justify-between text-left text-slate-700">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      {open ? (
                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                      ) : (
                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                      )}
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base leading-7 text-slate-600">{faq.answer}</p>
                </DisclosurePanel>
              </>
            )}
          </Disclosure>
        ))}
      </dl>
    </div>
  )

}