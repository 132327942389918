import { motion } from "framer-motion"
import {useEffect, useState} from "react"
import { usePopper } from "react-popper"

type PopoverProps = {
  children: any,
  managedVisibility?: boolean,
  renderContent: Function,
  className?: string,
  onHide?: () => void,
  onShow?: () => void,
  handleMobile?: boolean
}

export default function Popover ({
  handleMobile = false,
  children,
  className,
  renderContent,
  onHide,
  onShow,
  managedVisibility
} : PopoverProps) {

    const [width, setWidth] = useState<number>(window.innerWidth)
    const [height, setHeight] = useState<number>(window.innerHeight)
    const [visible, setVisible] = useState<boolean>(false)
    const [referenceElement, setReferenceElement] = useState<any>(null)
    const [popperElement, setPopperElement] = useState<any>(null)
    const [arrowElement, setArrowElement] = useState<any>(null)

    const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-start',
      strategy: 'fixed',
      modifiers: [{ name: 'arrow', options: { element: arrowElement } }]
    })

    function handleWindowResize () {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }

    useEffect(() => {
      window.addEventListener('resize', handleWindowResize)
      return () => {
        window.removeEventListener('resize', handleWindowResize)
      }
    }, [])

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (popperElement && !popperElement.contains(event.target)) {
                if (!managedVisibility) setVisible(false)
                if (onHide) onHide()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ popperElement ]);

    useEffect(() => {
        if (typeof managedVisibility === "boolean" && managedVisibility !== undefined) {
            setVisible(managedVisibility)
        }
    }, [
        managedVisibility
    ])

  return (
    <div>
      <div
        className={className}
        ref={setReferenceElement}
        onClick={() => {
            if (onShow) onShow()
          if (!managedVisibility) setVisible(true) 
        }}>
        {children}
      </div>
      {
        ((visible && height > 640) && !handleMobile) &&
        <motion.div
          className={'z-50'}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
          <div className={'m-1 pt-1 pb-1 border bg-white rounded shadow-md overflow-hidden'}>
            
            {renderContent()}

          </div>
          <div ref={setArrowElement} style={styles.arrow}></div>
        </motion.div>
      }

      {
        (handleMobile && visible) &&
        <div style={{ zIndex: 9999, backgroundColor: 'rgba(0,0,0,0.6)' }} className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
          <div className="bg-white rounded-lg">
            {renderContent()}
          </div>
        </div>
      }
    </div>
  )

}