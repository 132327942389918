import { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import 'react-day-picker/dist/style.css';

export default function DatePicker ({
  mode = 'single',
  className = '',
  fromDate,
  maxDate,
  renderFooter = undefined,
  onChange,
    value
} : {
  fromDate?: Date,
  maxDate?: Date,
  value?: any,
  renderFooter?: any,
  mode?: 'single' | 'multiple' | 'range' | any,
  className?: string,
  onChange?: (date: Date | { from?: Date, to?: Date }) => void
}) {

  const [currentDate, setCurrentDate] = useState<any>()

  useEffect(() => {
    if (onChange && currentDate) onChange(currentDate)
  }, [ currentDate ])

  return (
    <div>
      <DayPicker
          selected={currentDate}
          showOutsideDays={true}
          fixedWeeks={true}
          footer={renderFooter ? renderFooter() : undefined}
          fromDate={fromDate}
          toDate={maxDate}
          className={className}
          onSelect={(val: any) => {
            console.log('vak', val)
            setCurrentDate(val)
          }}
          mode={mode} />
    </div>
  )

}