import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";
import Button from "../components/form/Button";
import { Fragment, useState } from "react";
import TabSelector from "../components/navigation/TabSelector";
import HotelDetails from "../components/business/hotel/HotelDetails";
import HotelUsers from "../components/business/hotel/HotelUsers";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { deleteHotel, getAccomodationById, saveAccomodation } from "../shared/queries";
import DeleteModal from "../components/common/DeleteModal";
import toast from "react-hot-toast";
import { Accomodation } from "../types";
import { useTranslation } from "react-i18next";
import HotelProductList from "../components/business/hotel/HotelProductList";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export default function HotelPage () {

    const { t } = useTranslation()

    const { profile } = useSelector((state: RootState) => state.session)

    const history = useHistory()
    const { id } = useParams<{ id: string }>()

    const {
        data,
        refetch
    } = useQuery(['accomodation', id], () => getAccomodationById(id))

    const [currentTab, setCurrentTab] = useState('general')
    const [showDelete, setShowDelete] = useState<boolean>(false)

    const deleteMutation = useMutation((accomodation: Accomodation) => deleteHotel((accomodation && accomodation.idAccomodation) ? accomodation.idAccomodation : 0), {
        onSuccess: () => {
            history.push('/app/hotels')
            toast.success(t('accommodation.accommodationDeleted'))
        },
        onError: () => {
            toast.error('Impossibile eliminare la struttura')
        }
    })

    return (
        <PageWrapper centered maxWidth={'1000px'}>

            <DeleteModal
                askConfirmation={true}
                message={t('accommodation.confirmDelete')}
                visible={showDelete}
                onCancel={() => {
                    setShowDelete(false)
                }}
                onConfirm={() => {
                    if (data) deleteMutation.mutate(data)
                }} />

            {
                data &&
                <Fragment>

                    <PageTitle
                        title={`${data.accomodationName} - ${data.company.companyName}`}
                        renderActions={() => (
                            <div className={'flex space-x-2 items-center'}>
                                {
                                    (profile && profile.userAdmin) &&
                                    <Button
                                        className="text-white bg-red-600"
                                        label={t('general.deactivate')}
                                        onClick={() => setShowDelete(true)}
                                    />
                                }
                            </div>
                        )} />

                    <TabSelector
                        tabs={(profile && profile.userAdmin) ? [
                            { key: 'general', label: t('accommodation.details'), pulse: false, active: true },
                            { key: 'access', label: t('accommodation.access'), pulse: false, active: false },
                            { key: 'products', label: t('accommodation.products'), pulse: false, active: false }
                        ] : [
                            { key: 'general', label: t('accommodation.details'), pulse: false, active: true },
                            { key: 'access', label: t('accommodation.access'), pulse: false, active: false }
                        ]}
                        onChange={tab => {
                            setCurrentTab(tab.key)
                        }} />

                    {
                        currentTab === 'general' &&
                        <HotelDetails onUpdate={() => refetch()} accomodation={data} />
                    }

                    {
                        (currentTab === 'access' && data.idAccomodation) &&
                        <HotelUsers idAccomodation={data.idAccomodation} />
                    }

                    {
                        (profile && profile.userAdmin && currentTab === 'products' && data.idAccomodation) && (
                            <HotelProductList accomodation={data} onComplete={() => refetch()} />
                        )
                    }
                </Fragment>
            }

        </PageWrapper>
    )

}
