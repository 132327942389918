import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setRefundable } from "../../store/slices/offerSlice";
import Checkbox from "../form/Checkbox";
import QuotePolicySelector from "./QuotePolicySelector";

export default function QuotePolicyConfiguration () {

  const dispatch = useAppDispatch()

  const { t } = useTranslation()
  const { quote } = useAppSelector(state => state.offerSlice)

  return (
    <div>
      <Checkbox
        onChange={() => dispatch(setRefundable(!quote.refundable))}
        checked={quote.refundable}
        label={t('quote.noPenal')}
      />

      {
        !quote.refundable && <QuotePolicySelector />
      }
    </div>
  )

}