import { useTranslation } from "react-i18next";
import { QuoteOption } from "../../types";
import QuoteOptionModal from "./QuoteOptionModal";
import { useState } from "react";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { removeOption } from "../../store/slices/offerSlice";
import { formatCurrency } from "../../shared/utils";

export default function QuoteOptionRow ({ option, index } : { option: QuoteOption, index: number }) {

  const { t } = useTranslation()
  const { quote } = useAppSelector(state => state.offerSlice)
  const dispatch = useAppDispatch()

  const [editOption, setEditOption] = useState(false)

  return (
    <>
      <QuoteOptionModal
        option={option}
        visible={editOption}
        onClose={() => setEditOption(false)}
      />

      <div className="relative rounded-md px-2 button-shadow flex flex-col p-2">
        
        <div className="flex justify-between mb-4">
          <div className="font-medium text-gray-500">
            {t('quote.option')} {index + 1}
          </div>
          {
            !quote.idQuoteOptionAccepted &&
            <div className="flex space-x-2">
              <button className="font-medium text-orange-600" onClick={() => setEditOption(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                  <path d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z" />
                </svg>
              </button>
              <button className="font-medium text-red-600" onClick={() => dispatch(removeOption({
                id: option.idQuoteOption
              }))}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                  <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          }
        </div>

        {
          (option.rooms.filter(r => !r.toDelete).length === 0) &&
          <div onClick={() => setEditOption(true)} className="cursor-pointer hover:text-orange-500 flex flex-col items-center justify-center text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
              <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
            </svg>
            <div>
              {t('quote.addFirstOption')}
            </div>
          </div>
        }

        {
          option.rooms.filter(r => !r.toDelete).map((room, roomReapIdx) => (
            <>
              <div className="flex space-x-4" key={`opt-room-recap-${roomReapIdx}`}>
                <div>
                  <div
                    className="w-14 h-14 bg-gray-100 rounded-md bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${((room.room.images && room.room.images.length > 0) ? room.room.images[0].imageUrl : '')})`
                    }}
                  />
                </div>
                <div className="text-gray-500">
                  <div className="font-bold text-gray-600">
                    {room.title}
                  </div>
                  <div>{t('vary.from')} {dayjs(option.arrival.toString(), 'YYYYMMDD').format('DD MMMM YY')} {t('vary.to')} {dayjs(option.departure.toString(), 'YYYYMMDD').format('DD MMMM YY')}</div>
                  <div>{room.adults} {t('quote.adults')}, {room.children} {t('quote.children')}</div>
                  <div className="font-medium mt-1">
                    {formatCurrency(room.price)}
                  </div>
                </div>
              </div>
              { (roomReapIdx < option.rooms.filter(r => !r.toDelete).length - 1) && <div className="border-b pb-2 mb-2"></div>}
            </>
          ))
        }

      </div>
    </>
  )

}