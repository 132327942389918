import { useState } from "react"
import DatePickerInput from "./DatePickerInput"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

export default function DateRangePicker ({
  minDate,
  onStart,
  onEnd,
  dark
} : {
  minDate?: Date,
  onStart: (val: Date) => void,
  onEnd: (val: Date) => void,
  dark?: boolean
}) {

  const { t } = useTranslation()
  
  const [fromDate, setFromDate] = useState(new Date())
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day').toDate())

  return (
    <div className={`flex font-medium space-x-3 items-center`}>
      <div className={`${dark ? 'text-white' : 'text-slate-700'}`}>{t('from')}</div>
      <DatePickerInput
        fromDate={minDate}
        labelFormat="DD/MM/YY"
        value={fromDate}
        onChange={value => {
          if (dayjs(value).isAfter(fromDate)) {
            setEndDate(dayjs(value).add(1, 'day').toDate())
          }
          setFromDate(value)
          onStart(value)
        }}
      />
      <div className={`${dark ? 'text-white' : 'text-slate-700'}`}>{t('to')}</div>
      <DatePickerInput
        labelFormat="DD/MM/YY"
        fromDate={dayjs(fromDate).add(1, 'day').toDate()}
        value={endDate}
        onChange={value => {
          if (dayjs(value).isBefore(fromDate)) {
            setFromDate(dayjs(value).subtract(1, 'day').toDate())
          }
          setEndDate(value)
          onEnd(value)
        }}
      />
    </div>
  )

}