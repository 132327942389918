import {useQuery} from "react-query";
import {getAccomodationPolicyRate, getQueriedReservations} from "../shared/queries";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useDebounce from "../hooks/useDebounce";
import {formatDate} from "../shared/utils";
import Icon from "../components/common/Icon";
import NumberFormat from "react-number-format";
import TableFilters from "../components/data/TableFilters";
import ProgressBar from "../components/loading/ProgressBar";
import Button from "../components/form/Button";
import CreateBooking from "../components/business/booking/CreateBooking";
import dayjs from "dayjs";
import ReservationPage from "./ReservationPage";
import ExportModal from "../components/common/ExportModal";
import {RootState} from "../store";
import {setCurrentRes, setFilters, setTableFilters} from "../store/slices/bookingSlice";
import GlobalSearch from "../components/GlobalSearch";
import CreateBookingAdv from "../components/business/booking/CreateBookingAdv";

function ReservationList ({ condensed, data } : { condensed: boolean, data: any }) {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { currentRes } = useSelector((state: RootState) => state.bookings)

    function getMainGuestName (res: any) {
        if (res.guests.length > 0) {
            const mainGuest = res.guests[0]
            return mainGuest.guestFirstName + ' ' + mainGuest.guestLastName + ((res.guests.length > 1) ? ' + altri ' + (res.guests.length - 1) : '')
        } else {
            return t('reservations.missingGuest')
        }
    }

    function isCurrentBooking (res: any) {
        return currentRes && res.idBooking.toString() === currentRes
    }

    if (!data) return <></>

    return data && data.content.map((res: any, index: any) => (
      <div className="bg-white flex flex-col">
          <button
            onClick={() => {
                dispatch(setCurrentRes(res.idBooking.toString()))
                window.history.replaceState({}, '', '/app/reservations/' + res.idBooking)
            }}
            className={`${(res.bookingArrivalDate !== null && res.bookingArrivalDate !== undefined && !isCurrentBooking(res)) && dayjs(res.bookingArrivalDate.toString(), 'YYYYMMDD').isBefore(dayjs()) && 'striped-bg'} border-l-4 ${(res.processedAt && !res.isDeleted) && 'border-green-600'} ${res.isDeleted && 'border-red-600'} transition-all block w-full cursor-pointer hover:bg-gray-50 px-4 py-3 ${isCurrentBooking(res) && 'bg-orange-100'}`}
            key={index}>
              <div className="mb-1 flex items-center justify-between">
                  <div
                    className={`font-medium ${res.idBooking === currentRes && 'text-orange-700'} truncate text-ellipsis overflow-hidden`}>
                      {getMainGuestName(res)}
                  </div>
                  <div className={'text-xs text-gray-500'}>
                      {formatDate(res.bookingDate, 'DD/MM/YY')}
                  </div>
              </div>
              <div className="mb-1 text-gray-500 text-sm font-normal flex space-x-2 items-center flex justify-between">
                  <div className={'text-left runcate text-ellipsis overflow-hidden flex-1'}>
                      {res.bookingCode}
                  </div>
                  {
                    !condensed &&
                    <div className={'flex font-normal items-center space-x-1'}>
                        <div>
                            {(res.bookingArrivalDate != null && res.bookingArrivalDate != undefined) ? formatDate(res.bookingArrivalDate, 'DD/MM') : 'ND'}
                        </div>
                        <div className={'text-gray-500'}>
                            <Icon name={'arrow-sm-right'} size={'20px'}/>
                        </div>
                        <div>
                            {formatDate(res.bookingDepartureDate, 'DD/MM')}
                        </div>
                    </div>
                  }
              </div>
              {
                !condensed &&
                <div
                  className={'mt-1 font-medium text-gray-700 text-sm items-center justify-between flex'}>
                    <div>
                        <NumberFormat
                          value={res.bookingTotal}
                          displayType={'text'}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator={','}
                          thousandSeparator={'.'}
                          prefix={'EUR '}/>
                    </div>
                </div>
              }
          </button>
          <div style={{height: '1px'}} className="border-b"></div>
      </div>
    ))
}

export default function ReservationPageFace () {

    const { t } = useTranslation()

    const { id } = useParams<{ id: string }>()

    const dispatch = useDispatch()
    const { filters, tableFilters, currentRes } = useSelector((state: RootState) => state.bookings)

    const [create, setCreate] = useState<boolean>(false)
    const [condensed, setCondensed] = useState<boolean>(true)
    const [quoteView, setQuoteView] = useState<boolean>(false)
    const [showExport, setShowExport] = useState<boolean>(false)

    const [page, setPage] = useState<number>(0)
    const [sortBy, setSortBy] = useState('bookingDate')
    const [sortType, setSortType] = useState('DESC')

    const {currentHotel, currentHotelObject} = useSelector<any>(state => state.hotels) as any
    const debounceFilters = useDebounce(filters, 500)

    useEffect(() => {
        const lastStatus = localStorage.getItem('last_status_filter')
        const lastYear = localStorage.getItem('last_year_filter')
        const resetFilters = []

        if (lastStatus) {
            resetFilters.push({
                field: 'status',
                unmanaged: true,
                searchOperation: 'EQUAL',
                value: localStorage.getItem('last_status_filter')
            })
        }

        if (lastYear) {
            resetFilters.push({
                field: 'year',
                unmanaged: true,
                searchOperation: 'EQUAL',
                value: localStorage.getItem('last_year_filter')
            })
        } else {
            resetFilters.push({
                field: 'year',
                unmanaged: true,
                searchOperation: 'EQUAL',
                value: '2024'
            })
        }
        
        if (resetFilters.length > 0) {
            dispatch(setFilters(resetFilters))
        }
    }, [])


    useEffect(() => {
        if (id) dispatch(setCurrentRes(id))
    }, [ id ])

    const {
        data,
        refetch,
        isFetching
    } = useQuery([
        'filteredBookings',
        debounceFilters,
        currentHotel,
        page,
        sortBy,
        sortType
    ], () => getQueriedReservations(
        currentHotel,
        page,
        35,
        sortBy,
        filters,
        sortType
    ), {
        enabled: currentHotel !== null && currentHotel !== undefined,
        refetchOnMount: false
    })

    const {
        data: accomodationRate
    } = useQuery(['accomodationRates', currentHotel], () => getAccomodationPolicyRate(currentHotel as any), {
        enabled: (currentHotel !== undefined && currentHotel !== null),
        retry: false
    })

    function hasFixedPolicyCost () {
        if (accomodationRate) {
            if (accomodationRate.find(a => a.fixedCost)) return true
        }
        return false
    }

    return (
        <div className="flex flex-col h-full w-full">
        <ExportModal
            currentHotel={currentHotel}
            visible={showExport}
            onClose={() => setShowExport(false)}
            columns={[]}
        />

            <div className={'border-b md:space-x-2 flex items-center py-3 bg-gray-50'}>
                <div className={'px-4 md:border-r'}>
                    <Button
                        className={'hidden md:block border border-orange-500 shadow text-sm font-semibold rounded-md py-1 px-4 text-white bg-orange-600'}
                        label={t('reservations.newReservation')}
                        onClick={() => {
                            setCreate(true)
                        }}
                    />

                <Button
                    className={'md:hidden border border-orange-500 shadow text-sm font-semibold rounded-md py-1 px-4'}
                    icon={'plus'}
                    onClick={() => {
                        setCreate(true)
                    }} />
                </div>
                <div className="pr-3 pl-1 border-r flex items-center space-x-2 text-slate-600">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                            <path d="M2 3a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2Z" />
                            <path fillRule="evenodd" d="M2 7.5h16l-.811 7.71a2 2 0 0 1-1.99 1.79H4.802a2 2 0 0 1-1.99-1.79L2 7.5ZM7 11a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div>
                        <select
                            value={filters.find(f => f.field === 'year') ? filters.find(f => f.field === 'year')?.value : null }
                            onChange={event => {
                                localStorage.setItem('last_year_filter', event.target.value)
                                dispatch(setFilters([
                                    ...filters.filter(f => f.field !== 'year'),
                                    {
                                        field: 'year',
                                        unmanaged: true,
                                        searchOperation: 'EQUAL',
                                        value: event.target.value
                                    }
                                ]))
                            }}
                            className="bg-gray-50 font-medium">
                            <option value="2017">2017</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024" selected>2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2026">2027</option>
                            <option value="2026">2028</option>
                            <option value="2026">2029</option>
                        </select>
                    </div>
                </div>
              <div className="hidden md:flex space-x-2">
                  <button onClick={() => {
                      localStorage.removeItem('last_status_filter')
                      dispatch(setFilters([
                          ...filters.filter(f => f.field !== 'status')
                      ]))
                  }} className={`${!filters.find(f => f.field === 'status') && 'bg-orange-200 text-orange-800'} rounded-full flex-1 px-3 py-1 hover:bg-orange-100 transition-all font-medium text-xs h-full`}>
                        <span className={'relative'}>
                            {t('reservations.all')}
                        </span>
                  </button>
                  <button onClick={() => {
                      localStorage.setItem('last_status_filter', 'pending')
                      dispatch(setFilters([
                          ...filters.filter(f => f.field !== 'status'),
                          {
                              field: 'status',
                              unmanaged: true,
                              searchOperation: 'EQUAL',
                              value: 'pending'
                          }
                      ]))
                  }} className={`${filters.find(f => f.field === 'status' && f.value === 'pending') && 'bg-orange-200 text-orange-800'} rounded-full flex-1 px-3 py-1 hover:bg-orange-100 transition-all font-medium text-xs h-full`}>
                        <span className={'relative'}>
                            {t('reservations.toManage')}
                        </span>
                  </button>
                  <button onClick={() => {
                      localStorage.setItem('last_status_filter', 'insured')
                      dispatch(setFilters([
                          ...filters.filter(f => f.field !== 'status'),
                          {
                              field: 'status',
                              unmanaged: true,
                              searchOperation: 'EQUAL',
                              value: 'insured'
                          }
                      ]))
                  }} className={`${filters.find(f => f.field === 'status' && f.value === 'insured') && 'bg-orange-200 text-orange-800'} rounded-full flex-1 px-3 py-1 hover:bg-orange-100 transition-all font-medium text-xs`}>
                        <span className={'relative'}>
                            {t('reservations.allInsured')}
                        </span>
                  </button>
                  <button onClick={() => {
                      localStorage.setItem('last_status_filter', 'partially')
                      dispatch(setFilters([
                          ...filters.filter(f => f.field !== 'status'),
                          {
                              field: 'status',
                              unmanaged: true,
                              searchOperation: 'EQUAL',
                              value: 'partially'
                          }
                      ]))
                  }} className={`${filters.find(f => f.field === 'status' && f.value === 'partially') && 'bg-orange-200 text-orange-800'} whitespace-nowrap rounded-full flex-1 px-3 py-1 hover:bg-orange-100 transition-all font-medium text-xs`}>
                        <span className={'relative'}>
                            {t('vary.partiallyInsured')}
                        </span>
                  </button>
                  <button onClick={() => {
                      localStorage.setItem('last_status_filter', 'canceled')
                      dispatch(setFilters([
                          ...filters.filter(f => f.field !== 'status'),
                          {
                              field: 'status',
                              unmanaged: true,
                              searchOperation: 'EQUAL',
                              value: 'canceled'
                          }
                      ]))
                  }} className={`${filters.find(f => f.field === 'status' && f.value === 'canceled') && 'bg-orange-200 text-orange-800'} rounded-full flex-1 px-3 py-1 hover:bg-orange-100 transition-all font-medium text-xs`}>
                        <span className={'relative'}>
                            {t('reservations.allCanceled')}
                        </span>
                  </button>
              </div>
              <div className={'hidden md:block md:border-l pl-4'}>
                <div>
                    <TableFilters
                    onChange={filters => {
                        dispatch(setTableFilters(filters))
                        dispatch(setFilters(filters.filter(filter => filter.active).map(filter => {
                            return {
                                field: filter.key,
                                unmanaged: filter.unmanaged,
                                searchOperation: filter.operator,
                                value: filter.value
                            }
                        })))
                    }}
                    columns={(tableFilters && tableFilters.length > 0) ? tableFilters : [
                        {
                            key: 'bookingCode',
                            label: t('reservations.code'),
                            operator: 'CONTAINS',
                            active: false,
                            value: null,
                            type: 'text'
                        },
                        {
                            key: 'guestName',
                            label: t('reservations.guestName'),
                            operator: 'LIKE',
                            unmanaged: true,
                            active: false,
                            value: null,
                            type: 'text'
                        },
                        {
                            key: 'status',
                            label: t('reservations.status'),
                            operator: 'EQUAL',
                            unmanaged: true,
                            active: false,
                            value: null,
                            options: [
                                { label: 'In corso', value: 'pending' },
                                { label: 'Assicurata', value: 'insured' },
                                { label: 'Stornata', value: 'canceled' },
                            ],
                            type: 'select'
                        },
                        {
                            key: 'stayDate',
                            label: t('reservations.stayDate'),
                            operator: 'BETWEEN',
                            unmanaged: true,
                            active: false,
                            value: null,
                            type: 'daterange'
                        },
                        {
                            key: 'bookingArrivalDate',
                            label: t('reservations.arrivalDate'),
                            operator: 'BETWEEN',
                            unmanaged: false,
                            active: false,
                            value: null,
                            type: 'daterange'
                        },
                        {
                            key: 'bookingDate',
                            label: t('reservations.reservationDate'),
                            operator: 'BETWEEN',
                            unmanaged: false,
                            active: false,
                            value: null,
                            type: 'daterange'
                        }
                    ]}
                    />
                </div>
              </div>
              <div className="hidden md:block">
                  <button
                    style={{
                        boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px'
                    }}
                    className={'py-1 flex items-center bg-white focus:outline-none bg-white-600 px-2 text-sm font-medium border-gray-300 rounded'}
                    onClick={() => setShowExport(true)}>
                      <div className={'mr-2'}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                          </svg>
                      </div>
                      <div className="text-sm">{t('general.accountingReport')}</div>
                  </button>
              </div>
              <div className="flex flex-1 md:hidden pr-4">
                <GlobalSearch />
              </div>
          </div>
          <div className="flex-1 overflow-hidden">
              <div className={'h-full'}>
                <div className={'h-full overflow-hidden flex'}>
                    <div className="w-full md:w-80 h-full border-r overflow-auto">
                        <div className={'sticky top-0 bg-white'}>
                            <div className={'py-2 pl-4 flex justify-between items-center'}>
                                <div className={'flex-1 text-xs'}>
                                    {data && (data.size * (page + 1) > data.totalElements) ? data.totalElements : data ? data.size * (page + 1) : 0} di {data ? data.totalElements : '...'}
                                </div>
                                <div className={'flex space-x-2'}>
                                    <button
                                        onClick={() => {
                                            if (page === 0) return
                                            setPage(page - 1)
                                        }}
                                        className={`${page === 0 && 'opacity-50'} items-center font-medium rounded px-2 py-1 text-xs flex space-x-2`}>
                                        <Icon name={'chevron-left'} size={'18px'} />
                                        <span><span>{t('general.prev')}</span></span>
                                    </button>
                                    <button
                                        onClick={() => {
                                            if (data?.totalPages === (page + 1)) return
                                            setPage(page + 1)
                                        }}
                                        className={`${data && (page === data.totalPages - 1) && 'opacity-50'} items-center font-medium rounded px-2 py-1 text-xs flex space-x-2`}>
                                        <span>{t('general.next')}</span>
                                        <Icon name={'chevron-right'} size={'18px'} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white flex px-4 items-center justify-between px-2 py-2 border-t text-sm space-x-2 border-b">
                            <div className={'flex-1 items-center space-x-1'}>
                                {t('reservations.orderBy')}
                            </div>
                            <div className={'flex-1 cursor-pointer'}>
                                <select
                                    onChange={e => {
                                        setSortBy(e.target.value)
                                    }}
                                    value={sortBy}
                                    name="orderBy"
                                    style={{ boxShadow: '0px 0px 6px rgba(0,0,0,0.04), 0px 0px 0px 1px rgba(0,0,0,0.150)' }}
                                    className={'px-2 py-1 text-xs  font-medium appearance-none'}>
                                    <option value="bookingDate">{t('reservations.reservationDate')}</option>
                                    <option value="bookingArrivalDate">{t('reservations.arrivalDate')}</option>
                                    <option value="bookingDepartureDate">{t('reservations.departureDate')}</option>
                                </select>
                            </div>
                            <div className={'flex-1 cursor-pointer'}>
                                <select
                                    onChange={e => {
                                        setSortType(e.target.value)
                                    }}
                                    name="orderBy"
                                    style={{ boxShadow: '0px 0px 6px rgba(0,0,0,0.04), 0px 0px 0px 1px rgba(0,0,0,0.150)' }}
                                    className={'px-2 py-1 text-xs font-medium appearance-none'}>
                                    <option value="ASC">
                                        {t('reservations.ascending')}
                                    </option>
                                    <option value="DESC">
                                        {t('reservations.descending')}
                                    </option>
                                </select>
                            </div>
                        </div>
                        {
                            isFetching && <ProgressBar />
                        }
                        <ReservationList condensed={condensed} data={data} />
                    </div>
                    <div className="hidden md:block h-full flex-1 overflow-auto">
                        { currentRes && <ReservationPage id={currentRes} onRefresh={() => refetch()} /> }
                    </div>
                </div>

                {
                    (
                        create && (currentHotelObject.customerType === 'AGENCY' || currentHotelObject.customerType === 'TO')
                    )
                    ? <CreateBookingAdv
                        visible={create}
                        onClose={() => {
                            setCreate(false)
                        }}
                        onSave={data => {
                            setCreate(false)
                            dispatch(setCurrentRes(data.idBooking?.toString()))
                            refetch()
                        }} />
                    : <CreateBooking
                        visible={create}
                        onClose={() => {
                            setCreate(false)
                        }}
                        onSave={data => {
                            setCreate(false)
                            dispatch(setCurrentRes(data.idBooking?.toString()))
                            refetch()
                        }} />
                }
            </div>
        </div>
    </div>
    )

}
