import Lottie from "lottie-react"
import creditcard from '../../lottie/creditcard.json'
import dayjs from "dayjs"
import { useAppSelector } from "../../hooks/redux"
import { useTranslation } from "react-i18next"

export default function QuotePaymnetProcessing () {

  const { t } = useTranslation()
  const { quote } = useAppSelector(state => state.offerSlice)

  return (
    <div className="w-full max-w-xl mx-auto flex justify-center items-center flex-col space-y-6">
      <Lottie
        animationData={creditcard} 
        className="w-64"
        loop={true}
      />

      <div className="text-center">
        <div className="text-xl text-slate-700 font-medium">
          {t('Pagamento in corso')}
        </div>
        <div className="text-slate-600 text-lg mt-1">
          {t('il cliente ha iniziato la sessione di pagamento, il preventivo sarà bloccato per i prossimi 10 minuti')}
        </div>
        <div className="text-slate-600 text-lg mt-4 font-medium">
          {t('riprova alle')} {dayjs(quote.paymentStartedAt?.toString(), 'YYYYMMDDHHmm').add(10, 'minutes').format('DD/MM/YYYY HH:mm')}
        </div>
      </div>
    </div>
  )

}