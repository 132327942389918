export default function Logo ({ size, url } : { size?: string, url?: string }) {

    return (
        <div>
            <img
                className={size ? size : 'h-7'}
                src={url ? url : '/assets/logo.svg'}
                alt="BeSafe Rate" />
        </div>
    )

}