import { useTranslation } from "react-i18next";
import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";
import BaseTable from "../components/data/BaseTable";
import {useSelector} from "react-redux";
import {useQuery} from "react-query";
import {BillingReport, Invoice} from "../types";
import {getAccomodationInvoices, getBillableBookings} from "../shared/queries";
import toast from "react-hot-toast";
import DetailRow from "../components/data/DetailRow";
import {formatCurrency} from "../shared/utils";
import SectionCard from "../components/SectionCard";
import FormSectionTitle from "../components/form/FormSectionTitle";
import {useHistory, useLocation} from "react-router-dom";
import BookingPopoverDetail from "../components/business/booking/BookingPopoverDetail";
import dayjs from "dayjs";
import Checkbox from "../components/form/Checkbox";
import {useMemo, useState} from "react";
import InvoiceVerificationRequest from "./InvoiceVerificationRequest";
import Chip from "../components/common/Chip";
import DateRangePicker from "../components/form/DateRangePicker";
import AccountingReport from "../components/business/invoices/AccountingReport";
import PaymentMethodList from "../components/business/PaymentMethodList";

export default function InvoicesPage () {

  const { t } = useTranslation()

  const history = useHistory()
  const { search } = useLocation()

  const [currentView, setCurrentView] = useState<'current' | 'past' | 'accountingReport' | 'paymentMethods'>('current')

  const currentHotel = useSelector<any>(state => state.hotels.currentHotel) as any
  const currentHotelObject = useSelector<any>(state => state.hotels.currentHotelObject) as any

  const [fromDate, setFromDate] = useState(dayjs().startOf('year').toDate())
  const [endDate, setEndDate] = useState(dayjs().toDate())

  const [selectedInvoices, setSelectedInvoices] = useState<number[]>([])
  const [showVerifyDialog, setShowVerifyDialog] = useState(false)

  useMemo(() => {
    const data = new URLSearchParams(search)
    if (data.has('pastInvoices')) {
      setCurrentView('past')
    }
    if (data.has('paymentMethods')) {
      setCurrentView('past')
    }
}, [ search ])

  const {
    data
  } = useQuery<Invoice, Error>(['invoices', currentHotel, fromDate, endDate], () => getAccomodationInvoices(currentHotel, fromDate, endDate), {
    enabled: (currentHotel !== undefined && currentHotel !== null),
    onError: () => {
      toast.error("Can not load invoices")
    }
  })

  const {
    data: billableBookings,
  } = useQuery<BillingReport, Error>(['billable', currentHotel], () => getBillableBookings(currentHotel), {
    enabled: (currentHotel !== undefined && currentHotel !== null),
    onError: () => {
      toast.error("Can not load invoices")
    }
  })

  function getActiveClass (key: string) {
    if (key === currentView) {
      return 'border-b-4 text-orange-600 font-bold border-orange-600'
    }
    return 'font-medium text-gray-500 hover:text-orange-600'
  }

  return (
      <PageWrapper centered maxWidth={'1000px'}>

        <PageTitle title={t('invoices.invoices')} />

        <div className="bg-orange-100 mb-4 rounded-md flex items-center p-4 space-x-8">
          <div>
            <img className="w-20" src="/assets/news.svg" alt="BeSafe News" />
          </div>
          <div className="flex-1">
            <div className="text-orange-800 mb-2 text-lg font-bold">{t('vary.newsInvoiceTitle')}</div>
            <div className="text-orange-800">{t('vary.newsInvoiceText')}</div>
          </div>
        </div>

        <InvoiceVerificationRequest
          visible={showVerifyDialog}
          selectedInvoices={selectedInvoices}
          onClose={() => {
            setSelectedInvoices([])
            setShowVerifyDialog(false)
          }}
        />

        <div className="flex space-x-4">
          <div className="w-1/2">
            <SectionCard title={t('invoices.recap')}>
              <div className="flex flex-col space-y-4">
                <DetailRow label={t('invoices.recap')}>
                  {billableBookings ? billableBookings.invoices.length : 0}
                </DetailRow>

                <DetailRow label={t('invoices.totalInsured')}>
                  {formatCurrency(billableBookings?.totalInsured || 0)}
                </DetailRow>

                <DetailRow label={t('invoices.totalNextBill')}>
                  {formatCurrency(billableBookings?.totalNextBill || 0)}
                </DetailRow>

                <DetailRow label={t('invoices.credits')}>
                  {formatCurrency(billableBookings?.totalCredits || 0)}
                </DetailRow>

                <div className="text-sm font-semibold text-orange-600">
                  {t('invoices.updatedAt')} {dayjs().format('DD/MM/YYYY HH:mm')}
                </div>
              </div>
            </SectionCard>
          </div>
        </div>

        <div className="border-b flex mt-10 items-center">
          <button onClick={() => setCurrentView('current')} className={`p-4 px-6 ${getActiveClass('current')}`}>
            {t('vary.currentInvoices')}
          </button>
          <div className="border-r border-gray-300 h-10"></div>
          <button onClick={() => setCurrentView('past')} className={`p-4 px-6 ${getActiveClass('past')}`}>
            {t('vary.oldInvoicesTitle')}
          </button>
          <div className="border-r border-gray-300 h-10"></div>
          <button onClick={() => setCurrentView('accountingReport')} className={`p-4 px-6 ${getActiveClass('accountingReport')}`}>
            {t('accountReport.title')}
          </button>
          <>
            <div className="border-r border-gray-300 h-10"></div>
            <button onClick={() => setCurrentView('paymentMethods')} className={`p-4 px-6 ${getActiveClass('paymentMethods')}`}>
              {t('sepa.paymentMethod')}
            </button>
          </>
        </div>

        {
          (currentView === 'paymentMethods') && <PaymentMethodList />
        }

        {
          (currentView === 'current') &&
          <div className="pb-6">
            <FormSectionTitle
              borderless
              dense
              title={t('invoices.billDetail')}
              subtitle={t('invoices.billDetailText')} />

            <div>
              <button
                onClick={() => {
                  if (selectedInvoices.length === 0) {
                    alert(t('invoices.select'))
                    return
                  }
                  setShowVerifyDialog(true)
                }}
                className={'customshadow bg-white rounded-md text-orange-600 text-sm px-2 py-1'}>
                {t('invoices.askVerification')} ({selectedInvoices.length})
              </button>
            </div>

            <BaseTable
              filterable={false}
              showColumnSelector={true}
              filters={[]}
              paginated={false}
              selectedRows={selectedInvoices}
              columns={[
                {
                  width: 'w-8',
                  Header: t('invoices.selects').toString(),
                  accessor: row => (
                    <Checkbox
                      onChange={() => {
                        if (selectedInvoices.includes(row.idBooking)) {
                          setSelectedInvoices(prevState => prevState.filter(si => si !== row.idBooking))
                        } else {
                          setSelectedInvoices(prevState => [
                            ...prevState,
                            row.idBooking
                          ])
                        }
                      }}
                      checked={selectedInvoices.includes(row.idBooking)} />
                  )
                },
                {
                  Header: t('invoices.code').toString(),
                  accessor: row => (
                    <BookingPopoverDetail idBooking={row.idBooking}>
                      <button onClick={() => history.push('/app/reservations/' + row.idBooking)} className={'underline text-orange-600'}>
                        {row.bookingCode}
                      </button>
                    </BookingPopoverDetail>
                  )
                },
                {
                  Header: t('invoices.totalCashed').toString(),
                  accessor: row => {
                    if (row.totalCashed !== row.totalCashedLocalCurrency) {
                      return formatCurrency(row.totalCashedLocalCurrency, currentHotelObject?.currency) + ' -> ' + formatCurrency(row.totalCashed)
                    } else {
                      return formatCurrency(row.totalCashed) 
                    }
                  }
                },
                {
                  Header: t('invoices.totalCommission').toString(),
                  accessor: row => row.isDeleted ? formatCurrency(((row.insuranceRate * row.totalCashed / 100) + row.insuranceFixedRate) * -1) : formatCurrency(((row.insuranceRate * row.totalCashed / 100)) + row.insuranceFixedRate)
                }
              ]}
              data={billableBookings ? billableBookings.invoices : []} />
          </div>
        }

        {
          (currentView === 'past') &&
          <>
            <FormSectionTitle
              subtitle={t('invoices.invoiceListText')}
              borderless
              dense
              title={t('invoices.invoiceList')} />

            <div className="flex items-center space-x-2 mb-2 pt-4">
              <div className="font-medium text-gray-600">{t('vary.period')}</div>
              <DateRangePicker onStart={value => setFromDate(value)} onEnd={value => setEndDate(value)} />
            </div>

            <div className="pb-6">
              <BaseTable
                  filterable={false}
                  showColumnSelector={true}
                  filters={[]}
                  paginated={false}
                  columns={[
                    {
                      Header: t('invoices.type').toString(),
                      accessor: row => (
                        <div>
                          {(row.documentType === 'invoice') ? t('vary.invoice') : t('vary.creditNote')} 
                        </div>
                      )
                    },
                    {
                      Header: t('invoices.number').toString(),
                      accessor: 'invoiceNumber'
                    },
                    {
                      Header: t('invoices.year').toString(),
                      accessor: 'invoiceYear'
                    },
                    {
                      Header: t('invoices.sentOn').toString(),
                      accessor: row => (
                        <div>{dayjs(row.invoiceDate.toString()).format('DD/MM/YYYY')}</div>
                      )
                    },
                    {
                      Header: t('invoices.total').toString(),
                      accessor: row => (
                        <div>
                          {(row.documentType === 'invoice') ? row.invoiceTotal : row.invoiceTotal * -1} EUR
                        </div>
                      )
                    },
                    {
                      Header: t('reservations.status').toString(),
                      accessor: row => (
                        <div>
                          {
                              (row.paid || row.documentType === 'credit_note') ?
                              <Chip className={'bg-green-200 text-green-700'}>
                                  {t('invoices.paid')}
                              </Chip>
                              :
                              <Chip className={'bg-orange-200 text-orange-700'}>
                                  {t('invoices.due')}
                              </Chip>
                          }
                        </div>
                      )
                    },
                    {
                      Header: 'Download',
                      accessor: row => (
                        <div>
                          <a target={'_blank'} className={'text-orange-600 underline'} href={row.invoiceLink}>Download</a>
                        </div>
                      )
                    }
                  ]}
                  data={data ? data : []}
                />
            </div>
          </>
        }

        {
          (currentView === 'accountingReport') &&
          <div>
            <AccountingReport />
          </div>
        }

      </PageWrapper>
  )

}
