import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { getCompanyById, saveAccomodation, saveCompany, updateCompany } from "../../../shared/queries"
import { Company } from "../../../types"
import Modal from "../../common/Modal"
import ModalActions from "../../common/ModalActions"
import ModalCard from "../../common/ModalCard"
import FormField from "../../form/FormField"
import TextInput from "../../form/TextInput"
import toast from 'react-hot-toast'
import { id } from "date-fns/locale"
import CompanySelector from "../selectors/CompanySelector"

export default function CreateCompany ({
    idCompany,
    visible,
    onClose,
    onSave
} : {
    idCompany?: string | number,
    visible: boolean,
    onClose: () => void,
    onSave: (data: Company) => void
}) {

    const [companyName, setCompanyName] = useState<string>('')
    const [companyFiscalCode, setCompanyFiscalCode] = useState<string>('')
    const [companyEmail, setCompanyEmail] = useState<string>('')
    const [companyPec, setCompanyPec] = useState<string>('')
    const [companyVat, setCompanyVat] = useState<string>('')
    const [companySdi, setCompanySdi] = useState<string>('')

    const saveMutation = useMutation(() => saveCompany({
        idCompany,
        companyName,
        companyEmail,
        companyFiscalCode,
        companyPec,
        companyVat,
        companySdi
    }), {
        onSuccess: data => {
            onSave(data)
        }
    })

    const {
        data
    } = useQuery(['company', idCompany], () => getCompanyById(idCompany || 0), {
        enabled: idCompany !== undefined
    })

    useEffect(() => {
        if (data && data.idCompany) {
            setCompanyName(data.companyName)
            setCompanyFiscalCode(data.companyFiscalCode)
            setCompanyEmail(data.companyEmail)
            setCompanyPec(data.companyPec)
            setCompanyVat(data.companyVat)
            setCompanySdi(data.companySdi)
        }
    }, [ data ])

    return (
        <Modal
            visible={visible}>
            <ModalCard
                actionsLoading={saveMutation.isLoading}
                renderActions={() => (
                    <ModalActions
                        onClose={onClose}
                        onSave={() => {
                            saveMutation.mutate()
                        }}></ModalActions>
                )}
                title={'Nuova azienda'}>
                
                <div className="p-4 flex flex-col space-y-4">
                    <FormField label={'Ragione sociale'}>
                        <TextInput
                            value={companyName}
                            onChange={val => setCompanyName(val)} />
                    </FormField>

                    <div className="flex space-x-4">
                        <FormField className={'flex-1'} label={'Codice Fiscale'}>
                            <TextInput
                                value={companyFiscalCode}
                                onChange={val => setCompanyFiscalCode(val)} />
                        </FormField>

                        <FormField className={'flex-1'} label={'Partita IVA'}>
                            <TextInput
                                value={companyVat}
                                onChange={val => setCompanyVat(val)} />
                        </FormField>
                    </div>

                    <FormField className={'flex-1'} label={'Codice SDI'}>
                        <TextInput
                            value={companySdi}
                            onChange={val => setCompanySdi(val)} />
                    </FormField>

                    <div className="flex space-x-4">
                        <FormField className={'flex-1'} label={'Email'}>
                            <TextInput
                                value={companyEmail}
                                onChange={val => setCompanyEmail(val)} />
                        </FormField>

                        <FormField className={'flex-1'} label={'PEC'}>
                            <TextInput
                                value={companyPec}
                                onChange={val => setCompanyPec(val)} />
                        </FormField>
                    </div>
                </div>
            </ModalCard>
        </Modal>
    )

}