import { useHistory } from "react-router";
import Icon from "../components/common/Icon";
import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";

export default function IntelligencePage () {

    const history = useHistory()
    
    const IntelligenceCard = ({ title, info, icon, onClick } : {
        title: string,
        info: string,
        icon: string,
        onClick: () => void
    }) => (
        <button
            onClick={onClick}
            className={'text-left focus:outline-none focus:border-orange-300 focus:bg-orange-50 focus:ring-4 ring-orange-100 cursor-pointer hover:bg-gray-100 flex-1 max-w-xs border rounded-md p-4 flex space-x-4 text-gray-600'}>
            <Icon name={icon} size={'25px'} />
            <div>
                <div className={'font-semibold'}>
                    {title}
                </div>
                <div className={'mt-1 text-sm text-gray-500'}>
                    {info}
                </div>
            </div>
        </button>
    )

    return (
        <PageWrapper centered maxWidth={'1000px'}>
            
            <PageTitle
                title={'Intelligence'} />

            <div className="flex mt-10 space-x-4">

                <IntelligenceCard
                    icon={'eye'}
                    title={'Monitoraggio canali'}
                    info={'stato salute canali'}
                    onClick={() => {
                        history.push('/app/intelligence/monitoring')
                    }} />

                <IntelligenceCard
                    icon={'eye'}
                    title={'Monitoraggio assicurazione'}
                    info={'stato flussi assicurativi'}
                    onClick={() => {
                        history.push('/app/intelligence/insurances')
                    }} />

            </div>

        </PageWrapper>
    )

}