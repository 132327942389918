import { ApiRequest } from "../types"

export const auth0Helper = {
    tokenProvider: null as any,
    getToken (params: any) {
        if (this.tokenProvider) {
            return this.tokenProvider(params)
        }
        return null
    }
}

// const API_URL = 'https://insurance.besafesuite.com'
const API_URL = process.env.NODE_ENV === 'production' ?
    process.env.REACT_APP_API_URL_PROD
    :
    process.env.REACT_APP_API_URL

async function buildDefaultHeaders (headerList: any, noAuth = false) {
    const headers = new Headers()
    headers.append('X-Requested-With', 'XMLHttpRequest')
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    const token = localStorage.getItem('token')
    /*
    const token = await auth0Helper.getToken({
        audience: `insurance`,
        scope: "openid profile read:current_user",
    })
        */
    if (token && noAuth === false) {
        headers.append('Authorization', 'Bearer ' + token)
    }

    if (headerList) {
        headerList.forEach((header:any) => {
            headers.append(header.key, header.content)
        })
    }

    return headers
}

export const request = async ({
    method,
    path,
    body,
    form,
    headers,
    parseJson = true,
    isFile = false,
    noAuth = false,
    isJson = true,
    noPrefix = false
} : ApiRequest) => {
    const hds = await buildDefaultHeaders(headers, noAuth)
    const response = await fetch((noPrefix ? '' : API_URL) + path, {
        method,
        headers: hds,
        body: (body) ? (isJson ? JSON.stringify(body) : body as string) : null
    })

    /*
    if (response.status === 401) {
        const loginUrl = process.env.NODE_ENV === 'production' ?
            process.env.REACT_APP_LOGIN_URL_PROD
            :
            process.env.REACT_APP_LOGIN_URL
        document.location.href = loginUrl || ''
    }
    */

    let data = null

    if (isFile) {
        return await response.blob()
    }

    if (parseJson) {
        try {
            data = await response.json()
        } catch (e) {
            console.log(path, e)
        }
    } else {
        return Promise.resolve(await response.text())
    }

    if (!response.ok) {
        return Promise.reject(data)
    }

    return Promise.resolve(data)
}
