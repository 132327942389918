import dayjs from "dayjs"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useMutation } from "react-query"
import { saveBooking } from "../../../shared/queries"
import { Booking } from "../../../types"
import Modal from "../../common/Modal"
import ModalActions from "../../common/ModalActions"
import ModalCard from "../../common/ModalCard"
import DatePickerInput from "../../form/DatePickerInput"
import FormField from "../../form/FormField"
import TextInput from "../../form/TextInput"
import {useTranslation} from "react-i18next";
import CountrySelector from "../selectors/CountrySelector"
import DateRangePicker from "../../form/DateRangePicker"

export default function UpdateBooking ({
    booking,
    visible = false,
    onClose
} : {
    visible: boolean,
    booking: Booking,
    onClose: () => void
}) {

    const { t } = useTranslation()

    const [bookingArrivalDate, setBookingArrivalDate] = useState<Date>(new Date())
    const [bookingDepartureDate, setBookingDepartureDate] = useState<Date>(dayjs().add(1, 'day').toDate())
    const [bookingCode, setBookingCode] = useState<string>('')
    const [bookingTotal, setBookingTotal] = useState<number>(0)
    const [bookingDate, setBookingDate] = useState<Date>(new Date())
    const [country, setCountry] = useState<number>()

    useEffect(() => {
        if (booking.bookingArrivalDate) {
            setBookingArrivalDate(dayjs(booking.bookingArrivalDate.toString(), 'YYYYMMDD').toDate())
        }
        setBookingDepartureDate(dayjs(booking.bookingDepartureDate.toString(), 'YYYYMMDD').toDate())
        setBookingDate(dayjs(booking.bookingDate.toString(), 'YYYYMMDDHHmmss').toDate())
        setBookingCode(booking.bookingCode)
        setBookingTotal(booking.bookingTotal)
        setCountry(booking.idCountry)
    }, [ booking ])

    const saveMutation = useMutation(() => saveBooking({
        ...booking,
        bookingArrivalDate: parseInt(dayjs(bookingArrivalDate).format('YYYYMMDD')),
        bookingDepartureDate: parseInt(dayjs(bookingDepartureDate).format('YYYYMMDD')),
        bookingCode,
        bookingDate: parseInt(dayjs(bookingDate).format('YYYYMMDD')),
        bookingStatus: booking.bookingStatus,
        bookingTotal,
        guests: [],
        isDeleted: booking.isDeleted,
        idCountry: country
    }), {
        onSuccess: data => {
            toast.success('Prenotazione modificata')
            onClose()
        }
    })

    return (
        <Modal
            visible={visible}>
            <ModalCard
                className={'w-full max-w-md'}
                actionsLoading={saveMutation.isLoading}
                renderActions={() => (
                    <ModalActions
                        onClose={onClose}
                        saveLabel={t('general.confirm')}
                        closeLabel={t('general.cancel')}
                        onSave={() => saveMutation.mutate()}></ModalActions>
                )}
                title={t('vary.editRes')}>

                <div className="p-4 flex flex-col space-y-4">
                    <div>
                        <FormField
                            errorKey={'bookingDate'}
                            className={'flex-1'}
                            label={t('adv.startService')}>
                            <DatePickerInput
                                mode={'single'}
                                value={bookingArrivalDate}
                                onChange={(val) => {
                                    if (val) {
                                        setBookingArrivalDate(val)
                                    }
                                }} />
                        </FormField>
                    </div>

                    <div>
                        <FormField
                            errorKey={'bookingDate'}
                            className={'flex-1'}
                            label={t('adv.endService')}>
                            <DatePickerInput
                                mode={'single'}
                                value={bookingDepartureDate}
                                onChange={(val) => {
                                    if (val) {
                                        setBookingDepartureDate(val)
                                    }
                                }} />
                        </FormField>
                    </div>

                    <div>
                        <FormField
                            className={'flex-1'}
                            label={t('vary.bookingDate')}>
                            <DatePickerInput
                                value={bookingDate}
                                onChange={(val) => {
                                    setBookingDate(val)
                                }} />
                        </FormField>
                    </div>

                    <FormField className={'flex-1'} label={t('vary.bookingCode')}>
                        <TextInput
                            value={bookingCode}
                            onChange={val => setBookingCode(val)} />
                    </FormField>

                    <FormField className={'flex-1'} label={t('vary.bookingTotal')}>
                        <TextInput
                            numbered={true}
                            value={bookingTotal}
                            onChange={val => setBookingTotal(val)} />
                    </FormField>

                    <FormField className={'flex-1'} label={t('adv.destination')}>
                        <CountrySelector
                            initialCountryId={country}
                            onSelect={c => {
                                setCountry(c.idCountry)
                            }}
                        />
                    </FormField>
                </div>
            </ModalCard>
        </Modal>
    )

}
