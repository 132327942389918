import { useMemo } from "react"
import { useAppSelector } from "../../hooks/redux"
import { formatCurrency } from "../../shared/utils"
import DetailRow from "../data/DetailRow"
import dayjs from "dayjs"

export default function QuoteConfirmationInfo () {

  const { quote } = useAppSelector(state => state.offerSlice)

  const acceptedQuote = useMemo(() => {
    return quote.options.find(o => o.idQuoteOption === quote.idQuoteOptionAccepted)
  }, [ quote ])

  if (!acceptedQuote) return <></>

  return (
    <div className="bg-white shadow-lg border rounded-md p-6 mx-auto w-full max-w-xl flex-col space-y-6">
      <div>
        <div className="text-lg text-slate-600 font-medium">Dettagli del cliente</div>
        <div className="mt-2 flex flex-col space-y-1">
          <DetailRow label="Nominativo">{quote.firstName} {quote.lastName}</DetailRow>
          <DetailRow label="Email">{quote.customerEmail}</DetailRow>
          <DetailRow label="Indirizzo">{quote.customerAddress}, {quote.customerCountryCode} {quote.customerZipCode}</DetailRow>
          <DetailRow label="Telefono">{quote.customerPhone}</DetailRow>
        </div>

        {
          (quote.besafeIncluded || (quote.insuranceCost && quote.insuranceCost > 0)) &&
          <div className="mt-4 border-t pt-4 text-orange-600">
            Questo preventivo gode della prenotazione BeSafe Rate. In caso di annullamento non perderai
            le somme prepagate.
          </div>
        }
      </div>

      <div className="text-lg text-slate-600 font-medium">Opzione accetta</div>
      {
        acceptedQuote.rooms.map((room, roomIndex) => (
          <div className="flex items-center space-x-4" key={roomIndex}>
            {
              (room.room.images) ?
                <div className="bg-cover bg-center rounded-md w-32 h-32" style={{
                  backgroundImage: `url(${room.room.images[0].imageUrl})`
                }} />
                :
                <div className="w-32 h-32" style={{
                  backgroundImage: `url()`
                }} />
            }

            <div className="text-slate-600 flex flex-col space-y-1">
              <div className="text-xl font-medium text-slate-600">{room.title}</div>
              <div className="flex space-x-2 items-center">
                <div>
                {dayjs(acceptedQuote.arrival.toString(), 'YYYYMMDD').format('DD/MM/YY')}
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                  </svg>
                </div>
                <div>
                  {dayjs(acceptedQuote.departure.toString(), 'YYYYMMDD').format('DD/MM/YY')}
                </div>
              </div>
              <div>{formatCurrency(room.price)} - {room.adults} Adulti, {room.children} Bambini</div>
            </div>
          </div>
        ))
      }

      <div>
        <div className="mb-4 text-lg text-slate-600 font-medium">Servizi dell'opzione</div>
        <div>
          {acceptedQuote.services.length === 0 && <div className="text-gray-600">
            Nessun servizio nell'offerta.
          </div>}
          {acceptedQuote.services.map((service, serviceIndex) => (
            <div key={serviceIndex}>
              <div className="flex space-x-2 text-slate-600">
                <div>{service.description}, {formatCurrency(service.price)} - {service.included ? 'Incluso nel prezzo' : service.payInHotel ? 'paga in hotel' : 'paga subito'}</div>
              </div>
              <div className="flex space-x-2 mt-1">
                <div className="bg-orange-100 text-orange-600 rounded-md px-1 text-sm font-medium">{service.perPerson && 'per persona'}</div>
                <div className="bg-orange-100 text-orange-600 rounded-md px-1 text-sm font-medium">{service.perNight && 'per notte'}</div>
              </div>
            </div>
          ))}
        </div>
      </div>


      {
          quote.besafeIncluded &&
          <div className="bg-orange-100 text-orange-700 p-4 rounded-md">
            <div className="font-medium text-lg mb-2">Il cliente è assicurato</div>
            <div>
              In caso di cancellazione di questo preventivo non dovrai preoccuparti di eventuali lamentele
              e /o richieste di rimborso. Puoi indirizzare il nostro cliente verso il nostro portale per i
              rimborsi <span><a className="underline font-medium" href="https://refundandgo.com" target={'_blank'}>Refundandgo</a></span>.
            </div>
          </div>
        }
    </div>
  )

}