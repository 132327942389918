import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getBookingPolicies } from "../../../shared/queries";
import { formatDate, getPolicyURL } from "../../../shared/utils";
import Chip from "../../common/Chip";
import BaseTable from "../../data/BaseTable";
import ReactTooltip from "react-tooltip";
import {Fragment, useState} from "react";
import { Booking, BookingPolicy, Policy } from "../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export default function BookingPoliciesTable ({
    policies,
    booking,
    loading
} : {
    policies: BookingPolicy[],
    booking: Booking,
    loading: boolean
}) {

    const { t } = useTranslation()

    const { profile } = useSelector((state: RootState) => state.session)

    const [loadingFile, setLoadingFile] = useState(false)

    function getCompanyLogo (companyCode: string) {
        switch (companyCode) {
            case 'AXA':
                return '/assets/guestportal/axalogo.jpg'
            case 'INTM':
                return '/assets/guestportal/intmlogo.png'
            case 'REVO':
                return 'https://besafesuite.com/besaferate/wp-content/uploads/sites/2/2021/09/cropped-Rate.png'
            case 'ERGO':
                return '/assets/guestportal/ergo.png'
            case 'EA':
                return '/assets/guestportal/europlogo.png'
            default:
                return '/assets/guestportal/axalogo.jpg'
        }
    }

    async function handleDownloadDynamicFile (policy: Policy) {
        try {
            setLoadingFile(true)
            let language = booking.guests.find(g => g.country.countryAlpha2 === 'IT') ? 'IT' : 'EN'
            await getPolicyURL(policy, booking.idBooking?.toString(), undefined, undefined, language)
        } catch (e) {
        } finally {
            setLoadingFile(false)
        }
    }

    return (
        <Fragment>
            <BaseTable
                showFilters={false}
                showColumnSelector={false}
                data={policies}
                loading={loading}
                paginated={false}
                filterable={false}
                identifierKey={'idBooking'}
                columns={(profile && profile.userAdmin) ? [
                    {
                        Header: t('bookingPolicy.policy').toString(),
                        accessor: row => (
                            <div className={'flex items-center space-x-2'}>
                                <div>
                                    <img
                                        className={'h-8 rounded-md'}
                                        src={getCompanyLogo(row.policy.policyCompany)}
                                        alt="Logo" />
                                </div>
                                <div className={'flex-1 text-ellipsis overflow-hidden'}>
                                    {row.policy.policyCode + ' - ' + row.policy.policyName}
                                    {
                                        (row.policy.dynamicFileUrl) ?
                                        <div>
                                            <button onClick={() => handleDownloadDynamicFile(row.policy)} className={'text-orange-600'}>
                                                {loadingFile ? 'Recupero il fascicolo personale...' : t('general.dowloadFile')}
                                            </button>
                                        </div>
                                        :
                                        <>
                                            <div className={'border-t pt-1 mt-1 space-x-2 flex'}>
                                                <a target={'_blank'} href={`https://be-safe-assets.s3.eu-west-1.amazonaws.com/insurances/${row.policy.policyCompany}_${row.policy.policyCode}_it.pdf`} className={'text-orange-600'}>{t('general.file')}</a>
                                                {
                                                    row.policy.policyCode === '67530082' &&
                                                    <a href={`https://be-safe-assets.s3.eu-west-1.amazonaws.com/insurances/voucher_winter_en.pdf`} className={'text-orange-600'}>Badge Neve</a>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        )
                    },
                    {
                        Header: t('bookingPolicy.processedAt').toString(),
                        accessor: row => (
                            <div>
                                {
                                    row.processedAt ?
                                        formatDate(row.processedAt.toString(), 'DD-MM-YYYY HH:mm:ss')
                                        :
                                        t('bookingPolicy.waitingToSend')
                                }
                            </div>
                        )
                    },
                    {
                        Header: t('bookingPolicy.status').toString(),
                        accessor: row => (
                            <div className={'flex space-x-2'}>
                                {
                                    row.hasErrors &&
                                    <div data-tip={row.errors}>
                                        <Chip className={'bg-red-100 bg-red-600'}>{t('bookingPolicyMsg.statusRejected')}</Chip>
                                    </div>
                                }
                                <div>
                                    {
                                        (!row.toReprocess) ?
                                            <Chip className={'text-white bg-blue-600'}>{t('bookingPolicyMsg.statusProcessed')}</Chip>
                                            :
                                            <Chip className={'text-orange-600 bg-orange-100'}>{t('bookingPolicyMsg.statusReprocess')}</Chip>
                                    }
                                </div>
                            </div>
                        )
                    }
                ] : [
                    {
                        Header: t('bookingPolicy.policy').toString(),
                        accessor: row => (
                            <div className={'flex items-center space-x-2'}>
                                <div>
                                    <img
                                        className={'h-8 rounded-md'}
                                        src={getCompanyLogo(row.policy.policyCompany)}
                                        alt="Logo" />
                                </div>
                                <div className={'flex-1 text-ellipsis overflow-hidden'}>
                                    {row.policy.policyCode + ' - ' + row.policy.policyName}
                                    {
                                        row.policy.dynamicFileUrl ?
                                        <>
                                            <button onClick={() => handleDownloadDynamicFile(row.policy)} className={'text-orange-600'}>
                                                {loadingFile ? 'Recupero il fascicolo personale...' : t('general.dowloadFile')}
                                            </button>
                                        </>
                                        :
                                        <>
                                            <div className={'border-t pt-1 mt-1 space-x-2 flex'}>
                                                <a target={'_blank'} href={`https://be-safe-assets.s3.eu-west-1.amazonaws.com/insurances/${row.policy.policyCompany}_${row.policy.policyCode}_it.pdf`} className={'text-orange-600'}>{t('general.file')}</a>
                                                {
                                                    row.policy.policyCode === '67530082' &&
                                                    <a href={`https://be-safe-assets.s3.eu-west-1.amazonaws.com/insurances/voucher_winter_en.pdf`} className={'text-orange-600'}>Badge Neve</a>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        )
                    }
                ]}
                />
            <ReactTooltip />
        </Fragment>
    )

}