import { useTranslation } from "react-i18next"
import Modal from "../components/common/Modal"
import ModalCard from "../components/common/ModalCard"
import ModalActions from "../components/common/ModalActions"
import { useMutation } from "react-query"
import { sendBillingAlert } from "../shared/queries"
import { useSelector } from "react-redux"
import { useState } from "react"
import toast from "react-hot-toast"
import FormField from "../components/form/FormField"
import TextInput from "../components/form/TextInput"

export default function InvoiceVerificationRequest ({ visible, onClose, selectedInvoices } : { selectedInvoices: number[], visible: boolean, onClose: () => void }) {

  const { t } = useTranslation()

  const currentHotelObject = useSelector<any>(state => state.hotels.currentHotelObject) as any

  const [verifyComment, setVerifyComment] = useState('')

  const billingAlertMutation = useMutation(() => sendBillingAlert(
    currentHotelObject.idAccomodation,
    currentHotelObject.accomodationName,
    verifyComment,
    selectedInvoices
  ), {
    onSuccess: () => {
      toast.success('Richiesta inviata')
      onClose()
    }
  })

  return (
    <Modal
      visible={visible}>
      <ModalCard
        title={t('invoices.verifyTitle')}
        renderActions={() => (
          <ModalActions
            onClose={onClose}
            isLoading={billingAlertMutation.isLoading}
            onSave={() => billingAlertMutation.mutate()} />
        )}>

        <div className="p-4 flex flex-col space-y-2">
          <p className={'text-gray-600'}>
            {t('invoices.text1', {
              length: selectedInvoices.length
            })}
          </p>

          <p className={'text-gray-600'}>
            {t('invoices.text2')}
          </p>

          <div className={'pt-4'}>
            <FormField
              required={true}
              label={t('invoices.verifyComment')}>
              <TextInput
                type={'textarea'}
                value={verifyComment}
                placeholder={t('invoices.verifyComment')}
                onChange={val => setVerifyComment(val)} />
            </FormField>
          </div>
        </div>

      </ModalCard>
    </Modal>
  )

}