import { useState } from "react";
import { QuoteOption } from "../../types";
import AddQuoteServiceModal from "./AddQuoteServiceModal";
import { formatCurrency } from "../../shared/utils";
import { useTranslation } from "react-i18next";

export default function QuoteServices ({ option } : { option: QuoteOption }) {

  const { t } = useTranslation()

  const [editService, setEditService] = useState<string | undefined>(undefined)
  const [addService, setAddService] = useState(false)

  return (
    <div>
      <AddQuoteServiceModal
        editService={editService}
        optionId={option.idQuoteOption}
        visible={addService}
        onClose={() => {
          setEditService(undefined)
          setAddService(false)
        }}
      />

      {
        (option.services.filter(o => !o.toDelete).length === 0) &&
        <div className="text-slate-600 mb-4">
          {t('quote.noSerivces')}
        </div>
      }

      {
        (option.services.filter(o => !o.toDelete).length > 0) &&
          <div className="mb-4">
          {
            option.services.filter(o => !o.toDelete).map((service, index) => (
              <div key={'serv' + index} className={`flex justify-between space-x-2 pt-2 ${(index < (option.services.length - 1)) ? 'pb-2 border-b' : ''}`}>
                <div className="flex flex-col space-y-1">
                  <div className="font-medium text-slate-700">{service.description}</div>
                  <div className="text-slate-600">({service.included ? 'incluso' : 'non incluso'}, {service.payInHotel ? 'paga in hotel' : 'paga subito'})</div>
                </div>
                <div className="flex space-x-2 items-center">
                  <div className="font-bold text-slate-700">{formatCurrency(service.price)}</div>
                  <button onClick={() => {
                    setEditService(service.idQuoteOptionService)
                    setAddService(true)
                  }} className="text-orange-600">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                    </svg>
                  </button>
                </div>
              </div>
            ))
          }
        </div>
      }

      <button onClick={() => {
        setAddService(true)
        setEditService(undefined)
      }} className="flex space-x-2 font-medium items-center text-orange-600">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
          <path fillRule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm.75-11.25a.75.75 0 0 0-1.5 0v2.5h-2.5a.75.75 0 0 0 0 1.5h2.5v2.5a.75.75 0 0 0 1.5 0v-2.5h2.5a.75.75 0 0 0 0-1.5h-2.5v-2.5Z" clipRule="evenodd" />
        </svg>
        <div>{t('quote.addService')}</div>
      </button>
    </div>
  )

}