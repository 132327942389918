import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { useSelector } from "react-redux"
import { getAccomodationPolicyRatesInternal, getAccomodationTags, saveBooking } from "../../../shared/queries"
import { RootState } from "../../../store"
import { Booking, Country } from "../../../types"
import Modal from "../../common/Modal"
import ModalActions from "../../common/ModalActions"
import ModalCard from "../../common/ModalCard"
import DatePickerInput from "../../form/DatePickerInput"
import FormField from "../../form/FormField"
import TextInput from "../../form/TextInput"
import SelectInput from "../../form/SelectInput"
import CountrySelector from "../selectors/CountrySelector"
import bookingSchema from "../../../validation/bookingSchema"
import toast from "react-hot-toast"
import InsuranceProductCard from "./InsuranceProductCard"

export default function CreateBooking ({
    visible,
    onClose,
    onSave
} : {
    visible: boolean,
    onClose: () => void,
    onSave: (data: Booking) => void
}) {

    const { t } = useTranslation()

    const currentHotel = useSelector<any>((state: RootState) => state.hotels.currentHotel)
    const currentHotelObject = useSelector((state: RootState) => state.hotels.currentHotelObject)
    const { profile } = useSelector((state: RootState) => state.session)

    const [errors, setErrors] = useState<any>([])

    const [bookingArrivalDate, setBookingArrivalDate] = useState<Date>(new Date())
    const [bookingDepartureDate, setBookingDepartureDate] = useState<Date>(dayjs().add(1, 'day').toDate())
    const [bookingCode, setBookingCode] = useState<string>('')
    const [bookingRate, setBookingRate] = useState<string>('MANUAL')
    const [tag, setTag] = useState<any>(undefined)
    const [bookingTotal, setBookingTotal] = useState<number>(0)
    const [totalCashed, setTotalCashed] = useState<number>(0)
    const [dateCashed, setDateCashed] = useState<Date>(new Date())
    const [pax, setPax] = useState<number>(0)
    const [bookingDate, setBookingDate] = useState<Date>(new Date())

    const [country, setCountry] = useState<Country>({
        idCountry: 1,
        countryAlpha2: 'ND',
        countryName: 'Unknown',
        isActive: true
    })
    const [guestEmail, setGuestEmail] = useState<string>('')
    const [guestFirstName, setGuestFirstName] = useState<string>('')
    const [guestLastName, setGuestLastName] = useState<string>('')

    const saveMutation = useMutation(() => saveBooking({
        idAccomodation: currentHotel as any,
        bookingArrivalDate: parseInt(dayjs(bookingArrivalDate).format('YYYYMMDD')),
        bookingDepartureDate: parseInt(dayjs(bookingDepartureDate).format('YYYYMMDD')),
        bookingCode,
        accomodationTagId: tag,
        bookingDate: parseInt(dayjs(bookingDate).format('YYYYMMDD')),
        bookingStatus: 'Reserved',
        bookingTotal,
        totalCashed,
        dateCashed: parseInt(dayjs(dateCashed).format('YYYYMMDDHHmm')),
        payments: [],
        guests: [{
            guestFirstName,
            guestLastName,
            isBooker: true,
            guestEmail,
            guestFiscalCode: '',
            country: country,
            idBooking: undefined
        }],
        pax,
        bookingRate: bookingRate || 'MANUAL',
        isDeleted: false
    }), {
        onSuccess: data => {
            onSave(data)
        },
        onError: (error: any) => {
            toast.error(error.message)
        }
    })

    async function handleSubmit () {
        const { error, value } = bookingSchema.validate({
            bookingArrivalDate: parseInt(dayjs(bookingArrivalDate).format('YYYYMMDD')),
            bookingDepartureDate: parseInt(dayjs(bookingDepartureDate).format('YYYYMMDD')),
            bookingCode,
            bookingDate: parseInt(dayjs(bookingDate).format('YYYYMMDD')),
            bookingTotal,
            totalCashed,
            pax,
            country,
            guestFirstName,
            guestLastName,
            guestEmail
        })
        console.log(error?.details)
        if (error) {
          setErrors(error)
          toast.error(t('general.missingFields'))
        } else {
            setErrors(null)
            saveMutation.mutate(value)
        }
    }

    const {
        data: internalRates
    } = useQuery(['internalRates', currentHotel], () => getAccomodationPolicyRatesInternal(currentHotel), {
        enabled: (currentHotel !== undefined && currentHotel !== null)
    })

    const {
        data: tags
    } = useQuery(['tags', currentHotel], () => getAccomodationTags(currentHotel))
  
    const products = useMemo(() => {
      return [
        {
          image: '/assets/guestportal/refund.svg',
          offImage: '/assets/guestportal/refund_gray.svg',
          key: 'MANUAL',
          label: 'BeSafe',
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage')
          ],
          includedPlus: (currentHotelObject && currentHotelObject.customerType !== 'AGENCY') ? [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ] : []
        },
        {
          image: '/assets/guestportal/refund.svg',
          offImage: '/assets/guestportal/refund_gray.svg',
          key: 'MANUAL_PLUS',
          label: 'BeSafe Plus',
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage')
          ],
          includedPlus: (currentHotelObject && currentHotelObject.customerType !== 'AGENCY') ? [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ] : []
        },
        {
          image: '/assets/guestportal/refund.svg',
          offImage: '/assets/guestportal/refund_gray.svg',
          key: 'CANCELLATION',
          label: 'BeSafe solo annullamento',
          included: [
            t('vary.cardRefund')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/guestportal/outdoor.svg',
          offImage: '/assets/guestportal/outdoor_gray.svg',
          key: 'WINTER',
          label: 'BeSafe Winter',
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage'),
            t('vary.rcSki')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/guestportal/summer.svg',
          offImage: '/assets/guestportal/summer_gray.svg',
          key: 'SUMMER',
          label: 'BeSafe Summer',
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage'),
            t('vary.rcOutdoor')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/sanitary.svg',
          offImage: '/assets/sanitary_gray.svg',
          key: 'SANITARY',
          label: 'BeSafe Flex',
          included: [
            t('vary.med'),
            t('vary.baggage'),
            t('Attivabile fino al check-in'),
            t('Attivabile su prenotazioni rimborsabili')
          ],
          includedPlus: []
        },
        {
          image: '/assets/guestportal/business.svg',
          offImage: '/assets/guestportal/business_gray.svg',
          key: 'BUSINESS',
          label: 'BeSafe Business',
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage'),
            t('vary.rcOutdoor')
          ],
          includedPlus: [
            t('vary.refund7days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/guestportal/refund.svg',
          offImage: '/assets/guestportal/refund_gray.svg',
          key: 'MANUAL_PLUS_INTERRUPTION',
          label: 'BeSafe Plus - Interruzione',
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage'),
            t('Interruzione viaggio')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/guestportal/rain.svg',
          offImage: '/assets/guestportal/rain.svg',
          key: 'BESAFE_RAIN',
          label: 'BeSafe Pioggia',
          included: [
            t('vary.rainRefund'),
            t('vary.parametricPolicy'),
          ],
          includedPlus: [
            t('vary.automaticRefund'),
            t('vary.noClaim'),
          ]
        }
      ]
    }, [])

    useEffect(() => {
      if (!internalRates) return
      const availableRates = internalRates.map(r => r.rateCode)
      if (availableRates.length > 0) {
        setBookingRate(availableRates[0])
      }
    }, [ internalRates ])

    function getMaxCashedDate () {
      // if admin no limit
      if (profile && profile.userAdmin) return undefined
      let permittedDays = 3
      if (currentHotelObject && currentHotelObject.customerType === 'AGENCY') {
        // if agency limit to 1 day
        permittedDays = 3
      } else {
        // for all the rest, limit to 30 days
        permittedDays = 30
      }
      return dayjs().subtract(permittedDays, 'day').toDate()
    }

    return (
        <Modal
            visible={visible}>
            <ModalCard
                className={`overflow-hidden w-full max-w-4xl`}
                actionsLoading={saveMutation.isLoading}
                renderActions={() => (
                    <ModalActions
                      isLoading={saveMutation.isLoading}
                      saveLabel={t('general.confirm')}
                      closeLabel={t('general.cancel')}
                      onClose={onClose}
                      onSave={() => handleSubmit()}
                    />
                )}>

                <div className="border-b overflow-hidden rounded-t-lg bg-gray-100 flex">
                    <button className={`transition-all text-lg p-4 py-5 w-full text-left border-b-4 border-orange-600 bg-white text-gray-700 font-medium`}>
                        {t('reservations.newReservation')}
                        <div className={`text-gray-700 font-normal text-sm`}>
                            {t('vary.addNewRes')}
                        </div>
                    </button>
                </div>

                <div className="mt-4 px-4 pb-4 flex flex-col space-y-4">
                  <p className={'text-gray-500'}>
                      {t('addBooking.message')}
                  </p>

                  {
                    (tags && tags.length > 0) &&
                    <div>
                      <div className="flex-1 z-50 relative">
                        <FormField
                          label={t('Tag')}>
                            <SelectInput
                              onChange={(item: any) => {
                                if (item) {
                                  setTag(item.value)
                                }
                              }}
                              options={tags ? tags.map(tag => {
                                return {
                                  label: tag.description,
                                  value: tag.idAccomodationTag
                                }
                              }) : []} />
                        </FormField>
                      </div>
                    </div>
                  }

                  <div className="flex space-x-4">
                      <div className={'flex-1'}>
                          <FormField
                            errors={errors}
                            errorKey={'bookingArrivalDate'}
                            label={t('general.arrival')}>
                              <DatePickerInput
                                mode={'single'}
                                fromDate={(profile && profile.userAdmin) ? undefined : new Date()}
                                value={bookingArrivalDate}
                                onChange={(val) => {
                                    if (val) {
                                        setBookingArrivalDate(val)
                                        setBookingDepartureDate(
                                          dayjs(val).add(1, 'days').toDate()
                                        )
                                    }
                                }} />
                          </FormField>
                      </div>

                      <div className={'flex-1'}>
                          <FormField
                            errors={errors}
                            errorKey={'bookingDepartureDate'}
                            label={t('general.departure')}>
                              <DatePickerInput
                                fromDate={dayjs(bookingArrivalDate).add(1, 'day').toDate()}
                                mode={'single'}
                                value={bookingDepartureDate}
                                onChange={(val) => {
                                    if (val) {
                                        setBookingDepartureDate(val)
                                    }
                                }} />
                          </FormField>
                      </div>
                  </div>

                  <div className="flex space-x-4">
                    <FormField
                        errors={errors}
                        errorKey={'bookingCode'}
                        className={'flex-1'}
                        label={t('reservation.reservationCode')}>
                        <TextInput
                            value={bookingCode}
                            onChange={val => setBookingCode(val)} />
                        <div className={'text-sm leading-relaxed font-normal w-full mt-2 text-gray-600'}>
                            {t('reservation.autoCode')}
                        </div>
                    </FormField>

                    <FormField
                        errors={errors}
                        errorKey={'bookingDate'}
                        className={'flex-1'}
                        label={t('reservations.reservationDate')}>
                            <DatePickerInput
                                mode={'single'}
                                fromDate={getMaxCashedDate()}
                                value={dateCashed}
                                onChange={(val) => {
                                    if (val) {
                                        setBookingDate(val)
                                    }
                                }} />
                    </FormField>
                  </div>

                  <div className="flex space-x-4">
                      <FormField
                        errors={errors}
                        errorKey={'pax'}
                        className={'flex-1'}
                        label={t('reservation.pax')}>
                          <TextInput
                            numbered
                            value={pax}
                            onChange={val => {
                                setPax(val)
                            }} />
                      </FormField>
                      <FormField
                        errors={errors}
                        errorKey={'bookingTotal'}
                        className={'flex-1'}
                        label={t('reservation.reservationTotal')}>
                          <TextInput
                            suffix={(currentHotelObject) ? currentHotelObject.currency : 'EUR'}
                            numbered={true}
                            value={bookingTotal}
                            onChange={val => {
                                setBookingTotal(val)
                            }} />
                      </FormField>
                  </div>

                  <div className="flex space-x-4">
                      <FormField
                        errors={errors}
                        errorKey={'totalCashed'}
                        className={'flex-1'}
                        label={t('reservation.dateCashed')}>
                          <DatePickerInput
                            mode={'single'}
                            fromDate={dayjs().subtract(30, 'day').toDate()}
                            value={dateCashed}
                            onChange={(val) => {
                                if (val) {
                                    setDateCashed(val)
                                }
                            }} />
                      </FormField>
                      <FormField
                        errors={errors}
                        errorKey={'totalCashed'}
                        className={'flex-1'}
                        label={t('reservation.totalCashed')}>
                          <TextInput
                            suffix={(currentHotelObject) ? currentHotelObject.currency : 'EUR'}
                            numbered={true}
                            value={totalCashed}
                            onChange={val => {
                                setTotalCashed(val)
                            }} />
                      </FormField>
                  </div>

                  <div className={'text-sm leading-relaxed font-normal w-full mt-2 text-gray-600'}>
                      {t('reservation.totalCashedInfo')}
                  </div>

                  <div className="text-lg pt-4 text-gray-600">
                      {t('vary.booker')}
                  </div>

                  <div className="flex space-x-4">
                      <FormField
                        errors={errors}
                        errorKey={'guestFirstName'}
                        className={'flex-1'}
                        label={t('createGuest.name')}>
                          <TextInput
                            value={guestFirstName}
                            onChange={val => setGuestFirstName(val)} />
                      </FormField>

                      <FormField
                        errors={errors}
                        errorKey={'guestLastName'}
                        className={'flex-1'}
                        label={t('createGuest.surname')}>
                          <TextInput
                            value={guestLastName}
                            onChange={val => setGuestLastName(val)} />
                      </FormField>
                  </div>

                  <FormField
                    label={t('createGuest.country')}
                    errors={errors}
                    errorKey={'country'}>
                      <CountrySelector
                        onSelect={c => setCountry(c)} />
                  </FormField>

                  <FormField
                    className={'flex-1'}
                    label={'Email'}
                    errors={errors}
                    errorKey={'guestEmail'}>
                      <TextInput
                        value={guestEmail}
                        onChange={val => setGuestEmail(val)} />
                  </FormField>

                  <>
                    <div className="text-lg text-gray-600">
                      {t('reservation.policyType')}
                    </div>

                    {
                      (!internalRates || internalRates?.length) === 0 &&
                      <div>
                        <div className="text-semibold text-slate-600">
                          {t('reservations.noProducts')}
                        </div>
                      </div>
                    }

                    <div className="grid gap-2 grid-cols-2">
                      {
                        products.filter(p => internalRates?.map(ir => ir.rateCode).includes(p.key)).map((p, i) => <InsuranceProductCard
                          product={p}
                          internalRates={internalRates || []}
                          onClick={rate => setBookingRate(rate)}
                          currentSelection={bookingRate}
                          key={'card-' + i}
                        />
                        )
                      }
                    </div>
                  </>
              </div>
            </ModalCard>
        </Modal>
    )

}
