import { Fragment, useEffect, useState } from "react"
import { EntityUpdateData } from "../../types"
import ModalCard from "./ModalCard"
import Modal from "./Modal"
import TextInput from "../form/TextInput"
import Checkbox from "../form/Checkbox"
import SelectInput from "../form/SelectInput"
import ModalActions from "./ModalActions"
import FormField from "../form/FormField"
import CurrencySelector from "../CurrencySelector"

export default function EntityUpdateDialog ({
  title,
  subTitle,
  data,
  fields,
  visible,
  loading = false,
  saveLabel = 'Save',
  closeLabel = 'Cancel',
  customRender,
  onDataChange,
  onClose,
  onSave
} : {
  title: string,
  subTitle?: string,
  fields: EntityUpdateData[],
  customRender?: any,
  data: any,
  loading?: boolean,
  visible: boolean,
  saveLabel?: string,
  closeLabel?: string,
  onDataChange?: (data: any) => void,
  onClose?: () => void,
  onSave?: (data: any) => void
}) {

  const [entityData, setEntityData] = useState<any>()

  /**
   * Set the base status of the object
   */
  useEffect(() => {
    if (data) {
      setEntityData(data)
    }
  }, [ data ])

  function renderControl (data: EntityUpdateData) {
    let value = (
      <TextInput
        type={data.inputType}
        value={entityData[data.field]}
        onChange={value => {
          setEntityData({
            ...entityData,
            [data.field]: value
          })
        }} />
    )

    switch (data.type) {
      case 'checkbox':
        value = (
          <Checkbox
            label={data.label}
            checked={entityData[data.field]}
            onChange={() => {
              setEntityData({
                ...entityData,
                [data.field]: !entityData[data.field]
              })
            }} />
        )
        break
      case 'currency':
        value = (
          <CurrencySelector initialValue={entityData[data.field]} onChange={value => {
            setEntityData({
              ...entityData,
              [data.field]: value.value
            })
          }} />
        )
        break
      case 'select':
        value = (
          <Fragment>
            <SelectInput
              menuShouldScrollIntoView={false}
              menuPortalTarget={document.body}
              styles={{
                container: (base: any) => ({ ...base, zIndex: 9999 }),
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
              }}
              onChange={(item: any) => {
                if (item) {
                  setEntityData({
                    ...entityData,
                    [data.field]: item.value
                  })
                }
              }}
              value={data.options ? data.options.find(o => o.value && entityData[data.field] && o.value.toString() === entityData[data.field].toString()) : undefined}
              options={data.options} />
          </Fragment>
        )
        break
    }

    return value
  }

  return (
    <Modal
      visible={visible}>
      <ModalCard
        className={'w-full max-w-lg'}
        title={title}
        subTitle={subTitle}
        actionsLoading={loading}
        renderActions={() => (
          <ModalActions
            isLoading={loading}
            saveLabel={saveLabel}
            closeLabel={closeLabel}
            onClose={onClose}
            onSave={() => {
              if (onSave) onSave(entityData)
            }} />
          )}>
          {
            entityData &&
            <div className={'p-4 flex-wrap flex -mx-2'}>
              {
                fields.map((data: EntityUpdateData, index: number) => {
                  if (data.condition) {
                    if (data.condition(entityData)) {
                      return (
                        <FormField
                          key={index}
                          className={'px-2 mb-4 w-full'}
                          label={data.noLabel ? undefined : data.label}>
                          {renderControl(data)}
                        </FormField>
                      )
                    }
                  } else {
                    return (
                      <FormField
                        key={index}
                        className={'px-2 mb-4 w-full'}
                        label={data.noLabel ? undefined : data.label}>
                        {renderControl(data)}
                      </FormField>
                    )
                  }
                })
              }

              <div className="px-2">
                { customRender && customRender() }
              </div>
            </div>
          }
        </ModalCard>
    </Modal>
  )

}