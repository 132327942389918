import { useSelector } from "react-redux";
import CompanyVerification from "../components/CompanyVerification";
import NavHeader from "../components/navigation/NavHeader";
import { RootState } from "../store";
import UnpaidInvoice from "../components/UnpaidInvoice";
import SepaIntro from "../components/quote/SepaIntro";

export default function InternalLayout ({
    children
} : {
    children: any
}) {

  const { profile } = useSelector((state: RootState) => state.session)
  const currentHotelObject = useSelector<any>(state => state.hotels.currentHotelObject) as any

  const NewLayout = () => (
    <div className="h-screen flex flex-col">
      { false && <SepaIntro /> }
      {false && <UnpaidInvoice />}
      {
        (currentHotelObject && currentHotelObject.verificationNeeded && (profile && !profile.userAdmin)) && <CompanyVerification />
      }
      {
        (profile && profile.userType !== 'PARTNER') && <div>
          <NavHeader />
        </div>
      }
      <div id={'content-container'} className="overflow-auto flex-1 bg-gray-50">
        {children}
      </div>
    </div>
  )

  return (
    <NewLayout />
  )

}
