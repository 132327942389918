import Skeleton from "react-loading-skeleton"
import Icon from "../common/Icon"

type DetailRowProps = {
  label: string,
  hint?: string,
  children: any,
  loading?: boolean
}

export default function DetailRow ({
  label,
  hint,
  children,
  loading
} : DetailRowProps) {

  return (
    <div className="flex flex-wrap space-x-6">
      <div className={'w-full mb-1 md:mb-0 md:w-52 xs:w-full text-gray-500'}>{label}</div>
      <div className={'flex-1 text-gray-800'}>
        {!loading && children}
        {loading && <Skeleton width={'10rem'}></Skeleton>}
        {
          hint &&
          <div className={'space-x-2 text-sm text-gray-600 flex font-normal mt-1'}>
            <div>
              <Icon name={'information-circle'} size={'20px'}></Icon>
            </div>
            <div>{hint}</div>
          </div>
        }
      </div>
    </div>
  )

}
