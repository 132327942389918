import Joi from "joi";
import { QuoteRoom } from "../types";

export default Joi.object<QuoteRoom, false>({
  idQuoteRoom: Joi.any(),
  title: Joi.any(),
  price: Joi.number().min(1).required(),
  adults: Joi.number().min(1).required(),
  children: Joi.number().min(0).required(),
  idRoom: Joi.any(),
  idQuote: Joi.any(),
  imageUrl: Joi.any(),
  description: Joi.any(),
  accepted: Joi.any(),
  room: Joi.any(),
  toDelete: Joi.any(),
})