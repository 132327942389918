import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
    getPartner,
    getPartnerQuarterReport,
    getPartnerReport
} from "../shared/queries";
import { RootState } from "../store";
import { fetchAccomodations } from "../store/slices/hotelsSlice";
import { useAppDispatch } from "../hooks/redux";
import { formatCurrency } from "../shared/utils";
import { PartnerReport } from "../types";
import PartnerHeader from "../components/partner/PartnerHeader";

const PartnerCard = ({ label, value } : { label: string, value: any }) => {
    return (
        <div className="border flex-1 p-4 bg-white rounded-md">
            <div className="text-slate-500 text-lg">{label}</div>
            <div className="font-bold mt-2 text-2xl text-slate-600">
                {value}
            </div>
        </div>
    )
}

const QuarterStatus = ({ paid } : { paid?: boolean }) => {
    
    if (paid) {
        return (
            <div className="text-green-600 mt-2">
                Pagato
            </div>
        )
    } else {
        return (
            <div className="text-yellow-600 mt-2">
                Attesa
            </div>
        )
    }

}

export default function PartnerDashboard () {

    const dispatch = useAppDispatch()
    const { profile } = useSelector((state: RootState) => state.session)
    const [year, setYear] = useState(2024)

    const {
        data: partner
    } = useQuery(['partner', profile], () => getPartner((profile && profile.partnerId) || 0), {
        enabled: (profile !== undefined && profile.partnerId !== null && profile.partnerId !== undefined)
    })

    const {
        data,
        refetch
    } = useQuery(['partnerReport', partner], () => getPartnerReport(profile?.partnerId || 0, 20240101, 20241231), {
        enabled: (profile !== null && profile !== undefined)
    })

    const {
        data: quarterReport,
        refetch: refetchQuarter
    } = useQuery(['partnerReportQuarter', profile], () => getPartnerQuarterReport(profile?.partnerId || 0, year), {
        enabled: (profile !== null && profile !== undefined)
    })

    useEffect(() => {
        dispatch(fetchAccomodations())
    }, [])

    function getQuarterTotal (idAccomodation: string, quarter: number) {
        if (!data || data.length === 0) return 0
        return data
            .filter(d => (d.accomodationName === idAccomodation) && d.quarter === quarter)
            .reduce((prev, curr) => prev + curr.totalCashed, 0)
    }

    function getListGrouped () {
        if (!data || data.length === 0) return []
        console.log(data)
        const result = [] as PartnerReport[]
        for (let i = 0; i < data.length; i++) {
            if (!result.find(r => r.accomodationName === data[i].accomodationName)) {
                result.push(data[i])
            }
        }
        return result
    }

    function getTotalCashback () {
        if (quarterReport) {
            return quarterReport.quarterOne + quarterReport.quarterTwo + quarterReport.quarterThree + quarterReport.quarterFour
        }
        return 0
    }

    return (
        <div className="max-w-6xl w-full mx-auto p-8">

            <PartnerHeader partner={partner} />

            <div className="flex flex-col space-y-8">
                <div className="flex space-x-4">
                    <div className="w-[60%] space-x-6 mx-auto flex items-center  bg-orange-600 rounded-xl p-6">
                        <div className="w-[35%] text-center">
                            <img className="w-full inline-block" src="/assets/dashboard.svg" alt="Analytics" />
                        </div>
                        <div className="flex-1">
                            <div className="text-white text-4xl font-bold">
                                Partner Dashboard
                            </div>
                            <div className="text-gray-100 text-xl font-light mt-2">
                                bentornato, questa è la tua partner dashboard per BeSafe Rate
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col space-y-4">
                        <div onClick={() => window.open('https://besafesuite.notion.site/96628b3f7a044383adbe7935a56cfe9c?v=78793b01550a4c8cadf0aa27145d2078&pvs=4', '_blank')} className="cursor-pointer p-4 flex overflow-hidden items-center bg-gray-100 hover:bg-orange-100 transition-all rounded-md flex-1">
                            <div className="flex-1">
                                <div className="text-slate-900 text-2xl font-bold">Marketing KIT</div>
                                <div className="text-slate-700 font-medium flex space-x-2">
                                    <div>accedi al tuo kit</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                        <path fillRule="evenodd" d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex-1 flex justify-end"><img src="/assets/marketing.svg" className="w-[80%] relative top-5" alt="" /></div>
                        </div>
                        <div onClick={() => window.open('https://besafesuite.notion.site/FAQs-d0e861a38d414e0a9edd9df53583c678', '_blank')} className="cursor-pointer p-4 flex overflow-hidden items-center bg-gray-100 hover:bg-orange-100 transition-all rounded-md flex-1">
                            <div className="flex-1 flex justify-start"><img src="/assets/faq.svg" className="w-[80%] relative top-5" alt="" /></div>
                            <div className="flex-1">
                                <div className="text-slate-900 text-2xl font-bold">FAQ</div>
                                <div className="text-slate-700 font-medium flex space-x-2">
                                    <div>scopri di più su BeSafe</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                        <path fillRule="evenodd" d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="font-semibold text-2xl text-slate-600">
                        Andamento corrente
                    </div>
                    <div className="text-slate-500 mt-1">
                        l'andamento corrente rappresenta il totale generato dalle strutture connesse. I valori sono soggetti a vairazioni
                        a seguito di modifiche alle prenotazioni o di annullamento delle stesse
                    </div>
                </div>

                <div className="flex space-x-4">
                    <PartnerCard label={'Strutture attive'} value={getListGrouped() ? getListGrouped().length : 0} />
                    <PartnerCard label={'Totale assicurato'} value={data ? formatCurrency(data.reduce((prev, curr) => prev + curr.totalCashed, 0)) : 0} />
                    <PartnerCard label={'Cashback'} value={partner?.commission + '%'} />
                    <PartnerCard label={'Totale cashback'} value={formatCurrency(getTotalCashback())} />
                </div>

                <div>
                    <div className="text-2xl font-medium text-slate-600">
                        Cashback - dati consolidati
                    </div>
                    <div className="text-slate-500 mt-1">
                        il totale viene mostrato alla fine di ogni trimestre ed è calcolato sull'effettivo ammontare fatturato alle strutture
                        al netto di eventuali storni
                    </div>
                </div>
                
                <div className="flex space-x-2">
                    <div className="flex-1 bg-white p-4 rounded-md border">
                        <div className="text-lg text-slate-600">Trimestre 1</div>
                        <div className="text-xl text-slate-700 font-semibold mt-2">
                            {quarterReport ? formatCurrency(quarterReport.quarterOne) : 'ND'}
                        </div>
                        <QuarterStatus paid={quarterReport?.paidQuarterOne} />
                    </div>
                    <div className="flex-1 bg-white p-4 rounded-md border">
                        <div className="text-lg text-slate-600">Trimestre 2</div>
                        <div className="text-xl text-slate-700 font-semibold mt-2">
                        {quarterReport ? formatCurrency(quarterReport.quarterTwo) :'ND'}
                        </div>
                        <QuarterStatus paid={quarterReport?.paidQuarterTwo} />
                    </div>
                    <div className="flex-1 bg-white p-4 rounded-md border">
                        <div className="text-lg text-slate-600">Trimestre 3</div>
                        <div className="text-xl text-slate-700 font-semibold mt-2">
                            {quarterReport ? formatCurrency(quarterReport.quarterThree) : 'ND'}
                        </div>
                        <QuarterStatus paid={quarterReport?.paidQuarterThree} />
                    </div>
                    <div className="flex-1 bg-white p-4 rounded-md border">
                        <div className="text-lg text-slate-600">Trimestre 4</div>
                        <div className="text-xl text-slate-700 font-semibold mt-2">
                            {quarterReport ? formatCurrency(quarterReport.quarterFour) : 'ND'}
                        </div>
                        <QuarterStatus paid={quarterReport?.paidQuarterFour} />
                    </div>
                </div>

                <div>
                    <div className="w-full flex-1 flex space-x-2 items-center justify-between">
                        <div>
                            <div className="text-2xl font-medium text-slate-600">
                                La produzione nel dettaglio
                            </div>
                            <div className="text-slate-500 mt-1">
                                Analisi della produzione per singola struttura connessa
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Struttura
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Q1
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Q2
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Q3
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Q4
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {data && getListGrouped().map((data: any, index: any) => (
                                        <tr key={index}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                S{data.accomodationName}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {formatCurrency(getQuarterTotal(data.accomodationName, 1))}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {formatCurrency(getQuarterTotal(data.accomodationName, 2))}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {formatCurrency(getQuarterTotal(data.accomodationName, 3))}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {formatCurrency(getQuarterTotal(data.accomodationName, 4))}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
