import {Popover} from "react-tiny-popover";
import {useState} from "react";
import {useQuery} from "react-query";
import {Booking} from "../../../types";
import {getBookingsById} from "../../../shared/queries";
import Skeleton from "react-loading-skeleton";
import {formatCurrency, formatDate} from "../../../shared/utils";
import Icon from "../../common/Icon";

export default function BookingPopoverDetail ({ idBooking, children } : { children: any, idBooking: number }) {

  const [show, setShow] = useState(false)

  const {
    data: booking
  } = useQuery<Booking, Error>(['booking', idBooking, show], () => getBookingsById(idBooking), {
    enabled: show
  })

  return (
    <Popover
      onClickOutside={() => setShow(false)}
      isOpen={show}
      align={'center'}
      content={
        <div
          className={'w-full max-w-md bg-white shadow-xl border rounded p-2'}
          onMouseLeave={() => setShow(false)}>
          {
            booking ? <div>
              <div className="text-gray-700 font-semibold mb-1">
                {booking.bookingCode}
              </div>
              {
                booking.guests.length > 0 &&
                  <div className={'text-gray-600 text-sm mb-1'}>
                    {booking.guests[0].guestFirstName}, {booking.guests[0].guestLastName}
                  </div>
              }
              <div className={'mb-1 flex space-x-2 text-sm text-gray-600'}>
                <div>
                  {formatDate(booking.bookingArrivalDate, 'DD/MM')}
                </div>
                <div className={'text-gray-500'}>
                  <Icon name={'arrow-sm-right'} size={'20px'} />
                </div>
                <div>
                  {formatDate(booking.bookingDepartureDate, 'DD/MM')}
                </div>
              </div>
              <div className="text-gray-600 text-sm mb-1">
                {formatCurrency(booking.bookingTotal ? booking.bookingTotal : 0)}
              </div>
            </div> : <Skeleton width={'10rem'} />
          }
        </div>
      }
    >
      <div onMouseLeave={() => setShow(false)} onMouseEnter={() => setShow(true)}>
        {children}
      </div>
    </Popover>
  )

}
