import Joi from "joi";
import { QuoteOption } from "../types";
import quoteRoomSchema from "./quoteRoomSchema";

export default Joi.object<QuoteOption>({
  idQuoteOption: Joi.any(),
  idQuote: Joi.any(),
  idAccomodation: Joi.any(),
  title: Joi.any(),
  toDelete: Joi.any(),
  arrival: Joi.number().required(),
  departure: Joi.number().required(),
  optionType: Joi.valid('ROOM', 'DESTINATION'),
  destination: Joi.any(),
  destinationTotal: Joi.number(),
  services: Joi.any(),
  rooms: Joi.array().min(1).items(
    quoteRoomSchema
  )
})