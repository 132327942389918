import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";
import DetailRow from "../components/data/DetailRow";
import Button from "../components/form/Button";
import FormSectionTitle from "../components/form/FormSectionTitle";
import NumberFormat from "react-number-format";
import Chip from "../components/common/Chip";
import BaseTable from "../components/data/BaseTable";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { getUser } from "../shared/queries";
import { Fragment } from "react";

export default function UserPage () {

    const { id } = useParams<{id: string}>()

    const {
        data,
        isFetching
    } = useQuery(['user', id], () => getUser(id))

    return (
        <PageWrapper centered maxWidth={'1000px'}>
            
            {
                data &&
                <Fragment>
                    <PageTitle
                        title={data.userFirstName + ' ' + data.userLastName} />

                    <FormSectionTitle
                        title={'Dettagli utente'} />

                    <div className="flex flex-col space-y-2">
                        <DetailRow
                            label={'Nome completo'}>
                            {data.userFirstName} {data.userLastName}
                        </DetailRow>
                        
                        <DetailRow
                            label={'Telefono'}>
                            {data.userPhone || 'ND'}
                        </DetailRow>

                        <DetailRow
                            label={'Promocode'}>
                            {data.userPromocode || 'ND'}
                        </DetailRow>

                        <DetailRow
                            label={'Agente'}>
                            {
                                data.userContactType === 1 ?
                                <Chip className={'bg-green-200 text-green-700'}>
                                    Si
                                </Chip>
                                :
                                <Chip className={'bg-red-200 text-red-700'}>
                                    No
                                </Chip>
                            }
                        </DetailRow>
                    </div>

                    <FormSectionTitle
                        title={'Dettagli accesso'} />

                    <DetailRow
                        label={'Email'}>
                        {data.userEmail}
                    </DetailRow>
                    
                    <FormSectionTitle
                        borderless={true}
                        title={'Strutture associate'}
                        edit={true}
                        editLabel={'Aggiungi struttura'} />

                    <BaseTable
                        filterable={false}
                        paginated={false}
                        columns={[
                            {
                                Header: 'Struttura',
                                accessor: 'accomodationName'
                            },
                            {
                                Header: 'Stato',
                                accessor: (row: any) => (
                                    row.isActive ? 
                                    <Chip className={'bg-green-200 text-green-700'}>
                                        Attivo
                                    </Chip>
                                    :
                                    <Chip className={'bg-red-200 text-red-700'}>
                                        Disattivo
                                    </Chip>
                                )
                            }
                        ]}
                        data={data.accomodations} />
                </Fragment>
            }

        </PageWrapper>
    )

}