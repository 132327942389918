import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";
import DetailRow from "../components/data/DetailRow";
import FormSectionTitle from "../components/form/FormSectionTitle";
import Chip from "../components/common/Chip";
import BaseTable from "../components/data/BaseTable";
import { useParams } from "react-router";
import { useMutation, useQuery } from "react-query";
import { getPolicy, getUser, savePolicy } from "../shared/queries";
import { Fragment } from "react";
import { useState } from "react";
import EntityUpdateDialog from "../components/common/EntityUpdateDialog";
import { Policy } from "../types";
import toast from "react-hot-toast";

export default function PolicyPage () {

    const { id } = useParams<{id: string}>()

    const [update, setUpdate] = useState<boolean>(false)

    const {
        data,
        isFetching,
        refetch
    } = useQuery(['policy', id], () => getPolicy(id))

    const updateMutation = useMutation((data: Policy) => savePolicy(data), {
        onSuccess: () => {
            toast.success('Polizza aggiornata')
            setUpdate(false)
            refetch()
        },
        onError: () => {
            toast.error('Impossibile aggiornare la polizza')
        }
    })

    return (
        <PageWrapper centered maxWidth={'1000px'}>
            
            {
                data &&
                <Fragment>
                    <PageTitle
                        title={data.policyCode + ' - ' + data.policyCompany} />

                    <FormSectionTitle
                        edit={true}
                        onEdit={() => setUpdate(true)}
                        title={'Dettagli polizza'} />
                    
                    <DetailRow
                        label={'Compagnia'}>
                        {data.policyCompany}
                    </DetailRow>

                    <DetailRow
                        label={'Descrizione'}>
                        {data.policyName}
                    </DetailRow>

                    <DetailRow
                        label={'Identificativo'}>
                        {data.policyCode}
                    </DetailRow>

                    <DetailRow
                        label={'Tipologia copertura'}>
                        {
                            data.policyIsSanitary ?
                            <Chip className={'text-green-600 bg-green-100'}>
                                Sanitaria
                            </Chip>
                            :
                            <Chip className={'text-blue-600 bg-blue-100'}>
                                Cancellazione
                            </Chip>
                        }
                    </DetailRow>

                </Fragment>
            }

            <EntityUpdateDialog
                fields={[
                    {
                        label: 'Identificativo',
                        value: '',
                        field: 'policyCode'
                    },
                    {
                        label: 'Compagnia',
                        value: '',
                        field: 'policyCompany'
                    },
                    {
                        label: 'Tipologia copertura',
                        value: '',
                        field: 'policyIsSanitary',
                        type: 'select',
                        options: [
                            { label: 'Cancellazione', value: false },
                            { label: 'Sanitaria', value: true }
                        ]
                    }
                ]}
                visible={update}
                onClose={() => setUpdate(false)}
                onSave={(data: any) => {
                    updateMutation.mutate(data)
                }}
                title={'Modifica polizza'}
                data={data} />

        </PageWrapper>
    )

}