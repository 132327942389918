import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CountrySelector from "../../components/business/selectors/CountrySelector";
import Button from "../../components/form/Button";
import FormField from "../../components/form/FormField";
import TextInput from "../../components/form/TextInput";
import { RootState } from "../../store";
import { deleteBookingGuest, fetchBookingWithPin, storeExternalGuest } from "../../store/slices/guestAreaSlice";
import { BookingGuest, Country } from "../../types";
import addGuestSchema from "../../validation/addGuestSchema";
import { useAppSelector } from "../../hooks/redux";

export default function AddGuestModal ({
    editGuest,
    onSave,
    onCancel
} : {
    editGuest?: BookingGuest,
    onSave: (guest: BookingGuest) => void,
    onCancel: () => void
}) {

    const { loading } = useAppSelector(state => state.guestArea)
    const { t } = useTranslation()

    const dispatch = useDispatch<any>()

    const [errors, setErrors] = useState<any>([])

    const { bookingDetails } = useSelector((state: RootState) => state.guestArea)

    const [country, setCountry] = useState<Country>({
        idCountry: 1,
        countryName: 'Unknown',
        countryAlpha2: 'ND',
        isActive: true
    })
    const [guestEmail, setGuestEmail] = useState<string>('')
    const [guestFirstName, setGuestFirstName] = useState<string>('')
    const [guestLastName, setGuestLastName] = useState<string>('')

    useEffect(() => {
        if (editGuest) {
            setGuestFirstName(editGuest.guestFirstName)
            setGuestLastName(editGuest.guestLastName)
            setGuestEmail(editGuest.guestEmail)
            setCountry(editGuest.country)
        }
    }, [
        editGuest
    ])

    async function handleSubmit () {
        const { error, value } = addGuestSchema.validate({
            idBooking: bookingDetails ? bookingDetails.booking.idBooking : 0,
            country,
            guestEmail,
            booker: false,
            guestFirstName,
            guestFiscalCode: '',
            guestLastName
        })
        if (error) {
            setErrors(error)
            toast.error(t('general.missingFields'))
        } else {
            setErrors(null)
            if (bookingDetails && bookingDetails.booking.idBooking) {
                const result = await dispatch(storeExternalGuest({
                    idBookingGuest: editGuest ? editGuest.idBookingGuest : undefined,
                    idBooking: bookingDetails.booking.idBooking,
                    country,
                    booker: editGuest ? editGuest.isBooker : false,
                    guestEmail,
                    guestFirstName,
                    guestFiscalCode: '',
                    guestLastName,
                }))

                if (storeExternalGuest.fulfilled.match(result)) {
                    toast.success('Ospite aggiunto con successo')
                    onSave(result.payload)
                } else {
                    toast.error('Impossibile creare l\'ospite')
                }
            }
        }
    }

    return (
        <div
            className={'flex flex-col items-center px-4 w-screen h-screen fixed top-0 left-0'}
            style={{ background: 'rgba(0,0,0,0.6)' }}>
            {
                bookingDetails &&
                <div className="mt-4 lg:mt-20 overflow-auto w-full max-w-lg rounded-md shadow-lg bg-white p-4">
                    <div className="text-xl">
                        {t('guestPage.addGuestTitle')}
                    </div>
                    <div className={'font-normal text-gray-600 mt-2'}>
                        {t('guestPage.addGuestText')}
                    </div>

                    <div className="mt-6 mb-6 flex flex-col space-y-4">
                        <div className="flex flex-wrap lg:flex-nowrap space-y-4  lg:space-y-0 lg:space-x-4">
                            <FormField
                                className={'w-full lg:w-1/2'}
                                label={t('createGuest.name')}
                                errors={errors}
                                errorKey={'guestFirstName'}>
                                <TextInput
                                    readonly={(editGuest && editGuest.isBooker) ? true : false}
                                    value={guestFirstName}
                                    onChange={val => setGuestFirstName(val)} />
                            </FormField>

                            <FormField
                                className={'w-full lg:w-1/2'}
                                label={t('createGuest.surname')}
                                errors={errors}
                                errorKey={'guestLastName'}>
                                <TextInput
                                    readonly={(editGuest && editGuest.isBooker) ? true : false}
                                    value={guestLastName}
                                    onChange={val => setGuestLastName(val)} />
                            </FormField>
                        </div>

                        {
                            (editGuest && editGuest.isBooker) &&
                            <div className="text-orange-600">
                                {t('vary.canNotEditGuest')} {t('vary.askHelp')}
                            </div>
                        }

                        <FormField
                            label={t('createGuest.country')}
                            errors={errors}
                            errorKey={'country'}>
                            <CountrySelector 
                                value={country}
                                onSelect={c => setCountry(c)} />
                        </FormField>

                        <FormField
                            className={'flex-1'}
                            label={'Email'}
                            errors={errors}
                            errorKey={'guestEmail'}>
                            <TextInput
                                value={guestEmail}
                                onChange={val => setGuestEmail(val)} />
                        </FormField>
                    </div>

                    <div className="mt-8 flex items-center space-x-2">
                        <Button
                            className={'text-white bg-orange-600'}
                            onClick={() => handleSubmit()}
                            label={loading ? '...' : t('general.confirm')} />
                        <Button
                            onClick={() => onCancel()}
                            className={'bg-red-100 text-red-600'}
                            label={loading ? '...' : t('general.cancel')} />
                    </div>

                </div>
            }

        </div>
    )

}
