import { useQuery } from "react-query"
import { listBookingInvoices } from "../../../shared/queries"
import { formatCurrency } from "../../../shared/utils"
import dayjs from "dayjs"
import InvoiceVerificationRequest from "../../../pages/InvoiceVerificationRequest"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export default function InvoicingTable ({ idBooking } : { idBooking: any }) {

  const { t } = useTranslation()
  const [showVerifyDialog, setShowVerifyDialog] = useState(false)

  const {
    data
  } = useQuery(['invoiceRows', idBooking],  () => listBookingInvoices(idBooking), {
    enabled: (idBooking !== null && idBooking !== undefined)
  })

  if (!data || data.length === 0) return <div className="overflow-auto bg-white card-shadow rounded relative p-4">
    {t('reservations.noBilling')}
  </div>

  return (
    <div className="overflow-auto bg-white card-shadow rounded relative p-4">
      <div className="flex flex-col">
        {
          data && data.map((invoice, invoiceKey) => (
            <div key={invoiceKey} className="flex space-x-4">
              <div className="flex flex-col">
                {
                  ((invoice.gross > 0 && invoice.type === 'invoice')) ?
                  <div className="text-green-600">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path fillRule="evenodd" d="M1 4a1 1 0 011-1h16a1 1 0 011 1v8a1 1 0 01-1 1H2a1 1 0 01-1-1V4zm12 4a3 3 0 11-6 0 3 3 0 016 0zM4 9a1 1 0 100-2 1 1 0 000 2zm13-1a1 1 0 11-2 0 1 1 0 012 0zM1.75 14.5a.75.75 0 000 1.5c4.417 0 8.693.603 12.749 1.73 1.111.309 2.251-.512 2.251-1.696v-.784a.75.75 0 00-1.5 0v.784a.272.272 0 01-.35.25A49.043 49.043 0 001.75 14.5z" clipRule="evenodd" />
                    </svg>
                  </div>
                  :
                  <div className="text-red-600">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path fillRule="evenodd" d="M4.93 1.31a41.401 41.401 0 0110.14 0C16.194 1.45 17 2.414 17 3.517V18.25a.75.75 0 01-1.075.676l-2.8-1.344-2.8 1.344a.75.75 0 01-.65 0l-2.8-1.344-2.8 1.344A.75.75 0 013 18.25V3.517c0-1.103.806-2.068 1.93-2.207zm4.822 4.997a.75.75 0 10-1.004-1.114l-2.5 2.25a.75.75 0 000 1.114l2.5 2.25a.75.75 0 001.004-1.114L8.704 8.75h1.921a1.875 1.875 0 010 3.75.75.75 0 000 1.5 3.375 3.375 0 100-6.75h-1.92l1.047-.943z" clipRule="evenodd" />
                    </svg>
                  </div>
                }
              </div>
              <div className="flex-1">
                <div className="mb-1 font-medium">{invoice.type === 'invoice' && <span>{(invoice.gross > 0) ? t('vary.charge') : t('vary.refund') }, </span>}{invoice.type === 'invoice' ? t('vary.invoice') : t('vary.creditNote')} <a className="underline text-orange-600" href={invoice.invoiceLink} target="_blank">{invoice.invoiceNumber}/{invoice.invoiceYear}</a></div>
                <div className="mb-1">{t('vary.amount')}: {formatCurrency(invoice.type === 'invoice' ? invoice.gross : invoice.gross * -1)}</div>
                <div className="mb-5">{t('vary.date')}: {dayjs(invoice.invoiceDate.toString(), 'YYYYMMDD').format('DD/MM/YYYY')}</div>
              </div>
            </div>
          ))
        }

        <div className="flex space-x-4">
          <div className="flex flex-col">
            <div className="text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
          <div className="flex-1">
            {t('vary.endEvents')}
          </div>
        </div>

        <div className="mt-6" onClick={() => setShowVerifyDialog(true)}>{t('vary.billingError')} <button className="text-orange-600 font-medium underline">{t('vary.billingWarning')}</button></div>

      </div>

      <InvoiceVerificationRequest visible={showVerifyDialog} onClose={() => setShowVerifyDialog(false)} selectedInvoices={[idBooking]} />
    </div>
  )

}