import Joi from 'joi'
import i18n from '../i18n/index'

export default Joi.object({
    bookingArrivalDate: Joi.any().required().messages({
        'any.required': i18n.t('validation.required')
    }),
    bookingDepartureDate: Joi.any().required().messages({
        'any.required': i18n.t('validation.required')
    }),
    bookingRate: Joi.any(),
    bookingCode: Joi.any(),
    bookingDate: Joi.any().required().messages({
        'any.required': i18n.t('validation.required')
    }),
    pax: Joi.any().required().messages({
        'any.required': i18n.t('validation.required')
    }),
    bookingTotal: Joi.any().required().messages({
        'any.required': i18n.t('validation.required')
    }),
    totalCashed: Joi.any().required().messages({
        'any.required': i18n.t('validation.required')
    }),
    guests: Joi.array().items({
        idBooking: Joi.any(),
        isBooker: Joi.boolean(),
        guestFirstName: Joi.string().required().messages({
            'string.required': i18n.t('validation.required'),
            'string.empty': i18n.t('validation.required')
        }),
        guestLastName: Joi.string().required().messages({
            'string.required': i18n.t('validation.required'),
            'string.empty': i18n.t('validation.required')
        }),
        guestEmail: Joi.string().required().messages({
            'string.required': i18n.t('validation.required'),
            'string.empty': i18n.t('validation.required'),
            'string.email': i18n.t('validation.invalidEmail')
        }),
        country: Joi.any().required().messages({
            'any.base': i18n.t('validation.required'),
            'any.required': i18n.t('validation.required'),
            'any.empty': i18n.t('validation.required')
        }),
        guestFiscalCode: Joi.any()
    }),
    idCountry: Joi.any()
})
