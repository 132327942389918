import { useQuery } from "react-query";
import { getBookingsById} from "../shared/queries";
import { Booking } from "../types";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {setCurrentHotel, setCurrentHotelObject} from "../store/slices/hotelsSlice";
import ReservationDetail from "../components/ReservationDetail";

export default function ReservationPage ({ id, onRefresh }: { id: any, onRefresh?: () => void }) {

    const dispatch = useDispatch()

    const {
        accommodations,
        currentHotel,
        currentHotelObject
    } = useSelector((state: RootState) => state.hotels)

    const {
        data
    } = useQuery<Booking, Error>(['booking', id], () => getBookingsById(id), {
        onError: () => {
            // TODO: addtoast
        }
    })

    useEffect(() => {
        if (!currentHotel && !currentHotelObject && data && accommodations && accommodations.length > 0) {
            const hotel = accommodations.find(a => a.idAccomodation === data.idAccomodation)
            if (hotel) {
                dispatch(setCurrentHotel(hotel.idAccomodation))
                dispatch(setCurrentHotelObject(hotel))
            }
        }
    }, [
        accommodations,
        data
    ])

    return (
        <div>
            <ReservationDetail id={id} onRefresh={onRefresh} />
        </div>
    )

}
