import { useEffect, useState } from 'react'
import Button from '../form/Button'
import {motion} from 'framer-motion'
import { useTranslation } from 'react-i18next'

export default function QuoteIntro () {

  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      const viewed = localStorage.getItem('viewQuoteIntro')
      if (!viewed) setVisible(true)
    }, 600)
  }, [])

  if (!visible) return <></>

  return (
    <div className="top-0 left-0 fixed w-screen h-screen" style={{ zIndex: 99999 }}>
      <motion.div initial={{  opacity: 0 }} animate={{ opacity: 1 }} className="absolute top-0 left-0 z-10 w-screen h-screen overflow-auto" style={{ background: 'rgba(0,0,0,0.6)' }}>
        <motion.div transition={{ delay: 0.2 }} initial={{ opacity: 0, top: 50 }} animate={{ opacity: 1, top: 0 }} className="relative overflow-hidden z-20 w-full mx-auto mt-12 max-w-xl bg-white rounded-md">
          <img className="w-full" src="https://media.giphy.com/media/aWPGuTlDqq2yc/giphy.gif" />
          <div className="p-4">
            <div className="text-2xl text-gray-700 font-bold">🌟 {t('quote.introTitle')}</div>
            <div className="text-gray-600 text-lg mt-4" dangerouslySetInnerHTML={{
              __html: t('quote.introText')
            }}>
            </div>
            <div className="mt-4">
              <Button label={t('quote.go')} className="text-white bg-orange-600" onClick={() => {
                localStorage.setItem('viewQuoteIntro', 'true')
                setVisible(false)
              }} />
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  )

}