import Icon from '../common/Icon'
import SelectInput from '../form/SelectInput'
import { useDispatch, useSelector } from 'react-redux'
import {fetchAccomodations, setCurrentHotel, setCurrentHotelObject} from '../../store/slices/hotelsSlice'
import { RootState } from '../../store'
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

export default function HotelSelector ({ isMulti = false, onChange, value } : { isMulti?: boolean, onChange?: any, value?: any }) {

    const { t } = useTranslation()

    const {
        currentHotel,
        accommodations,
        fetchingHotels
    } = useSelector((state: RootState) => state.hotels)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!accommodations || accommodations.length === 0) {
            console.log('load')
            dispatch(fetchAccomodations())
        }
    }, [])

    useEffect(() => {
        if (!currentHotel && accommodations && accommodations.length > 0) {
            dispatch(setCurrentHotel(accommodations[0].idAccomodation))
            dispatch(setCurrentHotelObject(accommodations[0]))
        }
    }, [ accommodations ])

    function getSelectedAccomodation () {
        if (currentHotel && accommodations) {
            const accomodation = accommodations.find(a => a.idAccomodation === currentHotel)
            if (accomodation) {
                return {
                    label: accomodation.accomodationName,
                    value: accomodation.idAccomodation
                }
            }
        }
        return undefined
    }

    return (
    <SelectInput
        value={value ? value : getSelectedAccomodation()}
        isLoading={fetchingHotels}
        isMulti={isMulti}
        options={accommodations ? accommodations.map(d => {
            return {
                label: d.accomodationName,
                value: d.idAccomodation,
                active: d.isActive
            }
        }) : []}
        components={{
            Option: ({innerProps, innerRef, data} : any) => (
                <button ref={innerRef} {...innerProps} className={'text-left w-full block py-2 flex items-center space-x-2 px-2 hover:bg-gray-100 cursor-pointer'}>
                    <div>
                        <div className={`w-3 h-3 ${data.active ? 'bg-green-600' : 'bg-red-600'} rounded-full`}></div>
                    </div>
                    <div className={'flex-1 truncate text-clip overflow-hidden text-sm text-gray-600 font-normal'}>{data.label}</div>
                </button>
            )
        }}
        placeholder={t('Seleziona struttura')}
        style={{
            cursor: 'pointer',
        }}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        styles={{
            menu: (provided: any) => ({ ...provided, zIndex: 99999 }),
            menuPortal: (provided: any) => ({ ...provided, zIndex: 99999 }),
            indicatorSeparator: (provided: any) => {},
            control: (provided: any) => ({
                ...provided,
                cursor: 'pointer',
                border: 0
            })
        }}
        onChange={onChange ? onChange : (item: any) => {
            dispatch(setCurrentHotel(item.value))
            const hotel = accommodations?.find(h => h.idAccomodation === item.value)
            if (hotel) dispatch(setCurrentHotelObject(hotel))
        }} />
    )

}
