import { useEffect, useState } from "react";
import Modal from "../common/Modal";
import ModalActions from "../common/ModalActions";
import ModalCard from "../common/ModalCard";
import FormField from "../form/FormField";
import TextInput from "../form/TextInput";
import Checkbox from "../form/Checkbox";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { addOptionService, updateOptionService } from "../../store/slices/offerSlice";
import { useTranslation } from "react-i18next";

export default function AddQuoteServiceModal ({ visible, onClose, optionId, editService } : { optionId: string, visible: boolean, onClose: () => void, editService?: string }) {

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { options, idAccomodation } = useAppSelector(state => state.offerSlice.quote)

  const [description, setDescription] = useState('')
  const [price, setPrice] = useState(0)
  const [included, setIncluded] = useState(false)
  const [payInHotel, setPayInHotel] = useState(false)
  const [perPerson, setPerPerson] = useState(false)
  const [perNight, setPerNight] = useState(false)

  useEffect(() => {
    const o = options.find(o => o.idQuoteOption === optionId)
    if (o) {
      const s = o.services.find(s => s.idQuoteOptionService === editService)
      if (s) {
        setDescription(s.description)
        setPrice(s.price)
        setIncluded(s.included)
        setPayInHotel(s.payInHotel)
        setPerPerson(s.perPerson)
        setPerNight(s.perNight)
      } else {
        setDescription('')
        setPrice(0)
        setIncluded(false)
        setPayInHotel(false)
        setPerPerson(false)
        setPerNight(false)
      }
    }
  }, [
    editService
  ])

  return (
    <Modal visible={visible}>
      <ModalCard
        renderActions={() => (
          <ModalActions
            isLoading={false}
            onSave={() => {
                if (editService) {
                  dispatch(updateOptionService({
                    idOption: optionId,
                    service: {
                      idQuoteOptionService: editService,
                      price,
                      perPerson,
                      perNight,
                      idAccomodation: idAccomodation,
                      idQuoteOption: optionId,
                      description,
                      included,
                      payInHotel,
                    }
                  }))
                  onClose()
                } else {
                  dispatch(addOptionService({
                    optionId: optionId,
                    service: {
                      price,
                      perPerson,
                      perNight,
                      idAccomodation: idAccomodation,
                      idQuoteOption: optionId,
                      description,
                      included,
                      payInHotel,
                    }
                  }))
                  onClose()
                }
            }}
            onClose={onClose}
          />
        )}
        className={'w-full max-w-xl'} title={t('quote.addService')}>
          <div className="p-4 pb-6 flex flex-col space-y-4">
            
            <div className="flex space-x-4">
              <FormField className={'flex-1'} label={'Nome'}>
                <TextInput value={description} onChange={val => setDescription(val)} />
              </FormField>

              <FormField className={'flex-1'} label={'Prezzo'}>
                <TextInput type={'currency'} value={price} onChange={val => setPrice(parseFloat(val))} />
              </FormField>
            </div>

            <Checkbox label="Incluso gratuitamente" checked={included} onChange={() => setIncluded(!included)} />
            <Checkbox label="Da pagare in hotel" checked={payInHotel} onChange={() => setPayInHotel(!payInHotel)} />

            <div className="font-medium text-slate-700">Logica di prezzo</div>
            <Checkbox label="Per persona" checked={perPerson} onChange={() => setPerPerson(!perPerson)} />
            <Checkbox label="Per notte" checked={perNight} onChange={() => setPerNight(!perNight)} />

          </div>

        </ModalCard>
    </Modal>
  )

}