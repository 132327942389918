import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { addOption } from "../../store/slices/offerSlice"
import QuoteOption from "./QuoteOptionRow"

export default function QuoteOptions () {

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { quote } = useAppSelector(state => state.offerSlice)

  if (quote.options.length === 0) return <>
    <button
      onClick={() => dispatch(addOption())}
      className="w-full border rounded-md border-dashed p-4 font-medium text-gray-600 hover:bg-orange-200 bg-gray-50">
      {t('quote.addProposal')}
    </button>
  </>

  return <>
    {
      quote.options.filter(o => !o.toDelete).map((option, index) => (
        <QuoteOption
          index={index}
          option={option}
          key={`quote-opt-${index}`}
        />
      ))
    }

    <div>
      <button
        onClick={() => {
          dispatch(addOption())
        }}
        className="px-2.5 py-1.5 inline-flex items-center gap-x-1.5 rounded-md bg-white  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        {t('quote.addOption')}
      </button>
    </div>
  </>

}