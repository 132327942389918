import { useTranslation } from "react-i18next"

export default function ApplicationLoading () {

    const { t } = useTranslation()

    return (
        <div className={'w-screen h-screen bg-white top-0 left-0 fixed z-50 flex items-center justify-center'}>
            <div className="flex flex-col items-center space-y-3">
                <img
                    style={{ height: '120px' }}
                    src="/assets/animatedlogo.gif" />
                
                <div>
                    <div className="bg-gray-200 text-sm font-medium text-gray-700 rounded-full px-4 py-2">
                        {t('vary.loadingInfo')}
                    </div>
                </div>
            </div>
        </div>
    )

}