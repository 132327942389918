import dayjs from "dayjs"
import {
  Accomodation,
  AccomodationApi,
  AccomodationRate,
  AccomodationSettings,
  Address,
  ApiChannel,
  ApiUser,
  Audit,
  Booking,
  BookingGuest,
  BookingGuestDetails,
  BookingPayment,
  BookingPolicy,
  BusinessInformation,
  ChannelStatus,
  Company,
  CompanyProduct,
  Country,
  CreateAccomodationRequest,
  CreateCompanyRequest, ExportRequest,
  FeeReport, Invoice, MonthReport,
  OnboardingRequest,
  PaginatedResponse,
  Policy,
  PolicyMap,
  PolicyRate,
  ProductionReport,
  Quote,
  ReportRequest,
  RequestFilters,
  RestCriteria,
  ResyncRequest,
  Room,
  SignupRequest,
  OpportunityReport,
  User,
  Group,
  CreateGroup,
  AccomodationTag,
  DashboardReport,
  BookingInvoiceRow,
  Promocode,
  QuoteCustomer,
  CancellationPolicy,
  RoomImage,
  AccomodationInfo,
  AccomodationAmenity,
  QuoteKanbanEvent,
  Partner,
  PartnerReport,
  PartnerQuarterReport,
  AccomodationGateway,
  InsuranceFile,
  BillingReport,
  UnpaidInvoices,
  OpenClaimRequest,
  OpenClaimResponse,
  ClaimDetail,
  InsuranceData,
  InsuranceJobResponse,
  InsuranceJobStatusResponse,
  SetupPaymentMethodResponse,
  AccomodationBilling,
  PaymentMethod
} from "../types"
import { request } from "./api"

export async function getUsers () {
  const responseData = await request({
      method: 'GET',
      path: '/API/users/list'
  })
  return responseData as User[]
}

export async function getDecodedBooking (code: string, accessPin: string, marketingConsent: boolean) {
  const responseData = await request({
      method: 'POST',
      noAuth: true,
      path: '/API/ext/booking/decode',
      body: {
        code: decodeURIComponent(code),
        accessPin,
        marketingConsent
      }
  })
  return responseData as BookingGuestDetails
}

export async function submitBookingClaim (code: string, claimRequest: OpenClaimRequest) {
  const responseData = await request({
      method: 'POST',
      noAuth: true,
      path: `/API/ext/claims/submit`,
      body: {
        ...claimRequest,
        code: decodeURIComponent(code)
      }
  })
  return responseData as OpenClaimResponse
}

export async function getClaimDetail (code: string) {
  const responseData = await request({
      method: 'GET',
      noAuth: true,
      path: `/API/ext/claims/retrieve?encryptedBookingId=${code}`,
  })
  return responseData as ClaimDetail
}

export async function getPolicies () {
  const responseData = await request({
      method: 'GET',
      path: '/API/policies/list'
  })
  return responseData as Policy[]
}

export async function getUserById (idUser: string | number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/users/' + idUser
  })
  return responseData as User
}

export async function getUsersByAccomodation (idAccomodation: string) {
  const responseData = await request({
      method: 'GET',
      path: '/API/users/accomodation/' + idAccomodation
  })
  return responseData as User[]
}

export async function getUser (idUser: string) {
  const responseData = await request({
      method: 'GET',
      path: '/API/users/' + idUser
  })
  return responseData as User
}

export async function getPolicy (id: string) {
  const responseData = await request({
      method: 'GET',
      path: '/API/policies/' + id
  })
  return responseData as Policy
}

export async function getAccomodations () {
  const responseData = await request({
      method: 'GET',
      path: '/API/accomodations/list'
  })
  return responseData as Accomodation[]
}

export async function getAccomodationById (id: string|number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/accomodations/' + id
  })
  return responseData as Accomodation
}

export async function getFilteredBookings (filters: RequestFilters) : Promise<PaginatedResponse<Booking>> {
  const responseData = await request({
      method: 'POST',
      path: '/API/bookings/filtered',
      body: filters
  })
  return responseData as PaginatedResponse<Booking>
}

export async function  getQueriedReservations<T> (idAccomodation: string | number, page: number, maxResults: number, orderBy: string, filters: RestCriteria[], sortType: string) : Promise<PaginatedResponse<T>> {
  const responseData = await request({
      method: 'POST',
      path: '/API/bookings/' + idAccomodation + '/query',
      body: {
        criterias: filters,
        pageOffset: page,
        maxResults: maxResults,
        orderBy,
        sortType
      }
  })
  return responseData as PaginatedResponse<T>
}

export async function  getQueriedQuotes (idAccomodation: string | number, page: number, maxResults: number, orderBy: string, filters: RestCriteria[], sortType: string) : Promise<PaginatedResponse<Quote>> {
  const responseData = await request({
      method: 'POST',
      path: '/API/quotes/' + idAccomodation + '/query',
      body: {
        criterias: filters,
        pageOffset: page,
        maxResults: maxResults,
        orderBy,
        sortType
      }
  })
  return responseData as PaginatedResponse<Quote>
}

export async function  exportReservations<T> (
  reportFilters: ExportRequest
) : Promise<PaginatedResponse<T>> {
  return request({
    method: 'POST',
    path: '/API/bookings/export',
    isFile: true,
    body: {
      year: reportFilters.year,
      accomodations: reportFilters.accomodations,
      columns: reportFilters.columns,
      filters: reportFilters.filters
    }
  })

}

export async function  getQueryableResource<T> (resource: string, page: number, maxResults: number, orderBy: string, filters: RestCriteria[], sortType?: string) : Promise<PaginatedResponse<T>> {
  const responseData = await request({
      method: 'POST',
      path: '/API/' + resource + '/query',
      body: {
        criterias: filters,
        pageOffset: page,
        maxResults: maxResults,
        orderBy,
        sortType: sortType ? sortType : 'ASC'
      }
  })
  return responseData as PaginatedResponse<T>
}

export async function getBookingsByAccomodation (accomodationId: string | number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/bookings/accomodation/' + accomodationId
  })
  return responseData as Booking[]
}

export async function getBookingsById (bookingId: string | number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/bookings/' + bookingId
  })
  return responseData as Booking
}

export async function getChannelStatus () {
  const responseData = await request({
      method: 'GET',
      path: '/API/channelStatus/list'
  })
  return responseData as ChannelStatus[]
}

export async function saveResyncRequest (data: ResyncRequest) {
  const responseData = await request({
    method: 'POST',
    path: '/API/channelStatus/resync-request',
    body: data
  })
  return responseData as ResyncRequest
}

export async function getResyncRequest (id: string) {
  const responseData = await request({
    method: 'GET',
    path: '/API/channelStatus/resync-request/' + id,
  })
  return responseData as ResyncRequest
}

export async function getChannelStatusFiltered (filters: RequestFilters) {
  const responseData = await request({
      method: 'POST',
      path: '/API/channelStatus/filtered',
      body: filters
  })
  return responseData as ChannelStatus[]
}

export async function getProcessedBookings (
  idCompany: string | number,
  fromDate: string | number,
  toDate: string | number
) {
  const responseData = await request({
      method: 'GET',
      path: `/API/bookings/processed/${idCompany}/${fromDate}/${toDate}`
  })
  return responseData as Booking[]
}

export async function getAccomodationApiChannels (idAccomodation: string | number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/accomodationApi/accomodation/' + idAccomodation
  })
  return responseData as AccomodationApi[]
}

export async function saveAccomodationApiChannel (accomodationApi: AccomodationApi) {
  const responseData = await request({
      method: 'POST',
      path: '/API/accomodationApi',
      body: accomodationApi
  })
  return responseData as AccomodationApi
}

export async function getAccomodationPolicyRate (idAccomodation: string | number | undefined) {
  const responseData = await request({
      method: 'GET',
      path: '/API/accomodationsRates/accomodation/' + idAccomodation
  })
  return responseData as AccomodationRate[]
}

export async function getChannelStatusById (idAccomodationApi: string | number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/channelStatus/accomodationApi/' + idAccomodationApi
  })
  return responseData as ChannelStatus
}

export async function saveAccomodation (accomodation: CreateAccomodationRequest) {
  const responseData = await request({
      method: 'POST',
      path: '/API/accomodations',
      body: accomodation
  })
  return responseData as Accomodation
}

export async function getCompanies () {
  const responseData = await request({
      method: 'GET',
      path: '/API/companies/list'
  })
  return responseData as Company[]
}

export async function saveCompany (company: CreateCompanyRequest) {
  const responseData = await request({
      method: 'POST',
      path: '/API/companies',
      body: company
  })
  return responseData as Company
}

export async function getAuditByType (type: string, id: number) {
  const responseData = await request({
    method: 'GET',
    path: `/API/audits/${type}/${id}`
  })
  return responseData as Audit[]
}


export async function saveAccomodationRate (rate: AccomodationRate) {
  const responseData = await request({
      method: 'POST',
      path: '/API/accomodationsRates',
      body: rate
  })
  return responseData as AccomodationRate
}

export async function deleteAccomodationRate (id: any) {
  const responseData = await request({
    method: 'DELETE',
    path: '/API/accomodationsRates/' + id
  })
  return responseData as AccomodationRate
}

export async function getAccomodationRate (id: string | number | undefined) {
  const responseData = await request({
      method: 'GET',
      path: '/API/accomodationsRates/' + id
  })
  return responseData as AccomodationRate
}

export async function updateCompany (id: string|number, company: CreateCompanyRequest) {
  const responseData = await request({
      method: 'PATCH',
      path: '/API/companies/' + id,
      body: company
  })
  return responseData as Company
}

export async function updateCompanyData (company: Company) {
  const responseData = await request({
    method: 'POST',
    path: '/API/companies',
    body: company
  })
  return responseData as Company
}


export async function deleteCompany (id: string | number) {
  const responseData = await request({
      method: 'PATCH',
      path: '/API/companies/' + id
  })
  return responseData as Company
}

export async function deleteHotel (id: string | number) {
  const responseData = await request({
      method: 'PATCH',
      path: '/API/accomodations/' + id
  })
  return responseData as Accomodation
}

export async function getCountries () {
  const responseData = await request({
      method: 'GET',
      noAuth: true,
      path: '/API/countries/list'
  })
  return responseData as Country[]
}

export async function getAddresses () {
  const responseData = await request({
      method: 'GET',
      path: '/API/addresses/list'
  })
  return responseData as Address[]
}

export async function getAddress (id: any) {
  const responseData = await request({
    method: 'GET',
    path: `/API/addresses/${id}`
  })
  return responseData as Address
}

export async function saveAddress (address: Address) {
  const responseData = await request({
      method: 'POST',
      path: '/API/addresses',
      body: address
  })
  return responseData as Address
}

export async function saveBookingPayment (payment: BookingPayment) {
  const responseData = await request({
      method: 'POST',
      path: '/API/payments',
      body: payment
  })
  return responseData as BookingPayment
}

export async function deleteBookingGuest (id: string | number) {
  const responseData = await request({
      method: 'DELETE',
      path: '/API/guests/' + id
  })
  return responseData as BookingPayment
}

export async function deleteBookingPayment (id: string | number) {
  const responseData = await request({
      method: 'DELETE',
      path: '/API/payments/' + id
  })
  return responseData as BookingPayment
}

export async function getBookingPayments (idBooking: string | number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/payments/booking/' + idBooking
  })
  return responseData as BookingPayment[]
}

export async function getProductionReport (data: ReportRequest) {
  const responseData = await request({
      method: 'POST',
      path: '/API/reports/production',
      body: data
  })
  return responseData as ProductionReport
}

export async function getFeeReport (data: ReportRequest) {
    const responseData = await request({
        method: 'POST',
        path: '/API/reports/fee',
        body: data
    })
    return responseData as FeeReport
}

export async function getMonthReport (data: ReportRequest) {
    const responseData = await request({
        method: 'POST',
        path: '/API/reports/month',
        body: data
    })
    return responseData as MonthReport[]
}

export async function getPendingReport () {
  const responseData = await request({
    method: 'GET',
    path: '/API/bookings/pending',
    isFile: true
  })
  return responseData as MonthReport[]
}

export async function getChannelList () {
  const responseData = await request({
      method: 'GET',
      path: '/API/apiChannels/list'
  })
  return responseData as ApiChannel[]
}

export async function saveApiChannel (data: ApiChannel) {
  const responseData = await request({
      method: 'POST',
      path: '/API/apiChannels',
      body: data
  })
  return responseData as ApiChannel
}

export async function saveApiChannelConfiguration (data: ApiChannel) {
  const responseData = await request({
    method: 'POST',
    path: '/API/apiChannels/save-configuration/' + data.idApiChannel,
    body: data
  })
  return responseData as ApiChannel
}

export async function saveBooking (booking: Booking) {
  const responseData = await request({
      method: 'POST',
      path: '/API/bookings',
      body: booking
  })
  return responseData as Booking
}

export async function deleteBooking (id: number | string, reason: string, comment: string) {
  const responseData = await request({
    method: 'DELETE',
    path: '/API/bookings/' + id,
    body: {
      reason,
      comment
    }
  })
  return responseData as Booking
}

export async function getBillableBookings (id: number | string) {
  const responseData = await request({
    method: 'GET',
    path: '/API/bookings/billable/' + id
  })
  return responseData as BillingReport
}

export async function resendBookingMail (idAccomodation: number | string, idBooking: number | string) {
    const responseData = await request({
        method: 'POST',
        path: '/API/bookings/resend/' + idAccomodation + '/' + idBooking
    })
    return responseData as Booking
}

export async function getGeuest (guestId: string | number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/guests/' + guestId
  })
  return responseData as BookingGuest
}

export async function getPayment (paymentId: string | number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/payments/' + paymentId
  })
  return responseData as BookingPayment
}

export async function saveGuest (guest: BookingGuest) {
  const responseData = await request({
      method: 'POST',
      path: '/API/guests',
      body: guest
  })
  return responseData as BookingGuest
}

export async function saveExternalGuest (code: string, pin: string, guest: BookingGuest) {
  const responseData = await request({
      method: 'POST',
      path: `/API/ext/booking/decode/guests`,
      body: {
        code: decodeURIComponent(code),
        accessPin: pin,
        bookingGuest: guest
      }
  })
  return responseData as BookingGuest
}

export async function deleteExternalGuest (code: string, pin: string, idBookingGuest: number) {
  const responseData = await request({
      method: 'DELETE',
      path: `/API/ext/booking/decode/guests/${idBookingGuest}`,
      body: {
        code: decodeURIComponent(code),
        accessPin: pin
      }
  })
  return responseData as BookingGuest
}


export async function getCompanyById (id: string|number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/companies/' + id
  })
  return responseData as Company
}

export async function savePolicy (policy: Policy) {
  const responseData = await request({
      method: 'POST',
      path: '/API/policies',
      body: policy
  })
  return responseData as Policy
}

export async function getAccomodationPolicyRates (idAccomodation: string | number) {
  const responseData = await request({
      method: 'GET',
      path: '/API/policyRates/accomodation/' + idAccomodation
  })
  return responseData as PolicyMap
}

export async function getAccomodationInvoices (idAccomodation: string | number, from: any, to: any) {
  const responseData = await request({
    method: 'GET',
    path: `/API/invoices/accomodation/${idAccomodation}/${dayjs(from).format('YYYYMMDD')}/${dayjs(to).format('YYYYMMDD')}`
  })
  return responseData as Invoice
}

export async function getAccomodationUnpaidInvoices (idAccomodation: string | number) {
  const responseData = await request({
    method: 'GET',
    path: `/API/invoices/accomodation/${idAccomodation}/unpaid`
  })
  return responseData as UnpaidInvoices
}

export async function getAccomodationPolicyRatesInternal (idAccomodation: any) {
  const responseData = await request({
      method: 'GET',
      path: '/API/policyRates/accomodation/' + idAccomodation + '/list/internal'
  })
  return responseData as PolicyRate[]
}


export async function saveAccomodationPolicyRate (data: PolicyRate) {
  const responseData = await request({
      method: 'POST',
      path: '/API/policyRates',
      body: data
  })
  return responseData as PolicyRate
}

export async function deleteAccomodationPolicyRate (idPolicyRate: string | number) {
  const responseData = await request({
      method: 'DELETE',
      path: '/API/policyRates/' + idPolicyRate,
  })
  return responseData as PolicyRate
}

export async function getApiUsers () {
  const responseData = await request({
      method: 'GET',
      path: '/API/basicAuth/list'
  })
  return responseData as ApiUser[]
}

export async function saveUser (data: ApiUser) {
  const responseData = await request({
      method: 'POST',
      path: '/API/basicAuth/',
      body: data
  })
  return responseData as ApiUser
}

export async function getProfile () {
  const responseData = await request({
      method: 'GET',
      path: '/API/authorizationServer/profile'
  })
  return responseData as User
}

export async function updateProfile (data: User) {
  const responseData = await request({
      method: 'PATCH',
      path: '/API/authorizationServer/updateProfile',
      body: data
  })
  return responseData as ApiUser
}

export async function getCompanyProducts (idCompany: number | string) {
  const responseData = await request({
      method: 'GET',
      path: '/API/companyProducts/company/' + idCompany + '/list'
  })
  return responseData as CompanyProduct[]
}

export async function getBookingPolicies (idBooking: number | string) {
  const responseData = await request({
      method: 'GET',
      path: '/API/booking-policies/booking/' + idBooking
  })
  return responseData as BookingPolicy[]
}

export async function activateProduct (data: CompanyProduct, type: 'rate' | 'pay', lang: string) {
  const responseData = await request({
      method: 'POST',
      path: '/API/companyProducts/activate/' + type + '/' + lang,
      body: data
  })
  return responseData as CompanyProduct
}

export async function signup (data: SignupRequest) {
  const responseData = await request({
      method: 'POST',
      path: '/API/authorizationServer/signup',
      body: data,
      noAuth: true
  })
  return responseData as any
}

export async function initOnboarding (data: OnboardingRequest) {
  const responseData = await request({
      method: 'POST',
      path: '/API/onboarding/init',
      body: data
  })
  return responseData as Accomodation
}

export async function toggleOpportuntiy (id: any) {
  const responseData = await request({
      method: 'POST',
      path: `/API/accomodations/opportunity-toggle/${id}`
  })
  return responseData as Accomodation
}

export async function changePassword (password: string) {
  const responseData = await request({
      method: 'PATCH',
      path: '/API/authorizationServer/changePassword',
      body: {
        newPassword: password
      }
  })
  return responseData as any
}

export async function getBusinessInformation (countryCode: string, identifier: string) {
  const responseData = await request({
      method: 'GET',
      path: '/API/onboarding/business-info/' + countryCode + '/' + identifier.trim()
  })
  return responseData as BusinessInformation
}

export async function associateUserToAccomodation (idUser: string | number, idAccomodation: string | number) {
  const responseData = await request({
      method: 'POST',
      path: '/API/users/accomodation/associate/' + idUser + '/' + idAccomodation
  })
  return responseData as User
}

export async function removeUserFromAccomodation (idUser: string | number, idAccomodation: string | number) {
  const responseData = await request({
      method: 'POST',
      path: '/API/users/accomodation/remove/' + idUser + '/' + idAccomodation
  })
  return responseData as User
}

export async function createUser (user: any) {
  const responseData = await request({
      method: 'POST',
      path: '/API/users/create',
      body: user
  })
  return responseData as User
}

export async function searchIndex (query: string) {
  const responseData = await request({
    method: 'GET',
    path: '/API/bookings/index/search?query=' + query
  })
  return responseData as any
}

export async function sendBillingAlert (idAccommodation: number, hotelName: string, message: string, idBookings: number[]) {
  const responseData = await request({
    method: 'POST',
    path: `/API/bookings/billing-alert/${idAccommodation}`,
    body: {
      hotelName,
      message,
      idBookings
    }
  })
  return responseData as any
}

export async function getQuote (quoteId: string | number) {
  const responseData = await request({
    method: 'GET',
    path: '/API/quotes/' + quoteId
  })
  return responseData as Quote
}

export async function saveQuote (quote: Quote) {
  const responseData = await request({
    method: 'POST',
    path: '/API/quotes',
    body: quote
  })
  return responseData as Quote
}

export async function getQuoteCustomer (customerId: any) {
  const responseData = await request({
    method: 'GET',
    path: `/API/quote-customers/${customerId}`
  })
  return responseData as QuoteCustomer
}

export async function getQuoteCustomersByEmail (accommodationId: string | number, email: string) {
  const responseData = await request({
    method: 'GET',
    path: `/API/quote-customers/accomodation/${accommodationId}/search?query=${encodeURIComponent('%' + email + '%')}`
  })
  return responseData as QuoteCustomer[]
}

export async function saveQuoteCustomer (customer: QuoteCustomer) {
  const responseData = await request({
    method: 'POST',
    path: '/API/quote-customers',
    body: customer
  })
  return responseData as QuoteCustomer
}

export async function deleteQuoteCustomer (id: number) {
  const responseData = await request({
    method: 'DELETE',
    path: `/API/quote-customers/${id}`
  })
  return id
}

export async function getRooms (accommodationId: string | number) {
  const responseData = await request({
    method: 'GET',
    path: '/API/rooms/accomodation/' + accommodationId
  }) as Room[]

  responseData.forEach(r => {
    return {
      ...r,
      translations: r.translations ? JSON.parse(r.translations as any) : []
    }
  })

  return responseData
}

export async function getRoom (id: number) {
  const responseData = await request({
    method: 'GET',
    path: '/API/rooms/' + id
  })
  return responseData as Room
}

export async function deleteRoom (id: number) {
  const responseData = await request({
    method: 'DELETE',
    path: '/API/rooms/' + id
  })
  return responseData as Room
}

export async function storeRoom (room: Room) {
  const responseData = await request({
    method: 'POST',
    path: '/API/rooms',
    body: {
      ...room,
      translations: JSON.stringify(room.translations)
    }
  })
  return responseData as Room
}

export async function storeRoomImage (room: RoomImage) {
  const responseData = await request({
    method: 'POST',
    path: '/API/room-images',
    body: room
  })
  return responseData as RoomImage
}

export async function getSettings (id: string) {
  const responseData = await request({
    method: 'GET',
    path: `/API/accomodations/settings/${id}`
  })
  return responseData as AccomodationSettings
}

export async function getDashboardReport (idAccomodations: any) {
  const responseData = await request({
    method: 'POST',
    path: `/API/reports/dashboard`,
    body: {
      idAccomodations
    }
  })
  return responseData as DashboardReport[]
}

export async function getOpportunityReport (id: any) {
  const responseData = await request({
    method: 'GET',
    path: `/API/opportunities/report/${id}`
  })
  return responseData as OpportunityReport
}

export async function updateSettingsMedia (id: string, type: 'logo' | 'cover', fileUrl: string) {
  const responseData = await request({
    method: 'POST',
    path: `/API/accomodations/settings-update-media/${id}/${type}`,
    body: {
      fileUrl
    }
  })
  return responseData as any
}

export async function getGroups () {
  const responseData = await request({
    method: 'GET',
    path: `/API/accomodation-groups/list`
  })
  return responseData as Group[]
}

export async function saveGroup (group: CreateGroup) {
  const responseData = await request({
    method: 'POST',
    path: `/API/accomodation-groups/save`,
    body: group
  })
  return responseData as any
}

export async function deleteGroup (idGroup: number, idAccomodation: number) {
  const responseData = await request({
    method: 'DELETE',
    path: `/API/accomodation-groups/remove/${idGroup}/${idAccomodation}`
  })
  return responseData as any
}

export async function getAccomodationGroups (idAccomodation: number) {
  const responseData = await request({
    method: 'GET',
    path: `/API/accomodation-groups/accomodation/${idAccomodation}`
  })
  return responseData as Group[]
}

export async function getAccomodationTags (idAccomodation: any) {
  const responseData = await request({
    method: 'GET',
    path: `/API/accomodation-tags/accomodation/${idAccomodation}`
  })
  return responseData as AccomodationTag[]
}

export async function listBookingInvoices (idBooking: any) {
  const responseData = await request({
      method: 'GET',
      path: `/API/bookings/${idBooking}/invoices`,
  })
  return responseData as BookingInvoiceRow[]
}

export async function getPromoCode (code: string) {
  const responseData = await request({
    method: 'GET',
    path: `/API/promocode/code/${code}`,
    noAuth: true
  })
  return responseData as Promocode
}

export async function getCancellationPolicies (idAccomodation: any) {
  const responseData = await request({
    method: 'GET',
    path: `/API/cancellation-policies/accomodation/${idAccomodation}`
  })
  return responseData as CancellationPolicy[]
}

export async function deletePolicy (id: number) {
  const responseData = await request({
    method: 'DELETE',
    path: '/API/cancellation-policies/' + id
  })
  return responseData as Room
}

export async function getCancellationPolicy (idCancellationPolicy: any) {
  const responseData = await request({
    method: 'GET',
    path: `/API/cancellation-policies/${idCancellationPolicy}`
  })
  return responseData as CancellationPolicy
}

export async function saveCancellationPolicy (policy: CancellationPolicy) {
  const responseData = await request({
    method: 'POST',
    path: `/API/cancellation-policies`,
    body: {
      ...policy,
      translations: JSON.stringify(policy.translations)
    }
  })
  return responseData as CancellationPolicy
}

export async function deleteCancellationPolicy (policy: CancellationPolicy) {
  const responseData = await request({
    method: 'DELETE',
    path: `/API/cancellation-policies/${policy.idCancellationPolicy}`
  })
  return responseData as CancellationPolicy
}

export async function deleteRoomImage (id: number) {
  const responseData = await request({
    method: 'DELETE',
    path: `/API/room-images/${id}`
  })
  return id
}

export async function getAccomodationInfo (accomodationId: number | string | null) {
  const responseData = await request({
    method: 'GET',
    path: `/API/accomodation-information/accomodation/${accomodationId}`,
  })
  return responseData as AccomodationInfo
}

export async function saveAccomodationInformation (info: AccomodationInfo) {
  const responseData = await request({
    method: 'POST',
    path: `/API/accomodation-information`,
    body: info
  })
  return responseData as AccomodationInfo
}

export async function getAccommodationAmenities (accomodationId: number | string | null) {
  const responseData = await request({
    method: 'GET',
    path: `/API/accomodation-amenities/accomodation/${accomodationId}`,
  })
  return responseData as AccomodationAmenity[]
}

export async function saveAccomodationAmenity (amenity: AccomodationAmenity) {
  const responseData = await request({
    method: 'POST',
    path: `/API/accomodation-amenities`,
    body: amenity
  })
  return responseData as AccomodationAmenity
}

export async function triggerKanbanEvent (kanbanEvent: QuoteKanbanEvent) {
  const responseData = await request({
    method: 'POST',
    path: `/API/quotes/kanban-event`,
    body: kanbanEvent
  })
  return responseData as Quote
}

export async function markHotelQuote (id: number, action: 'confirm' | 'reject') {
  const responseData = await request({
    method: 'POST',
    path: `/API/quotes/mark-quote/${id}/${action}`
  })
  return responseData as Quote
}

export async function getPartner (id: number) {
  const responseData = await request({
    method: 'GET',
    path: `/API/partners/${id}`
  })
  return responseData as Partner
}

export async function getPartnerReport (partnerId: number, from: number, to: number) {
  const responseData = await request({
    method: 'GET',
    path: `/API/partners/report/${partnerId}/${from}/${to}`
  })
  return responseData as PartnerReport[]
}

export async function getPartnerQuarterReport (partnerId: number, year: number) {
  const responseData = await request({
    method: 'GET',
    path: `/API/partners/quarters/${partnerId}/${year}`
  })
  return responseData as PartnerQuarterReport
}

export async function fetchPartnerBySlug (slug: string) {
  const responseData = await request({
      method: 'GET',
      path: `/API/ext/partners/slug/${slug}`
  })
  return responseData as Partner
}

export async function fetchAccomodationGateways (idAccomodation: number) {
  const responseData = await request({
    method: 'GET',
    path: `/API/accomodation-gateways/list/${idAccomodation}`
  })
  return responseData as AccomodationGateway[]
}

export async function saveGateways (data: Partial<AccomodationGateway>) {
  const responseData = await request({
    method: 'POST',
    path: `/API/accomodation-gateways`,
    body: data
  })
  return responseData as AccomodationGateway
}

export async function fetchInsuranceFile (encryptedBookingCode?: string, idBooking?: string, company?: string, langauge?: string) {
  const responseData = await request({
    method: 'GET',
    path: `/API/ext/insurance/file/${company}?${(idBooking !== undefined) ? `idBooking=${idBooking}` : `bookingCode=${encryptedBookingCode}`}&language=${langauge ? langauge : 'IT'}`
  })
  return responseData as InsuranceFile
}

export async function getReservationClaim (idBooking: number) {
  const responseData = await request({
      method: 'GET',
      path: `/API/claims/retrieve/${idBooking}`,
  })
  return responseData as ClaimDetail
}

export async function getPolicyPricing (data : {
  arrival: string,
  departure: string,
  bookingDate: string,
  pax: number,
  destinationCountry: string,
  region: string,
  travelCost: number,
  currency: string
}) {
  const responseData = await request({
    method: 'GET',
    noAuth: true,
    noPrefix: true,
    headers: [{
      key: 'ApiKey',
      content: 'jCLxTExH0Ue59Hq3puRQwY3bVqHt3E3FFqt3g9mYQVytQrjqpXvDibaLEpSZgKjtwRmuwy5QRaBsjQZOfUaXHFu63fTqSQT1C4pbuX8m7GPAqltpWc5AXrETglcxkVTy'
    }],
    path: `https://b2c-api.besafesuite.com/plans?departureDate=${data.arrival}&returnDate=${data.departure}&guestsNumber=${data.pax}&destinationCountry=${data.destinationCountry}&region=${data.region}&travelCost=${data.travelCost}&locale=it&bookingDate=${dayjs(data.bookingDate, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD')}&currency=${data.currency}&showPrivate=true`,
})
return responseData as InsuranceData
}

export async function checkJobStatus (data : { jobId: string | number }) {
  const responseData = await request({
    method: 'GET',
    noAuth: true,
    noPrefix: true,
    headers: [{
      key: 'Authorization',
      content: `Basic dGVzdHVzZXI6dGVzdHBhc3N3b3Jk`
    }],
    path: `https://insurance-batching.besafesuite.com/jobs/status/${data.jobId}`
  })
  return responseData as InsuranceJobStatusResponse
}

export async function startInsuranceEmission (data : { idBooking: string | number, policyCode: string, idPolicy: string | number }) {
  const responseData = await request({
    method: 'POST',
    noAuth: true,
    noPrefix: true,
    headers: [{
      key: 'Authorization',
      content: `Basic dGVzdHVzZXI6dGVzdHBhc3N3b3Jk`
    }],
    path: 'https://insurance-batching.besafesuite.com/jobs/intermundial',
    body: {
      jobName: 'test',
      params: {
        policyCode: data.policyCode,
        idBooking: data.idBooking,
        idPolicy: data.idPolicy
      }
    }
  })
  return responseData as InsuranceJobResponse
}

export async function startEmailJob (data : { idBooking: string | number }) {
  const responseData = await request({
    method: 'POST',
    noAuth: true,
    noPrefix: true,
    headers: [{
      key: 'Authorization',
      content: `Basic dGVzdHVzZXI6dGVzdHBhc3N3b3Jk`
    }],
    path: `https://insurance-batching.besafesuite.com/jobs/email/${data.idBooking}`
  })
  return responseData as InsuranceJobResponse
}

export async function setupCustomer (data : {
  firstName: string,
  lastName: string,
  email: string,
  currency: string,
  paymentRail: string,
  idAccommodation: number
}) {
  const responseData = await request({
    method: 'POST',
    path: `/API/billing/setup-customer`,
    body: data
  })
  return responseData as AccomodationBilling
}

export async function listPaymentMethods (idAccomodation: number | string) {
  const responseData = await request({
    method: 'GET',
    path: `/API/billing/payment-methods/${idAccomodation}`
  })
  return responseData as PaymentMethod[]
}

export async function deletePaymentMethod (idMethod: number | string) {
  const responseData = await request({
    method: 'DELETE',
    path: `/API/billing/payment-methods/${idMethod}`
  })
  return responseData as PaymentMethod
}

export async function serachAddress (address: string) {
  const responseData = await request({
    method: 'GET',
    path: `/API/companies/search-address?address=${address}`
  })
  return responseData as any
}

export async function placeDetails (placeId: string) {
  const responseData = await request({
    method: 'GET',
    path: `/API/companies/address-details/${placeId}`
  })
  return responseData as any
}
