import { useState } from "react"
import { getApiUsers, saveUser } from "../shared/queries"
import { ApiUser } from "../types"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { PageWrapper } from "../components/common/PageWrapper"

import toast from "react-hot-toast"
import Chip from "../components/common/Chip"
import EntityUpdateDialog from "../components/common/EntityUpdateDialog"
import PageTitle from "../components/common/PageTitle"
import BaseTable from "../components/data/BaseTable"
import Button from "../components/form/Button"

export default function ApiUsersPage () {

    const history = useHistory()

    const {
        data,
        isFetching,
        refetch
    } = useQuery('apiUsers', getApiUsers)

    const [create, setCreate] = useState<boolean>(false)
    const [newUser, setNewUser] = useState<ApiUser>({
        basicAuthPassword: '',
        basicAuthUsername: '',
        isActive: true,
        hasRateFilter: false
    })

    const createMutation = useMutation((data: ApiUser) => saveUser(data), {
        onSuccess: () => {
            toast.success('Utente creato')
            setNewUser({
                basicAuthPassword: '',
                basicAuthUsername: '',
                isActive: true,
                hasRateFilter: false
            })
            setCreate(false)
            refetch()
        },
        onError: () => {
            toast.error('Impossibile creare l\'utente')
        }
    })

    return (
        <PageWrapper centered maxWidth={'1000px'}>
            
            <PageTitle
                title={'Utenti API'}
                renderActions={() => (
                    <Button
                        className="text-white bg-orange-600"
                        label={'Nuovo utente'}
                        onClick={() => {
                            setCreate(true)
                        }} />
                )} />

            <BaseTable
                loading={isFetching}
                showFilters={true}
                showColumnSelector={true}
                identifierKey={'idBasicAuthUser'}
                onEdit={id => {
                    history.push('/app/configuration/policies/' + id)
                }}
                columns={[
                    {
                        Header: 'Username',
                        accessor: (row: ApiUser) => {
                            return row.basicAuthUsername
                        }
                    },
                    {
                        Header: 'Password',
                        accessor: (row: ApiUser) => {
                            return row.basicAuthPassword
                        }
                    },
                    {
                        Header: 'Stato',
                        accessor: (row: ApiUser) => (
                            row.isActive ? 
                            <Chip className={'text-green-600 bg-green-100'}>
                                Attivo
                            </Chip>
                            :
                            <Chip className={'text-red-600 bg-red-100'}>
                                Disattivo
                            </Chip>
                        )
                    }
                ]}
                data={data} />

            <EntityUpdateDialog
                fields={[
                    {
                        label: 'Username',
                        value: '',
                        field: 'basicAuthUsername'
                    },
                    {
                        label: 'Password',
                        value: '',
                        field: 'basicAuthPassword'
                    },
                    {
                        label: 'Attivo',
                        value: '',
                        field: 'isActive',
                        type: 'checkbox'
                    }
                ]}
                visible={create}
                onClose={() => setCreate(false)}
                onSave={(data: any) => {
                    createMutation.mutate(data)
                }}
                title={'Nuovo utente'}
                data={newUser} />

        </PageWrapper>
    )
    
}