import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import es from './es.json'
import it from './it.json'
import en from './en.json'
import el from './el.json'
import de from './de.json'
import fr from './fr.json'
import pt from './pt.json'

i18n
    .use(initReactI18next)
    .init({
        resources: {
            it: {
                translation: it
            },
            es: {
                translation: es
            },
            en: {
                translation: en
            },
            el: {
                translation: el
            },
            pt: {
                translation: pt
            },
            fr: {
                translation: fr
            },
            de: {
                translation: de
            }
        },
        lng: 'it',
        fallbackLng: 'en',
        keySeparator: '.',
        interpolation: {
            escapeValue: false
        }
    })

export default i18n
