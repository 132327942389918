import Joi from "joi";
import i18n from '../i18n/index'

export default Joi.object<any, false>({
  accomodationName: Joi.string().required().messages({
    'string.required': i18n.t('validation.required'),
    'string.empty': i18n.t('validation.required'),
    'string.email': i18n.t('validation.invalidEmail')
  }),
  accomodationPhone: Joi.any(),
  usePendingModel: Joi.any(),
  opportunity: Joi.any(),
  opportunitySendEmails: Joi.any(),
  billingEmail: Joi.any(),
  generalNotificationEmail: Joi.any(),
  customerType: Joi.any(),
  verificationNeeded: Joi.any(),
  vatCountry: Joi.number().required().messages({
    'string.required': i18n.t('validation.required'),
    'string.empty': i18n.t('validation.required'),
    'string.email': i18n.t('validation.invalidEmail')
  }),
  hasVat: Joi.any(),
  company: Joi.object({
    createdAt: Joi.any(),
    updatedAt: Joi.any(),
    idCompany: Joi.any(),
    address: Joi.object({
      addressProvince: Joi.any(),
      addressLatitude: Joi.any(),
      addressLongitude: Joi.any(),
      addressState: Joi.any(),
      idCountry: Joi.any().required().messages({
        'string.required': i18n.t('validation.required'),
        'string.empty': i18n.t('validation.required')
      }),
      addressStreet: Joi.string().required().messages({
        'string.required': i18n.t('validation.required'),
        'string.empty': i18n.t('validation.required')
      }),
      addressCity: Joi.string().required().messages({
        'string.required': i18n.t('validation.required'),
        'string.empty': i18n.t('validation.required')
      }),
      addressZipCode: Joi.string().required().messages({
        'string.required': i18n.t('validation.required'),
        'string.empty': i18n.t('validation.required')
      })
    }),
    companySdi: Joi.alternatives().conditional('...vatCountry', {
      is: 113,
      then: Joi.alternatives().conditional('companyPec', {
        is: Joi.any().valid(null, '', ' '),
        then: Joi.string().required().messages({
          'string.required': i18n.t('validation.required'),
          'string.empty': i18n.t('validation.required'),
          'string.email': i18n.t('validation.invalidEmail')
        }),
        otherwise: Joi.any()
      }),
      otherwise: Joi.any()
    }),
    companyVat: Joi.alternatives().conditional(Joi.ref('...hasVat'), {
      is: true,
      then: Joi.string().required().messages({
        'string.required': i18n.t('validation.required'),
        'string.empty': i18n.t('validation.required'),
        'string.email': i18n.t('validation.invalidEmail')
      }),
      otherwise: Joi.any()
    }),
    companyFiscalCode: Joi.alternatives().conditional(Joi.ref('...vatCountry'), {
      is: 113,
      then: Joi.string().required().messages({
        'string.required': i18n.t('validation.required'),
        'string.empty': i18n.t('validation.required'),
        'string.email': i18n.t('validation.invalidEmail')
      }),
      otherwise: Joi.any()
    }),
    companyName: Joi.string().required().messages({
      'string.required': i18n.t('validation.required'),
      'string.empty': i18n.t('validation.required'),
      'string.email': i18n.t('validation.invalidEmail')
    }),
    companyEmail: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages({
      'string.required': i18n.t('validation.required'),
      'string.empty': i18n.t('validation.required'),
      'string.email': i18n.t('validation.invalidEmail')
    }),
    companyPec: Joi.alternatives().conditional('...vatCountry', {
      is: 113,
      then: Joi.alternatives().conditional('companySdi', {
        is: Joi.any().valid(null, '', ' '),
        then: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages({
          'string.required': i18n.t('validation.required'),
          'string.empty': i18n.t('validation.required'),
          'string.email': i18n.t('validation.invalidEmail')
        }),
        otherwise: Joi.any()
      }),
      otherwise: Joi.any()
    })
  }),
  isSuspended: Joi.any(),
  policies: Joi.any(),
  isActive: Joi.any(),
  address: Joi.object({
    addressProvince: Joi.any(),
    addressLatitude: Joi.any(),
    addressLongitude: Joi.any(),
    addressState: Joi.any(),
    idCountry: Joi.any().required().messages({
      'string.required': i18n.t('validation.required'),
      'string.empty': i18n.t('validation.required'),
      'string.email': i18n.t('validation.invalidEmail')
    }),
    addressStreet: Joi.string().required().messages({
      'string.required': i18n.t('validation.required'),
      'string.empty': i18n.t('validation.required'),
      'string.email': i18n.t('validation.invalidEmail')
    }),
    addressCity: Joi.string().required().messages({
      'string.required': i18n.t('validation.required'),
      'string.empty': i18n.t('validation.required'),
      'string.email': i18n.t('validation.invalidEmail')
    }),
    addressZipCode: Joi.string().required().messages({
      'string.required': i18n.t('validation.required'),
      'string.empty': i18n.t('validation.required'),
      'string.email': i18n.t('validation.invalidEmail')
    })
  })
})