import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { fetchInfo, setInfo } from "../store/slices/accommodationSettingsSlice";
import SelectInput from "../components/form/SelectInput";
import FormField from "../components/form/FormField";
import TextInput from "../components/form/TextInput";
import Checkbox from "../components/form/Checkbox";
import Button from "../components/form/Button";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { saveAccomodationInformation } from "../shared/queries";
import { AccomodationInfo } from "../types";
import toast from "react-hot-toast";

const CHECK_TIMES = [
  { label: '00:00', value: '00:00' },
  { label: '01:00', value: '01:00' },
  { label: '02:00', value: '02:00' },
  { label: '03:00', value: '03:00' },
  { label: '04:00', value: '04:00' },
  { label: '05:00', value: '05:00' },
  { label: '06:00', value: '06:00' },
  { label: '07:00', value: '07:00' },
  { label: '08:00', value: '08:00' },
  { label: '09:00', value: '09:00' },
  { label: '10:00', value: '10:00' },
  { label: '11:00', value: '11:00' },
  { label: '12:00', value: '12:00' },
  { label: '13:00', value: '13:00' },
  { label: '14:00', value: '14:00' },
  { label: '15:00', value: '15:00' },
  { label: '16:00', value: '16:00' },
  { label: '17:00', value: '17:00' },
  { label: '18:00', value: '18:00' },
  { label: '19:00', value: '19:00' },
  { label: '21:00', value: '21:00' },
  { label: '22:00', value: '22:00' },
  { label: '23:00', value: '23:00' },
]

export default function QuoteSettingsInfo () {
  
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { currentHotel } = useAppSelector(state => state.hotels)
  const { info } = useAppSelector(state => state.accommodationSettingsSlice)

  useEffect(() => {
    dispatch(fetchInfo(currentHotel))
  }, [
    currentHotel
  ])

  const saveMutation = useMutation((accomodationInfo: AccomodationInfo) => saveAccomodationInformation({
    ...accomodationInfo,
    idAccomodation: currentHotel ? parseInt(currentHotel.toString()) : 0
  }), {
    onSuccess: () => {
      toast.success(t('vary.infoUpdated'))
    }
  })

  if (!info) return <></>

  return (
    <div>
      <div className="text-xl font-bold text-gray-600 mb-6">
        {t('quote.accommodationInfo')}
      </div>
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-4 items-center">
          <FormField className={'flex-1'} label={t('quote.checkInFrom')}>
            <SelectInput
              options={CHECK_TIMES}
              value={CHECK_TIMES.find(ct => ct.value === info.checkIn)}
              onChange={(item: any) => {
                if (item) {
                  dispatch(setInfo({...info, checkIn: item.value}))
                }
              }}
            />
          </FormField>

          <FormField className={'flex-1'} label={t('quote.checkInTo')}>
            <SelectInput
              options={CHECK_TIMES}
              value={CHECK_TIMES.find(ct => ct.value === info.checkOut)}
              onChange={(item: any) => {
                if (item) {
                  dispatch(setInfo({...info, checkOut: item.value}))
                }
              }}
            />
          </FormField>
        </div>

        <FormField className={'flex-1'} label={t('quote.maxAgeChildren')}>
          <TextInput value={info.childrenMaxAge} suffix={t('quote.years')} numbered={true} onChange={val => dispatch(setInfo({...info, childrenMaxAge: val}))} />
        </FormField>

        <FormField className={'flex-1'} label={t('quote.accommodationDescription')}>
          <TextInput value={info.description} type={'textarea'} onChange={val => dispatch(setInfo({...info, description: val}))} />
        </FormField>

        <Checkbox label={t('quote.animals')} checked={info.petsAllowed} onChange={() => dispatch(setInfo({...info, petsAllowed: !info.petsAllowed}))}></Checkbox>

        {
          info.petsAllowed &&
          <FormField className={'flex-1'} label={t('quote.animalsCost')}>
            <TextInput value={info.petsCost} suffix='EUR' type={'currency'} numbered={true} onChange={val => dispatch(setInfo({...info, petsCost: val}))} />
          </FormField>
        }

        <div className="mt-4">
          <Button loading={saveMutation.isLoading} className="text-white bg-orange-600" onClick={() => saveMutation.mutate(info)} label={t('general.confirm')} />
        </div>
      </div>
    </div>
  )

}