import { Fragment, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import Link from "../../navigation/Link";
import { getAccomodationApiChannels, getChannelStatusById, saveAccomodationApiChannel } from "../../../shared/queries";
import Chip from "../../common/Chip";
import Modal from "../../common/Modal";
import ModalActions from "../../common/ModalActions";
import ModalCard from "../../common/ModalCard";
import BaseTable from "../../data/BaseTable";
import { AccomodationApi } from '../../../types'
import FormField from "../../form/FormField";
import TextInput from "../../form/TextInput";
import Skeleton from "react-loading-skeleton";
import FormSectionTitle from "../../form/FormSectionTitle";
import CreateApiChannel from "../hotel/CreateApiChannel";
import DataViewer from "../common/DataViewer";
import Icon from "../../common/Icon"
import Editor from "@monaco-editor/react"

function SystemStatus ({
    idSystem
} : {
    idSystem: number | string
}) {

    const responseWrapper = useRef<any>()
    const scrollWrapper = useRef<any>()
    const [query, setQuery] = useState('')
    
    const [channelResponse, setChannelResponse] = useState<string>()

    const {
        data,
        isFetching
    } = useQuery(['accomodationChannelStatus', idSystem], () => getChannelStatusById(idSystem))

    return (
        <Fragment>
            {
                (data) &&
                <div className={'flex space-x-2'}>
                    <div>
                        {
                            data.isWarning ? 
                            <Chip className={'text-orange-600 bg-orange-100'}>
                                {data.channelStatusErrorCode || 'NOK'}
                            </Chip>
                            :
                            <Chip className={'text-green-600 bg-green-100'}>
                                OK
                            </Chip>
                        }
                    </div>
                    <div>
                        <Link onClick={() => setChannelResponse(data.channelStatusResponse)}>
                            Visualizza ultima risposta
                        </Link>
                    </div>
                </div>
            }
            {
                isFetching && <Skeleton />
            }

            <DataViewer
                visible={channelResponse !== undefined}
                data={data}
                onClose={() => {
                    setChannelResponse(undefined)
                }}></DataViewer>

        </Fragment>
    )

}

export default function HotelConnectedSystems ({
    idAccomodation
} : {
    idAccomodation: string | number
}) {

    const [invalidJson, setInvalidJson] = useState(false)
    const [createChannel, setCreateChannel] = useState<boolean>(false)
    const [currentChannelDetails, setCurrentChannelDetails] = useState<AccomodationApi>()
    const [channelProps, setChannelProps] = useState<any>()

    useEffect(() => {
        if (currentChannelDetails) {
            try {
                const props = JSON.parse(currentChannelDetails.apiProps)
                setChannelProps(props)
            } catch (e) {
                console.log(e)
            }
        }
    }, [
        currentChannelDetails
    ])

    const {
        data,
        refetch
    } = useQuery(['accomodationApiChannel', idAccomodation], () => getAccomodationApiChannels(idAccomodation))

    const updateMutation = useMutation((data: AccomodationApi) => saveAccomodationApiChannel(data), {
        onSuccess: () => {
            setCurrentChannelDetails(undefined)
        }
    })

    function getChannelOverrideParsed () {
        if (channelProps.override) {
            try {
                return JSON.stringify(channelProps.override, null, 2)
            } catch (e) {
                return '{}'
            }
        }
        return '{}'
    }

    function getFiltersParsed () {
        if (channelProps.filters) {
            try {
                return JSON.stringify(JSON.parse(channelProps.filters), null, 2)
            } catch (e) {
                return '{}'
            }
        }
        return '{}'
    }

    return (
        <Fragment>
            <FormSectionTitle
                borderless
                title={'Sistemi connessi'}
                subtitle={'canali collegati per lo scarico di prenotazioni'}
                onEdit={() => {
                    setCreateChannel(true)
                }}
                edit={true}
                editLabel={'Aggiungi canale'} />

            <BaseTable
                filterable={false}
                paginated={false}
                columns={[
                    {
                        Header: 'Nome',
                        accessor: (row: any) => (
                            <Link onClick={() => setCurrentChannelDetails(row)}>
                                {row.apiChannel.apiChannelName}
                            </Link>
                        )
                    },
                    {
                        Header: 'Stato',
                        accessor: (row: any) => (
                            <SystemStatus
                                idSystem={row.idAccomodationApi} />
                        )
                    }
                ]}
                data={data} />

            <Modal
                visible={(currentChannelDetails !== null && currentChannelDetails !== undefined)}>
                <ModalCard
                    className={'w-full'}
                    title={'Impostazioni canale'}>
                    <div className="p-4 flex flex-col space-y-4">
                        {
                            (currentChannelDetails && channelProps) && Object.keys(channelProps).map((key: any, index: number) => (
                                <Fragment>
                                    {
                                        (key !== 'rates' && key !== 'override' && key !== 'filters') &&
                                        <FormField label={key}>
                                            <TextInput
                                                value={channelProps[key]}
                                                onChange={val => {
                                                    setChannelProps({
                                                        ...channelProps,
                                                        [key]: val
                                                    })
                                                }} />
                                        </FormField>
                                    }

                                    {
                                        (key === 'rates') &&
                                        <FormField label={'Lista Tariffe'}>
                                            <div className="flex flex-col space-y-2">
                                                {
                                                    channelProps.rates.map((rate: any, index: any) => (
                                                        <div className="flex w-full space-x-2 items-center">
                                                            <div className={'flex-1'}>
                                                                <TextInput
                                                                    key={index}
                                                                    value={rate}
                                                                    onChange={val => {
                                                                        setChannelProps({
                                                                            ...channelProps,
                                                                            rates: channelProps.rates.map((rate: any, current: any) => {
                                                                                if (current === index) return val
                                                                                return rate
                                                                            })
                                                                        })
                                                                    }}></TextInput>
                                                            </div>
                                                            <div className={'text-red-600 cursor-pointer'}>
                                                                <button
                                                                    onClick={() => {
                                                                        setChannelProps({
                                                                            ...channelProps,
                                                                            rates: channelProps.rates.filter((rate: any, current: any) => {
                                                                                if (current !== index) return rate
                                                                            })
                                                                        })
                                                                    }}>
                                                                    <Icon name={'trash'} size={'25px'} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <Link className={'block mt-2'} onClick={() => {
                                                setChannelProps({
                                                    ...channelProps,
                                                    rates: [
                                                        ...channelProps.rates,
                                                        ''
                                                    ]
                                                })
                                            }}>
                                                Aggiungi tariffa
                                            </Link>
                                        </FormField>
                                    }

                                    {
                                        (key === 'override') &&
                                        <FormField label="Connection override (from great power comes great responsibility)" className="border p-2">
                                            {
                                                invalidJson &&
                                                <div className="text-red-600 font-medium">
                                                    La confiurazione inserita non ha una sintassi valida, correggi per continuare.
                                                </div>
                                            }
                                            <Editor
                                                height="300px"
                                                width={'100%'}
                                                defaultLanguage="json"
                                                onChange={(value, event) => {
                                                    if (value) {
                                                        try {
                                                            setInvalidJson(false)
        
                                                            setChannelProps({
                                                                ...channelProps,
                                                                override: JSON.parse(value)
                                                            })
                                                        } catch (e) {
                                                            setInvalidJson(true)
                                                        }
                                                    }
                                                }}
                                                value={getChannelOverrideParsed()}
                                            />
                                        </FormField>
                                    }

                                    {
                                        (key === 'filters') &&
                                        <FormField label="Connection filters (from great power comes great responsibility)" className="border p-2">
                                            {
                                                invalidJson &&
                                                <div className="text-red-600 font-medium">
                                                    La confiurazione inserita non ha una sintassi valida, correggi per continuare.
                                                </div>
                                            }
                                            <Editor
                                                height="300px"
                                                width={'100%'}
                                                defaultLanguage="json"
                                                onChange={(value, event) => {
                                                    if (value) {
                                                        try {
                                                            JSON.parse(value)
                                                            setInvalidJson(false)
        
                                                            setChannelProps({
                                                                ...channelProps,
                                                                filters: value
                                                            })
                                                        } catch (e) {
                                                            setInvalidJson(true)
                                                        }
                                                    }
                                                }}
                                                value={getFiltersParsed()}
                                            />
                                        </FormField>
                                    }
                                </Fragment>
                            ))
                        }
                    </div>
                    <ModalActions
                        onSave={() => {
                            if (currentChannelDetails) {
                                updateMutation.mutate({
                                    ...currentChannelDetails,
                                    apiProps: JSON.stringify({
                                        ...channelProps,
                                        override: channelProps.override ? channelProps.override : undefined,
                                        filters: channelProps.filters ? JSON.stringify(JSON.parse(channelProps.filters)) : undefined,
                                    })
                                })
                            }
                        }}
                        onClose={() => {
                            setChannelProps(undefined)
                            setCurrentChannelDetails(undefined)
                        }} />
                </ModalCard>
            </Modal>

            <CreateApiChannel
                idAccomodation={idAccomodation}
                visible={createChannel}
                onSave={() => {
                    refetch()
                    setCreateChannel(false)
                }}
                onClose={() => {
                    setCreateChannel(false)
                }} />

        </Fragment>

    )

}