import dayjs from "dayjs"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { usePopper } from "react-popper"
import { TableFilterColumn } from "../../types"
import Icon from "../common/Icon"
import Checkbox from "../form/Checkbox"
import DatePickerInput from "../form/DatePickerInput"
import SelectInput from "../form/SelectInput"
import TextInput from "../form/TextInput"

type TableFiltersProps = {
  columns: TableFilterColumn[],
  filtersValue?: TableFilterColumn[] | null,
  onChange?: (cols: TableFilterColumn[]) => void
}

export default function TableFilters ({
  columns,
  onChange,
  filtersValue
} : TableFiltersProps) {

  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const [cols, setCols] = useState<TableFilterColumn[]>(columns)

  const [referenceElement, setReferenceElement] = useState<any>(null)
  const [popperElement, setPopperElement] = useState<any>(null)
  const [filterCount, setFilterCount] = useState<number>(0)

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom'
    }
  )

  useEffect(() => {
    setFilterCount(cols.filter(c => c.active === true).length)
  }, [ cols ])

  useEffect(() => {
    if (filtersValue) {
      setCols(filtersValue)
    }
  }, [ filtersValue ])

  return (
    <div>
      <button
        ref={setReferenceElement}
        onClick={() => setVisible(!visible)}
        style={{
          boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px'
        }}
        className={'flex items-center bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded'}>
        <div className={'flex items-center py-1'}>
          <div className={'flex items-center'}>
            <Icon name={'filter'} size={'16px'} className={'mr-2'}></Icon> {t('general.filters')}
          </div>
          {
            filterCount > 0 &&
            <div className={'border-l text-sm font-medium ml-2 pl-2'}>
              <div
                style={{
                  height: '18px',
                  width: '18px'
                }}
                className={'bg-orange-500 text-xs flex text-white items-center justify-center rounded-full'}>{ filterCount }</div>
            </div>
          }
        </div>
      </button>
      {
        visible &&
        <div
          className={'z-50'}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
            <motion.div
              transition={{
                duration: 0.1
              }}
              initial={{
                scale: 0.8
              }}
              animate={{
                scale: 1
              }}>
              <div className={'m-1 w-56 bg-white border rounded shadow-lg overflow-hidden'}>
                <div className={'flex p-2 bg-gray-50 border-b justify-between items-center'}>
                  <div className={'font-medium text-sm'}>{t('general.filters')}</div>
                  <div>
                    <button
                      style={{
                        boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px'
                      }}
                      onClick={() => {
                        setVisible(false)
                        if (onChange) onChange(cols)
                      }}
                      className={'bg-white text-sm flex text-orange-600 items-center focus:outline-none px-3 text-sm font-medium py-1 rounded'}>
                      {t('general.done')}
                    </button>
                  </div>
                </div>
                {
                  cols.map((column, index) => (
                    <div key={index}>
                      <div className={'p-2 py-2 flex items-center border-b'}>
                        <Checkbox
                          size={'sm'}
                          label={column.label}
                          checked={column.active}
                          onChange={() => {
                            setCols(cols.map((c:TableFilterColumn, i:number) => {
                              if (i === index) {
                                return {
                                  ...c,
                                  active: !c.active
                                }
                              }
                              return c
                            }))
                          }} />
                      </div>
                      {
                        (column.active && column.type) &&
                        <motion.div
                          initial={{
                            height: 0
                          }}
                          animate={{
                            height: 'auto'
                          }}
                          className={'bg-gray-100 p-2'}>
                          {
                            (column.type === 'text') &&
                            <TextInput
                              size={'sm'}
                              placeholder={'Filtra'}
                              value={column.value ? column.value : ''}
                              onChange={(value: any) => {
                                setCols(cols.map((c:TableFilterColumn, i:number) => {
                                  if (i === index) {
                                    return {
                                      ...c,
                                      value: value
                                    }
                                  }
                                  return c
                                }))
                              }} />
                          }

                          {
                            (column.type === 'select') &&
                            <SelectInput
                              menuShouldScrollIntoView={false}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base: any) => ({ ...base, zIndex: 99999 })
                              }}
                              options={column.options}
                              onChange={(item: any) => {
                                if (item) {
                                  setCols(cols.map((c:TableFilterColumn, i:number) => {
                                    if (i === index) {
                                      c.value = item.value
                                      return c
                                    }
                                    return c
                                  }))
                                }
                              }} />
                          }

                          {
                            (column.type === 'datepicker' || column.type === 'daterange') &&
                            <DatePickerInput
                                mode={column.type === 'datepicker' ? 'single' : 'range'}
                                onChange={value => {
                                  if (value) {
                                    if (column.type === 'daterange') {
                                      setCols(cols.map((c:TableFilterColumn, i:number) => {
                                        if (i === index) {
                                          return {
                                            ...c,
                                            value: dayjs(value.from).format('YYYYMMDD') + "," + dayjs(value.to).format('YYYYMMDD')
                                          }
                                        }
                                        return c
                                      }))
                                    } else {
                                      setCols(cols.map((c:TableFilterColumn, i:number) => {
                                        if (i === index) {
                                          return {
                                            ...c,
                                            value: dayjs(value).format('YYYYMMDD')
                                          }
                                        }
                                        return c
                                      }))
                                    }
                                  }
                                }} />
                          }
                        </motion.div>
                      }
                    </div>
                  ))
                }
              </div>
            </motion.div>
        </div>
      }
    </div>
  )

}
