import { classNames } from "../../shared/utils"
import Icon from "../common/Icon"

export default function Button ({
    label,
    icon,
    type,
    className,
    disabled = false,
    loading = false,
    onClick
} : {
    icon?: string,
    label?: string,
    loading?: boolean,
    disabled?: boolean,
    type?: 'primary' | 'negative' | 'alternate',
    className?: string,
    onClick: () => void
}) {

    return (
        <button
            disabled={disabled}
            onClick={() => {
                if (loading) return
                onClick()
            }}
            className={
                classNames(
                    `${disabled && 'opacity-50 cursor-not-allowed'} px-2 py-1.5 text-sm rounded-md font-semibold focus:ring-4`,
                    type === 'primary' ? 'text-white bg-orange-600 hover:bg-orange-700' : '',
                    type === 'negative' ? 'text-white bg-red-600 hover:bg-red-800' : '',
                    type === 'alternate' ? 'text-red-600 bg-red-100 hover:bg-red-200' : '',
                    className
                )
            }>
            <div>{loading ? 'Attendi...' : label}</div>
            {
                icon && <div><Icon name={icon} size={'20px'} /></div>
            }
        </button>
    )

}