import AsyncCreatableSelect from 'react-select/async-creatable'
import { QuoteCustomer } from '../../types'
import { deleteQuoteCustomer, getQuoteCustomersByEmail } from '../../shared/queries'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useState } from 'react'
import AddCustomerModal from './AddCustomerModal'
import { setCustomer } from '../../store/slices/offerSlice'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export default function QuoteCustomerSelector () {

  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { currentHotel } = useAppSelector(state => state.hotels)
  const { quote } = useAppSelector(state => state.offerSlice)

  const [addCustomer, setAddCustomer] = useState(false)

  function handleCreateCustomer (inputValue: string) {
    setAddCustomer(true)
  }

  async function handleDeleteCustomer () {
    try {
      if (!quote || !quote.customer || !quote.customer.idQuoteCustomer) return
      await deleteQuoteCustomer(quote.customer.idQuoteCustomer)
      toast.success(t('quote.customerDeleted'))
      dispatch(setCustomer(undefined))
    } catch (e) {
    }
  }

  function handleSearchCustomer (
    inputValue: string,
    callback: (options: QuoteCustomer[]) => void
  ) {
    if (!currentHotel) return callback([])
    getQuoteCustomersByEmail(currentHotel, inputValue).then(data => {
      callback(data)
    }).catch(e => {
      callback([])
    })
  }

  if (quote.customer) return (
    <div className='flex space-x-4'>
      <div className='text-gray-600'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
        </svg>
      </div>
      <div className='text-gray-500'>
        <div className='text-lg font-medium text-gray-700'>{quote.customer.firstName} {quote.customer.lastName}</div>
        <div>{quote.customer.email}</div>
        <div>{t('quote.country')} {quote.customer.countryCode}</div>
        <div className="mt-2 flex items-center space-x-2">
          <div>
            <button onClick={() => dispatch(setCustomer(undefined))} className='text-gray-600 font-medium'>{t('quote.change')}</button>
          </div>
          <svg viewBox="0 0 2 2" className="text-gray-600 md:inline mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1"></circle></svg>
          <div>
            <button onClick={() => handleDeleteCustomer()} className='text-red-600 font-medium'>{t('quote.delete')}</button>
          </div>
        </div>
      </div>
    </div>
  )
    
  return (
    <div>
      <AddCustomerModal
        visible={addCustomer}
        onClose={() => {
          setAddCustomer(false)
        }}
      />

      <AsyncCreatableSelect<QuoteCustomer, false>
        cacheOptions
        defaultOptions
        getOptionValue={o => {
          if (o.idAccomodation) {
            return o.idAccomodation.toString()
          }
          return 'Crea'
        }}
        getOptionLabel={o => {
          if (o.idAccomodation) {
            return `${o.firstName} ${o.lastName}`
          }
          return t('quote.createNew')
        }}
        isClearable
        onCreateOption={handleCreateCustomer}
        loadOptions={handleSearchCustomer}
        onChange={item => {
          if (item) {
            dispatch(setCustomer(item))
          }
        }}
        placeholder={t('quote.createOrAddGuest')}
      />
    </div>
  )

}