import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { useSelector } from "react-redux"
import { getGeuest, saveGuest } from "../../../shared/queries"
import { BookingGuest, Country } from "../../../types"
import Modal from "../../common/Modal"
import ModalActions from "../../common/ModalActions"
import ModalCard from "../../common/ModalCard"
import FormField from "../../form/FormField"
import TextInput from "../../form/TextInput"
import CountrySelector from "../selectors/CountrySelector"

export default function CreateGuest ({
    idGuest,
    idBooking,
    visible,
    showFiscal,
    onClose,
    onSave
} : {
    idGuest?: string | number | undefined,
    idBooking: string,
    visible: boolean,
    showFiscal: boolean,
    onClose: () => void,
    onSave: (data: BookingGuest) => void
}) {
    
    const { t } = useTranslation()

    const currentHotel = useSelector<any>(state => state.hotels.currentHotel)

    const [country, setCountry] = useState<Country>({
        idCountry: 1,
        countryName: 'Unknown',
        countryAlpha2: 'ND',
        isActive: true
    })
    const [guestEmail, setGuestEmail] = useState<string>('')
    const [guestFirstName, setGuestFirstName] = useState<string>('')
    const [guestLastName, setGuestLastName] = useState<string>('')
    const [guestFiscalCode, setGuestFiscalCode] = useState<string>('')

    const {} = useQuery(['guest', idGuest], () => getGeuest(idGuest ? idGuest : ''), {
        enabled: (idGuest !== undefined),
        onSuccess: data => {
            setGuestFirstName(data.guestFirstName)
            setGuestLastName(data.guestLastName)
            setGuestEmail(data.guestEmail)
            setCountry(data.country)
            setGuestFiscalCode(data.guestFiscalCode)
        }
    })

    const saveMutation = useMutation(() => saveGuest({
        idBookingGuest: idGuest ? parseInt(idGuest.toString()) : undefined,
        idBooking,
        country,
        isBooker: false,
        guestEmail,
        guestFirstName,
        guestFiscalCode,
        guestLastName
    }), {
        onSuccess: data => {
            onSave(data)
        }
    })

    return (
        <Modal
            visible={visible}>
            <ModalCard
                actionsLoading={saveMutation.isLoading}
                renderActions={() => (
                    <ModalActions
                        onClose={onClose}
                        isLoading={saveMutation.isLoading}
                        onSave={() => saveMutation.mutate()}></ModalActions>
                )}
                title={t('createGuest.newGuest')}>
                
                <div className="p-4 flex flex-col space-y-4">

                    <div className="flex space-x-4">
                        <FormField className={'flex-1'} label={t('createGuest.name')}>
                            <TextInput
                                value={guestFirstName}
                                onChange={val => setGuestFirstName(val)} />
                        </FormField>

                        <FormField className={'flex-1'} label={t('createGuest.surname')}>
                            <TextInput
                                value={guestLastName}
                                onChange={val => setGuestLastName(val)} />
                        </FormField>
                    </div>

                    <FormField
                        label={t('createGuest.country')}>
                        <CountrySelector 
                            value={country}
                            onSelect={c => setCountry(c)} />
                    </FormField>

                    <FormField className={'flex-1'} label={'Email'}>
                        <TextInput
                            value={guestEmail}
                            onChange={val => setGuestEmail(val)} />
                    </FormField>

                    {
                        showFiscal &&
                        <FormField className={'flex-1'} label={t('createGuest.fiscalCodePassport')}>
                            <TextInput
                                value={guestFiscalCode}
                                onChange={val => setGuestFiscalCode(val)} />
                        </FormField>
                    }
                </div>
            </ModalCard>
        </Modal>
    )

}