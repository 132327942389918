import { useHistory } from "react-router-dom";
import Icon from "../components/common/Icon";
import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";

export default function ConfigurationPage () {

    const history = useHistory()

    const ConfigurationCard = ({ title, info, icon, onClick } : {
        title: string,
        info: string,
        icon: string,
        onClick: () => void
    }) => (
        <button
            onClick={onClick}
            className={'w-full text-left focus:outline-none focus:border-orange-300 focus:bg-orange-50 focus:ring-4 ring-orange-100 cursor-pointer hover:bg-gray-100 flex-1 border rounded-md p-4 flex space-x-4 text-gray-600'}>
            <Icon name={icon} size={'25px'} />
            <div>
                <div className={'font-semibold'}>
                    {title}
                </div>
                <div className={'mt-1 text-sm text-gray-500'}>
                    {info}
                </div>
            </div>
        </button>
    )

    return (
        <PageWrapper className={'w-full'} centered maxWidth={'1000px'}>

          <PageTitle
              title={'Configurazione'} />

            <div className="grid grid-cols-2 gap-2">
              <div>
                <ConfigurationCard
                  icon={'eye'}
                  title={'Utenti'}
                  info={'gestione degli utenti'}
                  onClick={() => {
                    history.push('/app/configuration/integrations')
                  }} />
              </div>
              <div>
                  <ConfigurationCard
                      icon={'eye'}
                      title={'Integrazioni'}
                      info={'configurazione canali collegati'}
                      onClick={() => {
                          history.push('/app/configuration/integrations')
                      }} />
              </div>

              <div>
                  <ConfigurationCard
                      icon={'eye'}
                      title={'Polizze'}
                      info={'gestione polizze attive'}
                      onClick={() => {
                          history.push('/app/configuration/policies')
                      }} />
              </div>

              <div>
                  <ConfigurationCard
                      icon={'eye'}
                      title={'Api User'}
                      info={'gestione credenziali API'}
                      onClick={() => {
                          history.push('/app/configuration/api-users')
                      }} />
              </div>
            </div>

        </PageWrapper>
    )

}
