import { useState } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import Chip from "../components/common/Chip"
import PageTitle from "../components/common/PageTitle"
import { PageWrapper } from "../components/common/PageWrapper"
import BaseTable from "../components/data/BaseTable"
import Button from "../components/form/Button"
import { getPolicies } from "../shared/queries"

export default function PoliciesPage () {

    const history = useHistory()

    const {
        data,
        isFetching,
        refetch
    } = useQuery('policies', getPolicies)

    const [create, setCreate] = useState<boolean>(false)

    return (
        <PageWrapper centered maxWidth={'1000px'}>
            
            <PageTitle
                title={'Polizze'}
                renderActions={() => (
                    <Button
                        className="text-white bg-orange-600"
                        label={'Nuova polizza'}
                        onClick={() => {
                            setCreate(true)
                        }} />
                )} />

            <BaseTable
                loading={isFetching}
                filters={[]}
                showFilters={true}
                showColumnSelector={true}
                identifierKey={'idPolicy'}
                onEdit={id => {
                    history.push('/app/configuration/policies/' + id)
                }}
                columns={[
                    {
                        Header: 'Identificativo',
                        accessor: (row: any) => {
                            return row.policyCode
                        }
                    },
                    {
                        Header: 'Descrizione',
                        accessor: 'policyName'
                    },
                    {
                        Header: 'Compagnia',
                        accessor: 'policyCompany'
                    },
                    {
                        Header: 'Tipologia copertura',
                        accessor: (row: any) => (
                            row.policyIsSanitary ? 
                            <Chip className={'text-green-600 bg-green-100'}>
                                Sanitaria
                            </Chip>
                            :
                            <Chip className={'text-blue-600 bg-blue-100'}>
                                Cancellazione
                            </Chip>
                        )
                    }
                ]}
                data={data} />

        </PageWrapper>
    )
    
}