import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { createUser } from "../../../shared/queries";
import { checkPasswordValidity } from "../../../shared/utils";
import { User } from "../../../types";
import userSchema from "../../../validation/userSchema";
import Modal from "../../common/Modal";
import ModalActions from "../../common/ModalActions";
import ModalCard from "../../common/ModalCard";
import FormField from "../../form/FormField";
import TextInput from "../../form/TextInput";
import SelectInput from "../../form/SelectInput";

export default function CreateUser({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<any>([]);
  const [email, setEmail] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userType, setUserType] = useState<"USER" | "AGENT" | "PARTNER">(
    "USER",
  );
  const [passwordMissingParts, setPasswordMissingParts] = useState<string[]>(
    [],
  );

  useEffect(() => {
    if (!password) return;
    setPasswordMissingParts(checkPasswordValidity(password));
  }, [password]);

  const saveMutation = useMutation(
    () =>
      createUser({
        email: email,
        familyName: lastName,
        givenName: firstName,
        hotelName: "",
        password: password,
        promoCode: "",
        customerType: userType,
        username: email,
        partnerId: partnerId,
      }),
    {
      onSuccess: (data: User) => {
        console.log(data);
      },
    },
  );

  function handleSubmit() {
    if (passwordMissingParts.length > 0) {
      toast.error("Invalid password, too weak");
      return;
    }
    const { error, value } = userSchema.validate({
      email: email,
      familyName: lastName,
      givenName: firstName,
      hotelName: "",
      password: password,
      userType: userType,
      promoCode: "",
      username: email,
      partnerId: partnerId,
    });
    if (error) {
      setErrors(error);
      toast.error(t("general.missingFields"));
    } else {
      setErrors(null);
      saveMutation.mutate(value);
    }
  }

  return (
    <Modal visible={visible}>
      <ModalCard
        className={`overflow-hidden w-full max-w-xl`}
        actionsLoading={saveMutation.isLoading}
        title={t("createUser.title")}
        renderActions={() => (
          <ModalActions
            isLoading={saveMutation.isLoading}
            saveLabel={t("general.confirm")}
            closeLabel={t("general.cancel")}
            onClose={() => onClose()}
            onSave={() => handleSubmit()}
          />
        )}
      >
        <div className="p-4 flex flex-col space-y-4">
          <div className="flex space-x-4">
            <FormField
              errors={errors}
              errorKey={"email"}
              className={"flex-1"}
              label={t("createUser.firstName")}
            >
              <TextInput
                value={firstName}
                onChange={(val) => setFirstName(val)}
              />
            </FormField>

            <FormField
              errors={errors}
              errorKey={"password"}
              className={"flex-1"}
              label={t("createUser.lastName")}
            >
              <TextInput
                value={lastName}
                onChange={(val) => setLastName(val)}
              />
            </FormField>
          </div>

          <FormField label={t("Tipo")}>
            <SelectInput
              onChange={(item: any) => {
                if (item) {
                  setUserType(item.value);
                }
              }}
              options={[
                { label: "Utente", value: "USER" },
                { label: "Agente", value: "AGENT" },
                { label: "Partner", value: "PARTNER" },
              ]}
            />
          </FormField>

          {userType === "PARTNER" && (
            <FormField
              errors={errors}
              errorKey={"partnerId"}
              className={"flex-1"}
              label={"Parnter ID"}
            >
              <TextInput
                value={partnerId}
                onChange={(val) => setPartnerId(val)}
              />
            </FormField>
          )}

          <FormField
            errors={errors}
            errorKey={"email"}
            className={"flex-1"}
            label={"Email"}
          >
            <TextInput value={email} onChange={(val) => setEmail(val)} />
          </FormField>

          <FormField
            errors={errors}
            errorKey={"email"}
            className={"flex-1"}
            label={"Password"}
          >
            <TextInput value={password} onChange={(val) => setPassword(val)} />
            <div className="mt-2">
              {passwordMissingParts.length > 0 && (
                <div className={"text-sm font-medium text-red-600"}>
                  {passwordMissingParts.indexOf("LENGTH") > -1 && (
                    <div>{t("signup.minCharacters")}</div>
                  )}
                  {passwordMissingParts.indexOf("SPECIAL") > -1 && (
                    <div>{t("signup.minSpecial")}</div>
                  )}
                  {passwordMissingParts.indexOf("LOWERCASE") > -1 && (
                    <div>{t("signup.lowercase")}</div>
                  )}
                  {passwordMissingParts.indexOf("UPPERCASE") > -1 && (
                    <div>{t("signup.upperCase")}</div>
                  )}
                </div>
              )}
            </div>
          </FormField>
        </div>
      </ModalCard>
    </Modal>
  );
}
