import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useAppSelector } from '../../hooks/redux';
import { getAccomodationById, setupCustomer } from '../../shared/queries';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

const stripePromise = loadStripe('pk_live_51LhX11Esp4ZUkQTsfl3OVJG4TsZmB2yJdWnqjUmvVvsXHStjlA05K6fSHojZgC9ixOSTRb8GqnRMguIdxHfuGCho00qxC3AP0T')

const CheckoutForm = ({ clientSecret, idAccommodation, onCancel } : { idAccommodation?: number, clientSecret: string, onCancel?: () => void }) => {
  const { t } = useTranslation()

  const currentHotelObject = useAppSelector(state => state.hotels.currentHotelObject)

  const stripe = useStripe()
  const elements = useElements()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<any>(null)

  const handleSubmit = async (event: any) => {
    try {
      setLoading(true)
      event.preventDefault()

      if (elements == null || stripe == null) {
        return
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        // Show error to your customer
        setErrorMessage(submitError.message)
        return
      }

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `https://insurance.besafesuite.com/API/billing/payment-callback?rail=STRIPE&idAccomodation=${idAccommodation ? idAccommodation : currentHotelObject?.idAccomodation}`,
        },
      })

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message)
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (e) {
      setErrorMessage('Unable to process payment')
    } finally {
      setLoading(false)
    }
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="flex space-x-2 items-center">
        <button className='px-4 py-2 font-medium bg-orange-600 w-full rounded-md text-lg text-white mt-6'>
          {loading ? t('general.wait') + '...' : t('sepa.save')}
        </button>
        {
          (onCancel !== undefined) &&
          <button onClick={onCancel} className='px-4 py-2 font-medium bg-orange-100 text-orange-600 w-full rounded-md text-lg mt-6'>
            {t('vary.ignoreForNow')}
          </button>
        }
      </div>
    </form>
  )

}

export default function PaymentMethods ({ idAccommodation, onCancel } : { idAccommodation: number, onCancel?: () => void }) {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const { profile } = useAppSelector(state => state.session)

  const [intent, setIntent] = useState<string | undefined>()

  useEffect(() => {
    if (idAccommodation) {
      handleSetupIntent()
    }
  }, [
    idAccommodation
  ])

  async function handleSetupIntent () {
    try {
      setLoading(true)
      const accommodation = await getAccomodationById(idAccommodation)
        
      if (!accommodation || !accommodation.idAccomodation || !accommodation.company || !accommodation.company.companyEmail) {
        alert('Can not generate')
        return
      }

      const intent = await setupCustomer({
        firstName: profile?.userFirstName || accommodation.accomodationName,
        lastName: profile?.userLastName || accommodation.accomodationName,
        email: accommodation.company.companyEmail,
        currency: accommodation.currency || 'eur',
        paymentRail: 'STRIPE',
        idAccommodation: accommodation.idAccomodation
      })
      setIntent(intent.paymentSetupIdentifier)
    } catch (e) {
      alert('Impossibile caricare i metodi di pagamento, ti preghiamo di riprovare più tardi dalla sezione fatturazione')
      if (onCancel) onCancel()
    } finally {
      setLoading(false)
    }
  }
  
  const options = {
    // passing the client secret obtained from the server
    clientSecret: intent,
  }

  if (!intent || loading) return (
    <>
      <Skeleton height={400} />
    </>
  )

  return (
    <div>
      <div className='mb-6 text-slate-500'>
        {t('sepa.modalText')}
      </div>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm idAccommodation={idAccommodation} clientSecret={intent} onCancel={onCancel} />
      </Elements>
    </div>
  )

}