import { useEffect, useState } from "react"
import AddRoomModal from "./AddRoomModal"
import { useMutation } from "react-query"
import { deleteRoom } from "../../shared/queries"
import toast from "react-hot-toast"
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { fetchRooms } from "../../store/slices/offerSlice"
import { classNames } from "../../shared/utils"
import { useTranslation } from "react-i18next"

export default function RoomList ({
  onUpdate,
  onSelect
} : {
  onUpdate: () => void,
  onSelect: (id: number) => void,
}) {

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { currentHotel } = useAppSelector(state => state.hotels)
  const { rooms } = useAppSelector(state => state.offerSlice)

  useEffect(() => {
    if (currentHotel) {
      dispatch(fetchRooms({ idAccomodation: currentHotel }))
    }
  }, [ currentHotel ])

  const [addRoom, setAddRoom] = useState(false)
  const [editRoom, setEditRoom] = useState<number | undefined>()

  const deleteMutation = useMutation((id: any) => deleteRoom(id), {
    onSuccess: () => {
      toast.success(t('quote.roomDeleted'))
      onUpdate()
    },
    onError: () => {
      toast.error(t('quote.cannotCreateRoom'))
    }
  })

  return (
    <div>

      <AddRoomModal
        visible={addRoom}
        editRoom={editRoom}
        onClose={() => {
          dispatch(fetchRooms({ idAccomodation: currentHotel }))
          setAddRoom(false)
          setEditRoom(undefined)
          onUpdate()
        }}
      />

      <div className="flex flex-col">
        {
          (rooms) && rooms.map((room, index) => (
            <button
              onClick={() => {
                if (room.idRoom) onSelect(room.idRoom)
              }}
              key={index}
              className={
                classNames(
                  'hover:bg-gray-100 text-gray-600 w-full flex items-center space-x-4 cursor-pointer p-4',
                  (index < (rooms.length - 1) ? 'border-b' : '')
                )
              }>
              <div className="w-full flex items-center justify-between">
                <div className={'flex items-center space-x-4'}>
                  <div>
                    <div className={'bg-cover bg-center w-16 h-16 rounded-lg'} style={{ backgroundImage: `url(${((room.images.length > 0) ? room.images[0].imageUrl : '')})` }}></div>
                  </div>
                  <div>
                    <div className="text-lg font-medium">{room.name}</div>
                  </div>
                </div>
                <div className={'flex space-x-3 items-center'}>
                  <button className="text-gray-600" onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (room && room.idRoom) {
                      setAddRoom(true)
                      setEditRoom(room.idRoom)
                    }
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                      <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                      <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                    </svg>
                  </button>
                  <button className="text-red-600" onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (window.confirm('Sei sicuro di voler cancellare la camera?')) {
                      deleteMutation.mutate(room.idRoom)
                    }
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                      <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            </button>
          ))
        }
      </div>

      <button onClick={() => setAddRoom(true)} className="w-full mt-4 font-bold text-orange-700 p-4 bg-orange-100 rounded-md text-center">
        {t('quote.addNewRoom')}
      </button>
    </div>
  )

}