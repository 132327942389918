import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
    getDashboardReport,
    getFeeReport,
    getMonthReport,
    getProductionReport
} from "../shared/queries";
import {formatCurrency, kFormatter, tourOptions} from "../shared/utils";
import { RootState } from "../store";
import WelcomeOnboard from "../components/common/WelcomeOnboard";
import {MonthReport} from "../types";
import TourStarter from "../components/Tour";
import SelectInput from "../components/form/SelectInput";
import Popover from "../components/common/Popover";
import Icon from "../components/common/Icon";
import { useHistory } from "react-router-dom";
import { fetchAccomodations } from "../store/slices/hotelsSlice";
import { useAppDispatch } from "../hooks/redux";
import DateRangePicker from "../components/form/DateRangePicker";
import { ShepherdTour } from "react-shepherd";

const time = parseInt(dayjs().format('HH'))

export default function DashboardPage () {

    const dispatch = useAppDispatch()
    
    const { t } = useTranslation()

    const steps = [
        {
            id: 'menu',
            attachTo: { element: '.navmenutour', on: 'bottom' },
            scrollTo: true,
            title: t('tour.title1'),
            text: [
            t('tour.text1')
            ],
            classes: 'bg-white rounded-md shadow-xl',
            buttons: [
                {
                    text: t('general.done'),
                    type: 'cancel',
                    classes: 'shep-exit'
                },
                {
                    text: t('general.next'),
                    classes: 'shep-next',
                    type: 'next'
                }
            ],
        },
        {
            id: 'invoices',
            attachTo: { element: '.invoicestour', on: 'bottom' },
            scrollTo: true,
            title: t('tour.title2'),
            text: [t('tour.text2')],
            classes: 'bg-white rounded-md shadow-xl',
            buttons: [
                {
                    text: t('general.prev'),
                    type: 'back',
                    classes: 'shep-back'
                },
                {
                    text: t('general.next'),
                    classes: 'shep-next',
                    type: 'next'
                }
            ]
        },
        {
            id: 'globalsearch',
            attachTo: { element: '.globalsearchtour', on: 'bottom' },
            scrollTo: true,
            title: t('tour.title3'),
            text: [t('tour.text3')],
            classes: 'bg-white rounded-md shadow-xl',
            buttons: [
                {
                    text: t('general.prev'),
                    type: 'back',
                    classes: 'shep-back'
                },
                {
                    text: t('general.done'),
                    type: 'cancel',
                    classes: 'shep-exit'
                },
            ]
        }
    ] as any

    const history = useHistory()

    const {
        accommodations,
        fetchingHotels
    } = useSelector((state: RootState) => state.hotels)

    const { profile } = useSelector((state: RootState) => state.session)
    const [filterHotel, setFilterHotel] = useState<any>([])
    const [dateFrom, setDateFrom] = useState<Date>(dayjs().startOf('month').toDate())
    const [dateTo, setDateTo] = useState<Date>(dayjs().endOf('month').toDate())

    const {
        data: productionReport,
        refetch,
        isFetching: fetchingReport
    } = useQuery(['production', dateFrom, dateTo, filterHotel], () => getProductionReport({
        dateFrom: parseInt(dayjs(dateFrom).format('YYYYMMDD')),
        dateTo: parseInt(dayjs(dateTo).format('YYYYMMDD')),
        idAccomodations: filterHotel.map((h: any) => parseInt(h)),
        maxResults: 0,
        pageOffset: 0
    }))

    const {
        data: feeReport,
        refetch: refetchFeeReport,
    } = useQuery(['fee'], () => getFeeReport({
        dateFrom: parseInt(dayjs(dateFrom).format('YYYYMMDD')),
        dateTo: parseInt(dayjs(dateTo).format('YYYYMMDD')),
        idAccomodations: filterHotel.map((h: any) => parseInt(h)),
        maxResults: 0,
        pageOffset: 0
    }))

    const {
        data: monthReport,
        refetch: refetchMonthReport,
    } = useQuery(['month', filterHotel], () => getMonthReport({
        dateFrom: parseInt(dayjs().startOf('year').format('YYYYMMDD')),
        dateTo: parseInt(dayjs().endOf('year').format('YYYYMMDD')),
        idAccomodations: filterHotel.map((h: any) => parseInt(h)),
        maxResults: 0,
        pageOffset: 0
    }))

    const {
        data: dashboardReport,
        refetch: refetchDashboardReport
    } = useQuery(['dashboard', filterHotel], () => getDashboardReport(
        filterHotel.map((h: any) => parseInt(h))
    ))

    const [page, setPage] = useState<number>(0)

    const DashboardRow = ({
        title,
        value,
        loading,
        divider = true
    } : {
        title: string,
        value: string | number,
        loading: boolean,
        divider?: boolean
    }) => {
        return (
            <>
                <div className="px-4 flex justify-between hover:bg-orange-100 text-gray-600 py-2">
                    <div className="w-auto text-md">{title}</div>
                    <div className="flex-1 text-md text-right">
                        {
                        !loading &&
                        <NumberFormat
                            value={value}
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator={','}
                            thousandSeparator={'.'}
                            prefix={'€ '} />
                        }
                        {loading && <Skeleton />}
                    </div>
                </div>
                {
                    divider &&
                    <div className={'px-4'}>
                        <div className={'border-b border-dashed'}></div>
                    </div>
                }
            </>
        )
    }

    function getMonthData (key: string) {
        try {
            if (!monthReport) throw new Error('No data')
            const monthData = monthReport.find(m => m.periodMonth.toString() === key)
            if (!monthData) return {
                periodMonth: 0,
                totalCashed: 0,
                totalBooking: 0,
                heightPercent: 0
            } as MonthReport
            if (monthReport) return {
                ...monthData,
                heightPercent: ((monthData.totalCashed / monthData.totalBooking) * 100)
            }
            return {
                periodMonth: 0,
                totalCashed: 0,
                totalBooking: 0,
                heightPercent: 0
            } as MonthReport & { heightPercent: number }
        } catch (e) {
            return {
                periodMonth: 0,
                totalCashed: 0,
                totalBooking: 0,
                heightPercent: 0
            } as MonthReport & { heightPercent: number }
        }
    }

    function multiValueContainer ({ selectProps, data } : any) {
        const label = data.label;
        const allSelected = selectProps.value;
        const index = allSelected.findIndex((selected: any) => selected.label === label);
        const isLastSelected = index === allSelected.length - 1;
        const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
        const val = `${label}${labelSuffix}`;
        return val;
    }

    function getNonNegative (value: number) {
        if (value < 0) return 0
        return value   
    }

    useEffect(() => {
        dispatch(fetchAccomodations())
    }, [])

    return (
        <ShepherdTour steps={steps} tourOptions={tourOptions}>
            <div className="w-full">
                <TourStarter />
                <WelcomeOnboard />

                <div className="w-full px-12 bg-orange-500 relative">
                    <div className="pt-32 pb-44">
                        <div className="text-white text-6xl font-bold">
                            {time > 12 ? t('vary.goodevening') : t('vary.goodmorning')}
                        </div>
                        <div className="text-white text-2xl mt-2">
                            {t('vary.newDashboard')}
                        </div>
                    </div>

                    <img src="/assets/dashboard.svg" alt="Analytics" className="absolute right-0" style={{ height: '85%', bottom: '0' }} />
                </div>

                <div className="relative -top-20 px-12">
                    <div className=" text-white mb-4 text-2xl font-medium">
                        {t('vary.currentYear')}
                    </div>
                    <div className="flex space-x-6 ">
                        <div className="h-44 flex-1 rounded-md bg-white shadow-xl p-6 text-center flex items-center justify-center flex-col space-y-2">
                            <div className="text-4xl text-gray-700 font-semibold">
                                {kFormatter(dashboardReport?.reduce((p, c) => p + c.insuredGuests, 0))}
                            </div>
                            <div className="text-gray-600 text-lg">{t('vary.protectedTravellers')}</div>
                        </div>
                        <div className="h-44 flex-1 rounded-md bg-white shadow-xl p-6 text-center flex items-center justify-center flex-col space-y-2">
                            <div className="text-4xl text-gray-700 font-semibold">
                                {formatCurrency(
                                    getNonNegative(dashboardReport ? dashboardReport.reduce((p, c) => p + c.insuredAmount, 0) : 0)
                                )}
                            </div>
                            <div className="text-gray-600 text-lg">{t('vary.securedCashflow')}</div>
                        </div>
                        <div className="h-44 flex-1 rounded-md bg-white shadow-xl p-6 text-center flex items-center justify-center flex-col space-y-2">
                            <div className="text-4xl text-gray-700 font-semibold">
                                {formatCurrency(
                                    getNonNegative(dashboardReport ? dashboardReport.reduce((p, c) => p + c.totalBookings, 0) - dashboardReport.reduce((p, c) => p + c.insuredAmount, 0) : 0)
                                )}
                            </div>
                            <div className="text-gray-600 text-lg">{t('vary.unsecuredCashflow')}</div>
                        </div>
                        <div className="h-44 flex-1 rounded-md bg-white shadow-xl p-6 text-center flex items-center justify-center flex-col space-y-2">
                            <div className="text-4xl text-gray-700 font-semibold">
                                {formatCurrency(
                                    getNonNegative((dashboardReport ? dashboardReport.reduce((p, c) => p + c.totalBookings, 0) : 0) * 10 / 100)
                                )}
                            </div>
                            <div className="text-gray-600 text-lg">{t('vary.otaCost')}</div>
                        </div>
                    </div>
                </div>

                <div className="flex space-x-4 justify-center items-center">
                    <div className="border-gray-200 border-b flex-1"></div>
                    <div className="relative">
                        <div className="absolute -top-8 w-full text-center font-medium text-lg mb-1">{t('accommodations.accommodations')}</div>
                        <Popover renderContent={() => (
                            <div className="w-56">
                                <SelectInput
                                    autoFocus={true}
                                    defaultMenuIsOpen={true}
                                    isMulti={true}
                                    backspaceRemovesValue={false}
                                    hideSelectedOptions={false}
                                    isClearable={false}
                                    controlShouldRenderValue={false}
                                    menuIsOpen={true}
                                    tabSelectsValue={false}
                                    components={{
                                        IndicatorSeparator: null,
                                        DropdownIndicator: null,
                                        MultiValueContainer: multiValueContainer
                                    }}
                                    styles={{
                                        container: (provided: any) => ({ ...provided, background: '#FFF' }),
                                        control: (provided: any) => ({ ...provided, minWidth: '100%' }),
                                        menuPortal: (base: any) => ({ position: 'relative', top: 0, left: 0, zIndex: 999999 }),
                                        menu: () => ({ position: 'relative' })
                                    }}
                                    placeholder={'Ricerca struttura'}
                                    loading={fetchingHotels}
                                    options={accommodations ? accommodations.map(a => {
                                        return {
                                            label: a.accomodationName,
                                            value: a.idAccomodation
                                        }
                                    }) : []}
                                    onChange={(val: any) => {
                                        if (val) setFilterHotel(val.map((v: any) => v.value))
                                    }} />
                            </div>
                        )}>
                            <button className="text-left font-medium w-full bg-white rounded-md appearance-none px-2 py-1 focus:outline-none h-9 cursor-pointer hover:bg-gray-100" style={{
                                boxShadow: '0px 2px 3px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1)'
                            }}>
                                <div className="flex space-x-2 items-center">
                                    <div className="flex-1">
                                        { (filterHotel && filterHotel.length === 0) && t('Tutte le strutture') }
                                        { (filterHotel && filterHotel.length > 0) && filterHotel.length + ' ' + t('strutture') }
                                    </div>
                                    <div className={'text-gray-500'}>
                                        <Icon name={'search'} size={'20px'} />
                                    </div>
                                </div>
                            </button>
                        </Popover>
                    </div>
                    <div className="relative">
                        <div className="absolute -top-8 w-full text-center font-medium text-lg mb-1">{t('vary.period')}</div>
                        <DateRangePicker onStart={val => setDateFrom(val)} onEnd={val => setDateTo(val)} />
                    </div>
                    <div className="border-gray-200 border-b flex-1"></div>
                </div>

                <div className="px-12 pb-24">
                    <div className="mt-16 flex space-x-20">

                        <div className="w-1/2">
                        <div className="mb-6 text-2xl font-semibold text-gray-600">{t('vary.overviewByPayment')}</div>
                            <div className="border border-gray-300 rounded-md p-6">
                                <div className="flex space-x-2">
                                    <div className="flex-1">
                                        <div className="text-2xl text-gray-700 font-semibold">
                                            {formatCurrency(productionReport?.cashed || 0)}
                                        </div>
                                        <div className="text-gray-600 text-lg mt-1">{t('vary.valueInsured')}</div>
                                    </div>
                                    <div className="px-4">
                                        <div className="border-r h-full"></div>
                                    </div>
                                    <div className="flex-1">
                                        <div className="text-2xl text-gray-700 font-semibold">
                                            {formatCurrency(productionReport ? (productionReport?.total - productionReport?.cashed) > 0 ? (productionReport?.total - productionReport?.cashed) : 0 : 0)}
                                        </div>
                                        <div className="text-gray-600 text-lg mt-1">{t('vary.valueNotInsured')}</div>
                                    </div>
                                    <div className="px-4">
                                        <div className="border-r h-full"></div>
                                    </div>
                                    <div className="flex-1">
                                        <div className="text-2xl text-gray-700 font-semibold">
                                            {formatCurrency(productionReport ? (productionReport.beSafeTotal + productionReport.insuranceTotal + productionReport.agentTotal + productionReport.subAgentTotal) : 0)}
                                        </div>
                                        <div className="text-gray-600 text-lg mt-1">{t('vary.generatedCommission')}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-6 text-2xl font-semibold text-gray-600 mt-6">{t('vary.monthOverview')}</div>
                            <div className="border rounded-md border-gray-300 overflow-hidden">
                                <table className="w-full table-auto">
                                    <thead className="border-b border-gray-300">
                                        <tr>
                                            <th className="px-4 py-1 text-left">{t('vary.month')}</th>
                                            <th className="px-4 py-1 text-left">{t('vary.booked')}</th>
                                            <th className="px-4 py-1 text-left">{t('vary.insured')}</th>
                                            <th className="px-4 py-1 text-left">%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[1,2,3,4,5,6,7,8,9,10,11,12].map((m, mI) => (
                                            <tr key={mI} className="border-b border-gray-300">
                                                <td className="capitalize px-4 py-1">{dayjs().month(m - 1).format('MMMM')}</td>
                                                <td className="px-4 py-1">{formatCurrency(getMonthData(m.toString()).totalBooking)}</td>
                                                <td className="px-4 py-1">{formatCurrency(getMonthData(m.toString()).totalCashed)}</td>
                                                <td className="px-4 py-1">{(isNaN(getMonthData(m.toString()).totalCashed / getMonthData(m.toString()).totalBooking * 100) ? 0 : getMonthData(m.toString()).totalCashed / getMonthData(m.toString()).totalBooking * 100).toFixed()}%</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="text-gray-600 mt-2">{t('vary.oldInvoices')} <button onClick={() => history.push('/app/invoices')} className="underline text-orange-700 font-medium">{t('vary.invoiceSection')}.</button></div>
                        </div>

                        <div className="flex-1">
                            <div className="mb-6 text-2xl font-semibold text-gray-600">
                                {t('vary.beSafeNews')}
                            </div>

                            <div className="relative mb-4 flex space-x-2 items-center">
                                <span className="text-xs bg-orange-600 px-2 py-1 rounded-full text-white font-medium">
                                    {t('vary.communications')}
                                </span>
                                <div className="text-xl text-gray-700 font-bold">
                                    {t('vary.aNewDashboard')}
                                </div>
                                <div>
                                    <span className="relative flex h-3 w-3">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                    </span>
                                </div>
                            </div>

                            <div className="leading-relaxed text-gray-700">
                                {t('vary.newDashboardText')}
                            </div>

                            <div className="shadow-md border mt-8 flex flex-col rounded-md overflow-hidden space-y-4">
                                <a target="_blank" href="http://get.besaferate.com/besafe-rate-plus">
                                    <img src={t('dashboardbanner')} />
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </ShepherdTour>
    )
}
