import { useTranslation } from "react-i18next"

type FormSectionTitleProps = {
  icon?: any,
  title: string,
  subtitle?: string,
  borderless?: boolean,
  className?: string,
  editLabel?: string,
  edit?: boolean,
  dense?: boolean,
  onEdit?: () => void
}

export default function FormSectionTitle ({
  icon,
  title,
  subtitle,
  borderless,
  editLabel,
  className,
  edit = false,
  dense = false,
  onEdit
} : FormSectionTitleProps) {


  const { t } = useTranslation()

  return (
    <div className={`pt-8 border-gray-300 ${dense ? 'mb-2' : 'mb-4'} ${className}`}>
      <div className={`${!borderless && 'border-b'} pb-2 flex flex-wrap md:flex-nowrap justify-between`}>

        <div className={'text-lg text-gray-800 font-semibold'}>
          {icon && icon} {title}

          {
            subtitle &&
            <div className={'text-sm mt-1 font-normal text-gray-600'}>
              {subtitle}
            </div>
          }
        </div>

        {
          edit &&
          <div>
            <button
              onClick={() => {
                if (onEdit) onEdit()
              }}
              className={'bg-white btn-shadow text-sm text-orange-600 focus:outline-none focus:ring-2 ring-cyan-600 font-medium hover:bg-cyan-100 md:px-2 py-1 rounded text-cyan-600'}>
              {editLabel || t('general.edit')}
            </button>
          </div>
        }

      </div>
    </div>
  )

}
