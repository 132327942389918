import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getCompanies } from "../../../shared/queries";
import { Company } from "../../../types";
import SelectInput from "../../form/SelectInput";
import Link from "../../navigation/Link";
import CreateCompany from "../companies/CreateCompany";

export default function CompanySelector ({
    onSelect
} : {
    onSelect: (item: Company) => void
}) {

    const { t } = useTranslation()

    const [value, setValue] = useState<any>()
    const [create, setCreate] = useState<boolean>(false)

    const {
        data,
        refetch,
        isFetching
    } = useQuery('companies', getCompanies)

    return (
        <>
            <SelectInput
                value={value}
                options={data ? data.map(c => {
                    return {
                        label: c.companyName,
                        value: c.idCompany
                    }
                }) : []}
                onChange={(item: any) => {
                    if (data) {
                        const company = data.find(c => c.idCompany === item.value)
                        if (company) onSelect(company)
                    }
                }}
                isLoading={isFetching} />
            <div className={'mt-2'}>
                <Link
                    className={'text-sm'}
                    onClick={() => setCreate(true)}>
                    {t('company.companyNew')}
                </Link>
            </div>

            <CreateCompany
                visible={create}
                onSave={company => {
                    refetch().then(() => {
                        setValue({
                            label: company.companyName,
                            value: company.idCompany
                        })
                        onSelect(company)
                        setCreate(false)
                    })

                }}
                onClose={() => {
                    setCreate(false)
                }} />
        </>
    )

}