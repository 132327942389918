import styled from 'styled-components'
import { useEffect, useRef } from "react"
import Icon from '../common/Icon'

const CustomInput = styled.input<any>`
  box-shadow: 0px 2px 3px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  :focus{
    outline: none;
    box-shadow: 0px 0px 0px 4px #f59e0b40, 0px 0px 0px 1px #f59e0b90;
  }
`

const CustomTextarea = styled.textarea<any>`
  box-shadow: 0px 2px 3px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  :focus{
    outline: none;
    box-shadow: 0px 0px 0px 4px #f59e0b40, 0px 0px 0px 1px #f59e0b90;
  }
`

const numberExpression = new RegExp('^[0-9.,]+$')

type TextInputProps = {
  type?: string,
  suffix?: string,
  readonly?: boolean,
  placeholder?: string,
  className?: string,
  value?: string | number,
  onChange?: (value:any) => void,
  numbered?: Boolean,
  max?: number,
  onBlur?: () => void,
  min?: number,
  size?: string,
  renderButton?: Function,
  autofocus?: boolean,
  clearable?: boolean,
  ref?: any
}

export default function TextInput ({
  ref,
  type = 'text',
  clearable = false,
  readonly = false,
  suffix,
  size,
  placeholder,
  className,
  value = '',
  onChange,
  numbered,
  max,
  min,
  renderButton,
  autofocus = false,
  onBlur
} : TextInputProps) {

  const internalRef = useRef<any>()

  useEffect(() => {
    if (internalRef && internalRef.current) {
      if (autofocus) internalRef.current.focus()
    }
  }, [
    internalRef
  ])

  return (
    <div className={'relative w-full'}>
      {
        type !== 'textarea' &&
          <CustomInput
              ref={ref ? ref : internalRef}
              placeholder={placeholder}
              type={type}
              onBlur={onBlur}
              className={`w-full rounded-md appearance-none px-2 py-1 ${size === 'sm' ? 'h-8 text-sm' : 'h-9'} ${size === 'lg' && 'h-12 text-lg'} focus:outline-none ${className} ${readonly ? 'bg-gray-100 text-gray-600' : 'bg-white'}`}
              value={value}
              readonly={readonly}
              onInput={(event: any) => {
                if (readonly) return
                let updateValue = event.target.value.toString()

                if (onChange) {
                  if (numbered) {
                    updateValue = updateValue.replace(',', '.')
                    if (numberExpression.test(updateValue) || updateValue === '') {
                      onChange(updateValue)
                    }
                  } else {
                    onChange(updateValue)
                  }
                }
              }} />
      }

      {
        type === 'textarea' &&
        <CustomTextarea
            onInput={(event: any) => {
              let updateValue = event.target.value.toString()

              if (onChange) {
                onChange(updateValue)
              }
            }}
            value={value}
            placeholder={placeholder}
            className={`shadow border w-full bg-white rounded-md appearance-none px-2 py-1 focus:outline-none ${className}`}
            rows={4} />
      }

      {
        (suffix || numbered || renderButton || clearable) &&
        <div className={'flex py-2 absolute top-0 right-0 flex h-full text-sm text-gray-500 font-bold'}>

          {
            renderButton &&
            renderButton()
          }

          {
            suffix &&
            <div className={'px-2 border-l flex items-center h-full'}>
              {suffix}
            </div>
          }

          {
            numbered &&
            <div className={'border-l flex items-center h-full'}>
              <button
                tabIndex={-1}
                className={'focus:outline-none cursor-pointer hover:text-cyan-600 px-2 h-full border-r items-center flex justify-center'}
                onClick={() => {
                  if (value === undefined) return
                  let floatVal = parseFloat(value.toString())
                  if (!isNaN(floatVal)) {
                    if (max !== undefined && ((floatVal) >= max)) return
                    floatVal = floatVal + 1
                    if (onChange) onChange(floatVal)
                  }
                }}>
                <Icon name={'chevron-up'} size={'12px'}></Icon>
              </button>
              <button
                tabIndex={-1}
                className={'focus:outline-none cursor-pointer hover:text-cyan-600 px-2 h-full items-center flex justify-center'}
                onClick={() => {
                  if (value === undefined) return
                  let floatVal = parseFloat(value.toString())
                  if (!isNaN(floatVal)) {
                    if (min !== undefined && ((floatVal) <= min)) return
                    floatVal = floatVal - 1
                    if (onChange) onChange(floatVal)
                  }
                }}>
                <Icon name={'chevron-down'} size={'12px'}></Icon>
              </button>
            </div>
          }

          {
            clearable &&
            <div className={'border-l flex items-center h-full'}>
            <button
              tabIndex={-1}
              className={'focus:outline-none cursor-pointer hover:text-cyan-600 px-2 h-full border-r items-center flex justify-center'}
              onClick={() => {
                if (onChange) {
                  onChange('')
                  if (ref && ref.current) ref.current.focus()
                }
              }}>
              <Icon name={'x-circle'} size={'20px'}></Icon>
            </button>
          </div>
          }

        </div>
      }
    </div>
  )

}
