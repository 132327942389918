import queryString from 'query-string'
import FormField from "../components/form/FormField";
import TextInput from "../components/form/TextInput";
import Checkbox from "../components/form/Checkbox";
import Button from "../components/form/Button";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { fetchPartnerBySlug, getPromoCode, signup } from "../shared/queries";
import { useHistory, useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ApiError, Promocode } from "../types";
import { useTranslation } from "react-i18next";
import Icon from "../components/common/Icon";
import LanguageSelector from "../components/common/LanguageSelector";
import { checkPasswordValidity, classNames } from "../shared/utils";
import SelectInput from "../components/form/SelectInput";
import Confetti from 'react-confetti'

const script = document.createElement('script')
script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WV8D2D2');`
document.head.appendChild(script)

const PARTNERS = [
    { promocode: 'BESTWESTERN100', key: 'bestwestern', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Best_Western_Hotels_%26_Resorts_logo.svg/2560px-Best_Western_Hotels_%26_Resorts_logo.svg.png' },
    { promocode: 'FDG', key: 'francescadigirolamo', logo: 'https://francescadigirolamo.it/wp-content/uploads/2023/08/cropped-favicon-180x180.jpg'},
    { promocode: 'RETORICA', key: 'retorica', logo: 'https://retorica.net/wp-content/uploads/2020/09/retorica-logo-no-comunicazione-colore-sito-2020-01.png'},
]

export default function SignupPage () {

    const history = useHistory()
    const { t } = useTranslation()

    const { search } = useLocation()

    const [customerType, setCustomerType] = useState('HOTEL')
    const [viewConfetti, setViewConfetti] = useState(false)
    const [viewPassword, setViewPassword] = useState(false)
    const [privacy, setPrivacy] = useState(false)
    const [contract, setContract] = useState(false)
    const [email, setEmail] = useState('')
    const [promocode, setPromocode] = useState('')
    const [promoDetails, setPromoDetails] = useState<Promocode>()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [structure, setStructure] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordMissingParts, setPasswordMissingParts] = useState<string[]>([])
    const [currentPartner, setCurrentPartner] = useState<{ logo: string, key: string }>()

    const customerTypes = [
        { label: t('vary.hotel'), value: 'HOTEL' },
        { label: t('vary.adv'), value: 'AGENCY' },
        { label: t('vary.tos'), value: 'TO' }
    ]

    const mutation = useMutation(() => signup({
        email,
        givenName: firstName,
        familyName: lastName,
        password,
        resetPassword: false,
        hotelName: structure,
        promoCode: promocode,
        customerType: customerType,
        username: email
    }), {
        onSuccess: () => {
            history.push('/app/dashboard')
        },
        onError: (data: ApiError) => {
            if (data && data.message) {
                toast.error(data.message)
            }
        }
    })

    useEffect(() => {
        if (!password) return
        setPasswordMissingParts(checkPasswordValidity(password))
    }, [password])

    function getTermsLink () {
        switch (customerType) {
            case 'HOTEL':
                return t('signup.termsLink')
            default:
                return t('signup.termsAdvLink')
        }
    }

    async function handleApplyCode (code: string) {
        try {
            if (!code || code === '') return
            const data = await getPromoCode(code)
            if (data && data.idPromocode) {
                setPromoDetails(data)
                setViewConfetti(true)
                setTimeout(() => {
                    setViewConfetti(false)
                }, 12000)
            }
        } catch (e) {
            setPromoDetails(undefined)
        }
    }

    async function handleLoadCode () {

    }

    async function handleLoadPartner () {
        try {
            const values = queryString.parse(search)
            if (!values || !values.partner) return
            const partner = await fetchPartnerBySlug(values.partner as any)
            if (partner) {
                setCurrentPartner({
                    key: partner.partnerSlug,
                    logo: partner.partnerLogoUrl
                })
                if (values.promoCode) {
                    setPromocode(values.promoCode as any)
                    handleApplyCode(values.promoCode as any)
                }
            }
        } catch (e) {
            return null
        }
    }

    useEffect(() => {
        handleLoadPartner()
    }, [ search ])

    return (
        <div className="w-screen h-screen flex">
            {
                viewConfetti && <Confetti />
            }
            <div className="w-full md:w-1/2 lg:w-1/3 overflow-auto p-4 md:p-10">
                <div className="mb-6 mt-4 flex items-center space-x-6">
                    <img
                        className={
                            classNames(
                                currentPartner ? 'h-8' : 'h-12',
                                'mb-4'
                            )
                        }
                        src="https://besafesuite.com/wp-content/themes/besafe/assets/images/besuite_logo.svg" alt=""/>

                    {
                        currentPartner && <div className='h-8 text-gray-400'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z" clipRule="evenodd" />
                            </svg>
                        </div>
                    }

                    {
                        currentPartner &&
                        <img
                            className={classNames(
                                currentPartner ? 'h-8' : 'h-12',
                                'mb-4'
                            )}
                            src={currentPartner.logo} alt=""/>
                    }
                </div>
                
                <div className='mb-4 md:hidden'>
                    <LanguageSelector />
                </div>

                <div className="text-2xl md:text-4xl leading-tight font-black text-gray-700">
                    {t('signup.signupFree')}
                </div>
                <div className="text-gray-600 leading-relaxed md:text-lg mt-2 font-normal">
                    {t('signup.intro')}.
                </div>

                <div
                    className="mt-6 flex flex-col space-y-4 rounded-md bg-white">
                    <div className="flex flex-wrap xl:flex-nowrap space-y-2 xl:space-y-0 xl:space-x-4">
                        <FormField required={true} className={'w-full'} label={t('vary.whatYouDo')}>
                            <SelectInput
                                value={
                                    customerType ? customerTypes.find(ct => ct.value === customerType) : undefined
                                }
                                className={'w-full'}
                                onChange={(item: any) => {
                                    if (!item) return
                                    setCustomerType(item.value)
                                }}
                                options={customerTypes}
                            />
                        </FormField>
                    </div>
                    <div className="flex flex-wrap xl:flex-nowrap space-y-2 xl:space-y-0 xl:space-x-4">
                        <div className="w-full xl:flex-1">
                            <FormField
                                required={true}
                                label={t('signup.name')}>
                                <TextInput
                                    value={firstName}
                                    onChange={val => setFirstName(val)} />
                            </FormField>
                        </div>

                        <div className="w-full xl:flex-1">
                            <FormField
                                required={true}
                                label={t('signup.surname')}>
                                <TextInput
                                    value={lastName}
                                    onChange={val => setLastName(val)} />
                            </FormField>
                        </div>
                    </div>

                    <div className="flex flex-wrap md:flex-nowrap space-x-0 space-y-4 md:space-y-0 md:space-x-4">
                        <div className="w-full md:w-1/2">
                            <FormField
                                required={true}
                                label={t('signup.structure')}>
                                <TextInput
                                    value={structure}
                                    onChange={val => setStructure(val)} />
                            </FormField>
                        </div>
                        <div className="w-full md:w-1/2">
                            <FormField
                                required={true}
                                label={'Email'}>
                                <TextInput
                                    value={email}
                                    onChange={val => setEmail(val)} />
                            </FormField>
                        </div>
                    </div>

                    <div className="flex flex-wrap md:flex-nowrap space-x-0 space-y-4 md:space-y-0 md:space-x-4">
                        <div className="w-full md:flex-1">
                            <FormField
                                required={true}
                                label={'Password'}>
                                <div className="flex space-x-4 items-center">
                                    <TextInput
                                        type={viewPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={val => setPassword(val)} />
                                </div>
                                <div className="mt-2">
                                    {
                                        (passwordMissingParts.length > 0) &&
                                        <div className={'text-sm font-medium text-red-600'}>
                                            {
                                            (passwordMissingParts.indexOf('LENGTH') > -1) &&
                                            <div>{t('signup.minCharacters')}</div>
                                            }
                                            {
                                            (passwordMissingParts.indexOf('SPECIAL') > -1) &&
                                            <div>{t('signup.minSpecial')}</div>
                                            }
                                            {
                                            (passwordMissingParts.indexOf('LOWERCASE') > -1) &&
                                            <div>{t('signup.lowercase')}</div>
                                            }
                                            {
                                            (passwordMissingParts.indexOf('UPPERCASE') > -1) &&
                                            <div>
                                                {t('signup.upperCase')}
                                            </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </FormField>
                        </div>

                        <div className="w-full md:flex-1">
                            <FormField
                                required={true}
                                label={t('signup.confirmPassword')}>
                                <div className="flex space-x-4 items-center">
                                    <TextInput
                                        type={viewPassword ? 'text' : 'password'}
                                        value={confirmPassword}
                                        onChange={val => setConfirmPassword(val)} />
                                    <div
                                        onClick={() => setViewPassword(!viewPassword)}
                                        className={'cursor-pointer text-gray-600'}>
                                        {
                                            viewPassword ?
                                                <Icon name={'eye'} size={'25px'} />
                                                :
                                                <Icon name={'eye-off'} size={'25px'} />
                                        }
                                    </div>
                                </div>
                            </FormField>
                        </div>
                    </div>

                    {
                        promoDetails
                        ? <div className="bg-blue-50 p-4 rounded-md text-blue-600 flex items-center space-x-3">
                            <div className="text-lg">
                                🥳
                            </div>
                            <div>
                                <strong>Promocode</strong> {promocode} {t('vary.discountActivated')} {promoDetails.credits} crediti
                            </div>
                        </div>
                        : <div className="flex space-x-2 pb-4">
                            <div className="flex-1">
                                <TextInput
                                    placeholder={t('vary.havePromocode')}
                                    value={promocode}
                                    onChange={val => setPromocode(val)} />
                            </div>
                            <div>
                                <button
                                    onClick={() => handleApplyCode(promocode)}
                                    className={
                                        classNames(
                                            (!promocode || promocode === '') ? 'opacity-20' : '',
                                            'h-full px-4 hover:bg-blue-200 bg-blue-100 text-blue-600 rounded-md'
                                        )
                                    }>
                                        {t('vary.apply')}
                                </button>
                            </div>
                        </div>
                    }

                    <Checkbox
                        checked={contract}
                        onChange={() => setContract(!contract)}
                        renderLabel={() => (
                        <div>
                            {t('signup.accept')} <a
                            href="#"
                            onClick={e => {
                                e.preventDefault()
                                window.open(getTermsLink(), '_blank')
                            }}
                            className={'text-blue-600'}>{t('signup.termsAndCondition')}</a> {t('signup.ofService')}
                        </div>
                        )} />

                    <Checkbox
                        checked={privacy}
                        onChange={() => setPrivacy(!privacy)}
                        renderLabel={() => (
                        <div>
                            {t('signup.accept')} <a
                            onClick={e => {
                                e.preventDefault()
                                window.open('https://www.iubenda.com/privacy-policy/56943492', '_blank')
                            }}
                            href="#" className={'text-blue-600'}>{t('signup.privacyAndCookie')}</a>.
                        </div>
                        )} />

                    <div className='pb-24 md:pb-0'>
                        <Button
                            loading={mutation.isLoading}
                            onClick={() => {
                                if (password !== confirmPassword) {
                                    toast.error(t('signup.passwordNoMatch'))
                                    return
                                }
                                if (passwordMissingParts.length > 0) {
                                    toast.error(t('signup.invalidPassword'))
                                    return
                                }
                                if (
                                !email || email === '' &&
                                !firstName || firstName === '' &&
                                !lastName || lastName === '' &&
                                !password || password === ''
                                ) {
                                    toast.error(t('signup.missingFields'))
                                    return
                                }

                                if (!privacy || !contract) {
                                    toast.error(t('signup.mustAccept'))
                                    return
                                }

                                mutation.mutate()
                            }}
                            disabled={passwordMissingParts.length > 0}
                            className={'w-full tranistion-all py-3 px-6 rounded-md text-lg font-semibold text-white bg-blue-600'}
                            label={t('signup.signupNow')} />
                    </div>
                </div>
            </div>
            <div className="hidden md:block flex-1">
                <div
                    style={{
                        backgroundImage: 'url(/bg_signup.png)'
                    }}
                    className="relative bg-cover bg-center w-full h-full">
                    <div className="flex p-4 justify-end w-full">
                        <LanguageSelector />
                    </div>

                    <div className="p-12 absolute bottom-0 left-0">
                        <div className="text-5xl lg:text-7xl font-black text-white">
                            The next level insurance <br/>and payments partner
                        </div>
                        <div className="mt-16 mb-8 lg:mb-0 text-center text-white text-xl font-bold">
                            You are in very good company
                        </div>
                        <div className="px-6 flex items-center justify-center">
                            <img className="hidden lg:block" src="/logos.png" alt="" />
                            <img className="hidden sm:block md:block lg:hidden xl:hidden" src="/logos_mobile.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}