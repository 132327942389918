import ReactTooltip from 'react-tooltip'
import Icon from '../common/Icon'
import { useTranslation } from 'react-i18next'

type FormFieldProps = {
  label?: string | undefined,
  className?: string,
  tooltip?: string,
  children: any,
  required?: boolean,
  optional?: boolean,
  errors?: any,
  errorKey?: string
}

export default function FormField ({
  label,
  children,
  className,
  tooltip,
  optional = false,
  required = false,
  errors,
  errorKey
} : FormFieldProps) {

  const { t } = useTranslation()

  return (
    <div className={className}>
      <div className={'mb-2 text-gray-500 font-medium'}>
        <div className={'flex items-center'}>
          {label}
          { required && <span className={'text-red-600'}>*</span>}
          { optional && <span className={'ml-2 text-xs bg-gray-100 text-gray-700 rounded-md px-2 py-1'}>{t('general.optional')}</span>}
          {
            tooltip &&
            <div data-tip={tooltip}>
              <Icon
                name={'information-circle'}
                className={'ml-2 cursor-pointer'}
                size={'15px'} />
            </div>
          }
        </div>
      </div>
      <div>
        {children}
      </div>
      {
        tooltip &&
        <ReactTooltip></ReactTooltip>
      }
      {
        (errors && errors.details && errorKey) && <div className="mt-2 text-sm font-medium text-red-600">
          { errors.details.filter((d: any) => d.context.key == errorKey).map((e: any) => (
            <div>
              {e.message}
            </div>
          ))}
        </div>
      }
    </div>
  )

}
