import dayjs from "dayjs"
import NumberFormat from "react-number-format"
import { useQuery } from "react-query"
import { getProductionReport } from "../../../shared/queries"
import DetailRow from "../../data/DetailRow"
import SectionCard from "../../SectionCard";

export default function HotelProduction ({
  idAccomodation
} : {
  idAccomodation: string | number
}) {

  const {
    data,
    isFetching
} = useQuery('production', () => getProductionReport({
    dateFrom: parseInt(dayjs().subtract(30, 'days').format('YYYYMMDD')),
    dateTo: parseInt(dayjs().format('YYYYMMDD')),
    idAccomodations: [
      idAccomodation.toString()
    ],
    maxResults: 0,
    pageOffset: 0
}))

  return (
    <SectionCard className={'mt-6'} title={'Produzione struttura'}>
      <div className="flex flex-col space-y-4">
        <DetailRow
          loading={isFetching}
          label={'Totale produzione'}>
          <NumberFormat
            value={data?.total}
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator={','}
            thousandSeparator={'.'}
            prefix={'EUR '} />
        </DetailRow>
        <DetailRow
          loading={isFetching}
          label={'Totale incassato'}>
          <NumberFormat
            value={data?.cashed}
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator={','}
            thousandSeparator={'.'}
            prefix={'EUR '} />
        </DetailRow>
      </div>
    </SectionCard>
  )

}
