import { useCallback, useEffect, useState } from "react";
import Modal from "../common/Modal";
import ModalActions from "../common/ModalActions";
import ModalCard from "../common/ModalCard";
import FormField from "../form/FormField";
import TextInput from "../form/TextInput";
import { useMutation } from "react-query";
import { CancellationPolicy, Translation } from "../../types";
import { getCancellationPolicy, saveCancellationPolicy, saveQuoteCustomer } from "../../shared/queries";
import toast from "react-hot-toast";
import { useAppSelector } from "../../hooks/redux";
import SelectInput from "../form/SelectInput";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Icon from "../common/Icon";
import { getTranslatedValue } from "../../shared/utils";
import { useTranslation } from "react-i18next";

const CANCELLATION_UNITS = [
  { label: 'Giorni', value: 'days' },
  { label: 'Ore', value: 'hours' },
]

const PREPAID_UNITS = [
  { label: 'Percentuale', value: 'percent' },
  { label: 'Fisso', value: 'fixed' },
]

export default function AddPolicyModal ({
  visible,
  editPolicy,
  onClose
} : {
  visible: boolean,
  editPolicy?: number,
  onClose: () => void
}) {

  const { t } = useTranslation()
  const { currentHotel } = useAppSelector(state => state.hotels) as any

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [prepaidAmount, setPrepaidAmount] = useState(0)
  const [prepaidUnit, setPrepaidUnit] = useState<'percent' | 'fixed'>('percent')
  const [cancellationUnit, setCancellationUnit] = useState<'days' | 'hours'>('days')
  const [cancellationAmount, setCancellationAmount] = useState(0)
  const [translations, setTranslations] = useState<Translation[]>([])
  const [currentLang, setCurrentLang] = useState<any>({
    label: 'Italian',
    value: 'it'
  })

  const editor = useEditor({
    extensions: [
        StarterKit,
        Link
    ],
    editorProps: {
        attributes: {
            class: 'p-4',
        },
    },
    onUpdate: ({ editor }) => {
      updateTranslatedProperty('description', editor.getHTML())
    },
    content: '<p></p>',
  }, [ currentLang ])

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(currentLang.value === 'it' ? description : getTranslatedValue(translations, 'description'))
    }
  }, [ editor, currentLang ])

  useEffect(() => {
    async function loadEdit () {
      if (editPolicy && editor) {
        const editData = await getCancellationPolicy(editPolicy)
        setTitle(editData.title)
        setDescription(editData.description)
        setTranslations(JSON.parse(editData.translations as any))
        setCancellationAmount(editData.cancellationAmount)
        setCancellationUnit(editData.cancellationUnit)
        setPrepaidAmount(editData.prepaidAmount)
        setPrepaidUnit(editData.prepaidUnit)
        editor.commands.setContent(currentLang.value === 'it' ? editData.description : getTranslatedValue(JSON.parse(editData.translations as any), 'description'))
      } else {
        setTitle('')
        setDescription('')
        setTranslations([])
      }
    }
    loadEdit()
  }, [ editor, editPolicy ])

  function updateTranslatedProperty (key: string, value: string) {
    if (currentLang.value === 'it') {
      setDescription(value)
    } else {
      const existingTranslationIndex = translations.findIndex(t => t.type === key)
      if (existingTranslationIndex > -1) {
        setTranslations(prevState => prevState.map((t, i) => {
          if (i === existingTranslationIndex) {
            return {
              ...t,
              value
            }
          } else {
            return t
          }
        }))
      } else {
        setTranslations([
          ...translations,
          {
            type: key,
            value,
            lang: currentLang.value
          }
        ])
      }
    }
  }

  const setLink = useCallback(() => {
    if (editor) {
        const previousUrl = editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)
    
        // cancelled
        if (url === null) {
        return
        }
    
        // empty
        if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink()
            .run()
    
        return
        }
    
        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: url })
        .run()
    }
  }, [editor])

  const saveMutation = useMutation((data: CancellationPolicy) => saveCancellationPolicy(data), {
    onSuccess: data => {
      toast.success('Policy salvata')
      onClose()
    }
  })

  return (
    <Modal visible={visible}>
      <ModalCard
        renderActions={() => (
          <ModalActions
            isLoading={saveMutation.isLoading}
            onSave={() => saveMutation.mutate({
              idCancellationPolicy: editPolicy ? editPolicy : undefined,
              idAccomodation: currentHotel,
              title: title,
              description: description,
              translations: translations,
              cancellationAmount,
              cancellationUnit,
              prepaidAmount,
              prepaidUnit
            })}
            onClose={onClose}
          />
        )}
        className={'w-full max-w-2xl'} title={editPolicy ? t(`Modifica policy`) : t('Crea nuova policy')}>

          <div className="p-4 pb-6 flex flex-col space-y-4">
            
            <div className="flex space-x-4">
              <FormField className={'flex-1'} label={t('quote.policyTitle')}>
                <TextInput value={title} onChange={val => setTitle(val)} />
              </FormField>
            </div>

            <FormField className={'w-full'} label={t('quote.language')}>
              <SelectInput
                className={'w-full'}
                value={currentLang}
                onChange={(val: any) => {
                  if (val) setCurrentLang(val)
                }}
                options={[
                  { label: 'Italiano', value: 'it' },
                  { label: 'English', value: 'en' }
                ]}
              />
            </FormField>

            <div className="my-4 text-gray-600 font-medium">
              {t('quote.policyDescription')}
            </div>

            {
              editor &&
              <div className="border">
                  <div className={'flex space-x-2 border-b'}>
                      <button
                          onClick={() => editor.chain().focus().toggleBold().run()}
                          className={editor.isActive('bold') ? 'p-2 is-active' : 'p-2'}>
                          <Icon name={'bold'} size={'20px'} />
                      </button>
                      <button
                          onClick={() => editor.chain().focus().toggleItalic().run()}
                          className={editor.isActive('italic') ? 'p-2 is-active' : 'p-2'}>
                          <Icon name={'italic'} size={'20px'} />
                      </button>
                      <button
                          onClick={() => editor.chain().focus().toggleStrike().run()}
                          className={editor.isActive('strike') ? 'p-2 is-active' : 'p-2'}>
                          <Icon name={'strike'} size={'20px'} />
                      </button>
                      <button
                          onClick={setLink}
                          className={editor.isActive('link') ? 'p-2 is-active' : 'p-2'}>
                          <Icon name={'link'} size={'20px'} />
                      </button>
                  </div>
                  <div style={{
                      minHeight: '200px'
                  }}>
                      <EditorContent editor={editor} />
                  </div>
              </div>
          }

          <div className="text-slate-700 font-medium text-lg">
            {t('quote.cancellationTitle')}
          </div>

          <div className="flex space-x-4 items-center">
            <FormField className={'flex-1'} label={t('quote.timeForCancellation')}>
              <TextInput numbered={true} value={cancellationAmount} onChange={val => setCancellationAmount(parseInt(val))} />
            </FormField>

            <FormField className={'flex-1'} label={t('quote.cancellationUnit')}>
              <SelectInput
                options={CANCELLATION_UNITS}
                value={CANCELLATION_UNITS.find(cu => cu.value === cancellationUnit)}
                onChange={(item: any) => {
                  if (item) setCancellationUnit(item.value as any)
                }}
              />
            </FormField>
          </div>

          <div className="text-slate-600">
            {t('quote.cancellationTip')}
          </div>

          <div className="text-slate-700 font-medium text-lg">
            {t('quote.paymentPolicy')}
          </div>

          <div className="flex space-x-4 items-center">
            <FormField className={'flex-1'} label={t('quote.prepayValue')}>
              <TextInput numbered={true} value={prepaidAmount} onChange={val => setPrepaidAmount(parseFloat(val))} />
            </FormField>

            <FormField className={'flex-1'} label={t('quote.prepayType')}>
              <SelectInput
                options={PREPAID_UNITS}
                value={PREPAID_UNITS.find(cu => cu.value === prepaidUnit)}
                onChange={(item: any) => {
                  if (item) setPrepaidUnit(item.value as any)
                }}
              />
            </FormField>
          </div>

        </div>

        </ModalCard>
    </Modal>
  )

}