import { Fragment, useState } from "react";
import Link from "../../navigation/Link";
import BaseTable from "../../data/BaseTable";
import {Accomodation, Policy} from '../../../types'
import FormSectionTitle from "../../form/FormSectionTitle";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getPolicies, saveAccomodation } from "../../../shared/queries";
import Modal from "../../common/Modal";
import ModalCard from "../../common/ModalCard";
import ModalActions from "../../common/ModalActions";
import toast from "react-hot-toast";
import SelectInput from "../../form/SelectInput";
import Chip from "../../common/Chip";
import { getPolicyType } from "../../../shared/utils";

export default function HotelConnectedPolicies ({
    accomodation
} : {
    accomodation: Accomodation
}) {

    const history = useHistory()
    const queryClient = useQueryClient()

    const [create, setCreate] = useState<boolean>(false)
    const [currentPolicy, setCurrentPolicy] = useState<{ label: string, value: number }>()

    const {
        data
    } = useQuery('policies', getPolicies)

    const updateMutation = useMutation((data: Accomodation) => saveAccomodation(data), {
        onSuccess: () => {
            toast.success('Polizza associata')
            queryClient.refetchQueries(['accomodation', accomodation.idAccomodation ? accomodation.idAccomodation.toString() : undefined])
            setCreate(false)
        },
        onError: () => {
            toast.error('Impossibile associare la polizza')
        }
    })

    return (
        <Fragment>
            <FormSectionTitle
                borderless
                title={'Polizze connesse'}
                subtitle={'associazione tra polize e struttura ricettiva'}
                onEdit={() => {
                    setCreate(true)
                }}
                edit={true}
                editLabel={'Aggiungi polizza'} />

            <BaseTable
                filterable={false}
                paginated={false}
                columns={[
                    {
                        Header: 'Identificativo',
                        accessor: (row: Policy) => (
                            <Link onClick={() => history.push('/app/configuration/policies/' + row.idPolicy)}>
                                {row.policyName} - {row.policyCode}
                            </Link>
                        )
                    },
                    {
                        Header: 'Compagnia',
                        accessor: 'policyCompany'
                    },
                    {
                        Header: 'Tipologia copertura',
                        accessor: (row: Policy) => (
                            row.policyIsSanitary ?
                            <Chip className={'text-green-600 bg-green-100'}>
                                Sanitaria
                            </Chip>
                            :
                            <Chip className={'text-blue-600 bg-blue-100'}>
                                Cancellazione
                            </Chip>
                        )
                    },
                    {
                        Header: 'Azioni',
                        accessor: (row: Policy) => (
                            <Link
                                onClick={() => {
                                    updateMutation.mutate({
                                        ...accomodation,
                                        policies: accomodation.policies.filter(p => p.idPolicy !== row.idPolicy)
                                    })
                                }}>
                                Dissocia
                            </Link>
                        )
                    }
                ]}
                data={accomodation.policies} />

            <Modal
                visible={create}>
                <ModalCard
                    renderActions={() => (
                        <ModalActions
                            onSave={() => {
                                if (currentPolicy) {
                                    updateMutation.mutate({
                                        ...accomodation,
                                        policies: [
                                            ...accomodation.policies,
                                            {
                                                idPolicy: currentPolicy.value,
                                                policyName: '',
                                                policyCode: '',
                                                policyCompany: '',
                                                policyCostRate: 0,
                                                description: {},
                                                policyIsSanitary: false,
                                                policyIsRain: false,
                                                policyIsRc: false,
                                                policySellRate: 0,
                                                policyValidFrom: 0,
                                                policyValidTo: 0,
                                                countries: [],
                                                dynamicGuests: false,
                                                dynamicFileUrl: false,
                                            }
                                        ]
                                    })
                                }
                            }}
                            onClose={() => {
                                setCreate(false)
                            }} />
                    )}
                    className={'w-full max-w-md'}
                    title={'Associa polizza'}>
                    <div className="p-4 flex flex-col space-y-4">
                        <SelectInput
                            onChange={(item: any) => {
                                if (item) setCurrentPolicy(item)
                            }}
                            options={data ? data.filter(p => accomodation.policies
                                .findIndex(ap => p.idPolicy === ap.idPolicy) < 0)
                                .map(p => {
                                return {
                                    label: p.policyName + ' - ' + p.policyCode + ' - ' + p.policyCompany + ' (' + getPolicyType(p) + ')',
                                    value: p.idPolicy
                                }
                            }) : []} />
                    </div>
                </ModalCard>
            </Modal>

        </Fragment>

    )

}
