import { useEffect, useMemo, useRef } from "react"
import { PolicyRate } from "../../../types"
import { useTranslation } from "react-i18next"

export default function InsuranceProductCard ({
  product,
  currentSelection,
  internalRates,
  onClick
} : {
  product: any,
  internalRates: PolicyRate[],
  currentSelection: string,
  onClick: (string: any) => void
}) {

  const { t } = useTranslation()

  const imageRef = useRef<any>()

  useEffect(() => {
    if (currentSelection === product.key) {
      imageRef.current.src = product.image
    } else {
      imageRef.current.src = product.offImage
    }
  }, [ currentSelection ])

  const hasPlus = useMemo(() => {
    if (internalRates.find(ir => ir.rateCode === product.key && ir.idPolicy === 13)) {
      return true
    }
    return false
  }, [ internalRates ])

  return (
    <div
      onClick={() => onClick(product.key)}
      className={`${currentSelection === product.key ? 'opacity-100 outline-none ring-4 ring-orange-300 border-orange-600' : 'opacity-60'} py-4 cursor-pointer relative flex space-x-2 border rounded-md shadow`}
    >
      <div className="absolute top-4 right-4 text-orange-600">
        {
          currentSelection === product.key &&
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
          </svg>
        }
      </div>
      <div className="w-28 p-4">
        <img ref={imageRef} src={product.offImage} className={'w-full'} />
      </div>
      <div className="flex-1 px-4">
        <div className="font-semibold text-gray-600">{product.label}</div>
        <div className="mt-1">
          {
            product.included.map((inc: any, id: any) => (
              <div key={'inc' + id} className="text-gray-600 flex items-center space-x-2">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </div>
                <div>{inc}</div>
              </div>
            ))
          }
        </div>
        {
          hasPlus && <div>
            <div className="border-t my-2 pt-2 flex space-x-2 text-slate-600 font-semibold">
            <div className="flex space-x-2 items-center">
              <div>{t('vary.include')}</div>
                <img className="h-6" src="/assets/pluslogo.svg"></img>
              </div>
            </div>
            <div className="mt-1">
              {
                product.includedPlus.map((inc: any, id: any) => (
                  <div key={'inc' + id} className="text-gray-600 flex items-center space-x-2">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div>{inc}</div>
                  </div>
                ))
              }
            </div>
          </div>
        }
      </div>
    </div>
  )

}