import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { useEffect, useMemo } from "react";
import { addAmenity, fetchAmenities, setAmenities, updateAmenities } from "../store/slices/accommodationSettingsSlice";
import Checkbox from "../components/form/Checkbox";
import { classNames } from "../shared/utils";
import { useMutation } from "react-query";
import { saveAccomodationAmenity } from "../shared/queries";
import { AccomodationAmenity } from "../types";

export default function QuoteSettingsAmenities () {

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { amenities } = useAppSelector(state => state.accommodationSettingsSlice)
  const { currentHotel } = useAppSelector(state => state.hotels)

  const amenityList = useMemo<{[key: string]: {key: string, label: string, category: string }[]}>(() => [
    { key: 'receptionh24', label: t('receptionh24'), category: 'room_service' },
    { key: 'concierge', label: t('concierge'), category: 'room_service' },
    { key: 'eletricCharge', label: t('eletricCharge'), category: 'room_service' },
    { key: 'freeWifi', label: t('freeWifi'), category: 'room_service' },
    { key: 'safeBox', label: t('safeBox'), category: 'room_service' },
    { key: 'pool', label: t('pool'), category: 'hotel_service' },
    { key: 'spa', label: t('spa'), category: 'hotel_service' },
    { key: 'massage', label: t('massage'), category: 'wellness' },
    { key: 'gym', label: t('gym'), category: 'wellness' },
    { key: 'jacuzzi', label: t('jacuzzi'), category: 'wellness' },
    { key: 'sauna', label: t('sauna'), category: 'wellness' },
    { key: 'kidsClub', label: t('kidsClub'), category: 'hotel_service' },
    { key: 'playgrouns', label: t('playgrouns'), category: 'hotel_service' },
    { key: 'golf', label: t('golf'), category: 'hotel_service' },
    { key: 'tennis', label: t('tennis'), category: 'hotel_service' },
    { key: 'padel', label: t('padel'), category: 'hotel_service' },
    { key: 'happyHour', label: t('happyHour'), category: 'activities' },
    { key: 'bikeTours', label: t('bikeTours'), category: 'activities' },
    { key: 'excurions', label: t('excurions'), category: 'activities' },
    { key: 'guidedTours', label: t('guidedTours'), category: 'activities' },
    { key: 'enogastronomy', label: t('enogastronomy'), category: 'activities' },
    { key: 'quickCheckInOut', label: t('quickCheckInOut'), category: 'business' },
    { key: 'meetingRooms', label: t('meetingRooms'), category: 'business' },
    { key: 'ironingService', label: t('ironingService'), category: 'business' },
    { key: 'laundryService', label: t('laundryService'), category: 'business' },
    { key: 'highSpeedInternet', label: t('highSpeedInternet'), category: 'business' },
    { key: 'reservedParking', label: t('reservedParking'), category: 'parking' },
    { key: 'nearPublicParking', label: t('nearPublicParking'), category: 'parking' },
    { key: 'towels', label: t('towels'), category: 'bathroom' },
    { key: 'bathOrShower', label: t('bathOrShower'), category: 'bathroom' },
    { key: 'hairdryer', label: t('hairdryer'), category: 'bathroom' },
    { key: 'courtesySet', label: t('courtesySet'), category: 'bathroom' }

  ].reduce((prev, curr) => {
    prev[curr.category] = prev[curr.category] || []
    prev[curr.category].push(curr)
    return prev
  }, Object.create(null)), [])

  useEffect(() => {
    dispatch(fetchAmenities(currentHotel))
  }, [
    currentHotel
  ])

  const toggleMutation = useMutation((data: AccomodationAmenity) => saveAccomodationAmenity(data), {
    onSuccess: data => {
      dispatch(updateAmenities({
        idAccomodationAmenity: data.idAccomodationAmenity,
        idAccomodation: currentHotel ? parseInt(currentHotel.toString()) : 0,
        amenityDescription: data.amenityDescription,
        amenityKey: data.amenityKey,
        category: data.category
      }))
    }
  })

  return (
    <div>
      <div className="text-xl font-bold text-gray-600 mb-6">
        {t('quote.amenities')}
      </div>

      {
        Object.keys(amenityList).map((category, index) => (
          <div className={
            classNames(
              'flex flex-col space-y-2',
              (index < (Object.keys(amenityList).length - 1)) ? 'mb-6' : ''
            )
          } key={`category-${index}`}>
            <div className="font-medium text-gray-500">{t(category)}</div>
            {
              amenityList[category].map((categoryItem, itemIndex) => (
                <div className="flex space-x-2" key={`category-item-${itemIndex}`}>
                  <Checkbox onChange={() => {
                    const currentAmenityId = amenities.find(a => a.amenityKey === categoryItem.key)?.idAccomodationAmenity
                    if (currentAmenityId) {
                      dispatch(setAmenities(amenities.filter(a => a.amenityKey !== categoryItem.key)))
                    } else {
                      dispatch(addAmenity({
                        idAccomodation: currentHotel ? parseInt(currentHotel.toString()) : 0,
                        amenityDescription: categoryItem.label,
                        amenityKey: categoryItem.key,
                        category: category
                      }))
                    }
                    toggleMutation.mutate({
                      idAccomodationAmenity: currentAmenityId,
                      idAccomodation: currentHotel ? parseInt(currentHotel.toString()) : 0,
                      amenityDescription: categoryItem.label,
                      amenityKey: categoryItem.key,
                      category: category
                    })
                  }}
                  checked={amenities.find(a => a.amenityKey === categoryItem.key) !== undefined}
                  label={categoryItem.label}
                />
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  )

}