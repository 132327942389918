import { useTranslation } from "react-i18next";
import Icon from "../components/common/Icon";
import { PageWrapper } from "../components/common/PageWrapper";
import Button from "../components/form/Button";

export default function ProductPage () {

    const { t } = useTranslation()

    return (
        <PageWrapper centered maxWidth={'1000px'}>
            <div className="px-8 flex flex-col space-y-6">
                <div
                    className="p-4 rounded-lg">

                    <div className="text-3xl font-semibold text-gray-600">
                        I prodotti BeSafe Rate
                    </div>

                    <div className={'mt-4 font-normal text-gray-500'}>
                        Scopri tutti i prodotti offerti dalla BeSafe Suite. Puoi usufruire di diverse coperture in base alle tue reali esigenze studiate da esperti del settore ricettivo.
                    </div>

               </div>

               <div
                    style={{
                        boxShadow: '0px 0px 12px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.1)'
                    }}
                    className="p-4 rounded-lg">

                   <div className="flex space-x-6">
                       <div>
                           <div
                                style={{
                                    backgroundImage: 'url(https://besafesuite.com/besaferate/wp-content/uploads/sites/2/2021/10/Rate-700x525.jpg)'
                                }}
                                className="w-52 h-40 bg-cover rounded-md"></div>
                       </div>

                       <div>
                            <div className="text-xl font-bold text-gray-600">
                                {t('products.classic.title')}
                            </div>

                            <div className={'text-sm mt-2 text-gray-600 font-normal'}>
                                {t('products.classic.text')}
                            </div>

                            <div className="flex flex-wrap -m-1 text-orange-600 mt-3 mb-2">
                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.classic.refund')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.classic.sanitary')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.classic.covid')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.classic.road')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.classic.interruption')}</div>
                                </div>
                            </div>

                            <Button
                                className={'rounded-lg px-2 py-2 mt-3 font-medium text-orange-600'}
                                onClick={() => {
                                    window.open('https://besafesuite.com/besaferate/albergatore/form-informazioni/')
                                }}
                                label={t('products.activate')} />
                        </div>
                    </div>

                </div>

                <div
                    style={{
                        boxShadow: '0px 0px 12px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.1)'
                    }}
                    className="p-4 rounded-lg">

                   <div className="flex space-x-6">
                       <div>
                           <div
                                style={{
                                    backgroundImage: 'url(https://images.wired.it/wp-content/uploads/2019/11/19130602/visore-sci-1050x590.jpg)'
                                }}
                                className="w-52 h-40 bg-cover rounded-md"></div>
                       </div>

                       <div>
                            <div className="text-xl font-bold text-gray-600">
                                {t('products.winter.title')}
                            </div>

                            <div className={'text-sm mt-2 text-gray-600 font-normal'}>
                                {t('products.winter.text')}
                            </div>

                            <div className="flex flex-wrap -m-1 mt-3 mb-2 text-orange-600">
                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.winter.refund')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.winter.sanitary')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.winter.covid')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.winter.luggage')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.winter.road')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.winter.interruption')}</div>
                                </div>
                                
                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.winter.snow')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.winter.rc')}</div>
                                </div>

                                <div className={'p-1 flex space-x-2 text-sm items-center'}>
                                    <div>
                                        <Icon name={'check'} size={'20px'} />
                                    </div>
                                    <div>{t('products.winter.services')}</div>
                                </div>
                            </div>

                            <Button
                                className={'rounded-lg px-2 py-2 mt-3 font-medium text-orange-600'}
                                onClick={() => {
                                    window.open('https://besafesuite.com/besaferate/albergatore/form-informazioni/')
                                }}
                                label={t('products.activate')} />
                       </div>
                   </div>
                   
               </div>
           </div>
        </PageWrapper>
    )

}