import Joi from 'joi'
import i18n from '../i18n/index'

export default Joi.object({
    idBooking: Joi.any().required().messages({
        'any.required': i18n.t('validation.required')
    }),
    booker: Joi.any(),
    guestFirstName: Joi.string().required().messages({
        'string.required': i18n.t('validation.required'),
        'string.empty': i18n.t('validation.required')
    }),
    guestLastName: Joi.string().required().messages({
        'string.required': i18n.t('validation.required'),
        'string.empty': i18n.t('validation.required')
    }),
    country: Joi.any().required().messages({
        'any.base': i18n.t('validation.required'),
        'any.required': i18n.t('validation.required'),
        'any.empty': i18n.t('validation.required')
    }),
    guestEmail: Joi.string().required().messages({
        'string.required': i18n.t('validation.required'),
        'string.empty': i18n.t('validation.required'),
        'string.email': i18n.t('validation.invalidEmail')
    }),
    guestFiscalCode: Joi.any()
})
