import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import ChangePasswordDialog from "./components/ChangePasswordDialog";
import ApplicationLoading from "./components/loading/ApplicationLoading";
import RouteTransition from "./components/RouteTranistion";
import i18n from "./i18n";
import InternalLayout from "./layouts/InternalLayout";
import ApiUsersPage from "./pages/ApiUsersPage";
import CompaniesPage from "./pages/CompaniesPage";
import CompanyPage from "./pages/CompanyPage";
import ConfigurationPage from "./pages/ConfigurationPage";
import DashboardPage from "./pages/DashboardPage";
import FeatureVote from "./pages/FeatureVote";
import FeatureVoteDetail from "./pages/FeatureVoteDetail";
import FeatureVoteNew from "./pages/FeatureVoteNew";
import HotelPage from "./pages/HotelPage";
import HotelsPage from "./pages/HotelsPage";
import IntegrationPage from "./pages/IntegrationPage";
import IntelligencePage from "./pages/IntelligencePage";
import InvoicesPage from "./pages/InvoicesPage";
import MonitoringInsurances from "./pages/MonitoringInsurances";
import MonitoringPage from "./pages/MonitoringPage";
import PoliciesPage from "./pages/PoliciesPage";
import PolicyPage from "./pages/PolicyPage";
import ProductPage from "./pages/Products";
import ProductsPage from "./pages/ProductsPage";
import ProfilePage from "./pages/ProfilePage";
import ReservationsPageFace from "./pages/ReservationsPageFace";
import UserPage from "./pages/UserPage";
import UsersPage from "./pages/UsersPage";
import { RootState } from "./store";
import { fetchCountries, fetchSessionProfile } from "./store/slices/sessionSlice";
import ReleaseNote from "./pages/ReleaseNote";
import SettingsPage from "./pages/SettingsPage";
import OnboardingPage from "./pages/OnboardingPage";
import OpportunityPage from "./pages/Opportunity";
import QuoteList from "./pages/QuoteList";
import QuoteSettings from "./components/quote/QuoteSettings";
import PartnerDashboard from "./pages/PartnerDashboard";
import PartnerFaqPage from "./pages/partner/PartnerFaqPage";
import { useTranslation } from "react-i18next";
import { auth0Helper } from "./shared/api";

export default function SecureLayout () {

  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { profile } = useSelector((state: RootState) => state.session)
  const currentHotelObject = useSelector((state: RootState) => state.hotels.currentHotelObject)

  const { loginWithRedirect, user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const pLang = localStorage.getItem('bsr_preferred_lang')
    if (pLang) {
      i18n.changeLanguage(pLang)
    }
  }, [])

  const [loading, setLoading] = useState<boolean>(true)
  const [showPasswordReset, setShowPasswordReset] = useState<boolean>(false)
  const [isCheckingAuth, setIsCheckingAuth] = useState<boolean>(true)

  useEffect(() => {
    if (!isCheckingAuth) {
      console.log('fetching profile')
      dispatch(fetchSessionProfile())
    }
  }, [ isCheckingAuth ])

  useEffect(() => {
    if (user && currentHotelObject) {
      const w = window as any
      const freshWorks = w['FreshworksWidget' as any] as any

      if (freshWorks) {
        freshWorks('identify', 'ticketForm', {
          name: user.given_name + ' ' + user.family_name,
          email: user.email,
          custom_fields: {
            'cf_id_struttura': currentHotelObject.idAccomodation.toString(),
            'cf_prodotto_della_suite': 'BeSafeRate'
          }
        })
      }
    }
  }, [
    currentHotelObject,
    user
  ])

  useEffect(() => {
    if (profile && profile.userPasswordReset) {
      setShowPasswordReset(true)
    }
  }, [ profile ])

  useEffect(() => {
    console.log('checking provider')
    auth0Helper.tokenProvider = getAccessTokenSilently
  }, [ getAccessTokenSilently ])

  async function checkAuthentication () {
    try {
      console.log('checking authh')
      setIsCheckingAuth(true)
      const token = await getAccessTokenSilently({
        audience: `insurance`,
        scope: "openid profile read:current_user",
      })
      if (token) {
        await dispatch(fetchSessionProfile())
        await dispatch(fetchCountries())
        localStorage.setItem('token', token)
        setIsCheckingAuth(false)
      } else {
        await loginWithRedirect()
      }
    } catch (e) {
      console.log('token retrival failed')
      await loginWithRedirect()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 4500)
  }, [])

  useEffect(() => {
    if (!isLoading) {
      console.log('checking autj')
      checkAuthentication()
    }
  }, [ isLoading ])

  function getRoutes () {
    const routes = [
      { exact: true, to: '/app/dashboard', component: profile?.partnerId ? <PartnerDashboard/> : <DashboardPage /> },
      { exact: true, to: '/app/partner/faq', component: <PartnerFaqPage /> },
      { exact: true, to: '/app/hotels', component: <HotelsPage /> },
      { exact: true, to: '/app/hotels/:id', component: <HotelPage /> },
      { exact: true, to: '/app/users', component: <UsersPage /> },
      { exact: true, to: '/app/users/:id', component: <UserPage /> },
      { exact: true, to: '/app/hotels/:id', component: <UserPage /> },
      { exact: true, to: '/app/configuration', component: <ConfigurationPage /> },
      { exact: true, to: '/app/intelligence', component: <IntelligencePage /> },
      { exact: true, to: '/app/intelligence/monitoring', component: <MonitoringPage /> },
      { exact: true, to: '/app/intelligence/insurances', component: <MonitoringInsurances/> },
      { exact: true, to: '/app/profile', component: <ProfilePage /> },
      { exact: true, to: '/app/companies', component: <CompaniesPage /> },
      { exact: true, to: '/app/companies/:id', component: <CompanyPage /> },
      { exact: true, to: '/app/configuration/integrations', component: <IntegrationPage /> },
      { exact: true, to: '/app/configuration/policies', component: <PoliciesPage /> },
      { exact: true, to: '/app/configuration/policies/:id', component: <PolicyPage /> },
      { exact: true, to: '/app/configuration/api-users', component: <ApiUsersPage /> },
      { exact: true, to: '/app/products', component: <ProductPage /> },
      { exact: true, to: '/app/invoices', component: <InvoicesPage /> },
      { exact: true, to: '/app/quotes', component: <QuoteList /> },
      { exact: false, to: '/app/quotes/settings', component: <QuoteSettings /> },
      { exact: true, to: '/app/community', component: <FeatureVote /> },
      { exact: true, to: '/app/settings', component: <SettingsPage /> },
      { exact: true, to: '/app/community-new', component: <FeatureVoteNew /> },
      { exact: true, to: '/app/community/:id', component: <FeatureVoteDetail /> },
      { exact: true, to: '/app/community/edit/:id', component: <FeatureVoteNew /> },
      { exact: true, to: '/app/release-note', component: <ReleaseNote /> },
      { exact: false, to: '/app/reservations/:id?', component: <ReservationsPageFace /> },
      { exact: false, to: '/app/onboarding', component: <OnboardingPage /> },
      { exact: false, to: '/app/opportunities', component: <OpportunityPage /> }
    ]

    return routes
  }

  return (
    <Route path={'/app'}>
      <ChangePasswordDialog
        onSave={() => {
          setShowPasswordReset(false)
        }}
        visible={showPasswordReset}
        title={t('changePassword.title')}
        subTitle={t('changePassword.text')} />
      {
        (!loading && (!isCheckingAuth && isAuthenticated)) ?
          <Fragment>
            <Switch>
              <Route exact path={'/app/suite'}>
                <RouteTransition>
                  <ProductsPage />
                </RouteTransition>
              </Route>

              <Route exact path={'/app/suite/account'}>
                <RouteTransition>
                  <ProfilePage />
                </RouteTransition>
              </Route>
            </Switch>
            <Switch>
              {
                getRoutes().map((route, index: number) => (
                  <Route key={index} exact={route.exact} path={route.to}>
                    <RouteTransition>
                      <InternalLayout>
                        {route.component}
                      </InternalLayout>
                    </RouteTransition>
                  </Route>
                ))
              }
            </Switch>
          </Fragment>
          :
          <ApplicationLoading />
      }
    </Route>
  )

}
