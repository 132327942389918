import PageTitle from "../components/common/PageTitle";
import DetailRow from "../components/data/DetailRow";
import Button from "../components/form/Button";
import FormSectionTitle from "../components/form/FormSectionTitle";
import NumberFormat from "react-number-format";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {deleteBooking, getAccomodationById, getAccomodationPolicyRate, getBookingPayments, getBookingPolicies, getBookingsById, getReservationClaim, resendBookingMail} from "../shared/queries";
import { formatCurrency, formatDate, getMainGuestFromBooking, needsTotalValues } from "../shared/utils";
import { Booking, ClaimDetail } from "../types";
import {Fragment, useEffect, useState} from "react";
import BookingGuestTable from "../components/business/booking/BookingGuestTable";
import CreatePayment from "../components/business/booking/CreatePayment";
import BookingPaymentsTable from "../components/business/booking/BookingPaymentsTable";
import UpdateBooking from "../components/business/booking/UpdateBooking";
import DeleteModal from "../components/common/DeleteModal";
import Chip from "../components/common/Chip";
import toast from "react-hot-toast";
import Icon from "../components/common/Icon";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import BookingPoliciesTable from "../components/business/booking/BookingPoliciesTable";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {setCurrentHotel, setCurrentHotelObject} from "../store/slices/hotelsSlice";
import dayjs from "dayjs";
import CopyToClipboard from "./CopyToClipboard";
import SelectInput from "./form/SelectInput";
import TextInput from "./form/TextInput";
import FormField from "./form/FormField";
import SectionCard from "./SectionCard";
import BookingAudit from "./business/booking/BookingAudit";
import InvoicingTable from "./business/booking/InvoicingTable";
import CountryFlag from "./common/CountryFlag";
import BookingPolicyJob from "./business/BookingPolicyJob";

export default function ReservationDetail ({ id, onRefresh } : { id: any, onRefresh?: () => void }) {

    const { t } = useTranslation()

    const { profile } = useSelector((state: RootState) => state.session)

    const dispatch = useDispatch()

    const queryClient = useQueryClient()

    const [addPayment, setAddPayment] = useState<boolean>(false)
    const [update, setUpdate] = useState<boolean>(false)
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const [deleteReason, setDeleteReason] = useState<string | undefined>()
    const [deleteComment, setDeleteComment] = useState<string | undefined>()

    const {
        accommodations,
        currentHotel,
        currentHotelObject
    } = useSelector((state: RootState) => state.hotels)

    const {
        data,
        refetch
    } = useQuery<Booking, Error>(['booking', id], () => getBookingsById(id), {
        onError: () => {
            // TODO: addtoast
        }
    })

    const {
        data: claimData
    } = useQuery<ClaimDetail, Error>(['bookingClaim', id], () => getReservationClaim(id), {
        retry: false,
        refetchOnMount: false,
        onError: () => {
            // TODO: addtoast
        }
    })

    const {
        data: accomodationData
    } = useQuery(['accomodation', data], () => getAccomodationById(data?.idAccomodation || 0), {
        enabled: (data !== undefined && data !== null)
    })

    const {
        data: accomodationRate,
    } = useQuery(['accomodationRates', currentHotel], () => getAccomodationPolicyRate(currentHotel as any), {
        enabled: (currentHotel !== undefined && currentHotel !== null),
        retry: false
    })

    const {
        data: policies,
        isFetching: fetchingPolicies,
        refetch: refetchPolicies
    } = useQuery(['bookingPolicies', id], () => getBookingPolicies(id))

    useEffect(() => {
        if (!currentHotel && !currentHotelObject && data && accommodations && accommodations.length > 0) {
            const hotel = accommodations.find(a => a.idAccomodation === data.idAccomodation)
            if (hotel) {
                dispatch(setCurrentHotel(hotel.idAccomodation))
                dispatch(setCurrentHotelObject(hotel))
            }
        }
    }, [
        accommodations,
        data
    ])

    const resendMutation = useMutation((data: {
        idAccomodation: string,
        idBooking: string
    }) => resendBookingMail(data.idAccomodation, data.idBooking), {
        onSuccess: () => {
            toast.success(t('reservation.emailResent'))
            refetch()
        },
        onError: () => {
            toast.error(t('general.generalError'))
        }
    })

    const {
        data: payments,
        isFetching: fetchingPayments,
        refetch: refetchPayments
    } = useQuery(['payments', id], () => getBookingPayments(id))

    function getGuestName () : String {
        if (data) {
            const mainGuest = getMainGuestFromBooking(data)
            if (mainGuest) {
                return `${mainGuest.guestFirstName} ${mainGuest.guestLastName}`
            }
        }
        return 'ND ND'
    }

    const deleteMutation = useMutation((data : { id: any, reason: string, comment: string }) => deleteBooking(data.id, data.reason, data.comment), {
        onSuccess: () => {
            toast.success(t('reservation.canceledReservation'))
            setShowDelete(false)
            if (onRefresh) onRefresh()
        }
    })

    function getPaymentsTotal () {
        if (payments) {
            return payments.reduce((prev, curr) => prev + curr.paymentAmount, 0)
        } else {
            return 0
        }
    }

    function getDeleteLabel (value: string) {
        const labels = [
            { label: t('reservation.neverPaid'), value: 'never_paid' },
            { label: t('reservation.neverConfirmed'), value: 'never_confirmed' },
            { label: t('reservation.mistake'), value: 'mistake' },
            { label: t('reservation.notBesafe'), value: 'not_besafe' },
            { label: t('reservation.other'), value: 'other' }
        ]

        return labels.find(l => l.value === value)?.label
    }

    return (
        <div className={'p-4'}>
            {
                !data &&
                <div>
                    <div className="flex items-center space-x-10 justify-between">
                        <Skeleton height={20} width={400} />
                        <Skeleton height={20} width={150} />
                    </div>

                    <Skeleton className={'mt-8'} height={15} width={250} />

                    <div className={'flex space-x-4'}>
                        <Skeleton className={'mt-8'} height={15} width={100} />
                        <Skeleton className={'mt-8'} height={15} width={250} />
                    </div>
                    <div className={'flex space-x-4'}>
                        <Skeleton className={'mt-2'} height={15} width={100} />
                        <Skeleton className={'mt-2'} height={15} width={250} />
                    </div>
                    <div className={'flex space-x-4'}>
                        <Skeleton className={'mt-2'} height={15} width={100} />
                        <Skeleton className={'mt-2'} height={15} width={250} />
                    </div>
                    <div className={'flex space-x-4'}>
                        <Skeleton className={'mt-2'} height={15} width={100} />
                        <Skeleton className={'mt-2'} height={15} width={250} />
                    </div>
                </div>
            }

            {
                data ?
                <Fragment>
                    <div className={'sticky relative z-40 top-0 bg-gray-50 px-8'}>
                        <PageTitle
                            showSearch={false}
                            title={`${data.bookingCode} - ${getGuestName()}`}
                            renderActions={() => (
                                <div className={'flex space-x-2 items-center'}>
                                    {
                                        !data.isDeleted &&
                                        <Button
                                            className="text-white bg-red-600"
                                            label={t('reservation.cancel')}
                                            onClick={() => {
                                                setShowDelete(true)
                                            }} />
                                    }
                                </div>
                            )} />
                    </div>

                    <div className="px-8 pb-8">
                        {
                            data.isDeleted &&
                            <div className="bg-orange-50 border border-orange-300 p-4 rounded-lg mb-4">
                                <div className="flex space-x-2">
                                    <div className={'text-orange-700'}>
                                        <Icon name={'information-circle'} size={'25px'} />
                                    </div>
                                    <div>
                                        <div className="text-xl text-orange-700 font-semibold">
                                            {t('reservation.canceledReservation')}
                                        </div>
                                        <div className="mt-2 text-gray-800">
                                            <p>{t('reservation.canceledReservationText')}</p>
                                        </div>
                                        {
                                            (data.deleteReason !== undefined && data.deleteReason !== null) &&
                                              <div className="mt-4">
                                                  <div className={'font-semibold text-orange-700'}>{t('reservation.youDeleted')}</div>
                                                  <div className={'mt-1'}>
                                                      {getDeleteLabel(data.deleteReason)}
                                                  </div>

                                                  {
                                                      (data.deleteComment !== undefined && data.deleteComment !== null && data.deleteComment !== '') &&
                                                    <div>
                                                        <div className={'mt-3 font-bold'}>{t('reservation.yourComment')}</div>
                                                        <div className={'mt-1'}>{data.deleteComment}</div>
                                                    </div>
                                                  }

                                                  {
                                                      data.updatedAt &&
                                                        <div className={'mt-3'}>
                                                            {t('reservation.lastUpdatedAt')} {dayjs(data.updatedAt.toString(), 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm')}
                                                        </div>
                                                  }
                                              </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            (payments && payments.length > 0 && data && data.guests.length === 0) &&
                            <div className="bg-orange-200 p-4 rounded-lg">
                                <div className="flex space-x-2">
                                    <div className={'text-orange-800'}>
                                        <Icon name={'information-circle'} size={'25px'} />
                                    </div>
                                    <div>
                                        <div className="text-orange-800 font-medium">
                                            {t('reservation.noGuests')}
                                        </div>
                                        <div className="mt-2 font-normal text-orange-700">
                                            <p>{t('reservation.noGuestsText')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            !data.isDeleted && <BookingPolicyJob booking={data} />
                        }

                        <DeleteModal
                            title={t('reservation.cancelReservation')}
                            askConfirmation={true}
                            message={t('reservation.cancelReservationText')}
                            visible={showDelete}
                            saveLabel={t('reservation.cancel')}
                            isLoading={deleteMutation.isLoading}
                            closeLabel={t('general.cancel')}
                            onCancel={() => {
                                setShowDelete(false)
                            }}
                            onConfirm={() => {
                                if (!deleteReason || deleteReason === '' || deleteReason === null) {
                                    alert(t('reservation.cancelReasonMandatory'))
                                    return
                                }
                                if (deleteReason === 'other') {
                                    if (deleteComment === '' || !deleteComment) {
                                        alert(t('reservation.cancelCommentMandatory'))
                                        return
                                    }
                                }
                                deleteMutation.mutate({
                                    id: data.idBooking,
                                    reason: deleteReason || '',
                                    comment: deleteComment || ''
                                })
                            }}>
                            <div className={'px-4 mb-6'}>
                                <FormField
                                  required={true}
                                  label={t('reservation.cancelReason')}>
                                    <SelectInput
                                      placeholder={t('reservation.cancelReason')}
                                      onChange={(item: any) => {
                                          if (item) {
                                              setDeleteReason(item.value)
                                          }
                                      }}
                                      options={[
                                          { label: t('reservation.neverPaid'), value: 'never_paid' },
                                          { label: t('reservation.neverConfirmed'), value: 'never_confirmed' },
                                          { label: t('reservation.mistake'), value: 'mistake' },
                                          { label: t('reservation.notBesafe'), value: 'not_besafe' },
                                          { label: t('reservation.other'), value: 'other' }
                                      ]} />
                                </FormField>

                                {
                                  (deleteReason === 'other') &&
                                  <div className={'mt-4'}>
                                      <FormField
                                        required={true}
                                        label={t('reservation.cancelComment')}>
                                          <TextInput
                                            type={'textarea'}
                                            value={deleteComment}
                                            placeholder={t('reservation.cancelComment')}
                                            onChange={val => setDeleteComment(val)} />
                                      </FormField>

                                  </div>
                                }
                            </div>
                        </DeleteModal>

                        <UpdateBooking
                            visible={update}
                            booking={data}
                            onClose={() => {
                                setUpdate(false)
                                refetchPolicies()
                                refetch()
                            }} />

                        <SectionCard
                            onEdit={() => setUpdate(true)}
                            title={t('reservation.reservationDetail')}>
                            <div className="flex flex-col space-y-5">
                                <DetailRow
                                    label={t('reservation.reservationId')}>
                                    <CopyToClipboard copyText={data.idBooking ? data.idBooking.toString() : ''}>
                                        {data.idBooking}
                                    </CopyToClipboard>
                                </DetailRow>
                                {
                                    (profile && profile.userAdmin) &&
                                    <>
                                        <DetailRow
                                            label={t('vary.hotelId')}>
                                            <CopyToClipboard copyText={data.idAccomodation ? data.idAccomodation.toString() : ''}>
                                                {data.idAccomodation}
                                            </CopyToClipboard>
                                        </DetailRow>

                                        <DetailRow
                                            label={t('vary.partnerName')}>
                                            <CopyToClipboard copyText={accomodationData ? accomodationData.accomodationName.toString() : ''}>
                                                {accomodationData ? accomodationData.accomodationName : 'ND'}
                                            </CopyToClipboard>
                                        </DetailRow>
                                    </>
                                }
                                <DetailRow
                                    label={t('reservation.reservationCode')}>
                                    <CopyToClipboard copyText={data.bookingCode}>
                                        {data.bookingCode}
                                    </CopyToClipboard>
                                </DetailRow>
                                
                                {
                                    (data.bookingRate && data.bookingRate !== '') && <DetailRow
                                    label={t('reservation.ratePlan')}>
                                        {data.bookingRate}
                                    </DetailRow>
                                }

                                {
                                    ((data && data.bookingRate) && needsTotalValues(data.bookingRate, accomodationRate)) &&
                                    <>
                                        <DetailRow
                                        label={t('reservation.reservationTotal')}>
                                            <div className="flex space-x-2">
                                                <div>
                                                    <NumberFormat
                                                    value={data.bookingTotal}
                                                    displayType={'text'}
                                                    decimalSeparator={','}
                                                    thousandSeparator={'.'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={currentHotelObject ? currentHotelObject.currency + ' ' : 'EUR '} />
                                                </div>
                                                <div>{t('vary.totalInsured')}</div>
                                                <div>
                                                    <NumberFormat
                                                    value={getPaymentsTotal()}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    decimalSeparator={','}
                                                    thousandSeparator={'.'}
                                                    prefix={currentHotelObject ? currentHotelObject.currency + ' ' : 'EUR '} />
                                                </div>
                                            </div>
                                        </DetailRow>
                                    </>
                                }

                                {
                                    ((data && data.bookingRate) && needsTotalValues(data.bookingRate, accomodationRate)) &&
                                    <DetailRow
                                        label={t('reservation.reservationStatus')}>
                                        {
                                            data.isDeleted && <Chip className={'bg-red-600 text-white'}>
                                                {t('reservations.canceled')}
                                            </Chip>
                                        }
                                        {
                                            (!data.isDeleted) &&
                                            <>
                                                {
                                                    (payments && payments && payments.length > 0) ?
                                                    <Chip className={'bg-green-200 text-green-700'}>
                                                        {t('reservations.insured')}
                                                    </Chip>
                                                    :
                                                    <Chip className={'bg-blue-200 text-blue-700'}>
                                                        {t('reservations.pending')}
                                                    </Chip>
                                                }
                                            </>
                                        }
                                    </DetailRow>
                                }

                                <DetailRow
                                  label={(currentHotelObject && currentHotelObject.customerType !== 'AGENCY') ? t('reservation.stayDates') : t('vary.serviceDates')}>
                                    {formatDate(data.bookingArrivalDate, 'ddd DD/MM/YYYY')} {'->'} {formatDate(data.bookingDepartureDate, 'ddd DD/MM/YYYY')}
                                </DetailRow>

                                <DetailRow
                                  label={t('reservation.bookDate')}>
                                    {formatDate(data.bookingDate, 'ddd DD/MM/YYYY')}
                                </DetailRow>
                                
                                {
                                    data.idCountry &&
                                    <DetailRow label={t('adv.destination')}>
                                        <CountryFlag countryId={data.idCountry} />
                                    </DetailRow>
                                }

                                {
                                    ((data.confirmationPrice !== null && data.confirmationPrice !== undefined && data.confirmationPrice > 0)) &&
                                    <DetailRow label={t('adv.confirmationPrice')}>
                                        {formatCurrency(data.confirmationPrice)}
                                    </DetailRow>
                                }

                                <DetailRow
                                  label={t('reservation.policyEmail')}>
                                    <div className="flex items-center space-x-4">
                                        <div>
                                            {
                                              data.notifiedAt
                                              && <span>{t('reservation.sentAt')} {dayjs(data.notifiedAt.toString(), 'YYYYMMDDHHmmssSSS').format('DD/MM/YY HH:mm')}</span>
                                            }
                                            {
                                              (!data.notifiedAt) &&
                                              <span>{t('reservation.waitingSed')}</span>
                                            }
                                            {
                                              data.renotify &&
                                              <span>{t('reservation.scheduled')}</span>
                                            }
                                        </div>
                                        {
                                          (data.notifiedAt && !data.renotify) &&
                                          <div>
                                              <button
                                                onClick={() => {
                                                    if (data && data.idBooking) {
                                                        resendMutation.mutate({
                                                            idAccomodation: data.idAccomodation.toString(),
                                                            idBooking: data.idBooking.toString()
                                                        })
                                                    }
                                                }}
                                                style={{
                                                    boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px'
                                                }}
                                                className={'flex h-7 items-center bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded'}>
                                                  {
                                                      resendMutation.isLoading
                                                        ? t('general.wait')
                                                        : t('reservation.resendEmail')
                                                  }
                                              </button>
                                          </div>
                                        }
                                    </div>
                                </DetailRow>
                            </div>
                        </SectionCard>

                        {
                            (profile && profile.userAdmin && claimData) &&
                            <SectionCard
                                className="mt-6"
                                onEdit={() => setUpdate(true)}
                                title={t('reservation.claim')}>
                                
                                <div className="flex-col space-y-2">
                                    <DetailRow label={t('reservation.claimIdealCose')}>
                                        {claimData.idealCloseDate}
                                    </DetailRow>

                                    <DetailRow label={t('reservation.claimStatus')}>
                                        {claimData.status}
                                    </DetailRow>

                                    {
                                        (claimData.payoutExecuted && claimData.payouts.length > 0) &&
                                        <>
                                            <DetailRow label={t('reservation.paymentStatus')}>
                                                {claimData.payoutPending && 'Liquidazione in approvazione presso la compagnia'}
                                                {claimData.payoutExecuted && 'La liquidazione è stata approvata'}
                                                {claimData.payoutRejected && 'La liquidazione è stata rigettatax'}
                                            </DetailRow>
                                            <DetailRow label={t('reservation.claimAmountPaid')}>
                                                {formatCurrency(claimData.payouts[0].amount)}
                                            </DetailRow>
                                        </>
                                    }
                                </div>

                            </SectionCard>
                        }

                        <BookingGuestTable
                            idBooking={id}
                            canAddGuests={
                                (policies && policies.findIndex(p => p.policy.policyCompany === 'INTM') > -1 ) ? false : true
                            }
                            guests={data.guests ? data.guests : []}
                            onRefetch={() => refetch()} />

                        {
                            ((data && data.bookingRate) && needsTotalValues(data.bookingRate, accomodationRate)) &&
                            <>
                                <FormSectionTitle
                                    borderless={true}
                                    dense={true}
                                    title={t('reservation.paymentDetails')}
                                    subtitle={t('reservation.paymentDetailsText')}
                                    edit={true}
                                    onEdit={() => {
                                        setAddPayment(true)
                                    }}
                                    editLabel={t('reservation.addPayment')}
                                />

                                <BookingPaymentsTable
                                    booking={data}
                                    onRefetch={() => refetch()}
                                    idBooking={id}
                                />
                            </>
                        }

                        <FormSectionTitle
                            borderless={true}
                            dense={true}
                            subtitle={t('vary.billingHistory')}
                            title={t('vary.billingDetails')}
                        />

                        <InvoicingTable idBooking={id} />

                        <FormSectionTitle
                            borderless={true}
                            dense={true}
                            title={t('reservation.availablePolicies')}
                            subtitle={t('reservation.availablePoliciesText')}
                            edit={false}
                        />

                        <BookingPoliciesTable booking={data} policies={policies || []} loading={fetchingPolicies} />

                        {
                            (profile && profile.userAdmin) &&
                              <>
                                  <FormSectionTitle
                                    borderless={true}
                                    title={'Dettaglio storico'}
                                    subtitle={'Storico delle modifiche valori della prenotazione'} />

                                  <BookingAudit id={id} />
                              </>
                        }

                        <CreatePayment
                            bookingTotal={data.bookingTotal}
                            visible={addPayment}
                            onClose={() => setAddPayment(false)}
                            onSave={() => {
                                setAddPayment(false)
                                refetch()
                                refetchPayments()
                                queryClient.invalidateQueries(['payments', data.idBooking])
                                if (data.idBooking) {
                                    const id = data.idBooking.toString()
                                    queryClient.fetchQuery(['payments', id], () => getBookingPayments(id))
                                }
                            }}
                            idBooking={id} />
                    </div>
                </Fragment>
                :
                <>
                </>
            }
        </div>
    )

}
