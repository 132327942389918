import {useState} from "react";
import Modal from "./Modal";
import ModalCard from "./ModalCard";
import ModalActions from "./ModalActions";
import Checkbox from "../form/Checkbox";
import DatePickerInput from "../form/DatePickerInput";
import FormField from "../form/FormField";
import {exportReservations} from "../../shared/queries";
import {ExportColumn} from "../../types";
import HotelSelector from "../navigation/HotelSelector";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import DateRangePicker from "../form/DateRangePicker";
import SelectInput from "../form/SelectInput";
import AccountingReport from "../business/invoices/AccountingReport";
import Button from "../form/Button";

export default function ExportModal ({
  currentHotel,
  columns = [],
  visible = false,
  onClose
} : {
  currentHotel: string | number,
  columns: ExportColumn[],
  visible: boolean,
  onClose: () => void
}) {

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [fromDate, setFromDate] = useState(new Date())
  const [year, setYear] = useState(parseInt(dayjs().format('YYYY')))
  const [toDate, setToDate] = useState(new Date())
  const [selectedHotels, setSelectedHotels] = useState<any>()
  const [selectedColumns, setSelectedColumns] = useState<ExportColumn[]>([])

  async function handleExport () {
    try {
      if (selectedHotels.length === 0) {
        alert('Devi selezionare almeno un hotel')
        return
      }
      if (columns.length > 0 && selectedColumns.length === 0) {
        alert('Devi selezionare almeno una colonna')
        return
      }
      setLoading(true)
      const accomodations = {} as any
      selectedHotels.forEach((accomodation: any) => {
        accomodations[accomodation.value] = accomodation.label
      })
      const blob = await exportReservations<any>({
        filters: {
          page: 0,
          pageOffset: 0,
          criterias: [],
          sortType: 'DESC',
          orderBy: 'idBooking',
          maxResults: 5000
        },
        year,
        fromDate: parseInt(dayjs(fromDate).format('YYYYMMDD')),
        toDate: parseInt(dayjs(fromDate).format('YYYYMMDD')),
        columns: selectedColumns,
        accomodations
      })
      const url = window.URL.createObjectURL(blob as any)
      const a = document.createElement('a')
      a.href = url;
      a.download = "besaferate_reservation_export.xlsx"
      document.body.appendChild(a)
      a.click()
      a.remove()
      toast.success('Export completato, trovi il file nei download')
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      visible={visible}>
      <ModalCard
        className={'w-full max-w-lg'}
        title={t('Report contabile')}>
        <div className="pb-4">
          <AccountingReport />
        </div>
        <div className="px-4 pb-4">
          <Button onClick={onClose} type='alternate' label='Annulla'></Button>
        </div>
      </ModalCard>
    </Modal>
  )

}
