import {
    doc,
    collection,
    deleteDoc,
    DocumentData,
    Firestore,
    getDoc,
    getDocs,
    limit,
    orderBy,
    Query,
    startAfter,
    query,
    QueryDocumentSnapshot,
    QuerySnapshot,
    addDoc,
    setDoc,
    onSnapshot,
    DocumentSnapshot
} from 'firebase/firestore'

import { BaseCollection, Feedback } from "../types"

const feedbackConverter = {
    toFirestore(data: Feedback): DocumentData {
        return data
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot
    ): Feedback {
        return {
            ...snapshot.data(),
            id: snapshot.id
        } as Feedback
    }
}

export interface FeedbackCollection extends BaseCollection<Feedback> {
    paginate: (lastSeen: number, limit: number) => Promise<QuerySnapshot<Feedback>>
}

export default function (db: Firestore) : FeedbackCollection {

    return {
        collection: collection(db, 'featurevotes').withConverter<Feedback>(feedbackConverter),

        async getAll () {
            const snapshot = await getDocs(this.collection)
            return snapshot.docs.map(doc => doc.data() as Feedback)
        },

        async getOne (id: string) {
            const docRef = doc<Feedback>(this.collection, id)
            return await getDoc<Feedback>(docRef)
        },

        async createOne (data) {
            return await addDoc<Feedback>(
                this.collection,
                data
            )
        },

        async updateOne (id, data) {
            const ref = doc(db, "featurevotes", id)
            return await setDoc(
                ref, data
            )
        },

        async deleteOne (id: string) {
            return await deleteDoc(doc(db, "featurevotes", id))
        },

        listenDocument (id: string, callback: (snapshot: DocumentSnapshot<Feedback>) => void) {
            return onSnapshot<Feedback>(
                doc<Feedback>(this.collection, id).withConverter<Feedback>(feedbackConverter),
                callback,
                err => {
                    console.log(err)
                }
            )
        },

        async paginate (lastSeen: number, lim: number) {
            return await getDocs<Feedback>(
                query<Feedback>(
                    this.collection,
                    limit(lim),
                    orderBy('date'),
                    startAfter(lastSeen)
                ).withConverter(feedbackConverter)
            )
        }

    } as FeedbackCollection

}