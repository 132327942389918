import dayjs from "dayjs";
import { Fragment, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getPayment, saveBookingPayment } from "../../../shared/queries";
import { BookingPayment } from "../../../types";
import Modal from "../../common/Modal";
import ModalActions from "../../common/ModalActions";
import ModalCard from "../../common/ModalCard";
import DatePickerInput from "../../form/DatePickerInput";
import FormField from "../../form/FormField";
import TextInput from "../../form/TextInput";
import DeleteModal from "../../common/DeleteModal";
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import { useTranslation } from "react-i18next";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../shared/utils";

export default function CreatePayment ({
    idBooking,
    idPayment,
    visible,
    bookingTotal,
    onClose,
    onSave
} : {
    idPayment?: string | number | undefined,
    idBooking: string | number,
    bookingTotal: string | number,
    visible: boolean,
    onClose: () => void,
    onSave: (data: BookingPayment) => void
}) {

    const { t } = useTranslation()

    const { profile } = useSelector((state: RootState) => state.session)

    const {
        currentHotelObject
    } = useSelector((state: RootState) => state.hotels)


    const [error, setError] = useState<string | undefined>(undefined)
    const [percentMode, setPercentMode] = useState(false)
    const [paymentAmount, setPaymentAmount] = useState<string>('0')
    const [currency, setCurrency] = useState<any>()
    const [exchangeRate, setExchangeRate] = useState<any>()
    const [paymentInsuredAt, setPaymentInsuredAt] = useState<any>()
    const [paymentBilledAt, setPaymentBilledAt] = useState<any>()
    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [paymentDate, setPaymentDate] = useState<Date>(new Date())

    const {} = useQuery(['payment', idPayment], () => getPayment(idPayment ? idPayment : ''), {
        enabled: (idPayment !== undefined),
        onSuccess: data => {
            setCurrency(data.currency)
            setExchangeRate(data.exchangeRate)
            setPaymentBilledAt(data.paymentBilledAt)
            setPaymentInsuredAt(data.paymentInsuredAt)
            setPaymentAmount(data.paymentAmount.toString())
            setPaymentDate(dayjs(data.paymentDate.toString(), 'YYYYMMDD').toDate())
        }
    })

    const saveMutation = useMutation(() => saveBookingPayment({
        idBooking,
        idBookingPayment: idPayment ? parseInt(idPayment.toString()) : undefined,
        paymentAmount: parseFloat(paymentAmount),
        paymentDate: parseInt(dayjs(paymentDate).format('YYYYMMDD')),
        paymentInsuredAt: paymentInsuredAt,
        paymentBilledAt: paymentBilledAt,
        exchangeRate: exchangeRate,
        currency: currency,
        paymentFromChannel: false
    }), {
        onSuccess: data => {
            onSave(data)
            setShowConfirm(false)
        } 
    })
    
    return (
        <Fragment>
            <DeleteModal
                title={t('createPayment.activatePolicy')}
                askConfirmation={true}
                message={t('createPayment.activatePolicyText')}
                visible={showConfirm}
                saveLabel={t('general.confirm')}
                onCancel={() => {
                    setShowConfirm(false)
                }}
                isLoading={saveMutation.isLoading}
                onConfirm={() => {
                    saveMutation.mutate()
                }} />

            <Modal
                visible={visible}>
                <ModalCard
                    renderActions={() => (
                        <ModalActions
                            onClose={onClose}
                            onSave={() => {
                                /*
                                if (parseFloat(paymentAmount) < 10) {
                                    setError(t('reservation.paymentErrorLow'))
                                    return
                                }
                                */
                                if (!idPayment && dayjs(paymentDate).isAfter(dayjs(), 'day')) {
                                    setError(t('reservation.paymentErrorFuture'))
                                    return
                                }
                                if (profile && !profile.userAdmin) {
                                    const permittedDays = (currentHotelObject && currentHotelObject.customerType === 'AGENCY') ? 3 : 10
                                    if (!idPayment && dayjs(paymentDate).isBefore(dayjs().subtract(permittedDays, 'days'))) {
                                        setError(t('reservation.paymentTooFar'))
                                        return
                                    }
                                }
                                setError(undefined)
                                if (parseFloat(paymentAmount) > 70000) {
                                    if (window.confirm(`${t('vary.confirmPaymentUpdate')} ${formatCurrency(parseFloat(paymentAmount))}`)) {
                                        setShowConfirm(true)
                                    }
                                } else {
                                    setShowConfirm(true)
                                }
                            }} />
                    )}
                    title={t('createPayment.newPayment')}
                    subTitle={t('createPayment.newPaymentText')}>

                    {
                        false &&
                        <div className="px-4 mt-2 flex space-x-6 mb-3">
                            <Toggle
                                id={'percentMode'}
                                checked={percentMode}
                                onChange={e => {
                                    setPercentMode(e.target.checked)
                                    if (e.target.checked) {
                                        setPaymentAmount('100')
                                    } else {
                                        setPaymentAmount('0')
                                    }
                                }} />
                            <label htmlFor={'percentMode'}>
                                {t('createPayment.usePercent')}
                                <div className="mt-1 font-normal text-gray-600">
                                    {t('createPayment.usePercentText')}
                                </div>
                            </label>
                        </div>
                    }
                    
                    <div className="p-4 flex space-x-4">
                        <div className="flex-1">
                            <FormField label={t('createPayment.paymentAmount')}>
                                <TextInput
                                    numbered={true}
                                    value={paymentAmount}
                                    suffix={(currentHotelObject) ? currentHotelObject.currency : 'EUR'}
                                    onChange={val => setPaymentAmount(val)} />
                            </FormField>
                            {
                                (percentMode && !isNaN((parseFloat(bookingTotal.toString()) * (parseFloat(paymentAmount) / 100)))) &&
                                <div className={'block mt-3 font-normal text-gray-600'}>
                                    {t('createPayment.the')} <span className={'font-medium'}>{paymentAmount}%</span> {t('createPayment.equalTo')} <span className={'font-medium'}>{(parseFloat(bookingTotal.toString()) * (parseFloat(paymentAmount) / 100))} EUR</span>
                                </div>
                            }
                        </div>

                        <div className="flex-1">
                            <FormField label={t('createPayment.paymentDate')}>
                                <DatePickerInput
                                    value={paymentDate}
                                    onChange={date => {
                                        setPaymentDate(date)
                                    }} />
                            </FormField>
                        </div>
                    </div>
                    {
                        error &&
                        <div className={'px-4 mt-2 mb-2'}>
                            <div className="flex-1 bg-red-100 text-red-600 rounded-md p-3">
                                {error}
                            </div>
                        </div>
                    }
                </ModalCard>
            </Modal>
        </Fragment>
    )

}