import Joi from 'joi'
import { Quote } from '../types'
import quoteOptionSchema from './quoteOptionSchema'

export default Joi.object<Quote, false>({
  idQuote: Joi.number(),
  isDeleted: Joi.any(),
  firstName: Joi.any(),
  lastName: Joi.any(),
  lastViewAt: Joi.any(),
  email: Joi.any(),
  language: Joi.any(),
  expiresAt: Joi.any(),
  idCancellationPolicy: Joi.alternatives().conditional(
    'refundable', { is: false, then: Joi.number().required(), otherwise: Joi.any() }
  ),
  policyTitle: Joi.any(),
  policyText: Joi.any(),
  insuranceRate: Joi.number().required(),
  message: Joi.string().allow('', null),
  refundable: Joi.boolean().required(),
  note: Joi.string().allow('', null),
  insuranceProduct: Joi.any(),
  notifiedAt: Joi.any(),
  acceptedAt: Joi.any(),
  rejectedAt: Joi.any(),
  rejectReason: Joi.any(),
  idAccomodation: Joi.number().required(),
  besafeIncluded: Joi.boolean().required(),
  askBilling: Joi.any(),
  customer: Joi.required(),
  insuranceMarkup: Joi.alternatives().conditional(
    'besafeIncluded', { is: false, then: Joi.number().required(), otherwise: Joi.any() }
  ),
  options: Joi.array().min(1).items(
    quoteOptionSchema
  ),
  insuranceCost: Joi.any(),
  idQuoteOptionAccepted: Joi.any(),
  customerAddress: Joi.any(),
  customerEmail: Joi.any(),
  customerZipCode: Joi.any(),
  customerPhone: Joi.any(),
  customerCountryCode: Joi.any(),
  gatewayReference: Joi.any(),
  paymentStatus: Joi.any(),
  cancellationUnit: Joi.any(),
  cancellationAmount: Joi.any(),
  prepaidAmount: Joi.any(),
  prepaidUnit: Joi.any(),
  paymentStartedAt: Joi.any(),
  hotelConfirmedAt: Joi.any(),
  hotelRejectedAt: Joi.any()
})