import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import CreateAccomodation from "../components/business/hotel/CreateAccomodation";
import Chip from "../components/common/Chip";
import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";
import BaseTable from "../components/data/BaseTable";
import Button from "../components/form/Button";
import OnBoarding from "../components/OnBoarding";
import { getQueryableResource } from "../shared/queries";
import { RootState } from "../store";
import { Accomodation, RestCriteria } from "../types";

export default function HotelsPage () {

    const { profile } = useSelector((state: RootState) => state.session)

    const { t } = useTranslation()

    const history = useHistory()

    const [createHotel, setCreateHotel] = useState<boolean>(false)
    const [onboard, setOnboard] = useState<boolean>(false)
    
    const [page, setPage] = useState<number>(0)
    const [filters, setFilters] = useState<RestCriteria[]>([])
    const [sortBy, setSortBy] = useState('idAccomodation')
    const [sortType, setSortType] = useState('DESC')

    const {
        data,
        refetch,
        isFetching
    } = useQuery([
      'filteredAccomodations',
        page,
        sortBy,
        sortType,
        filters
    ], () => getQueryableResource<Accomodation>(
      'accomodations',
      page,
      50,
      sortBy,
      filters,
      sortType
    ))
    

    return (
        <PageWrapper className={'p-4'} centered maxWidth={'1000px'}>
            
            <PageTitle
                title={t('accommodations.accommodations')}
                renderActions={() => (
                    <Fragment>
                        {
                            (false && profile?.userAdmin) &&
                            <Button
                                className="text-white bg-orange-600"
                                label={t('accommodations.newCompany')}
                                onClick={() => {
                                    setCreateHotel(true)
                                }} />
                        }

                        <Button
                            className={'border border-orange-500 shadow text-sm font-semibold rounded-md py-1 px-4 text-white bg-orange-600'}
                            label={t('accommodations.newCompany')}
                            onClick={() => {
                                setOnboard(true)
                            }} />
                    </Fragment>
                )} />

            <OnBoarding neutral={false} generateDeal={true} visible={onboard} onClose={() => setOnboard(false)} onDone={() => setOnboard(false)} />

            <BaseTable
                loading={isFetching}
                currentPage={page}
                pageCount={data ? data.totalPages : 0}
                onFilterChange={(filters, page) => {
                    if (page !== undefined) setPage(page)
                    if (filters) {
                        setFilters(filters.filter(filter => filter.active).map(filter => {
                            return {
                                field: filter.key,
                                searchOperation: filter.operator,
                                value: filter.value
                            }
                        }))
                    }
                }}
                onSorting={data => {
                    if (data && data.length > 0) {
                        setSortBy(data[0].id)
                        setSortType(data[0].desc ? 'DESC' : 'ASC')
                    }
                }}
                filters={[
                    {
                        key: 'idAccomodation',
                        label: 'ID',
                        operator: 'EQUAL',
                        active: false,
                        value: null,
                        type: 'text'
                    },
                    {
                        key: 'accomodationName',
                        label: 'Hotel',
                        operator: 'CONTAINS',
                        active: false,
                        value: null,
                        type: 'text'
                    },
                    {
                        key: 'companyName',
                        label: 'Company',
                        unmanaged: true,
                        operator: 'CONTAINS',
                        active: false,
                        value: null,
                        type: 'text'
                    },
                    {
                        key: 'isActive',
                        label: 'Status',
                        operator: 'EQUAL',
                        active: false,
                        value: null,
                        type: 'select',
                        options: [
                            { label: 'Attivo', value: 1 },
                            { label: 'Disattivo', value: 0 }
                        ]
                    } 
                ]}
                showColumnSelector={true}
                identifierKey={'idAccomodation'}
                onEdit={id => {
                    history.push('/app/hotels/' + id)
                }}
                columns={[
                    {
                        Header: 'ID',
                        accessor: 'idAccomodation'
                    },
                    {
                        Header: t('accommodations.name').toString(),
                        accessor: 'accomodationName'
                    },
                    {
                        Header: t('accommodations.company').toString(),
                        accessor: (row: any) => {
                            if (row.company) {
                                return row.company.companyName
                            }
                            return 'ND'
                        }
                    },
                    {
                        Header: 'Email',
                        accessor: (row: any) => {
                            if (row.company) {
                                return row.company.companyEmail
                            }
                            return 'ND'
                        }
                    },
                    {
                        Header: t('accommodations.status').toString(),
                        width: 'w-32',
                        accessor: (row: any) => (
                            (row.isActive && !row.deletedAt) ? 
                            <Chip className={'text-green-600 bg-green-100'}>
                                Attivo
                            </Chip>
                            :
                            <Chip className={'text-red-600 bg-red-100'}>
                                Disattivo
                            </Chip>
                        )
                    }
                ]}
                data={data ? data.content : []} />

            <CreateAccomodation
                visible={createHotel}
                onClose={() => setCreateHotel(false)}
                onSave={() => {
                    setCreateHotel(false)
                    refetch()
                }} />

        </PageWrapper>
    )
}