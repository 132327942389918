import { motion } from "framer-motion";
import { useRef } from "react";

type ProgressBarProps = {
  className?: string
}

export default function ProgressBar ({
  className
} : ProgressBarProps) {

  const progressBarRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={progressBarRef}
      className={'h-1 bg-gray-200 overflow-hidden ' + className}>
      <motion.div
        initial={{
          translateX: -300
        }}
        animate={{
          translateX: progressBarRef && progressBarRef.current ? progressBarRef.current.offsetWidth : 1000
        }}
        transition={{
          loop: Infinity,
          ease: 'linear',
          duration: 0.9,
        }}
        className={'h-1 w-40 bg-orange-600'}></motion.div>
    </div>
  )

}