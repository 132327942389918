import dayjs from "dayjs";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { activateProduct, getBusinessInformation, initOnboarding } from "../shared/queries";
import { Accomodation, CompanyProduct } from "../types";
import CountrySelector from "./business/selectors/CountrySelector";
import Modal from "./common/Modal";
import ModalCard from "./common/ModalCard";
import Checkbox from "./form/Checkbox";
import FormField from "./form/FormField";
import TextInput from "./form/TextInput";
import SelectInput from "./form/SelectInput";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { motion } from 'framer-motion'
import Stepper from "./Stepper";
import PaymentMethods from "./business/PaymentMethods";
import Button from "./form/Button";
import onboardingStep1Schema from "../validation/onboardingStep1Schema";
import { ValidationError } from "joi";
import onboardingStep2Schema from "../validation/onboardingStep2Schema";
import AddressSearch from "./AddressSearch";

export default function OnBoarding ({
    neutral = false,
    visible,
    generateDeal = false,
    onDone,
    onClose
} : {
    neutral: boolean,
    generateDeal?: boolean,
    visible: boolean,
    onDone: () => void,
    onClose: () => void
}) {

    const { t, i18n } = useTranslation()

    const { profile } = useSelector((state: RootState) => state.session)

    const [zipCodeRequired, setZipCodeRequired] = useState<'accommodation' | 'company' | undefined>()

    const [errors, setErrors] = useState<ValidationError | undefined>()
    const [currentStep, setCurrentStep] = useState(0)

    const [confirmingProducts, setConfirmingProducts] = useState(false)
    const [rateAccepted, setRateAccepted] = useState(false)
    const [payAccepted, setPayAccepted] = useState(false)

    const [hotelAddressValue, setHotelAddressValue] = useState<any>()
    const [companyAddressValue, setCompanyAddressValue] = useState<any>()

    const [createdAccommodation, setCreatedAccommodation] = useState<Accomodation | undefined>()
    const [creatingAccommodation, setCreatingAccommodation] = useState(false)
    const [vatSearched, setVatSearched] = useState(false)
    const [hasVat, setHasVat] = useState(true)
    const [vatCountry, setVatCountry] = useState<any>({
        label: 'Italy',
        code: 'IT',
        countryAlpha2: 'IT',
        value: 113
    })
    const [accomodation, setAccomodation] = useState<Accomodation>({
        accomodationName: '',
        accomodationPhone: '',
        usePendingModel: false,
        opportunity: false,
        opportunitySendEmails: false,
        customerType: 'HOTEL',
        verificationNeeded: false,
        address: {
            addressProvince: '',
            addressZipCode: '',
            addressCity: '',
            addressLatitude: 0,
            addressLongitude: 0,
            addressState: '',
            addressStreet: '',
            idCountry: 0
        },
        company: {
            companyEmail: '',
            companyFiscalCode: '',
            companyName: '',
            companyPec: '',
            companySdi: '',
            companyVat: '',
            createdAt: parseInt(dayjs().format('YYYYMMDD')),
            updatedAt: parseInt(dayjs().format('YYYYMMDD')),
            address: {
                addressProvince: '',
                addressZipCode: '',
                addressCity: '',
                addressLatitude: 0,
                addressLongitude: 0,
                addressState: '',
                addressStreet: '',
                idCountry: 0
            }
        },
        isActive: true,
        isSuspended: false,
        policies: []
    })

    const productMutation = useMutation((data: {
        data: CompanyProduct,
        type: 'pay' | 'rate'
    }) => activateProduct(data.data, data.type, i18n.language))

    const businessInfoMutation = useMutation((identifier: string) => getBusinessInformation(vatCountry.countryAlpha2, identifier), {
        onSuccess: data => {
            setVatSearched(true)
            setAccomodation({
                ...accomodation,
                company: {
                    ...accomodation.company,
                    companyName: data.companyName,
                    companyFiscalCode: data.fiscalCode,
                    companySdi: data.sdiCode,
                    companyVat: data.vat
                }
            })
        },
        retry: false
    })

    function getBusinessInfo () {
        if (vatSearched) return
        if (businessInfoMutation.isLoading) return
        if (vatCountry.countryAlpha2 === 'IT' && accomodation.company.companyVat.length <= 10) return
        if (hasVat) {
            if (!accomodation.company.companyVat || accomodation.company.companyVat === '') return
            businessInfoMutation.mutate(accomodation.company.companyVat)
        } else {
            if (!accomodation.company.companyFiscalCode || accomodation.company.companyFiscalCode === '') return
            businessInfoMutation.mutate(accomodation.company.companyFiscalCode)
        }
    }

    function handleAccommodationData () {
        const { error } = onboardingStep1Schema.validate(accomodation)
        if (error) {
            console.log(error.details)
        }
        setErrors(error)
        if (error && error.details.length > 0) {
            toast.error(t('general.missingFields'))
            return
        } else {
            setCurrentStep(currentStep + 1)
        }
    }

    function handleCompanyData () {
        const { error, value, warning } = onboardingStep2Schema.validate({
            ...accomodation,
            hasVat,
            vatCountry: vatCountry.idCountry
        })
        console.log(error)
        setErrors(error)
        if (error && error.details.length > 0) {
            toast.error(t('general.missingFields'))
            return
        } else {
            setCurrentStep(currentStep + 1)
        }
    }

    async function handleProductActivation () {
        try {
            setCreatingAccommodation(true)
            setConfirmingProducts(true)
            const data = await initOnboarding({
                ...accomodation,
                generateDeal
            })

            setCreatedAccommodation(data)

            if (rateAccepted) {
                await productMutation.mutate({
                    data: {
                        activationDate: parseInt(dayjs().format('YYYYMMDD')),
                        company: {
                            idCompany: data?.company.idCompany
                        },
                        product: {
                            idProduct: 1
                        }
                    },
                    type: 'rate'
                })
            }
    
            if (payAccepted) {
                await productMutation.mutate({
                    data: {
                        activationDate: parseInt(dayjs().format('YYYYMMDD')),
                        company: {
                            idCompany: data?.company.idCompany
                        },
                        product: {
                            idProduct: 2
                        }
                    },
                    type: 'pay'
                })
            }

            setCurrentStep(currentStep + 1)
        } catch (e) {
        } finally {
            setCreatingAccommodation(false)
            setConfirmingProducts(false)
        }
    }

    return (
        <Modal
            visible={visible} maximized={true}>
            <ModalCard
                className={'w-full max-w-6xl overflow'}>

                <div className="flex overflow-hidden">
                    <div className={`${neutral ? 'bg-sky-50' : 'bg-orange-50'} min-h-[460px] p-8 z-5 flex justify-center relative`}>
                        <div className="flex space-x-5 overflow-hidden">
                            <img src={`${neutral ? '/assets/welcomeblue.svg' : '/assets/welcome.svg'}`} className="w-44 absolute -bottom-28" alt="" />
                            <div className="flex-1 w-80">
                                <div className={`text-4xl font-bold ${neutral ? 'text-sky-800' : 'text-orange-800'}`}>
                                    {t('vary.welcomeBeSafe')}
                                </div>
                                <div className={`${neutral ? 'text-sky-800' : 'text-orange-800'} mt-4`}>
                                    {t('vary.welcomeBeSafeText')}
                                </div>
                                <div className={`flex flex-col space-y-2 ${neutral ? 'text-sky-800' : 'text-orange-800'} font-medium mt-6`}>
                                    <motion.div initial={{ opacity: 0, top: -10 }} animate={{ opacity: 1, top: 0 }} className="relative flex space-x-2">
                                        <div>
                                            ✅
                                        </div>
                                        <div>
                                            {t('vary.welcomeBeSafeTip1')}
                                        </div>
                                    </motion.div>
                                    <motion.div initial={{ opacity: 0, top: -10 }} animate={{ opacity: 1, top: 0 }} className="relative flex space-x-2">
                                        <div>
                                            ✅
                                        </div>
                                        <div>
                                            {t('vary.welcomeBeSafeTip2')}
                                        </div>
                                    </motion.div>
                                    <motion.div initial={{ opacity: 0, top: -10 }} animate={{ opacity: 1, top: 0 }} className="relative flex space-x-2">
                                        <div>
                                            ✅
                                        </div>
                                        <div>
                                            {t('vary.welcomeBeSafeTip3')}
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 shadow-xl z-10">
                        <Stepper neutral={neutral} currentStep={currentStep} onStepClick={stepIdx => setCurrentStep(stepIdx)} steps={[
                            { id: '01', name: t('stepper.accommodationDetails'), clickable: false },
                            { id: '02', name: t('stepper.billingDetails'), clickable: false },
                            { id: '03', name: t('stepper.contract'), clickable: false },
                            { id: '04', name: t('stepper.paymentDetails'), clickable: false }
                        ]} />

                        <div className="px-4 pb-4">

                            {
                                currentStep === 0 &&
                                <div className="py-4">
                                    <div className="flex space-x-4">
                                        {
                                            (profile && profile.userAdmin) &&
                                            <div className="w-1/2 mb-4">
                                                <FormField label={t('addHotel.partnerType')}>
                                                    <SelectInput
                                                        value={[
                                                                { label: 'HOTEL', value: 'HOTEL'},
                                                                { label: 'TO', value: 'TO '},
                                                                { label: 'AGENCY', value: 'AGENCY '}
                                                            ].find(t => t.value === accomodation.customerType)
                                                        }
                                                        options={[
                                                            { label: 'HOTEL', value: 'HOTEL'},
                                                            { label: 'TO', value: 'TO '},
                                                            { label: 'AGENCY', value: 'AGENCY '},
                                                        ]}
                                                        onChange={(val: any) => {
                                                            setAccomodation({
                                                                ...accomodation,
                                                                customerType: val.value
                                                            })
                                                        }}
                                                    />
                                                </FormField>
                                            </div>
                                        }
                                        <div className='w-1/2'>
                                            <FormField errors={errors} errorKey="accomodationName" label={t('onboarding.hotelName')}>
                                                <TextInput
                                                    value={accomodation.accomodationName}
                                                    onChange={val => setAccomodation({
                                                        ...accomodation,
                                                        accomodationName: val
                                                    })} />
                                            </FormField>
                                        </div>
                                    </div>

                                    <div className="mt-4 flex space-x-4">
                                        <FormField className="flex-1" label={t('vary.accommodationAddress')}>
                                            <AddressSearch onAddressSelected={(details, inputValue) => {
                                                setHotelAddressValue(inputValue)
                                                if (details.addressZipCode === null) {
                                                    setZipCodeRequired('accommodation')
                                                } else {
                                                    setZipCodeRequired(undefined)
                                                }
                                                setAccomodation({
                                                    ...accomodation,
                                                    address: details
                                                })
                                            }} />
                                        </FormField>

                                        {
                                            (zipCodeRequired === 'accommodation') &&
                                            <FormField errors={errors} errorKey={'addressZipCode'} label={t('onboarding.zipCode')}>
                                                <TextInput
                                                    value={accomodation.address.addressZipCode}
                                                    onChange={val => setAccomodation({
                                                        ...accomodation,
                                                        address: {
                                                            ...accomodation.address,
                                                            addressZipCode: val
                                                        }
                                                    })}
                                                />
                                            </FormField>
                                        }
                                    </div>

                                    <div className="mt-6 flex space-x-4">
                                        <FormField className={'flex-1'} label={t('vary.notificationEmail')}>
                                            <TextInput
                                                value={accomodation.generalNotificationEmail}
                                                onChange={val => setAccomodation({
                                                    ...accomodation,
                                                    generalNotificationEmail: val
                                                })} />
                                        </FormField>

                                        <FormField className={'flex-1'} label={t('vary.billingEmail')}>
                                            <TextInput
                                                value={accomodation.billingEmail}
                                                onChange={val => setAccomodation({
                                                    ...accomodation,
                                                    billingEmail: val,
                                                    company: {
                                                        ...accomodation.company,
                                                        companyEmail: val
                                                    }
                                                })} />
                                        </FormField>
                                    </div>

                                    <div className="mt-6 flex justify-end space-x-2">
                                        <Button className={`${neutral ? 'bg-sky-600' : 'bg-orange-600'} text-white`} label={t('general.next')} onClick={handleAccommodationData} />
                                        <Button className="bg-red-100 text-red-600" label={t('general.cancel')} onClick={onClose} />
                                    </div>
                                </div>
                            }
                            
                            {
                                currentStep === 1 &&
                                <div className="flex py-4 flex-col space-y-4">
                                    <div>
                                        <Checkbox
                                            checked={hasVat}
                                            onChange={() => setHasVat(!hasVat)}
                                            label={t('onboarding.needVat')} />
                                    </div>

                                    <div>
                                        <FormField errorKey="vatCountry" errors={errors} label={t('onboarding.country')}>
                                            <CountrySelector
                                                onSelect={c => {
                                                    setVatCountry(c)
                                                    if (c.countryAlpha2 !== 'IT') {
                                                        setAccomodation({
                                                            ...accomodation,
                                                            company: {
                                                                ...accomodation.company,
                                                                companySdi: 'XXXXXXX'
                                                            }
                                                        })
                                                    }
                                                }} />
                                        </FormField>
                                    </div>
                                    <div className="flex space-x-4">
                                        {
                                            hasVat &&
                                            <div className="flex-1">
                                                <FormField errors={errors} errorKey={'companyVat'} className={'flex-1'} label={t('onboarding.vat')}>
                                                    <TextInput
                                                        onBlur={() => {
                                                            setVatSearched(true)
                                                            getBusinessInfo()
                                                        }}
                                                        value={accomodation.company.companyVat}
                                                        onChange={val => setAccomodation({
                                                            ...accomodation,
                                                            company: {
                                                                ...accomodation.company,
                                                                companyVat: val
                                                            }
                                                        })}  />
                                                </FormField>
                                            </div>
                                        }
                                        {
                                            (!hasVat || (hasVat && (vatCountry && vatCountry.countryAlpha2 === 'IT'))) &&
                                            <div className="flex-1">
                                                <FormField errors={errors} errorKey={'companyFiscalCode'} className={'flex-1'} label={t('onboarding.fiscalCode')}>
                                                    <TextInput
                                                        onBlur={() => {
                                                            setVatSearched(true)
                                                            getBusinessInfo()
                                                        }}
                                                        value={accomodation.company.companyFiscalCode}
                                                        onChange={val => setAccomodation({
                                                            ...accomodation,
                                                            company: {
                                                                ...accomodation.company,
                                                                companyFiscalCode: val
                                                            }
                                                        })} />
                                                </FormField>
                                            </div>
                                        }
                                    </div>

                                    <div className="flex space-x-4">
                                        <FormField className="flex-1" label={t('vary.billingAddress')}>
                                            <AddressSearch onAddressSelected={(details, inputValue) => {
                                                setCompanyAddressValue(inputValue)
                                                if (details.addressZipCode === null) {
                                                    setZipCodeRequired('company')
                                                } else {
                                                    setZipCodeRequired(undefined)
                                                }
                                                setAccomodation({
                                                    ...accomodation,
                                                    company: {
                                                        ...accomodation.company,
                                                        address: details
                                                    }
                                                })
                                            }} />
                                        </FormField>

                                        {
                                                (zipCodeRequired) &&
                                                <FormField errors={errors} errorKey={'addressZipCode'} label={t('onboarding.zipCode')}>
                                                    <TextInput
                                                        value={accomodation.company.address?.addressZipCode}
                                                        onChange={val => {
                                                            if (accomodation.company.address) {
                                                                setAccomodation({
                                                                    ...accomodation,
                                                                    company: {
                                                                        ...accomodation.company,
                                                                        address: {
                                                                            ...accomodation.company.address,
                                                                            addressZipCode: val
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </FormField>
                                            }
                                    </div>

                                    <div className="flex space-x-4">
                                        <FormField errors={errors} errorKey={'companyName'} className={'flex-1'} label={t('onboarding.companyName')}>
                                            <TextInput
                                                value={accomodation.company.companyName}
                                                onChange={val => setAccomodation({
                                                    ...accomodation,
                                                    company: {
                                                        ...accomodation.company,
                                                        companyName: val
                                                    }
                                                })} />
                                        </FormField>
                                        <FormField errors={errors} errorKey={'companyEmail'} className={'flex-1'} label={t('onboarding.email')}>
                                            <TextInput
                                                value={accomodation.company.companyEmail}
                                                onChange={val => setAccomodation({
                                                    ...accomodation,
                                                    company: {
                                                        ...accomodation.company,
                                                        companyEmail: val
                                                    }
                                                })}/>
                                        </FormField>
                                    </div>

                                    {
                                        (vatCountry.countryAlpha2 === 'IT') &&
                                        <div className="flex space-x-4">
                                            <FormField errors={errors} errorKey={'companySdi'} className={'flex-1'} label={t('onboarding.codiceSDI')}>
                                                <TextInput
                                                    value={accomodation.company.companySdi}
                                                    onChange={val => setAccomodation({
                                                        ...accomodation,
                                                        company: {
                                                            ...accomodation.company,
                                                            companySdi: val
                                                        }
                                                    })} />
                                            </FormField>

                                            <FormField errors={errors} errorKey={'companyPec'} className={'flex-1'} label={t('onboarding.pec')}>
                                                <TextInput
                                                    value={accomodation.company.companyPec}
                                                    onChange={val => setAccomodation({
                                                        ...accomodation,
                                                        company: {
                                                            ...accomodation.company,
                                                            companyPec: val
                                                        }
                                                    })} />
                                            </FormField>
                                        </div>
                                    }
                                    
                                    <div className="mt-6 flex justify-end space-x-2">
                                        <Button className={`${neutral ? 'bg-sky-600' : 'bg-orange-600'} text-white`} label={creatingAccommodation ? t('general.wait') + '...' : t('general.next')} onClick={handleCompanyData} />
                                        <Button className="bg-red-100 text-red-600" label={t('general.back')} onClick={() => setCurrentStep(currentStep - 1)} />
                                    </div>
                                </div>
                            }
                            
                            {
                                (currentStep === 2) &&
                                <>
                                    <div className="p-6 pt-10">
                                        <div className="text-slate-600 font-medium text-center text-2xl mb-">
                                            {t('vary.chooseProduct')}
                                        </div>
                                        <div className="text-slate-600 mb-4 text-center mt-4">
                                            {t('vary.chooseProductText')}
                                        </div>

                                        <fieldset>
                                            <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                <label aria-label="BeSafe Rate" aria-description="The insured rate" className="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none">
                                                    <img src="/logo_rate_small.svg" className={'h-8 xl:h-14 mr-4'} alt="" />
                                                    <input type="radio" name="project-type" value="BeSafe Rate" className="sr-only" />
                                                    <span className="flex flex-1">
                                                        <span className="flex flex-col">
                                                            <span className="block text-lg font-medium text-gray-900">BeSafe Rate</span>
                                                            <span className="mt-1 flex items-center text-gray-500">The insured rate</span>
                                                            <span className="mt-1">
                                                                <a className="text-orange-600 underline" href={t('beSafeRateContract.link')} target="_blank">{t('contract.downloadContract')}</a>
                                                            </span>

                                                            <div className="mt-4">
                                                                <Checkbox
                                                                    label={t('vary.acceptTerms')}
                                                                    checked={rateAccepted}
                                                                    onChange={() => setRateAccepted(!rateAccepted)}
                                                                />
                                                            </div>
                                                        </span>
                                                    </span>
                                                    {
                                                        rateAccepted &&
                                                        <svg className="h-5 w-5 text-orange-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                        </svg>
                                                    }
                                                    <span className="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"></span>
                                                </label>
                                                <label aria-label="BeSafe Pay" aria-description="The future gateway" className="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none">
                                                    <img src="/logo_pay_small.svg" className={'h-8 xl:h-14 mr-4'} alt="" />
                                                    <input type="radio" name="project-type" value="BeSafe Pay" className="sr-only" />
                                                    <span className="flex flex-1">
                                                        <span className="flex flex-col">
                                                            <span className="block text-lg font-medium text-gray-900">BeSafe Pay</span>
                                                            <span className="mt-1 flex items-center text-gray-500">The future gateway</span>
                                                            <span className="mt-1">
                                                                <a className="text-orange-600 underline" href={i18n.language === 'it' ? '/assets/contracts/pay-contract.pdf' : '/assets/contracts/pay-contract-en.pdf'} target="_blank">{t('contract.downloadContract')}</a>
                                                            </span>
                                                            <div className="mt-4">
                                                                <Checkbox
                                                                    label={t('vary.acceptTerms')}
                                                                    checked={payAccepted}
                                                                    onChange={() => setPayAccepted(!payAccepted)}
                                                                />
                                                            </div>
                                                        </span>
                                                    </span>
                                                    {
                                                        payAccepted &&
                                                        <svg className="h-5 w-5 text-orange-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                        </svg>
                                                    }
                                                    <span className="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"></span>
                                                </label>
                                            </div>
                                            </fieldset>
                                    </div>
                                    {
                                        (rateAccepted || payAccepted) ?
                                        <div className="mt-6 flex justify-end space-x-2">
                                            <Button className={`${neutral ? 'bg-sky-600' : 'bg-orange-600'} text-white`} label={creatingAccommodation ? t('general.wait') + '...' : t('general.next')} onClick={handleProductActivation} />
                                            <Button className="bg-red-100 text-red-600" label={t('general.back')} onClick={() => setCurrentStep(currentStep - 1)} />
                                        </div>
                                        :
                                        <div className="text-right text-orange-600 font-medium">
                                            {t('vary.mustSelectProduct')}
                                        </div>
                                    }
                                </>
                            }

                            {
                                (createdAccommodation && createdAccommodation.idAccomodation && currentStep === 3) && <div className="py-4">
                                    <PaymentMethods idAccommodation={createdAccommodation.idAccomodation} onCancel={() => onDone()} />
                                </div>
                            }
                        </div>

                    </div>    
                </div>    
                
            </ModalCard>
        </Modal>
    )

}
