import Joi from "joi";
import i18n from '../i18n/index'

export default Joi.object<any, false>({
  distributor_code: Joi.string().required().messages({
    'string.required': i18n.t('validation.required'),
    'string.empty': i18n.t('validation.required'),
    'string.email': i18n.t('validation.invalidEmail')
  }),
  phone: Joi.string().required().messages({
    'string.required': i18n.t('validation.required'),
    'string.empty': i18n.t('validation.required'),
    'string.email': i18n.t('validation.invalidEmail')
  }),
  firstname: Joi.string().required().messages({
    'string.required': i18n.t('validation.required'),
    'string.empty': i18n.t('validation.required'),
    'string.email': i18n.t('validation.invalidEmail')
  }),
  lastname: Joi.string().required().messages({
    'string.required': i18n.t('validation.required'),
    'string.empty': i18n.t('validation.required'),
    'string.email': i18n.t('validation.invalidEmail')
  }),
  email: Joi.string().email({ tlds: { allow: false } }).required().messages({
    'string.required': i18n.t('validation.required'),
    'string.empty': i18n.t('validation.required'),
    'string.email': i18n.t('validation.invalidEmail')
  }),
  reason: Joi.string().required().messages({
    'string.required': i18n.t('validation.required'),
    'string.empty': i18n.t('validation.required'),
    'string.email': i18n.t('validation.invalidEmail')
  }),
  comment: Joi.string().required().messages({
    'string.required': i18n.t('validation.required'),
    'string.empty': i18n.t('validation.required'),
    'string.email': i18n.t('validation.invalidEmail')
  }),
  files: Joi.array().min(1).messages({
    'array.required': i18n.t('validation.required'),
    'array.min': i18n.t('validation.required')
  }),
  id_booking: Joi.any(),
  form_id: Joi.any()
})