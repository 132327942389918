import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import firebase from "../shared/firebase/firebase"
import { Feedback } from "../shared/firebase/types"

export default function FeatureVote () {

    const router = useHistory()

    const [features, setFeatures] = useState<Feedback[]>([])
    const [endOfFeed, setEndOfFeed] = useState<boolean>(false)

    useEffect(() => {
        loadData(dayjs().subtract(900, 'days').unix())
    }, [])

    async function loadData (lastSeen: number) {
        const features = await firebase.modules().feedback.paginate(lastSeen, 10)
        const elements = [] as Feedback[]
        if (features.size === 0) {
            setEndOfFeed(true)
            return
        }
        features.forEach(d => {
            elements.push(d.data())
        })
        setFeatures(elements)
    }

    function handleNextPage () {
        if (features && features.length > 0) {
            loadData(features[features.length - 1].date)
        }
    }

    return (
        <div className={'px-4 py-4 flex space-y-8 w-full max-w-4xl mx-auto flex-col'}>
            <div className={'flex space-x-20'}>
                <div className={'flex-1'}>
                    <div className={'inline-block mb-2 text-xs bg-orange-600 px-2 py-1 rounded-md text-white'}>
                        BETA
                    </div>
                    <div className="text-3xl text-orange-600">
                        La tua opinione è importante
                    </div>
                    <div className={'mt-2 font-normal leading-relaxed text-gray-600 text-sm mt-1'}>
                        Siamo fermamente convinti della fondamentale importanza dei nostri clienti. Per questa ragione abbiamo creato la sezione community
                        che permette a ogni cliente di proporre modifiche alle funzionalità del sistema o nuove implementazioni. Le richieste
                        saranno revisionate direttamente dal team di Engineering che potrà interagire tramite i commenti.
                    </div>
                </div>
                <div>
                    <img
                        className={'h-36'}
                        style={{
                            transform: 'scaleX(-1)'
                        }}
                        src="/assets/girltalk.png"
                        alt="Girl Feedback" />
                </div>
            </div>

            <div className={'flex space-x-8 items-center justify-between pb-4'}>
                <div>
                    <button
                        onClick={() => router.push('/app/community-new')}
                        className={'px-4 py-2 bg-orange-500 border shadow-md hover:bg-orange-600 active:bg-orange-700 border-orange-600 text-white rounded-md'}>
                        Fai la tua proposta
                    </button>
                </div>
            </div>

            <div>
                {
                    features.map((feature, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                router.push('/app/community/' + feature.id)
                            }}
                            className={'px-4 cursor-pointer hover:bg-gray-100 flex items-center space-x-8 border-b pt-4 pb-4'}>
                            <div className={'flex space-x-4 text-gray-600'}>
                                <div className="text-sm font-normal text-center">
                                    {feature.votes.length}<br/>
                                    voti
                                </div>
                                <div className="text-sm font-normal text-center">
                                    0<br/>
                                    risposte
                                </div>
                            </div>
                            <div>
                                <div className="text-xl font-normal text-orange-600">
                                    {feature.title}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className={'flex flex-col space-y-4 justify-between items-center pb-6'}>
                {
                    endOfFeed &&
                    <div className={'text-center'}>
                        Non c'è altro da caricare.
                    </div>
                }
                <button
                    className={'bg-orange-100 text-orange-700 w-full px-4 py-3 font-medium rounded-lg'}
                    onClick={handleNextPage}>
                        Carica altro
                </button>
            </div>
        </div>
    )

}