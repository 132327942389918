import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Accomodation, ShortAccomodation} from "../../types";
import {getAccomodations} from "../../shared/queries";

type HotelState = {
    currentHotel: number | string | null,
    currentHotelObject?: ShortAccomodation,
    accommodations: Accomodation[],
    fetchingHotels: boolean
}

export const fetchAccomodations = createAsyncThunk(
    'hotels/getAccomodations',
    async () => {
        return await getAccomodations()
    }
)

const hotelsSlice = createSlice({
    name: 'hotels',
    initialState: {
        currentHotel: null,
        fetchingHotels: false,
        currentHotelObject: undefined,
        accommodations: []
    } as HotelState,
    reducers: {
        setCurrentHotel: (state, action) => {
            state.currentHotel = action.payload
        },
        setCurrentHotelObject: (state, action) => {
            state.currentHotelObject = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchAccomodations.pending, (state) => {
            state.fetchingHotels = true
        })
        builder.addCase(fetchAccomodations.rejected, (state) => {
            state.fetchingHotels = false
        })
        builder.addCase(fetchAccomodations.fulfilled, (state, action: PayloadAction<Accomodation[]>) => {
            state.accommodations = action.payload
            state.fetchingHotels = false
        })
    }
})

export const { setCurrentHotel, setCurrentHotelObject } = hotelsSlice.actions

export default hotelsSlice.reducer