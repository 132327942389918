import { useState } from "react"
import { Fragment } from "react"
import Link from '../../navigation/Link'
import Button from "../../form/Button";
import { Accomodation, Policy, PolicyMap, PolicyRate } from "../../../types";
import Modal from "../../common/Modal";
import ModalCard from "../../common/ModalCard";
import ModalActions from "../../common/ModalActions";
import FormField from "../../form/FormField";
import TextInput from "../../form/TextInput";
import DetailRow from "../../data/DetailRow";
import SelectInput from "../../form/SelectInput";
import { useMutation, useQueryClient } from "react-query";
import { deleteAccomodationPolicyRate, saveAccomodationPolicyRate } from "../../../shared/queries";
import toast from "react-hot-toast";
import { getPolicyType } from "../../../shared/utils";
import DeleteModal from "../../common/DeleteModal";
import Checkbox from "../../form/Checkbox";

export default function HotelConnectedRate ({
    rateMap,
    accomodation,
    value
} : {
    rateMap: PolicyMap,
    accomodation: Accomodation,
    value: string
}) {

    const queryClient = useQueryClient()

    const [deleteRateId, setDeleteRateId] = useState<number>()
    const [associatePolicy, setAssociatePolicy] = useState<boolean>(false)

    const [currentPolicy, setCurrentPolicy] = useState<{ label: string, value: number | string }>()
    const [coverage, setCoverage] = useState<number | string>()
    const [isInternal, setIsInternal] = useState<boolean>(false)

    const associatePolicyMutation = useMutation((data: PolicyRate) => saveAccomodationPolicyRate(data), {
        onSuccess: () => {
            toast.success('Polizza associata')
            setAssociatePolicy(false)
            queryClient.refetchQueries('policyRates')
        }
    })

    const deletePolicyRateMutation = useMutation((id: string | number) => deleteAccomodationPolicyRate(id), {
        onSuccess: () => {
            toast.success('Tariffa eliminata')
            setDeleteRateId(undefined)
            queryClient.refetchQueries('policyRates')
        },
        onError: () => {
            toast.error('Impossibile eliminare la tariffa')
        }
    })

    function getPolicyRate (idPolicy: string | number) : string {
        const policy = accomodation.policies.find(p => p.idPolicy === idPolicy)
        if (policy) {
            return policy.policyName + ' - ' + policy.policyCode + ' - ' + policy.policyCompany + ' (' + getPolicyType(policy) + ')'
        }
        return 'ND'
    }

    return (
        <Fragment>
            <Fragment key={value}>
                <tr className="border-t border-gray-200">
                    <th
                    colSpan={5}
                    scope="colgroup"
                    className="bg-gray-100 py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                        {value}
                    </th>
                </tr>
                {
                    (rateMap[value].length === 0) &&
                    <tr>
                        <td className="bg-orange-100 text-center py-2 border-t" colSpan={4}>
                            <button onClick={() => setAssociatePolicy(true)} className="text-orange-600 flex space-x-2 justify-center items-center w-full">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clipRule="evenodd" />
                                </svg>
                                <div>Aggiungi polizza</div>
                            </button>
                        </td>
                    </tr>
                }
                {
                    rateMap[value].map((rate, index) => (
                        <tr
                            key={index}
                            className={'border-gray-200 border-t'}
                            >
                            <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm text-gray-600 sm:pl-3">
                                {getPolicyRate(rate.idPolicy)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{rate.coveragePercentage}%</td>
                            <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                                {
                                    rate.isInternal ?
                                    <span className="bg-green-600 text-xs font-medium text-white rounded-full px-2 py-1">
                                        SI
                                    </span>
                                    :
                                    <span className="bg-orange-600 text-xs font-medium text-white rounded-full px-2 py-1">
                                        NO
                                    </span>
                                }
                            </td>
                            <td className="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                <div className="flex justify-end space-x-2">
                                    <button onClick={() => setAssociatePolicy(true)} className="text-orange-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clipRule="evenodd" />
                                        </svg>
                                    </button>

                                    <button onClick={() => setDeleteRateId(rate.idPolicyRate)} className="text-red-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        )
                    )
                }
            </Fragment>

            <Modal
                visible={associatePolicy}>
                <ModalCard
                    renderActions={() => (
                        <ModalActions
                            onSave={() => {
                                if (currentPolicy && coverage && value && accomodation && accomodation.idAccomodation) {
                                    associatePolicyMutation.mutate({
                                        isInternal: isInternal,
                                        coveragePercentage: parseFloat(coverage.toString()),
                                        idPolicy: parseInt(currentPolicy.value.toString()),
                                        idAccomodation: accomodation.idAccomodation,
                                        rateCode: value.toString()
                                    })
                                    setAssociatePolicy(false)
                                }
                            }}
                            onClose={() => {
                                setAssociatePolicy(false)
                            }} />
                    )}
                    className={'w-full max-w-md'}
                    title={'Associa polizza'}>
                    <div className="p-4 flex flex-col space-y-4">
                        <FormField label={'Polizza'}>
                            <SelectInput
                                onChange={(item: any) => {
                                    if (item) setCurrentPolicy(item)
                                }}
                                options={accomodation ? accomodation.policies.filter(p => {
                                    if (value && rateMap[value]) {
                                        return rateMap[value].findIndex(cr => cr.idPolicy === p.idPolicy) < 0
                                    }
                                    return false
                                }).map(policy => {
                                    return {
                                        label: policy.policyName + ' - ' + policy.policyCode + ' - ' + policy.policyCompany + ' (' + getPolicyType(policy) + ')',
                                        value: policy.idPolicy
                                    }
                                }) : []} />
                        </FormField>

                        <FormField
                            label={'Percentuale copertura'}>
                            <TextInput
                                value={coverage}
                                type={'numbered'}
                                onChange={val => setCoverage(val)} />
                        </FormField>

                        <Checkbox
                            onChange={() => setIsInternal(!isInternal)}
                            hint={'Mappatura di un prodotto interno (BeSafe Classic, BeSafe Winter)'}
                            checked={isInternal}
                            label={'Mappatura internal'} />
                    </div>
                </ModalCard>
            </Modal>

            <DeleteModal
                askConfirmation={true}
                message={'Sei sicuro di voler eliminare la tariffa dalla polizza? Le prenotazioni non verranno più inviate alla compagnia relativa.'}
                visible={deleteRateId !== undefined}
                onCancel={() => {
                    setDeleteRateId(undefined)
                }}
                onConfirm={() => {
                    if (deleteRateId) {
                        deletePolicyRateMutation.mutate(deleteRateId)
                    }
                }} />
        </Fragment>
    )

}
