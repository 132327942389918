import { useAuth0 } from "@auth0/auth0-react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import Chip from "../components/common/Chip";
import { PageWrapper } from "../components/common/PageWrapper";
import UserAvatar from "../components/common/UserAvatar";
import DetailRow from "../components/data/DetailRow";
import { RootState } from "../store";

export default function ProfilePage () {

    const {
        user
    } = useAuth0()

    const { profile } = useSelector((state: RootState) => state.session)

    return (
        <PageWrapper centered maxWidth={'1000px'}>

            {
                user ?
                <Fragment>
                    <div className="items-center flex flex-col">
                        <UserAvatar
                            text={'text-3xl'}
                            height={'h-20'}
                            width={'w-20'}
                            name={'Wanny'} />
                        
                        <div className={'text-xl text-gray-700 mt-4 font-semibold'}>
                            {
                                user.name
                            }
                        </div>
                        <div className={'text-gray-600'}>
                            {
                                user.email
                            }
                        </div>
                    </div>

                    <div className="max-w-lg mx-auto p-6 mt-8 border rounded-md">
                        <DetailRow
                            label={'Nome completo'}>
                            {user.given_name} {user.family_name}
                        </DetailRow>
                        <DetailRow
                            label={'Email'}>
                            {user.email}
                        </DetailRow>
                        <DetailRow
                            label={'Email verificata'}>
                            {
                                user.email_verified ?
                                <Chip className={'text-green-600 bg-green-100'}>
                                    Si
                                </Chip>
                                :
                                <Chip className={'text-red-600 bg-red-100'}>
                                    No
                                </Chip>
                            }
                        </DetailRow>
                    </div>
                </Fragment>
                :
                <Fragment>
                    ...
                </Fragment>
            }

        </PageWrapper>
    )

}