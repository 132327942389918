import { useEffect, useState } from "react"
import { useAppSelector } from "../../hooks/redux"
import { deleteRoomImage, getRoom, storeRoom, storeRoomImage } from "../../shared/queries"
import { QuoteRoom, Room, RoomImage, Translation } from "../../types"
import { useMutation } from "react-query"
import toast from "react-hot-toast"
import Modal from "../common/Modal"
import ModalCard from "../common/ModalCard"
import ModalActions from "../common/ModalActions"
import FormField from "../form/FormField"
import SelectInput from "../form/SelectInput"
import TextInput from "../form/TextInput"
import Uploader from "../common/Uploader"
import { getTranslatedValue } from "../../shared/utils"
import { useTranslation } from "react-i18next"

export default function AddRoomModal ({ editRoom, visible, onClose } : { editRoom?: number, visible: boolean, onClose: () => void }) {

  const { t } = useTranslation()
  const { currentHotel } = useAppSelector<any>(state => state.hotels) as any

  const [name, setName] = useState('')
  const [translations, setTranslations] = useState<Translation[]>([])
  const [description, setDescription] = useState('')
  const [basePrice, setBasePrice] = useState(0)
  const [imageUrl, setImageUrl] = useState('')
  const [images, setImages] = useState<RoomImage[]>([])
  const [maxAdults, setMaxAdults] = useState(0)
  const [maxChildren, setMaxChildren] = useState(0)

  const [currentFile, setCurrentFile] = useState<any>()
  const [preview, setPreview] = useState<any>()

  const [currentLang, setCurrentLang] = useState<any>({
    label: 'Italian',
    value: 'it'
  })

  const deleteImageMutation = useMutation((id: number) => deleteRoomImage(id), {
    onSuccess: (data) => {
      toast.success('Immagine eliminata')
      setImages(images.filter(i => i.idRoomImage !== data))
    }
  })

  async function loadEditRoom () {
    if (editRoom) {
      const editRoomData = await getRoom(editRoom)
      setName(editRoomData.name)
      if (editRoomData.translations) {
        setTranslations(JSON.parse(editRoomData.translations as any))
      }
      setDescription(editRoomData.description)
      setBasePrice(editRoomData.basePrice)
      setImageUrl(editRoomData.imageUrl)
      setImages(editRoomData.images)
      setMaxAdults(editRoomData.maxAdults)
      setMaxChildren(editRoomData.maxChildren)
    } else {
      setName('')
      setTranslations([])
      setImages([])
      setDescription('')
      setBasePrice(0)
      setImageUrl('')
    }
  }

  useEffect(() => {
    loadEditRoom()
  }, [ editRoom ])

  useEffect(() => {
    if (currentFile) {
      const objectUrl = URL.createObjectURL(currentFile)
      const reader = new FileReader()
      reader.readAsDataURL(currentFile)
      reader.onload = () => {
        setImageUrl(reader.result as any)
      }

      setPreview(objectUrl)

      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [ currentFile ])

  const saveMutation = useMutation(({ data, close } : { data: Room, close: boolean }) => storeRoom(data), {
    onSuccess: (data, variables) => {
      toast.success('Camera salvata')
      if (!variables.close) loadEditRoom()
      if (variables.close) onClose()
    }
  })

  const updateGalleryMutation = useMutation((image: RoomImage) => storeRoomImage({
    ...image,
    idRoom: editRoom
  }), {
    onSuccess: () => {
      toast.success('Gallery salvata')
      loadEditRoom()
    }
  })

  function updateTranslatedProperty (key: string, value: string) {
    const existingTranslationIndex = translations.findIndex(t => t.type === key)
    if (existingTranslationIndex > -1) {
      setTranslations(prevState => prevState.map((t, i) => {
        if (i === existingTranslationIndex) {
          return {
            ...t,
            value
          }
        } else {
          return t
        }
      }))
    } else {
      setTranslations([
        ...translations,
        {
          type: key,
          value,
          lang: currentLang.value
        }
      ])
    }
  }

  function handleUploadImage (data: any) {
    updateGalleryMutation.mutate({
      imageUrl: data.data.uploadURL,
      description: ''
    })
  }

  return (
    <Modal visible={visible}>
      <ModalCard
        renderActions={() => (
          <ModalActions
            isLoading={saveMutation.isLoading}
            onSave={() => saveMutation.mutate({
              data: {
                basePrice,
                description,
                translations,
                name,
                images: [],
                idRoom: editRoom,
                imageUrl: imageUrl,
                maxAdults,
                maxChildren,
                idAccomodation: currentHotel
              },
              close: true
            })}
            onClose={onClose}
          />
        )}
        className={'w-full max-w-2xl'} title={editRoom ? `Modifica ${name}` : 'Crea nuova tipologia'}>
        <div className="p-4 flex space-x-8">
          <div className="w-1/2 flex-col space-y-4">
            <div className="mb-4 flex space-x-2">
              <FormField className={'w-full'} label={t('quote.language')}>
                <SelectInput
                  className={'w-full'}
                  value={currentLang}
                  onChange={(val: any) => {
                    if (val) setCurrentLang(val)
                  }}
                  options={[
                    { label: 'Italiano', value: 'it' },
                    { label: 'English', value: 'en' }
                  ]}
                />
              </FormField>
            </div>

            <FormField label={t('quote.name')}>
              <TextInput value={currentLang.value === 'it' ? name : getTranslatedValue(translations, 'name')} onChange={val => {
                if (currentLang.value === 'it') {
                  setName(val)
                } else {
                  updateTranslatedProperty('name', val)
                }
              }} />
            </FormField>

            <FormField label={t('quote.description')} optional={true}>
              <TextInput value={currentLang.value === 'it' ? description : getTranslatedValue(translations, 'description')} onChange={val => {
                if (currentLang.value === 'it') {
                  setDescription(val)
                } else {
                  updateTranslatedProperty('description', val)
                }
              }} type={"textarea"} />
            </FormField>

            <FormField label={t('quote.basePrice')} optional={true}>
              <TextInput value={basePrice} onChange={val => setBasePrice(val)} type={"currency"}></TextInput>
            </FormField>

            <div className="flex flex-col space-y-2">
              <FormField label={t('quote.maxAdults')} optional={true}>
                <TextInput value={maxAdults} onChange={val => setMaxAdults(val)} type={"number"} />
              </FormField>

              <FormField label={t('quote.maxChildren')}>
                <TextInput value={maxChildren} onChange={val => setMaxChildren(val)} type={"number"} />
              </FormField>
            </div>
          </div>
          <div className="w-1/2">
            <div>
              {}
            </div>
            <FormField label={t('quote.image') + ' (jpg, jpeg, png) max 2mb'} required={true}>
              <Uploader
                fileType={'image'}
                type={'dashboard'}
                folderKey={'quote-assets'}
                fileKey={'image'}
                onUpload={(data: any) => handleUploadImage(data)}
              />
            </FormField>

            <div className="flex space-x-2 mt-4">
              {
                images.map((image, imageIndex) => (
                  <div
                    className="relative w-14 h-14 rounded-md bg-cover bg-center"
                    key={`roomImage-${imageIndex}`}
                    style={{
                      backgroundImage: `url(${image.imageUrl})`
                    }}
                  >
                    <button
                      onClick={() => {
                        if (!image.idRoomImage) return
                        if (window.confirm(t('quote.confirmDeleteImage'))) {
                          deleteImageMutation.mutate(image.idRoomImage)
                        }
                      }}
                      className="bg-red-600 hover:bg-red-700 hover:shadow-xl w-8 h-8 rounded-full absolute left-0 right-0 mx-auto flex items-center justify-center text-white top-8"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                        <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>  
                ))
              }
            </div>
          </div>
        </div>
      </ModalCard>
    </Modal>
  )
}