import { useHistory } from "react-router-dom";
import Icon from "../../components/common/Icon";
import Button from "../../components/form/Button";

export default function ManageGuestPage () {

    const history = useHistory()
    
    return (
        <div>
            
            <div
                style={{
                    boxShadow: '0px 2px 3px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1)'
                }}
                className="rounded-lg">
                
                <div className="text-gray-600 p-4 text-lg flex items-center space-x-4">
                    <div>
                        <button
                            onClick={() => history.push('/guest-area')}>
                            <Icon name={'arrow-narrow-left'} size={'20px'} />
                        </button>
                    </div>
                    <div>
                        Ospiti della prenotazione
                    </div>
                </div>

                <div className={'px-4 border-b py-4 flex items-center justify-between text-gray-500'}>
                    Wanny Miarelli, Italia
                </div>
                <div className={'px-4 border-b py-4 flex items-center justify-between text-gray-500'}>
                    Wanny Miarelli, Italia
                </div>
                <div className={'px-4 border-b py-4 flex items-center justify-between text-gray-500'}>
                    Wanny Miarelli, Italia
                </div>


                <div className="p-4">
                    <Button
                        className={'text-sm rounded-sm font-semibold text-white bg-orange-600'}
                        onClick={() => {}}
                        label={'Aggiungi ospite'} />
                </div>

            </div>

        </div>
    )

}