import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { useSelector } from "react-redux"
import { getAccomodationPolicyRate, getAccomodationPolicyRatesInternal, getAccomodationTags, getPolicyPricing, saveBooking } from "../../../shared/queries"
import { RootState } from "../../../store"
import { Booking, BookingGuest, Country } from "../../../types"
import Modal from "../../common/Modal"
import ModalActions from "../../common/ModalActions"
import ModalCard from "../../common/ModalCard"
import DatePickerInput from "../../form/DatePickerInput"
import FormField from "../../form/FormField"
import TextInput from "../../form/TextInput"
import SelectInput from "../../form/SelectInput"
import toast from "react-hot-toast"
import InsuranceProductCard from "./InsuranceProductCard"
import CountrySelector from "../selectors/CountrySelector"
import { formatCurrency, getSelectedInternalRate, needsTotalValues } from "../../../shared/utils"
import bookingAdvSchema from "../../../validation/bookingAdvSchema"
import bookingAdvSchemaWithFiscal from "../../../validation/bookingAdvSchemaWithFiscal"
import BookingPolicyJob from "../BookingPolicyJob"

const GuestRow = ({ guest, errors, onChange, counter, onDelete, fiscalCodeRequired } : { counter: number, guest: BookingGuest, errors: any, onChange: (guest: BookingGuest, counter: number) => void, key: number, onDelete: () => void, fiscalCodeRequired: boolean }) => {

  const { t } = useTranslation()

  return (
    <div className="flex space-x-4 items-center border-b pb-6 pt-4">
      <div className="flex-1 space-y-2">
        <div className="flex space-x-2">
          <FormField
            errors={errors}
            errorKey={'guestFirstName'}
            className={'w-1/3'}
            label={t('createGuest.name')}>
              <TextInput
                value={guest.guestFirstName}
                onChange={val => onChange({
                  ...guest,
                  guestFirstName: val
                }, counter)}
              />
          </FormField>

          <FormField
            errors={errors}
            errorKey={'guestLastName'}
            className={'w-1/3'}
            label={t('createGuest.surname')}>
              <TextInput
                value={guest.guestLastName}
                onChange={val => onChange({
                  ...guest,
                  guestLastName: val
                }, counter)}
              />
          </FormField>

          <FormField
            className="flex-1"
            label={t('createGuest.country')}
            errors={errors}
            errorKey={'idCountry'}>
              <CountrySelector
                value={guest.country}
                onSelect={c => {
                  onChange({
                    ...guest,
                    country: c
                  }, counter)
                }} />
          </FormField>
        </div>

        <div className="flex space-x-2 items-center">
          {
            fiscalCodeRequired &&
            <FormField
              errors={errors}
              errorKey={'guestFiscalCode'}
              className={'flex-1'}
              label={t('createGuest.fiscalCodePassport')}>
                <TextInput
                  value={guest.guestFiscalCode}
                  onChange={val => onChange({
                    ...guest,
                    guestFiscalCode: val
                  }, counter)}
                />
            </FormField>
          }

          <FormField
            className={'flex-1'}
            label={'Email'}
            errors={errors}
            errorKey={'guestEmail'}>
              <TextInput
                value={guest.guestEmail}
                onChange={val => onChange({
                  ...guest,
                  guestEmail: val
                }, counter)}
              />
          </FormField>
        </div>
      </div>

      <div className={counter === 0 ? 'pt-8' : 'pt-4'}>
        <button onClick={onDelete} className="text-red-600">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  )

}

export default function CreateBookingAdv ({
    visible,
    onClose,
    onSave
} : {
    visible: boolean,
    onClose: () => void,
    onSave: (data: Booking) => void
}) {

    const { t } = useTranslation()

    const currentHotel = useSelector<any>((state: RootState) => state.hotels.currentHotel)
    const currentHotelObject = useSelector((state: RootState) => state.hotels.currentHotelObject)
    const { profile } = useSelector((state: RootState) => state.session)

    const [errors, setErrors] = useState<any>([])

    const [bookingArrivalDate, setBookingArrivalDate] = useState<Date>(new Date())
    const [bookingDepartureDate, setBookingDepartureDate] = useState<Date>(dayjs().add(1, 'day').toDate())
    const [bookingCode, setBookingCode] = useState<string>('')
    const [bookingDestination, setBookingDestination] = useState<Country>({
      idCountry: 1,
      countryAlpha2: 'ND',
      countryName: 'Unknown',
      isActive: true
    })
    const [bookingRate, setBookingRate] = useState<string>('MANUAL')
    const [tag, setTag] = useState<any>(undefined)
    const [bookingTotal, setBookingTotal] = useState<number>(0)
    const [totalCashed, setTotalCashed] = useState<number>(0)
    const [policyCost, setPolicyCost] = useState<number>(0)
    const [policyTaxableCost, setPolicyTaxableCost] = useState<number>(0)
    const [policyUnTaxableCost, setPolicyUnTaxableCost] = useState<number>(0)
    const [unsubmittablePolicy, setUnsubmittablePolicy] = useState(false)
    const [dateCashed, setDateCashed] = useState<Date>(new Date())
    const [bookingDate, setBookingDate] = useState<Date>(new Date())

    const [guests, setGuests] = useState<BookingGuest[]>([{
      guestFirstName: '',
      guestLastName: '',
      guestEmail: '',
      country: {
        idCountry: 1,
        countryAlpha2: 'ND',
        countryName: 'Unknown',
        isActive: true
      },
      idBooking: undefined,
      guestFiscalCode: '',
      isBooker: false
    }])

    const saveMutation = useMutation(() => saveBooking({
        idAccomodation: currentHotel as any,
        bookingArrivalDate: parseInt(dayjs(bookingArrivalDate).format('YYYYMMDD')),
        bookingDepartureDate: parseInt(dayjs(bookingDepartureDate).format('YYYYMMDD')),
        bookingCode,
        accomodationTagId: tag,
        bookingDate: parseInt(dayjs(bookingDate).format('YYYYMMDD')),
        bookingStatus: 'Reserved',
        idCountry: bookingDestination.idCountry,
        bookingTotal,
        totalCashed,
        dateCashed: parseInt(dayjs(bookingDate).format('YYYYMMDDHHmm')),
        payments: [],
        guests: guests,
        pax: guests.length,
        bookingRate: bookingRate || 'MANUAL',
        isDeleted: false,
        confirmationPrice: ((policyTaxableCost * 1.22) + policyUnTaxableCost)
    }), {
        onSuccess: data => {
            onSave(data)
        },
        onError: (error: any) => {
            toast.error(error.message)
        }
    })

    async function handleSubmit () {
        const { error, value } = (bookingRate === 'WORLD_SANITARY' || bookingRate === 'WORLD_CANC_SANITARY') ? bookingAdvSchemaWithFiscal.validate({
            bookingArrivalDate: parseInt(dayjs(bookingArrivalDate).format('YYYYMMDD')),
            bookingDepartureDate: parseInt(dayjs(bookingDepartureDate).format('YYYYMMDD')),
            bookingCode,
            bookingRate,
            bookingDate: parseInt(dayjs(bookingDate).format('YYYYMMDD')),
            bookingTotal,
            totalCashed,
            idCountry: bookingDestination.idCountry,
            pax: guests.length,
            guests
        }) : bookingAdvSchema.validate({
          bookingArrivalDate: parseInt(dayjs(bookingArrivalDate).format('YYYYMMDD')),
          bookingDepartureDate: parseInt(dayjs(bookingDepartureDate).format('YYYYMMDD')),
          bookingCode,
          bookingRate,
          bookingDate: parseInt(dayjs(bookingDate).format('YYYYMMDD')),
          bookingTotal,
          totalCashed,
          idCountry: bookingDestination.idCountry,
          pax: guests.length,
          guests
      })
      console.log(error)
      if (error || unsubmittablePolicy) {
          setErrors(error)
          toast.error(t('general.missingFields'))
        } else {
            setErrors(null)
            saveMutation.mutate(value)
        }
    }

    const products = useMemo(() => {
      return [
        {
          image: '/assets/guestportal/refund.svg',
          offImage: '/assets/guestportal/refund_gray.svg',
          key: 'MANUAL',
          label: 'BeSafe',
          included: [
            t('vary.cardRefund')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/guestportal/refund.svg',
          offImage: '/assets/guestportal/refund_gray.svg',
          key: 'MANUAL_PLUS',
          label: 'BeSafe Plus',
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/guestportal/refund.svg',
          offImage: '/assets/guestportal/refund_gray.svg',
          key: 'CANCELLATION',
          label: t('vary.besafeOnlyCancellation'),
          included: [
            t('vary.cardRefund')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/guestportal/outdoor.svg',
          offImage: '/assets/guestportal/outdoor_gray.svg',
          key: 'WINTER',
          label: 'BeSafe Winter',
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage'),
            t('vary.rcSki')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/guestportal/summer.svg',
          offImage: '/assets/guestportal/summer_gray.svg',
          key: 'SUMMER',
          label: 'BeSafe Summer',
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage'),
            t('vary.rcOutdoor')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/sanitary.svg',
          offImage: '/assets/sanitary_gray.svg',
          key: 'SANITARY',
          label: 'BeSafe Flex',
          included: [
            t('vary.med'),
            t('vary.baggage'),
            t('vary.untilCheckIn'),
            t('vary.onCancellation')
          ],
          includedPlus: []
        },
        {
          image: '/assets/guestportal/business.svg',
          offImage: '/assets/guestportal/business_gray.svg',
          key: 'BUSINESS',
          label: 'BeSafe Business',
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage'),
            t('vary.rcOutdoor')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/guestportal/refund.svg',
          offImage: '/assets/guestportal/refund_gray.svg',
          key: 'MANUAL_PLUS_INTERRUPTION',
          label: t('vary.besafeInterruption'),
          included: [
            t('vary.cardRefund'),
            t('vary.med'),
            t('vary.baggage'),
            t('classic.interruption')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/sanitary.svg',
          offImage: '/assets/sanitary_gray.svg',
          key: 'WORLD_SANITARY',
          label: t('vary.worldSanitary1kk'),
          included: [
            t('vary.med'),
            t('vary.baggage'),
            t('vary.OnemlnMed'),
            t('vary.allWorld')
          ]
        },
        {
          image: '/assets/world_san_canc.svg',
          offImage: '/assets/world_san_canc_gray.svg',
          key: 'WORLD_CANC_SANITARY',
          label: t('vary.worldSanitary1kkcancellation'),
          included: [
            t('vary.med'),
            t('vary.baggage'),
            t('vary.OnemlnMed'),
            t('vary.allWorld')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/sanitary.svg',
          offImage: '/assets/sanitary_gray.svg',
          key: 'WORLD_FIXED_SANITARY',
          label: t('vary.worldSanitary1kk'),
          included: [
            t('vary.med'),
            t('vary.baggage'),
            t('vary.OnemlnMed'),
            t('vary.allWorld')
          ]
        },
        {
          image: '/assets/world_san_canc.svg',
          offImage: '/assets/world_san_canc_gray.svg',
          key: 'WORLD_FIXED_CANC_SANITARY',
          label: t('vary.worldSanitary1kkcancellation'),
          included: [
            t('vary.med'),
            t('vary.baggage'),
            t('vary.OnemlnMed'),
            t('vary.allWorld')
          ],
          includedPlus: [
            t('vary.refund7Days'),
            t('vary.anyReasonRefund')
          ]
        },
        {
          image: '/assets/guestportal/rain.svg',
          offImage: '/assets/guestportal/rain.svg',
          key: 'BESAFE_RAIN',
          label: t('vary.besafeRain'),
          included: [
            t('vary.rainRefund'),
            t('vary.parametricPolicy'),
          ],
          includedPlus: [
            t('vary.automaticRefund'),
            t('vary.noClaim'),
          ]
        }
      ]
    }, [])

    const {
      data: internalRates
    } = useQuery(['internalRates', currentHotel], () => getAccomodationPolicyRatesInternal(currentHotel), {
      enabled: (currentHotel !== undefined && currentHotel !== null)
    })

    const {
      data: tags
    } = useQuery(['tags', currentHotel], () => getAccomodationTags(currentHotel))

    const {
      data: accomodationRate,
    } = useQuery(['accomodationRates', currentHotel], () => getAccomodationPolicyRate(currentHotel as any), {
      enabled: (currentHotel !== undefined && currentHotel !== null),
      retry: false
    })

    useEffect(() => {
      if (!internalRates) return
      const availableRates = internalRates.map(r => r.rateCode)
      if (availableRates.length > 0) {
        setBookingRate(availableRates[0])
      }
    }, [ internalRates ])

    function getMaxCashedDate () {
      // if admin no limit
      if (profile && profile.userAdmin) return undefined
      let permittedDays = 3
      if (currentHotelObject && currentHotelObject.customerType === 'AGENCY') {
        // if agency limit to 1 day
        permittedDays = 3
      } else {
        // for all the rest, limit to 30 days
        permittedDays = 30
      }
      return dayjs().subtract(permittedDays, 'day').toDate()
    }

    useEffect(() => {
      async function recalculate () {
        const cost = await getCurrentAccomodationRate()
        setPolicyCost(cost)
      }
      recalculate()
    }, [
      bookingArrivalDate,
      bookingDepartureDate,
      bookingDate,
      guests.length,
      bookingDestination,
      totalCashed,
      bookingRate
    ])

    async function getCurrentAccomodationRate () {
      setUnsubmittablePolicy(false)
      let untaxableCost = 0 as number
      let taxableCost = 0 as number

      const nights = dayjs(bookingDepartureDate).diff(dayjs(bookingArrivalDate), 'days')      

      if (internalRates && accomodationRate) {
        let foundRate = getSelectedInternalRate(bookingRate, accomodationRate)
        let internalRate = internalRates.find(r => r.rateCode === bookingRate)
        
        if (foundRate && internalRate) {
          if (bookingRate === 'WORLD_SANITARY' || bookingRate === 'WORLD_CANC_SANITARY') {
            const data = await getPolicyPricing({
              arrival: dayjs(bookingArrivalDate).format('YYYY-MM-DD'),
              departure: dayjs(bookingDepartureDate).format('YYYY-MM-DD'),
              bookingDate: dayjs(bookingDate).format('YYYY-MM-DD'),
              pax: guests.length,
              destinationCountry: bookingDestination.countryAlpha2,
              region: bookingDestination.countryAlpha2 === 'IT' ? 'Basilicata' : '-',
              travelCost: totalCashed,
              currency: currentHotelObject?.currency || 'EUR'
            })
            if (!data || !data.plans) {
              setUnsubmittablePolicy(false)
            } else {
              const foundPricing = data.plans.find(p => p.insId === 'ITB-69-I24-27A1_1')
              if (foundPricing) {
                untaxableCost = foundPricing.price || 0
                setPolicyUnTaxableCost(untaxableCost)
                setUnsubmittablePolicy(false)
              } else {
                setUnsubmittablePolicy(true)
              }
            }
          } else {
            if (foundRate.fixedCost) {
              let fixedCost = foundRate.insuranceFixedRate + foundRate.internalFixedRate
              if (foundRate.perPerson) taxableCost += fixedCost * guests.length
              if (foundRate.perNight) taxableCost += fixedCost * nights
            }
          }

          const variableRate = (foundRate.insuranceRate + foundRate.hotelRate + foundRate.internalRate + foundRate.agentRate)
          taxableCost += (totalCashed * variableRate / 100)
          setPolicyTaxableCost(taxableCost)
        }

        return taxableCost + untaxableCost
      }

      return 0
    }

    return (
        <Modal
            visible={visible}>
            <ModalCard
                className={`overflow-hidden w-full max-w-4xl`}
                actionsLoading={saveMutation.isLoading}
                renderActions={() => (
                    <ModalActions
                      isLoading={saveMutation.isLoading}
                      saveLabel={t('general.confirm')}
                      closeLabel={t('general.cancel')}
                      onClose={onClose}
                      onSave={() => handleSubmit()}
                    />
                )}>

                <div className="border-b overflow-hidden rounded-t-lg bg-gray-100 flex">
                    <button className={`transition-all text-lg p-4 py-5 w-full text-left border-b-4 border-orange-600 bg-white text-gray-700 font-medium`}>
                        {t('adv.newPolicy')}
                        <div className={`text-gray-700 font-normal text-sm`}>
                            {t('adv.newPolicySubtitle')}
                        </div>
                    </button>
                </div>

                <div className="mt-4 px-4 pb-4 flex flex-col space-y-4">
                  <div className="text-lg text-gray-600">
                    {t('adv.productType')}
                  </div>

                  {
                    (!internalRates || internalRates?.length) === 0 &&
                    <div>
                      <div className="text-semibold text-slate-600">Nessun prodotto configurato. Contatta il nostro team di onboarding per risolvere il problema in pochi minuti!</div>
                    </div>
                  }

                  <div className="grid gap-2 grid-cols-2">
                    {
                      products.filter(p => internalRates?.map(ir => ir.rateCode).includes(p.key)).map((p, i) => <InsuranceProductCard
                        product={p}
                        internalRates={internalRates || []}
                        onClick={rate => setBookingRate(rate)}
                        currentSelection={bookingRate}
                        key={'card-' + i}
                      />
                      )
                    }
                  </div>

                  {
                    (tags && tags.length > 0) &&
                    <div>
                      <div className="flex-1 z-50 relative">
                        <FormField
                          label={t('Tag')}>
                            <SelectInput
                              onChange={(item: any) => {
                                if (item) {
                                  setTag(item.value)
                                }
                              }}
                              options={tags ? tags.map(tag => {
                                return {
                                  label: tag.description,
                                  value: tag.idAccomodationTag
                                }
                              }) : []} />
                        </FormField>
                      </div>
                    </div>
                  }

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                        <FormField
                          errors={errors}
                          errorKey={'bookingDate'}
                          className={'flex-1'}
                          label={t('adv.packDate')}>
                              <DatePickerInput
                                  mode={'single'}
                                  maxDate={dayjs().toDate()}
                                  value={bookingDate}
                                  onChange={(val) => {
                                      if (val) {
                                          setBookingDate(val)
                                      }
                                  }} />
                        </FormField>
                      </div>
                      <div></div>
                  </div>
                  <div className="flex space-x-4">
                      <div className={'flex-1'}>
                          <FormField
                            errors={errors}
                            errorKey={'bookingArrivalDate'}
                            label={t('adv.startService')}>
                              <DatePickerInput
                                mode={'single'}
                                fromDate={(profile && profile.userAdmin) ? undefined : new Date()}
                                value={bookingArrivalDate}
                                onChange={(val) => {
                                    if (val) {
                                        setBookingArrivalDate(val)
                                        setBookingDepartureDate(
                                          dayjs(val).add(1, 'days').toDate()
                                        )
                                    }
                                }} />
                          </FormField>
                      </div>

                      <div className={'flex-1'}>
                          <FormField
                            errors={errors}
                            errorKey={'bookingDepartureDate'}
                            label={t('adv.endService')}>
                              <DatePickerInput
                                fromDate={dayjs(bookingArrivalDate).add(1, 'day').toDate()}
                                mode={'single'}
                                value={bookingDepartureDate}
                                onChange={(val) => {
                                    if (val) {
                                        setBookingDepartureDate(val)
                                    }
                                }} />
                          </FormField>
                      </div>
                  </div>

                  <div className="flex space-x-4">
                    <FormField
                        errors={errors}
                        errorKey={'bookingCode'}
                        className={'flex-1'}
                        label={t('adv.packCode')}>
                        <TextInput
                            value={bookingCode}
                            onChange={val => setBookingCode(val)} />
                        <div className={'text-sm leading-relaxed font-normal w-full mt-2 text-gray-600'}>
                            {t('reservation.autoCode')}
                        </div>
                    </FormField>

                    <FormField
                      label={t('adv.destination')}
                      className={'flex-1'}
                    >
                      <CountrySelector
                        value={bookingDestination}
                        onSelect={c => {
                          setBookingDestination(c)
                        }} />
                    </FormField>
                  </div>

                  {
                    needsTotalValues(bookingRate, accomodationRate) &&
                    <div className="flex space-x-4">
                      <FormField
                        errors={errors}
                        errorKey={'totalCashed'}
                        className={'flex-1'}
                        label={t('adv.totalCashed')}
                        tooltip={t('adv.totalBookingTip')}>
                          <TextInput
                            suffix={(currentHotelObject) ? currentHotelObject.currency : 'EUR'}
                            numbered={true}
                            value={bookingTotal}
                            onChange={val => {
                                setBookingTotal(val)
                            }} />
                      </FormField>

                      <FormField
                        errors={errors}
                        errorKey={'totalCashed'}
                        className={'flex-1'}
                        label={t('adv.totalInsured')}
                        tooltip={t('adv.totalInsuredTip')}>
                          <TextInput
                            suffix={(currentHotelObject) ? currentHotelObject.currency : 'EUR'}
                            numbered={true}
                            value={totalCashed}
                            onChange={val => {
                                setTotalCashed(val)
                            }} />
                      </FormField>
                    </div>
                  }

                  <div>
                    <div className="text-lg pt-4 text-gray-600">
                        {t('adv.guests')}
                    </div>

                    <div className="pt-1 text-gray-600">
                      {t('adv.addAllInsured')}
                    </div>
                  </div>

                  <div className="flex flex-col space-y-1">
                    {
                      guests.map((g, gI) => <GuestRow
                          errors={errors}
                          guest={g}
                          key={gI}
                          counter={gI}
                          fiscalCodeRequired={(bookingRate === 'WORLD_SANITARY' || bookingRate === 'WORLD_CANC_SANITARY' || bookingRate === 'WORLD_FIXED_CANC_SANITARY' || bookingRate === 'WORLD_FIXED_SANITARY')}
                          onDelete={() => {
                            if (guests.length === 1) return
                            setGuests(guests.filter((_, deleteGuestIndex) => {
                              if (deleteGuestIndex !== gI) return true
                              return false
                            }))
                          }}
                          onChange={(guest, updateIndex) => {
                            setGuests(guests.map((currentGuest, currentGuestIndex) => {
                              if (updateIndex == currentGuestIndex) {
                                return guest
                              }
                              return currentGuest
                            }))
                          }}
                        />
                      )
                    }
                  </div>

                  <div className="mt-4">
                    <button className="text-orange-700 font-medium bg-orange-100 px-2 py-1 rounded-md" onClick={() => {
                      setGuests([
                        ...guests,
                        {
                          guestFirstName: '',
                          guestLastName: '',
                          guestEmail: '',
                          country: {
                            idCountry: 1,
                            countryAlpha2: 'ND',
                            countryName: 'Unknown',
                            isActive: true
                          },
                          idBooking: undefined,
                          guestFiscalCode: '',
                          isBooker: false
                        }
                      ])
                    }}>{t('adv.addGuest')}</button>
                  </div>

                  {
                    unsubmittablePolicy &&
                    <div className="bg-red-100 rounded-md p-4">
                      <div className="font-bold text-red-800">Polizza non valida</div>
                      <div className="text-red-800">
                      I parametri inseriti non consentono l'emissione della polizza. Verifica di aver compilato tutti i campi correttamente. Ti ricordiamo che le polizze hanno limiti di durata, destinazione e importo. Per ulteriori dettagli consulta i fascicoli assicurativi.
                      </div>
                    </div>
                  }

                  <div className="pt-4 flex flex-col justify-center items-center">
                    <div className="text-center mb-1 text-xl font-medium text-slate-600 mt-2">
                      {t('adv.policyCost')}
                    </div>
                    <div className="text-center text-gray-500">
                      {t('adv.policyCostSubtitle')}
                    </div>
                  </div>
                  <div className="mx-auto rounded-md overflow-hidden max-w-xl flex w-full">
                    <div className="flex-1 bg-gray-50 p-4 flex justify-center flex-col">
                      <div className="mb-1 font-semibold text-orange-600">{t('vary.vatSubject')}</div>
                      <div className="text-xl text-orange-600">{formatCurrency(policyTaxableCost)}</div>
                    </div>
                    <div className="flex-1 bg-gray-100 p-4 flex justify-center flex-col">
                      <div className="mb-1 font-semibold text-orange-700">{t('vary.vatExempt')}</div>
                      <div className="text-xl text-orange-700">{formatCurrency(policyUnTaxableCost)}</div>
                    </div>
                    <div className="flex-1 bg-orange-600 border-l p-4 pl-8 flex justify-center flex-col relative">
                      <div className="font-bold text-lg text-gray-400 -left-4 shadow w-8 h-8 flex items-center justify-center rounded-full bg-white absolute">=</div>
                      <div className="mb-1 font-semibold text-white">{t('vary.totalCost')}</div>
                      <div className="text-xl text-white font-bold">{formatCurrency(policyCost)}</div>
                      <div className="text-sm text-gray-100">{formatCurrency((policyTaxableCost * 1.22) + policyUnTaxableCost)} {t('vary.taxesIncluded')}</div>
                    </div>
                  </div>
                </div>
            </ModalCard>
        </Modal>
    )

}
