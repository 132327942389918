import { ReactNode } from 'react'
import Text from './Text'
import GlobalSearch from "../GlobalSearch";

export default function PageTitle ({
  title,
  renderActions,
  showSearch = true
} : {
  showSearch?: boolean
  title: string,
  renderActions?: () => ReactNode
}) {

    return (
        <div className={'mb-6 flex flex-wrap md:flex-no-wrap justify-between items-center'}>

            <div className={'flex items-center flex-1 justify-between space-x-2'}>
                <Text size={'2xl'} weight={'semibold'}>
                    {title}
                </Text>
            </div>

            <div className={'w-auto mt-4 md:mt-0 flex space-x-2 items-center'}>
              {renderActions && renderActions()}
            </div>

        </div>
    )

}
