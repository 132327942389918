import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getAddresses } from "../../../shared/queries";
import { Address } from "../../../types";
import SelectInput from "../../form/SelectInput";
import Link from "../../navigation/Link";
import CreateAddress from "../address/CreateAddress";

export default function AddressSelector ({
    onSelect
} : {
    onSelect: (item: Address) => void
}) {

    const { t } = useTranslation()

    const [value, setValue] = useState<any>()
    const [createAddress, setCreateAddress] = useState<boolean>(false)

    const {
        data,
        isFetching,
        refetch
    } = useQuery('addresses', getAddresses)

    return (
        <Fragment>
            <SelectInput
                value={value}
                options={data ? data.map(a => {
                    return {
                        label: a.addressStreet,
                        value: a.idAddress
                    }
                }) : []}
                onChange={(item: any) => {
                    if (data) {
                        const address = data.find(a => a.idAddress === item.value)
                        if (address) {
                            onSelect(address)
                            setValue(item)
                        }
                    }
                }}
                isLoading={isFetching} />
            <div className={'mt-2'}>
                <Link
                    className={'text-sm'}
                    onClick={() => setCreateAddress(true)}>
                    {t('address.addressNew')}
                </Link>
            </div>

            <CreateAddress
                visible={createAddress}
                onSave={address => {
                    refetch().then(() => {
                        setValue({
                            label: address.addressStreet,
                            value: address.idAddress
                        })
                        onSelect(address)
                    })
                    setCreateAddress(false)
                }}
                onClose={() => {
                    setCreateAddress(false)
                }} />
        </Fragment>
    )

}