import { useHistory, useLocation } from "react-router-dom";
import Icon from "../common/Icon";
import Logo from "../common/Logo";
import HotelSelector from "./HotelSelector";
import { NavButton } from "./NavHeader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../common/LanguageSelector";

export default function MobileMenu ({ visible, onNavigate } : { visible: boolean, onNavigate: () => void }) {

  const { t } = useTranslation()

  const currentHotelObject = useSelector<any>(state => state.hotels.currentHotelObject) as any

  const history = useHistory()
  const location = useLocation()

  if (!visible) return <></>

  return (
    <div className="w-screen h-screen fixed top-0 left-0 flex justify-end" style={{ zIndex: 999999, backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="bg-white h-full" style={{ width: '90%' }}>

        <div className="p-4">
          <Logo />
        </div>
        
        <div className="p-4 border-b pb-6">
          <div className="items-center flex w-52 overflow-hidden rounded-md customshadow w-full">
            <div className={'text-gray-600 px-2'}>
              <Icon name={'office-building'} size={'20px'} />
            </div>
            <div className="flex-1">
              <HotelSelector />
            </div>
          </div>
        </div>

        <div>
          <nav className="px-1 py-4 flex flex-col space-y-4 navmenutour">
            <NavButton
              label={'Suite'}
              icon={'view-grid'}
              active={location && location.pathname === '/app/suite'}
              onClick={() => history.push('/app/suite')} />
            <NavButton
              label={'Dashboard'}
              icon={'home'}
              active={location && location.pathname === '/app/dashboard'}
              onClick={() => history.push('/app/dashboard')} />
            <NavButton
              label={t('sidebar.reservations')}
              icon={'book-open'}
              active={location && location.pathname === '/app/reservations'}
              onClick={() => history.push('/app/reservations')} />
            {
              false &&
              <NavButton
                label={'Community'}
                icon={'chat'}
                active={location && location.pathname === '/app/community'}
                onClick={() => history.push('/app/community')} />
            }
            {
              (currentHotelObject && currentHotelObject.opportunity) &&
              <NavButton
                label={t('Opportunità')}
                news={true}
                icon={'money'}
                active={location && location.pathname === '/app/opportunities'}
                onClick={() => history.push('/app/opportunities')} />
            }
            <div className="invoicestour">
              <NavButton
                label={t('sidebar.invoices')}
                icon={'document-text'}
                active={location && location.pathname === '/app/invoices'}
                onClick={() => history.push('/app/invoices')} />
            </div>
            <NavButton
              label={'Settings'}
              icon={'cog'}
              active={location && location.pathname === '/app/settings'}
              onClick={() => history.push('/app/settings')} />
          </nav>
        </div>

        <div className="px-4 border-t pt-2 mt-2">
          <LanguageSelector />
        </div>

      </div>
    </div>
  )

}