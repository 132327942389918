import { useState } from "react"
import { Room } from "../../types"
import Modal from "../common/Modal"
import ModalActions from "../common/ModalActions"
import ModalCard from "../common/ModalCard"
import AddRoomModal from "./AddRoomModal"
import RoomList from "./RoomList"
import { useAppSelector } from "../../hooks/redux"

export default function RoomListModal ({
  visible,
  onUpdate,
  onClose,
  onSelect
} : {
  visible: boolean,
  onUpdate: () => void,
  onClose: () => void,
  onSelect: (id: number) => void
}) {

  const [addRoom, setAddRoom] = useState(false)
  const [editRoom, setEditRoom] = useState<number | undefined>()

  return (
    <>
      <AddRoomModal
        visible={addRoom}
        editRoom={editRoom}
        onClose={() => {
          setAddRoom(false)
          setEditRoom(undefined)
          onUpdate()
        }}
      />

      <Modal visible={visible}>
        <ModalCard
          renderActions={() => (
            <ModalActions
              isLoading={false}
              onSave={() => {}}
              onClose={onClose}
            />
          )}
          className={'w-full max-w-2xl'} title={'Elenco camere'}>

            <RoomList onSelect={onSelect} onUpdate={onUpdate} />
          
        </ModalCard>
      </Modal>
    </>
  )

}