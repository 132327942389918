import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import {getAccomodationPolicyRates, getAccomodationRate, saveAccomodationRate} from "../../../shared/queries";
import { Accomodation } from "../../../types";
import Modal from "../../common/Modal";
import ModalActions from "../../common/ModalActions";
import ModalCard from "../../common/ModalCard";
import FormField from "../../form/FormField";
import TextInput from "../../form/TextInput";
import SelectInput from "../../form/SelectInput";
import Checkbox from "../../form/Checkbox";

export default function CreateCommissionPlan ({
    accomodation,
    accomodationRateId,
    visible,
    onSave,
    onClose
} : {
    accomodationRateId?: string | number | undefined,
    accomodation: Accomodation,
    visible: boolean,
    onSave: () => void,
    onClose: () => void
}) {

    const {
        data
    } = useQuery(['accomodationRate', accomodationRateId], () => getAccomodationRate(accomodationRateId), {
        retry: false,
        enabled: (accomodationRateId !== undefined)
    })

    const [agentRate, setAgentRate] = useState<number>(0)
    const [hotelRate, setHotelRate] = useState<number>(0)
    const [insuranceRate, setInsuranceRate] = useState<number>(0)
    const [insuranceFixedRate, setInsuranceFixedRate] = useState<number>(0)
    const [internalRate, setInternalRate] = useState<number>(0)
    const [internalFixedRate, setInternalFixedRate] = useState<number>(0)
    const [subAgentRate, setSubAgentRate] = useState<number>(0)
    const [fixedCost, setFixedCost] = useState(false)
    const [perNight, setPerNight] = useState(false)
    const [perPerson, setPerPerson] = useState(false)
    const [connectedRates, setConnectedRates] = useState<any>([])

    const mutation = useMutation(() => saveAccomodationRate({
        idAccomodationsRate: accomodationRateId || undefined,
        agentRate,
        hotelRate,
        insuranceRate,
        internalRate,
        subAgentRate,
        accomodation,
        connectedRates: connectedRates ? connectedRates.map((c: any) => c.value).join(',') : '',
        perPerson,
        perNight,
        fixedCost,
        internalFixedRate,
        insuranceFixedRate
    }), {
        onSuccess: () => {
            toast.success('Piano commissionale generato correttamente')
            onSave()
        }
    })

    const {
        data: policyRates
    } = useQuery('policyRates', () => getAccomodationPolicyRates(accomodation.idAccomodation ? accomodation.idAccomodation : 0), {
        // onSuccess: data => setRateMap(data)
    })

    useEffect(() => {
        if (data) {
            setAgentRate(data.agentRate)
            setHotelRate(data.hotelRate)
            setInsuranceRate(data.insuranceRate)
            setInternalRate(data.internalRate)
            setSubAgentRate(data.subAgentRate)
            setFixedCost(data.fixedCost)
            setPerPerson(data.perPerson)
            setPerNight(data.perNight)
            setInsuranceFixedRate(data.insuranceFixedRate)
            setInternalFixedRate(data.internalFixedRate)
            if (data.connectedRates !== undefined && data.connectedRates !== null && data.connectedRates !== '') {
                setConnectedRates(data.connectedRates?.split(',').map(v => {
                    return { label: v, value: v }
                }))
            }
        }
    }, [ data ])

    return (
        <Modal
            visible={visible}>
            <ModalCard
                className={'w-full max-w-lg'}
                renderActions={() => (
                    <ModalActions
                        onClose={onClose}
                        onSave={() => mutation.mutate()}></ModalActions>
                )}
                title={'Piano commissionale'}>

                <div className="p-4 flex flex-col space-y-4">
                    <FormField label={'Commissione assicurazione'}>
                        <TextInput
                            value={insuranceRate}
                            onChange={val => setInsuranceRate(val)}
                            suffix={'%'}
                        />
                    </FormField>

                    <FormField label={'Commissione BeSafe'}>
                        <TextInput
                            value={internalRate}
                            onChange={val => setInternalRate(val)}
                            suffix={'%'}
                        />
                    </FormField>

                    <FormField label={'Commissione Agente'}>
                        <TextInput
                            value={agentRate}
                            onChange={val => setAgentRate(val)}
                            suffix={'%'}
                        />
                    </FormField>

                    <FormField label={'Commissione Sub Agente'}>
                        <TextInput
                            value={subAgentRate}
                            onChange={val => setSubAgentRate(val)}
                            suffix={'%'}
                        />
                    </FormField>

                    <FormField label={'Commissione Hotel'}>
                        <TextInput
                            value={hotelRate}
                            onChange={val => setHotelRate(val)}
                            suffix={'%'}
                        />
                    </FormField>

                    <FormField tooltip={'Se vuoto valido per tutte le tariffe'} label={'Tariffe collegate'}>
                        {
                            policyRates &&
                                <SelectInput
                                value={connectedRates}
                                isMulti={true}
                                onChange={(values: any) => {
                                    if (values) setConnectedRates(values)
                                }}
                                options={Object.keys(policyRates).map(pr => {
                                    return { label: pr, value: pr }
                                })}
                                />
                        }
                    </FormField>

                    <Checkbox label="Prezzo fisso" checked={fixedCost} onChange={() => setFixedCost(!fixedCost)} />

                    {
                        fixedCost &&
                        <>
                            <div className="text-gray-600">
                                Questi prezzi verranno applicati alle eventuali polizze a costo fisso connesse alle tariffe
                                relative a questo piano commissionale
                            </div>
                            <FormField label={'Commissione Fissa Asscurazione'}>
                                <TextInput
                                    value={insuranceFixedRate}
                                    onChange={val => setInsuranceFixedRate(val)}
                                    suffix={'EUR'}
                                />
                            </FormField>
                            <FormField label={'Commissione Fissa BeSafe'}>
                                <TextInput
                                    value={internalFixedRate}
                                    onChange={val => setInternalFixedRate(val)}
                                    suffix={'EUR'}
                                />
                            </FormField>
                    </>
                    }

                    <div className="font-medium text-gray-700">Modificatori prezzo fisso</div>

                    <Checkbox label="Per persona" checked={perPerson} onChange={() => setPerPerson(!perPerson)} />
                    <Checkbox label="Per notte" checked={perNight} onChange={() => setPerNight(!perNight)} />
                </div>
            </ModalCard>
        </Modal>
    )

}
