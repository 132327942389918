import PageTitle from "../components/common/PageTitle";
import { PageWrapper } from "../components/common/PageWrapper";
import Button from "../components/form/Button";
import { Fragment, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import {
    deleteCompany,
    getAddress,
    getCompanyById,
    getCompanyProducts,
    saveAddress,
    saveCompany, updateCompanyData
} from "../shared/queries";
import FormSectionTitle from "../components/form/FormSectionTitle";
import DetailRow from "../components/data/DetailRow";
import DeleteModal from "../components/common/DeleteModal";
import toast from 'react-hot-toast'
import CreateCompany from "../components/business/companies/CreateCompany";
import BaseTable from "../components/data/BaseTable";
import {Address, Company, CompanyProduct} from "../types";
import dayjs from "dayjs";
import Chip from "../components/common/Chip";
import SectionCard from "../components/SectionCard";
import CreateAddress from "../components/business/address/CreateAddress";

export default function CompanyPage () {

    const history = useHistory()
    const { id } = useParams<{ id: string }>()

    const [showDelete, setShowDelete] = useState<boolean>(false)
    const [addressId, setAddressId] = useState<number | undefined>(undefined)
    const [updateCompany, setUpdateCompany] = useState<boolean>(false)
    const [updateAddress, setUpdateAddress] = useState<boolean>(false)

    const {
        data,
        refetch
    } = useQuery(['company', id], () => getCompanyById(id), {
        onSuccess: data => {
            if (data.address) {
                setAddressId(data.address.idAddress)
            }
        }
    })

    const {
        data: addressData,
        refetch: refetchAddress
    } = useQuery([addressId], () => getAddress(addressId), {
        enabled: (addressId !== undefined)
    })

    const {
        data: products,
    } = useQuery(['companyProducts', id], () => getCompanyProducts(id))

    const updateAddressMutation = useMutation((data: any) => saveAddress(data), {
        onSuccess: () => {
            toast.success('Indirizzo aggiornato')
        }
    })

    const deleteMutation = useMutation(() => deleteCompany(id), {
        onSuccess: () => {
            history.push('/app/companies')
            toast.success('Azienda eliminata')
        },
        onError: () => {
            toast.error('Impossibile eliminare l\'azienda')
        }
    })

    const updateCompanyMutation = useMutation((data: { company: Company, address: Address }) => updateCompanyData({
        ...data.company,
        address: data.address
    }), {
        onSuccess: () => {
            refetch()
        }
    })

    return (
        <PageWrapper centered maxWidth={'1000px'}>

            <DeleteModal
                askConfirmation={true}
                message={'Sei sicuro di voler cancellare l\'azienda? L\'operazione non è reversibile.'}
                visible={showDelete}
                onCancel={() => {
                    setShowDelete(false)
                }}
                onConfirm={() => {
                    deleteMutation.mutate()
                }} />

            {
                data &&
                <Fragment>
                    <PageTitle
                        title={`${data.companyName}`}
                        renderActions={() => (
                            <div className={'flex space-x-2 items-center'}>
                                <Button
                                    className="text-white bg-red-600"
                                    label={'Elimina'}
                                    onClick={() => {
                                        setShowDelete(true)
                                    }} />
                            </div>
                        )} />


                    <SectionCard
                      title={'Dettagli'}
                      onEdit={() => {
                          setUpdateCompany(true)
                      }}>
                        <div className="flex flex-col space-y-4">
                            <DetailRow
                              label={'Ragione sociale'}>
                                {data.companyName}
                            </DetailRow>

                            <DetailRow
                              label={'Email'}>
                                {data.companyEmail}
                            </DetailRow>
                        </div>
                    </SectionCard>

                    <SectionCard
                      className={'mt-6'}
                      onEdit={() => {
                          setUpdateCompany(true)
                      }}
                      title={'Informazioni fiscali'}>
                        <div className="flex flex-col space-y-4">
                            <DetailRow
                              label={'VAT'}>
                                {data.companyVat}
                            </DetailRow>

                            <DetailRow
                              label={'Codice fiscale'}>
                                {data.companyFiscalCode}
                            </DetailRow>

                            <DetailRow
                              label={'SDI Code'}>
                                {data.companySdi}
                            </DetailRow>

                            <DetailRow
                              label={'Pec'}>
                                {data.companyPec || 'ND'}
                            </DetailRow>
                        </div>
                    </SectionCard>

                    <SectionCard
                      className={'mt-6'}
                      onEdit={() => {
                          setUpdateAddress(true)
                      }}
                      title={'Indirizzo fatturazione'}>
                        {
                            addressData &&
                              <div className="flex flex-col space-y-4">
                                  <DetailRow
                                    label={'Indirizzo'}>
                                      {addressData.addressStreet}
                                  </DetailRow>

                                  <DetailRow
                                    label={'Città'}>
                                      {addressData.addressCity}
                                  </DetailRow>

                                  <DetailRow
                                    label={'Cap'}>
                                      {addressData.addressZipCode}
                                  </DetailRow>

                                  <DetailRow
                                    label={'Provincia'}>
                                      {addressData.addressProvince}
                                  </DetailRow>

                                  <DetailRow
                                    label={'Paese'}>
                                      {addressData.country?.countryName}
                                  </DetailRow>
                              </div>
                        }
                    </SectionCard>

                    <CreateCompany
                        visible={updateCompany}
                        idCompany={data.idCompany}
                        onSave={() => {
                            setUpdateCompany(false)
                            refetch()
                        }}
                        onClose={() => setUpdateCompany(false)} />

                    <CreateAddress
                        idAddress={addressData?.idAddress}
                        visible={updateAddress}
                        onClose={() => {
                            setUpdateAddress(false)
                        }}
                        onSave={a => {
                            updateCompanyMutation.mutate({
                                company: data,
                                address: a
                            })
                            setUpdateAddress(false)
                        }}
                    />

                    <FormSectionTitle
                        borderless
                        dense
                        title={'Prodotti'} />

                    <BaseTable
                        showFilters={false}
                        showColumnSelector={false}
                        data={products}
                        filterable={false}
                        paginated={false}
                        identifierKey={'idCompanyProducts'}
                        columns={[
                            {
                                Header: 'Prodotto',
                                accessor: (row: CompanyProduct) => {
                                    return row.product.productName
                                }
                            },
                            {
                                Header: 'Data attivazione',
                                accessor: (row: CompanyProduct) => {
                                    return dayjs(row.activationDate.toString(), 'YYYYMMDD').format('DD-MM-YYYY')
                                }
                            },
                            {
                                Header: 'Stato',
                                accessor: (row: CompanyProduct) => {
                                    return !row.deactivationDate ?
                                    <Chip className={'text-green-600 bg-green-100'}>
                                        Attivo
                                    </Chip>
                                    :
                                    <Chip className={'text-orange-600 bg-orange-100'}>
                                        Disattivo
                                    </Chip>
                                }
                            }
                        ]} />

                </Fragment>
            }

        </PageWrapper>
    )

}
