import { useTranslation } from "react-i18next";
import Modal from "../common/Modal";
import ModalActions from "../common/ModalActions";
import ModalCard from "../common/ModalCard";
import DatePickerInput from "../form/DatePickerInput";
import FormField from "../form/FormField";
import { QuoteOption } from "../../types";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import RoomListModal from "./RoomListModal";
import { useEffect, useState } from "react";
import { addOptionRoom, fetchRooms, updateOption } from "../../store/slices/offerSlice";
import QuoteOptionRoom from "./QuoteOptionRoom"
import {motion} from 'framer-motion'
import dayjs from "dayjs";
import { classNames } from "../../shared/utils";
import TextInput from "../form/TextInput";
import toast from "react-hot-toast";
import quoteOptionSchema from "../../validation/quoteOptionSchema";
import { ValidationError } from "joi";
import QuoteServices from "./QuoteServices";

export default function QuoteOptionModal ({
  option,
  visible,
  onClose
} : {
  option?: QuoteOption,
  visible: boolean,
  onClose: () => void
}) {

  const dispatch = useAppDispatch()

  const { t } = useTranslation()
  const { currentHotel } = useAppSelector(state => state.hotels)

  const [selectRoom, setSelectRoom] = useState(false)
  const [errors, setErrors] = useState<ValidationError | undefined>()

  useEffect(() => {
    if (currentHotel) {
      dispatch(fetchRooms({ idAccomodation: currentHotel }))
    }
  }, [ currentHotel ])

  if (!option) return <></>

  function handleValidation() {
    const { error, value, warning } = quoteOptionSchema.validate(option, {
      abortEarly: false
    })

    setErrors(error)

    if (error && error.details.length > 0) {
      console.log(error)
      toast.error('Alcune delle camere configurate contengono campi non validi, correggi e riprova')
      return false
    }

    return true
  }

  return (
    <>
      <RoomListModal
        visible={selectRoom}
        onSelect={id => {
          dispatch(addOptionRoom({
            optionId: option.idQuoteOption,
            roomId: id
          }))
          setSelectRoom(false)
        }}
        onUpdate={() => dispatch(fetchRooms({ idAccomodation: currentHotel }))}
        onClose={() => setSelectRoom(false)}
      />

      <Modal visible={visible}>
        <ModalCard
          renderActions={() => (
            <ModalActions
              isLoading={false}
              onSave={() => {
                if (handleValidation()) {
                  onClose()
                }
              }}
              onClose={onClose}
            />
          )}
          className={'w-full max-w-2xl'} title={t('quote.manageOption')}>

          <div className="border-b flex">
            <div className="h-full w-full flex-1 border-r">
              <button onClick={() => {
                dispatch(updateOption({
                  ...option,
                  optionType: 'ROOM'
                }))
              }} className={
                classNames(
                  'w-full p-4 font-medium text-gray-600',
                  (option.optionType === 'ROOM') ? 'border-b-2 border-orange-600 bg-orange-100' : ''
                )
              }>{t('quote.offerWithRooms')}</button>
            </div>
            <div className="h-full w-full flex-1">
              <button onClick={() => {
                dispatch(updateOption({
                  ...option,
                  optionType: 'DESTINATION'
                }))
              }} className={
                classNames(
                  'w-full p-4 font-medium text-gray-600',
                  (option.optionType === 'DESTINATION') ? 'border-b-2 border-orange-600 bg-orange-100' : ''
                )
              }>{t('quote.offerWithDestination')}</button>
            </div>
          </div>

          <div className="p-4 flex flex-col space-y-4">
            <div>
              <FormField
                className="flex-1"
                label={t('quote.offerTitle')}>
                  <TextInput
                    value={option.title}
                    onChange={val => {
                      dispatch(updateOption({
                        ...option,
                        title: val
                      }))
                    }}
                  />
              </FormField>
              <div className="mt-2 text-slate-500">
                {t('quote.titleInfo')}
              </div>
            </div>
            <div className="flex space-x-4">
              <FormField
                className="flex-1"
                label={t('general.arrival')}>
                  <DatePickerInput
                    mode={'single'}
                    fromDate={new Date()}
                    value={dayjs(option.arrival.toString(), 'YYYYMMDD').toDate()}
                    onChange={(val) => {
                        if (val) {
                          dispatch(updateOption({
                            ...option,
                            arrival: parseInt(dayjs(val).format('YYYYMMDD')),
                            departure: parseInt(dayjs(val).add(1, 'day').format('YYYYMMDD'))
                          }))
                        }
                    }} />
              </FormField>

              <FormField
                className="flex-1"
                label={t('general.departure')}>
                <DatePickerInput
                  mode={'single'}
                  fromDate={option.arrival ? dayjs(option.arrival.toString(), 'YYYYMMDD').add(1, 'days').toDate() : undefined}
                  value={dayjs(option.departure.toString(), 'YYYYMMDD').toDate()}
                  onChange={(val) => {
                    if (val) {
                      dispatch(updateOption({
                        ...option,
                        departure: parseInt(dayjs(val).format('YYYYMMDD'))
                      }))
                    }
                }}  />
              </FormField>
            </div>

            {
              (option.optionType === 'DESTINATION') &&
              <div className="flex space-x-4 pb-4">
                <FormField className={'w-full'} label={t('quote.destination')}>
                  <TextInput
                    className={'w-full'}
                    value={option.destination}
                    onChange={(val: any) => {
                      dispatch(updateOption({
                        ...option,
                        destination: val
                      }))
                    }}
                  />
                </FormField>

                <FormField className={'w-full'} label={t('quote.totalStay')}>
                  <TextInput
                    numbered={true}
                    className={'w-full'}
                    value={option.destinationTotal}
                    onChange={(val: any) => {
                      dispatch(updateOption({
                        ...option,
                        destinationTotal: val
                      }))
                    }}
                  />
                </FormField>
              </div>
            }

            {
              (option.optionType === 'ROOM') &&
              <>
                <div className="font-bold text-lg text-gray-700 pt-4 pb-4">
                  {t('quote.optionRooms')}
                </div>
                {
                  (option.rooms.filter(r => !r.toDelete).length === 0) && <div className="flex flex-col space-y-2 items-center text-gray-600 pb-8">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                      <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                    </svg>

                    <button className="inline-flex items-center gap-x-1.5 rounded-md bg-white  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 px-2.5 py-1.5" onClick={() => setSelectRoom(true)}>
                      {t('quote.addAnotherRoom')}
                    </button>
                  </div>
                }

                {
                  option.rooms.filter(r => !r.toDelete).map((room, roomIndex) => <motion.div initial={{ left: -200 }} animate={{ left: 0 }} className="relative">
                    <QuoteOptionRoom option={option} room={room} key={`option-room-${roomIndex}`} />
                    <div className="border-b pt-2"></div>
                  </motion.div>
                  )
                }

                {
                  option.rooms.length > 0 &&
                  <div className="pt-6">
                    <button className="inline-flex items-center gap-x-1.5 rounded-md bg-white  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 px-2.5 py-1.5" onClick={() => setSelectRoom(true)}>
                      {t('quote.addAnotherRoom')}
                    </button>
                  </div>
                }
              </>
            }
          </div>
          <div className="p-4 border-t bg-slate-100">
            <div className="font-bold text-lg text-gray-700">
              {t('quote.optionServices')}
            </div>
            <QuoteServices option={option} />
          </div>

        </ModalCard>
      </Modal>
    </>
  )
}