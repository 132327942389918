import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useMutation } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { changePassword } from "../shared/queries"
import { fetchSessionProfile } from "../store/slices/sessionSlice"
import EntityUpdateDialog from "./common/EntityUpdateDialog"
import { useTranslation } from "react-i18next"

export default function ChangePasswordDialog ({
  title = 'Change password',
  isCancalable = true,
  subTitle,
  visible = false,
  onSave
} : {
  title?: string,
  isCancalable?: boolean,
  subTitle?: string
  visible: boolean,
  onSave: () => void
}) {
  
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [checkPassword, setCheckPassword] = useState('')
  const [passwordMissingParts, setPasswordMissingParts] = useState<string[]>([])

  const changePasswordMutation = useMutation((password: string) => changePassword(password), {
    onSuccess: () => {
      toast.success('Password changed')
      dispatch(fetchSessionProfile())
      onSave()
    },
    onError: () => {
      toast.error('Can not change password')
    }
  })

  function checkPasswordValidity (password: string) : string[] {
    const missing = []
    // must be at least 8 characters long
    if (password.length < 8) missing.push('LENGTH')
    // must have at least 1 uppercase letter and 1 special character
    if (!/[A-Z]/.test(password)) missing.push('UPPERCASE')
    if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(password)) missing.push('SPECIAL')
    setPasswordMissingParts(missing)
    return missing
  }

  useEffect(() => {
    if (!checkPassword) return
    checkPasswordValidity(checkPassword)
  }, [ checkPassword ])

  return (
    <EntityUpdateDialog
      fields={[
        {
          label: 'Password',
          inputType: 'password',
          value: '',
          field: 'password'
        },
        {
          label: 'Confirm password',
          inputType: 'password',
          value: '',
          field: 'confirmPassword'
        }
      ]}
      visible={visible}
      loading={changePasswordMutation.isLoading}
      customRender={() => (
        <div className="mt-2">
          {
              (passwordMissingParts.length > 0) &&
              <div className={'text-sm font-medium text-red-600'}>
                  {
                  (passwordMissingParts.indexOf('LENGTH') > -1) &&
                    <div>{t('signup.minCharacters')}</div>
                  }
                  {
                  (passwordMissingParts.indexOf('SPECIAL') > -1) &&
                    <div>{t('signup.minSpecial')}</div>
                  }
                  {
                  (passwordMissingParts.indexOf('LOWERCASE') > -1) &&
                    <div>{t('signup.lowercase')}</div>
                  }
                  {
                  (passwordMissingParts.indexOf('UPPERCASE') > -1) &&
                  <div>
                      {t('signup.upperCase')}
                  </div>
                  }
              </div>
          }
        </div>
      )}
      onDataChange={data => {
        if (data && data.password) setCheckPassword(data.password)
      }}
      onSave={(data: {
        password: string,
        confirmPassword: string
      }) => {
        if (data.password !== data.confirmPassword) {
          toast.error('Le password non corrispondono')
          return
        }
        if (checkPasswordValidity(data.password).length > 0) {
          toast.error('La password deve essere lunga almeno 8 caratteri, contenere almeno 1 lettera maiuscola e 1 carattere speciale')
          return
        }
        changePasswordMutation.mutate(data.password)
      }}
      title={title}
      subTitle={subTitle}
      data={{
        password: '',
        confirmPassword: ''
      }}
    />
  )

}