import { useEditor, EditorContent } from '@tiptap/react'
import { useHistory, useParams } from 'react-router'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Feedback, User } from '../shared/firebase/types'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import StarterKit from '@tiptap/starter-kit'
import Icon from '../components/common/Icon'
import UserAvatar from '../components/common/UserAvatar'
import firebase from '../shared/firebase/firebase'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import Link from '@tiptap/extension-link'

export default function FeatureVoteDetail () {

    const history = useHistory()

    const { id } = useParams<{ id: string }>()
    const { user} = useAuth0()
    const { profile } = useSelector((state: RootState) => state.session)

    const [feature, setFeature] = useState<Feedback>()

    const editor = useEditor({
        extensions: [
            StarterKit,
            Link
        ],
        editorProps: {
            attributes: {
                class: 'p-4',
            },
        },
        content: '<p></p>',
    })

    /**
     * load the question
     */
    useEffect(() => {
        if (id) {
            const unsubscribe = firebase.modules().feedback.listenDocument(id, doc => {
                if (doc.exists()) {
                    setFeature(doc.data())
                }
            })
            return () => unsubscribe()
        }
    }, [ id ])

    function handleUpvote () {
        if (!user || !user.sub || !feature) return
        if (!feature.votes.includes(user.sub)) {
            firebase.modules().feedback.updateOne(id, {
                ...feature,
                votes: [
                    ...feature.votes,
                    user.sub
                ]
            })
        } else {
            handleDownvote()
        }
    }
    function handleDownvote () {
        if (!user || !user.sub || !feature) return
        firebase.modules().feedback.updateOne(id, {
            ...feature,
            votes: feature.votes.filter(sub => sub !== user.sub)
        })
    }

    function getUserName (user: User) {
        if (user) {
            if (user.name.length > 3) {
                return user.name
            }
        }
        return 'user' + Math.floor(Math.random() * 10000)
    }

    function handlePostComment () {
        if (feature && editor && user && user.sub && profile) {
            firebase.modules().feedback.updateOne(id, {
                ...feature,
                comments: [
                    ...feature.comments,
                    {
                        body: editor.getHTML(),
                        date: dayjs().unix(),
                        user: {
                            avatar: user.picture,
                            email: profile.userEmail,
                            name: profile.userFirstName + ' ' + profile.userLastName,
                            isAdmin: profile.userAdmin,
                            sub: user.sub
                        },
                        votes: [],
                    }
                ]
            })
            editor.commands.clearContent()
            toast.success('Commento inviato')
        }
    }

    function handleAdminDelete () {
        if (window.confirm("Are you sure you want to delete this request?")) {
            firebase.modules().feedback.deleteOne(id)
            toast.success('Richiesta eliminata')
            history.push('/app/community')
        }
    }

    const setLink = useCallback(() => {
        if (editor) {
            const previousUrl = editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)
        
            // cancelled
            if (url === null) {
            return
            }
        
            // empty
            if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink()
                .run()
        
            return
            }
        
            // update link
            editor.chain().focus().extendMarkRange('link').setLink({ href: url })
            .run()
        }
      }, [editor])

    return (
        <div className={'px-4 flex space-y-8 w-full max-w-4xl mx-auto flex-col'}>
            <div className={'flex space-x-2 text-orange-600 items-center'}>
                <Icon name={'arrow-narrow-left'} size={'20px'} />
                <button
                    onClick={() => {
                        history.push('/app/community')
                    }}>
                    Torna alla lista
                </button>
            </div>
            
            {
                feature &&
                <Fragment>
                    <div className={'flex space-x-4 font-normal border-b pb-4'}>
                        <div><span className={'text-gray-600'}>Richiesta</span> {dayjs.unix(feature.date).from(dayjs().toDate())}</div>
                    </div>
                    {
                        (profile && profile.userAdmin) &&
                        <div className="bg-red-50 px-4 py-2 space-x-2 flex">
                            <div className={'text-red-600 font-normal'}>Admin management</div>
                            <div>
                                <button onClick={handleAdminDelete} className={'font-semibold text-red-600'}>Elimina</button>
                            </div>
                        </div>
                    }
                    <div className="flex space-x-8">
                        <div className={'flex space-y-4 flex-col text-gray-600'}>
                            <div className="flex items-center flex-col text-sm font-normal text-center">
                                <button
                                    onClick={handleUpvote}
                                    className={'text-gray-400'}>
                                    <Icon name={(user && user.sub && feature.votes.includes(user.sub)) ? 'thumb-up-filled' : 'thumb-up'} size={'28px'} />
                                </button>
                                <div className={'text-2xl'}>
                                    {feature?.votes.length}
                                </div>
                                voti
                            </div>
                        </div>
                        <div className={'flex-1'}>
                            <div className="mb-4 text-xl font-normal text-orange-600">
                                {feature?.title}
                            </div>

                            <div className="font-normal mb-4 post-wrapper" dangerouslySetInnerHTML={{
                                __html: feature ? feature?.body : ''
                            }}></div>

                            <div className="items-center justify-between flex">
                                <div>
                                    {
                                        (feature && feature.user && feature.user.sub && user && user.sub && feature.user.sub === user.sub) &&
                                        <div className="flex space-x-4 text-sm">
                                            <div>
                                                <button onClick={() => {
                                                    history.push('/app/community/edit/' + feature.id)
                                                }}>Modifica</button>
                                            </div>
                                            <div>
                                                <button onClick={handleAdminDelete} className={'text-red-600'}>Elimina</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="flex justify-end text-orange-700">
                                    <div></div>
                                    <div className="flex items-center space-x-2 px-4 py-2 bg-orange-100">
                                        <div className={'relative'}>
                                            <UserAvatar name={feature.user.email} />
                                            {
                                                feature.user.isAdmin &&
                                                <div style={{ borderWidth: '2px' }} className={'absolute -bottom-1 -right-1 bg-blue-500 text-white border-orange-100 rounded-full shadow'}>
                                                    <Icon name={'badge-check'} size={'16px'} />
                                                </div>
                                            }
                                        </div>
                                        <div className={'text-sm font-normal px-2 py-2'}>
                                            <div className="font-medium mb-1 flex items-center space-x-1">
                                                <div>{feature && getUserName(feature.user)}</div>
                                            </div>
                                            <div>
                                                {feature && feature.user.isAdmin ? 'Engineering Team' : 'Cliente BeSafe Rate'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'text-lg pb-4'}>
                        Commenti ({feature.comments.length})
                    </div>

                    {
                        (feature && feature.comments.length <= 0) &&
                        <div className={'font-normal text-gray-600'}>
                            Vuoi condividere il tuo pensiero su questa proposta? Aggiungi il tuo commento!
                        </div>
                    }

                    {
                        (feature) && feature.comments.map((comment, index) => (
                            <div key={index} className="flex space-x-8 border-b pb-6">
                                {
                                    false &&
                                    <div className={'flex space-y-4 flex-col text-gray-600'}>
                                        <div className="flex items-center flex-col text-sm font-normal text-center">
                                            <button className={'text-gray-400'}>
                                                <Icon name={'chevron-up'} size={'28px'} />
                                            </button>
                                            <div className={'text-2xl'}>
                                                {comment.votes.length}
                                            </div>
                                            <button className={'text-gray-400'}>
                                                <Icon name={'chevron-down'} size={'28px'} />
                                            </button>
                                            voti
                                        </div>
                                    </div>
                                }
                                <div className={'flex-1'}>
                                    <div className="comment-body font-normal mb-4" dangerouslySetInnerHTML={{
                                        __html: comment.body
                                    }}></div>
                                    <div className={`${(comment && comment.user.isAdmin) ? 'flex justify-end text-blue-700' : 'flex justify-end text-orange-700'}`}>
                                        <div className={`${(comment && comment.user.isAdmin) ? 'flex items-center space-x-2 px-4 py-2 bg-blue-100' : 'flex items-center space-x-2 px-4 py-2 bg-orange-100'}`}>
                                            <div className={'relative'}>
                                                <UserAvatar bg={comment.user.isAdmin ? 'bg-blue-800' : 'bg-orange-600'} name={comment.user.email} />
                                                {
                                                    comment.user.isAdmin &&
                                                    <div style={{ borderWidth: '2px' }} className={'absolute -bottom-1 -right-1 bg-blue-500 text-white border-orange-100 rounded-full shadow'}>
                                                        <Icon name={'badge-check'} size={'16px'} />
                                                    </div>
                                                }
                                            </div>
                                            <div className={'text-sm font-normal px-2 py-2'}>
                                                <div className="font-medium mb-1 flex items-center space-x-1">
                                                    <div>{getUserName(comment.user)}</div>
                                                </div>
                                                <div>
                                                    {comment.user.isAdmin ? 'Engineering team' : 'Cliente BeSafe Suite'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    
                    <div className={'text-lg'}>
                        Il tuo commento
                    </div>

                    <div className={'pb-6'}>
                        {
                            editor &&
                            <div className="border">
                                <div className={'flex space-x-2 border-b'}>
                                    <button
                                        onClick={() => editor.chain().focus().toggleBold().run()}
                                        className={editor.isActive('bold') ? 'p-2 is-active' : 'p-2'}>
                                        <Icon name={'bold'} size={'20px'} />
                                    </button>
                                    <button
                                        onClick={() => editor.chain().focus().toggleItalic().run()}
                                        className={editor.isActive('italic') ? 'p-2 is-active' : 'p-2'}>
                                        <Icon name={'italic'} size={'20px'} />
                                    </button>
                                    <button
                                        onClick={() => editor.chain().focus().toggleStrike().run()}
                                        className={editor.isActive('strike') ? 'p-2 is-active' : 'p-2'}>
                                        <Icon name={'strike'} size={'20px'} />
                                    </button>
                                    <button
                                        onClick={setLink}
                                        className={editor.isActive('link') ? 'p-2 is-active' : 'p-2'}>
                                        <Icon name={'link'} size={'20px'} />
                                    </button>
                                </div>
                                <div style={{
                                    minHeight: '200px'
                                }}>
                                    <EditorContent editor={editor} />
                                </div>
                            </div>
                        }
                        <div>
                            <button
                                onClick={() => handlePostComment()}
                                className={'mt-2 px-4 py-2 bg-orange-500 border shadow-md hover:bg-orange-600 active:bg-orange-700 border-orange-600 text-white rounded-md'}>
                                Posta una risposta
                            </button>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    )

}