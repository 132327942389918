import { useEffect, useState } from "react"
import SelectInput from "../form/SelectInput"
import TextInput from "../form/TextInput"
import dayjs from "dayjs"
import { useDispatch } from "react-redux"
import { setExpireTime } from "../../store/slices/offerSlice"
import { useAppSelector } from "../../hooks/redux"

const EXPIRE_UNIT_TIME = [
  { label: 'Minuti', value: 'minutes' },
  { label: 'Ore', value: 'hours' },
  { label: 'Giorni', value: 'days' }
]

export default function QuoteExpireSettings () {

  const dispatch = useDispatch()

  const { quote } = useAppSelector(state => state.offerSlice)

  const [unitTime, setUnitTime] = useState<'minutes' | 'hours' | 'days'>('hours')
  const [unitValue, setUnitValue] = useState(12)

  useEffect(() => {
    const expireDate = parseInt(dayjs().add(unitValue, unitTime).format('YYYYMMDDHHmm'))
    dispatch(setExpireTime(expireDate))
  }, [
    unitTime,
    unitValue
  ])

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex space-x-2">
        <div className="flex-1">
          <SelectInput
            onChange={(item: any) => {
              if (item) {
                setUnitTime(item.value)
              }
            }}
            value={EXPIRE_UNIT_TIME.find(i => i.value === unitTime)}
            options={EXPIRE_UNIT_TIME}
          />
        </div>
        <div className="flex-1">
          <TextInput
            value={unitValue}
            numbered={true}
            onChange={val => setUnitValue(parseInt(val))}
          />
        </div>
      </div>

      <div className="font-medium mb-2 text-gray-600 flex space-x-2 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-13a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 000-1.5h-3.25V5z" clipRule="evenodd" />
        </svg>
        <div>Il preventivo scade il {dayjs(quote.expiresAt?.toString(), 'YYYYMMDDHHmm').format('DD/MM/YY HH:mm')}</div>
      </div>
    </div>
  )

}