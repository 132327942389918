import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { saveAccomodation } from "../../../shared/queries"
import { Company, Address } from "../../../types"
import Modal from "../../common/Modal"
import ModalActions from "../../common/ModalActions"
import ModalCard from "../../common/ModalCard"
import FormField from "../../form/FormField"
import TextInput from "../../form/TextInput"
import AddressSelector from "../selectors/AddressSelector"
import CompanySelector from "../selectors/CompanySelector"

export default function CreateAccomodation ({
    visible,
    onClose,
    onSave
} : {
    visible: boolean,
    onClose: () => void,
    onSave: () => void
}) {

    const { t } = useTranslation()

    const [accomodationName, setAccomodationName] = useState<string>('')
    const [accomodationPhone, setAccomodationPhone] = useState<string>('3888108490')
    const [address, setAddress] = useState<Address>()
    const [company, setCompany] = useState<Company>()
    
    const saveMutation = useMutation(() => saveAccomodation({
        accomodationName,
        accomodationPhone,
        address,
        usePendingModel: false,
        company: company,
        isActive: true,
        isSuspended: false,
        policies: []
    }), {
        onSuccess: () => {
            onSave()
        }
    })

    return (
        <Fragment>
            <Modal
                visible={visible}>
                <ModalCard
                    className={'w-full'}
                    renderActions={() => (
                        <ModalActions
                            onClose={onClose}
                            onSave={() => saveMutation.mutate()}></ModalActions>
                    )}
                    title={t('addHotel.newHotel')}>

                    <div className="p-4 flex flex-col space-y-4">
                        <FormField label={t('addHotel.hotelName')}>
                            <TextInput
                                value={accomodationName}
                                onChange={val => setAccomodationName(val)} />
                        </FormField>

                        <FormField
                            label={t('addHotel.address')}>
                            <AddressSelector
                                onSelect={item => setAddress(item)} />
                        </FormField>

                        <div className="space-x-4">
                            <FormField
                                label={t('addHotel.company')}>
                                <CompanySelector
                                    onSelect={c => setCompany(c)} />
                            </FormField>
                        </div>
                    </div>
                </ModalCard>
            </Modal>
        </Fragment>
    )

}