import Icon from "../components/common/Icon";
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'
import UserAvatar from "../components/common/UserAvatar";
import { useHistory, useParams } from "react-router";
import { ChangeEventHandler, MutableRefObject, useEffect, useRef, useState } from "react";
import firebase from "../shared/firebase/firebase";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import TextInput from "../components/form/TextInput";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, ref } from "firebase/storage";
import Checkbox from "../components/form/Checkbox";

export default function FeatureVoteNew () {

    const { id } = useParams<{ id: string }>()

    const history = useHistory()
    const uploadRef = useRef<any>()

    const { user} = useAuth0()
    const { profile } = useSelector((state: RootState) => state.session)

    const [title, setTitle] = useState<string>('')
    const [gotIt, setGotIt] = useState(false)
    const [votes, setVotes] = useState<any>([])
    const [comments, setComments] = useState<any>([])
    const [bucketRef, setBucketRef] = useState(uuidv4())

    const editor = useEditor({
        extensions: [
            StarterKit,
            Image,
            Paragraph,
            Text,
            Dropcursor
        ],
        editorProps: {
            attributes: {
                class: 'p-4',
            },
        },
        content: '<p></p>',
    })

    useEffect(() => {
        if (id && editor) {
            loadFeature()
        }
    }, [ id, editor ])

    async function loadFeature () {
        const doc = await firebase.modules().feedback.getOne(id)
        if (doc.exists()) {
            const data = doc.data()
            setTitle(data.title)
            if (data.bucketRef) {
                setBucketRef(data.bucketRef)
            }
            setVotes(data.votes)
            setComments(data.comments)
            if (editor) {
                editor.commands.setContent(data.body)
            }
        }
    }

    function getShortUserName () {
        let name = ''
        if (profile) {
            if (profile.userFirstName) {
                name = profile.userFirstName
            }
            if (profile.userLastName && profile.userLastName.length > 0) {
                name += ' ' + profile.userLastName.charAt(0) + '.'
            }
        }
        return name
    }

    async function handleSave () {
        if (!editor || !profile || !user || !user.sub) return
        
        if (id) {
            await firebase.modules().feedback.updateOne(id, {
                body: editor.getHTML(),
                title: title,
                bucketRef,
                user: {
                    sub: user.sub,
                    isAdmin: profile.userAdmin,
                    avatar: user?.picture,
                    email: profile.userEmail,
                    name: getShortUserName()
                },
                date: dayjs().unix(),
                votes,
                comments
            })
            history.push('/app/community/' + id)
        } else {
            const document = await firebase.modules().feedback.createOne({
                body: editor.getHTML(),
                title: title,
                bucketRef,
                user: {
                    sub: user.sub,
                    isAdmin: profile.userAdmin,
                    avatar: user?.picture,
                    email: profile.userEmail,
                    name: getShortUserName()
                },
                date: dayjs().unix(),
                votes: [],
                comments: []
            })
            if (document && document.id) {
                history.push('/app/community/' + document.id)
            }
        }
    }

    async function handleUpload (event: any) {
        if (editor && uploadRef && uploadRef.current && uploadRef.current.files && uploadRef.current.files.length > 0) {
            const file = uploadRef.current.files[0]
            const fileRef = 'posts/' + bucketRef + '/' + file.name
            await firebase.upload(fileRef, file)
            const fileUrl = await firebase.getFileUrl(fileRef)
            editor.commands.setImage({
                src: fileUrl
            })
        }
    }

    return (
        <div className={'px-4 flex space-y-8 w-full max-w-4xl mx-auto flex-col'}>
            <div className={'flex space-x-2 text-orange-600 items-center'}>
                <Icon name={'arrow-narrow-left'} size={'20px'} />
                <button
                    onClick={() => {
                        history.push('/app/community')
                    }}>
                    Torna alla lista
                </button>
            </div>
            <div className="mb-2 text-2xl font-bold">
                Proponi la tua funzionalità
            </div>
            <div className={'pb-6'}>
                <TextInput
                    value={title}
                    className={'mb-4'}
                    placeholder={'Un titolo breve ma significativo'}
                    onChange={val => setTitle(val)}
                    size={'lg'} />

                {
                    editor &&
                    <div className="border">
                        <div className={'flex space-x-2 border-b'}>
                            <button
                                onClick={() => editor.chain().focus().toggleBold().run()}
                                className={editor.isActive('bold') ? 'p-2 is-active hover:bg-gray-100 bg-orange-100 text-orange-600' : 'p-2 hover:bg-gray-100'}>
                                <Icon name={'bold'} size={'20px'} />
                            </button>
                            <button
                                onClick={() => editor.chain().focus().toggleItalic().run()}
                                className={editor.isActive('italic') ? 'p-2 is-active hover:bg-gray-100 bg-orange-100 text-orange-600' : 'p-2 hover:bg-gray-100'}>
                                <Icon name={'italic'} size={'20px'} />
                            </button>
                            <button
                                onClick={() => editor.chain().focus().toggleStrike().run()}
                                className={editor.isActive('strike') ? 'p-2 is-active hover:bg-gray-100 bg-orange-100 text-orange-600' : 'p-2 hover:bg-gray-100'}>
                                <Icon name={'strike'} size={'20px'} />
                            </button>
                            <button
                                className={'p-2'}
                                onClick={() => {
                                    if (uploadRef && uploadRef.current) {
                                        uploadRef.current.click()
                                    }
                                }}>
                                <input type="file" hidden ref={uploadRef} onChange={handleUpload} />
                                <Icon name={'image'} size={'20px'} />
                            </button>
                        </div>
                        <div style={{
                            minHeight: '200px'
                        }}>
                            <EditorContent
                                editor={editor} />
                        </div>
                    </div>
                }

                <div className="flex space-x-8 mt-4">
                    <div>
                        <div className="bg-orange-50 border border-orange-200 text-orange-800 px-6 py-6 rounded-md">
                            <div className="font-semibold text-orange-800">👀 Attenzione</div>
                            <p className={'leading-relaxed text-sm mt-3 font-normal mb-4'}>
                                Questa sezione <strong>non è adatta a richieste di supporto</strong> o per segnalare malfunzionamenti. Le richieste sono inoltrate al team di sviluppo e non al team di assistenza.
                            </p>
                            <p className={'leading-relaxed text-sm mt-2 font-normal mb-4'}>
                                <strong>Non condividere</strong> informazioni riservate quali codici prenotazioni, nominativi, password o link in quanto tutte le informazioni saranno pubbliche e visibili a tutti i clienti.
                            </p>
                            <p className={'leading-relaxed text-sm mt-4 font-normal mb-4'}>
                                Siamo felici di poter ascoltare tutti voi, vi chiediamo gentilmente di attenervi alle semplici indicazioni sopra citate per rendere questa funzionalità efficace.
                            </p>

                            <Checkbox
                                onChange={() => setGotIt(!gotIt)}
                                checked={gotIt}
                                label={'Ho capito e accetto il regolamento'}></Checkbox>
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        disabled={!gotIt}
                        onClick={() => {
                            if (!gotIt) return
                            handleSave()
                        }}
                        className={`${!gotIt ? 'opacity-50 cursor-not-allowed' : ''} mt-2 px-4 py-2 bg-orange-500 border shadow-md hover:bg-orange-600 active:bg-orange-700 border-orange-600 text-white rounded-md`}>
                        { id ? 'Aggiorna la tua richiesta' : 'Pubblica la tua richiesta' }
                    </button>
                </div>
            </div>
        </div>
    )

}